import {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import {useFormContext} from 'react-hook-form'
import Select from 'react-select'
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls'
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions';

const ShowsTab = (props) => {
  const intl = useIntl();
  const [gridRef, setGridRef] = useState(false)
  const methods = useFormContext()
  const [rowData, setRowData] = useState([])
  const [selectedShow, setSelectedShow] = useState(null)
  const [addedShows, setAddedShows] = useState([])
  const alertDialog = useAlert()

  /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
  /* WA - Row Selector */

  const columnDefs = [
    { field: 'show_name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.SHOWNAME' })},
    { field: 'start_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.STARTDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
    { field: 'end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.ENDDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
  ]

  useEffect(() => {
    setRowData(methods.getValues('show'))
  }, [methods.getValues('show')])

  useEffect(() => {
    if(addedShows?.length > 0){
      methods.setValue('added_shows', addedShows, {shouldDirty: true})
    }
  }, [addedShows])

  const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

  const addRow = (show) => {
    if (show && show.value !== '') {
      let label = show?.label
      let showName = show?.label
      if (label) {
        let lastIndex = label?.lastIndexOf(' (');
        if (lastIndex !== -1) {
            showName = label?.substring(0, lastIndex);
        }
      }

      const newRow = {
        show_id: show.value,
        show_name: showName,
        start_date: show.start_date,
        end_date: show.end_date
      }

      // Check if row with same show_id already exists
      const existingRow = rowData.find((row) => row.show_id === show.value)
      if (existingRow) {
        alertDialog({message: `The selected show (${show.label}) has already been added.`, title: 'Alert'})
        return
      }

      // Add the new row to the addedShows array
      setAddedShows([...addedShows, newRow])
      setRowData([...rowData, newRow])
    }
  }

  return (
    <>
        <div className='row mb-2'>
          <div className='col-lg-5'>
            {props.showsInSeriesRange && (
              <Select
                options={props.showsInSeriesRange}
                id='showsNotInSeries'
                isSearchable={true}
                onChange={(selectedShow) => {
                  setSelectedShow(selectedShow)
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                styles={reactSelectStyles}
                placeholder='Select'
              />
            )}
          </div>
          <button
            className='btn btn-sm btn-secondary fw-bold text-uppercase h-120 py-1 d-flex align-items-center mx-1'
            style={{width: 'fit-content'}}
            type='button'
            onClick={() => {
              addRow(selectedShow)
            }}
            disabled={!selectedShow}
            data-tooltip-id="SHOWSERIES.DETAIL.TAB.SHOWS.BUTTON.ADDTOSERIES"
          >
            {intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.TAB.SHOW.LABEL.BUTTON.ADDTOSERIES' })}
          </button>
        </div>
        <div className='row mb-2'>
            <div className='col-lg d-flex align-items-center mb-2'>
              <div className='form-group mb-2 w-100'> {/* WA - Row Selector */}
                <div className="d-flex justify-content-end align-items-center">
                  <div className='me-6'>{rowData !== undefined ? rowData.length : ""}</div>
                  <div>
                    <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Show Series - Detail"}/>
                  </div>
                </div>
                  <OutputListingGridStatic area ={"Show Series - Detail"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} onRowDoubleClicked={(row_data) => {props.getDetailPageInfo(row_data.show_id, methods.getValues('show_series'), "Shows", "ShowDetail", row_data)}} visibleRows={visibleRows}></OutputListingGridStatic>
                </div> {/* WA - Row Selector */}
            </div>
        </div>
    </>
    )
  }
  
export {ShowsTab}