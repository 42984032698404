import { useEffect, useMemo, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import { useFormContext } from 'react-hook-form';
import  { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { AddFeeForm } from './Accounts/AddFeeForm';
import { AddPaymentForm } from './Accounts/AddPaymentForm';
import { TransferCreditForm } from './Accounts/TransferCreditForm';
import { RollForwardForm } from './Accounts/RollForwardForm';
import { PaymentDetailForm } from './Accounts/PaymentDetailForm';
import { FeeDetailForm } from './Accounts/FeeDetailForm';
import { SearchInput } from './SearchInput';
import { PersonLookupForm } from './General/PersonLookupForm';
import { DeleteFeeForm } from './Accounts/DeleteFeeForm';
import { useAlert, useConfirm, useFlashAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { ChangeCreditCardForm } from './Accounts/ChangeCreditCardForm';
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { BooleanFilterOptions } from '../../../modules/output-listing/filters/BooleanFilter';


import { SignatureAuthRequestForm } from './General/SignatureAuthRequestForm';
import { PaperEntryConfirmationForm } from './General/PaperEntryConfirmationForm';

// Assets
import "flatpickr/dist/themes/material_blue.css";

// Constants
import { date_options } from '../../../modules/sgl-utils/fieldControls';
import DecimalPointRenderer from '../../../modules/output-listing/renderers/DecimalPointRenderer';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { PmrRpChangeDialog } from './Classes/PmrRpChangeDialog';
import { RolledbackPeoplePaymentsDetail } from './Classes/RolledbackPeoplePaymentsDetail';
import { addFloatingNumbers } from '../../../modules/sgl-utils/SglFunctions';
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AccountTab = (props) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const methods = useFormContext()
    const selectRef = useRef();
    const flashAlert = useFlashAlert()
    const loadingOverlay = useLoadingOverlay()
    const [entryFeeGridRef, setEntryFeeRef] = useState()
    const [paymentxEntriesGridRef, setPaymentxEntriesGridRef] = useState()
    const { hasSpecialPermissionToAccess } = useAccessChecker()
    const currentShowStartDate = useAppSelector(state => state.currentShow.start_date)

    const [onCreditHold, setOnCreditHold] = useState()
    const responsiblePartyId = methods.watch("entry.responsibleparty_id")
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const show_financials_locked = methods.watch('show.islocked')
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [showChangeCCForm, setShowChangeCCForm] = useState(false);
    const secured_payment_method_id = methods.watch('entry.secured_payment_method_id')
    const [rpEntryAuthorizationRequest, setRpEntryAuthorizationRequest] = useState({});
    const [showSignAuthRequestForm, setShowSignAuthRequestForm] = useState(false)
    const [showManualSignatureForm, setShowManualSignatureForm] = useState(false)
    const [rollableEntries, setRollableEntries] = useState([])
    const [rollableEntriesColumnToBeDisplayed, setRollableEntriesColumnToBeDisplayed] = useState([])
    
    /* WA - Row Selector */
    const [visibleRowsOne, setVisibleRowsOne] = useState(DEFAULT_VISIBLE_ROWS)
    const [visibleRowsTwo, setVisibleRowsTwo] = useState(DEFAULT_VISIBLE_ROWS)
    const [rowDataOne, setRowDataOne] = useState(methods.getValues("entryFeesData.entryFees")?.filter(entryFee => !entryFee.isDeleted) ?? [])
    const [rowDataTwo, setRowDataTwo] = useState(methods.watch("paymentxEntries") ?? [])
    /* WA - Row Selector */

    const [oldRpId, setOldRpId] = useState(0);
    const [oldRpSource, setOldRpSource] = useState('');
    const [displayBalanceRefundRollbackDialog, setDisplayBalanceRefundRollbackDialog] = useState(false); // display dialog that show which entries balance will be changed with balance refund rollback
    const [rolledbackBalanceRefundDetail, setRolledbackBalanceRefundDetails] = useState([])
    const [displayRpChangeConfirmDialog, setDisplayRpChangeConfirmDialog] = useState(false); // display confirm dialog before rp change

    const [person_search_status, setPersonSearchStatus] = useState('');
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [people_search_list, setPeopleSearchList] = useState([]);
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })

    useEffect(() => {
        if (entryFeeGridRef && methods.getValues("entryFeesData.entryFees")) {
            setRowDataOne(methods.getValues("entryFeesData.entryFees")) // WA - Row Selector
            entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
        }
    }, [methods.getValues("entryFeesData.entryFees")])


    useEffect(() => {
        if (paymentxEntriesGridRef && methods.getValues("paymentxEntries")) {
            setRowDataTwo(methods.getValues("paymentxEntries")) // WA - Row Selector
            paymentxEntriesGridRef.setRowData(methods.getValues("paymentxEntries"))
        }
    }, [methods.getValues("paymentxEntries")])

    useEffect(() => {
        // Get entry missing stall status
        props.checkMissingEntryStall(methods.getValues('entry'), methods.getValues("entryFeesData"))
        .then(res => 
            {
                methods.setValue("checkMissingEntryStall", res); 
                let dropInputRef = document.getElementById('ENTERDROPS');
                if (dropInputRef && methods.getValues('dropInputRef')) {
                    setTimeout(() => {
                        dropInputRef.focus();
                    }, 400);
                }
            })
    }, [methods.watch("entry.trainer_id"), methods.watch("entryFeesData.entryFees") ])

    useEffect(() => {
        // set responsible party id and name on load
        if(methods.getValues('entry.responsiblepartysource')){
            if (methods.getValues('entry.responsiblepartysource') == '5') {
                selectRef.current.value = methods.getValues("entry.responsiblepartysource")
            } else if(methods.getValues('entry.responsiblepartysource') == '2'){
                selectRef.current.value = methods.getValues("entry.responsiblepartysource")+ '_' + methods.getValues("entry.responsibleparty_id")
            }else if(parseInt(methods.getValues('entry.responsiblepartysource')) > 4){
                selectRef.current.value = '2_'+ methods.getValues('entry.responsibleparty_id')
            }else{
                selectRef.current.value = methods.getValues("entry.responsiblepartysource")
            }
        }
        console.log('responsible_party_search before: ', methods.getValues('responsible_party_search'))

        let key = `linked_people[${methods.getValues("entry.responsibleparty_id")}].people_data.fl_name`
        console.log('responsible_party_search after: ', methods.getValues(key))

        methods.setValue('entry.responsibleparty', methods.getValues(key))
        methods.setValue('responsible_party_search', methods.getValues(key), {shouldDirty: true})

        // set on credit hold label to responsible party's on credit hold
        key = `linked_people[${methods.getValues('entry.responsibleparty_id')}].people_data.on_credit_hold`
        setOnCreditHold(methods.getValues(key))
        setSearchDisabled(selectRef.current.value != "4")

    }, [methods.watch("entry.responsibleparty_id"), methods.watch("entry.responsiblepartysource")])

    const onResponsiblePartyChange = (e) => {
        // set responsible party to the value of selected option (id of that people)
        methods.setValue("entry.responsiblepartysource", e.target.value, {shouldDirty: true})

        switch(e.target.value){
            case '1': // RP is Owner
                methods.setValue("entry.responsibleparty_id", methods.getValues('entry.entryowner_id'), {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${methods.getValues('entry.entryowner_id')}].people_data.fl_name`), {shouldDirty: true})
                break

            case '3': // RP is Trainer
                methods.setValue("entry.responsibleparty_id", methods.getValues('entry.trainer_id'), {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}].people_data.fl_name`), {shouldDirty: true})
                break

            case '5': // RP is PMR
                methods.setValue("entry.responsibleparty_id", methods.getValues('entry.prizemoneyrecipient_id'), {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}].people_data.fl_name`), {shouldDirty: true})
                break
                
            case '0': // RP is None
            case '4': // RP is Other
                methods.setValue("entry.responsibleparty_id", 0, {shouldDirty:true})
                methods.setValue("entry.responsibleparty", '', {shouldDirty: true})
                break
            
            default:
                let rider_id = e.target.value.split('_')[1]
                methods.setValue("entry.responsibleparty_id", rider_id, {shouldDirty: true})
                methods.setValue("entry.responsibleparty", methods.getValues(`linked_people[${rider_id}].people_data.fl_name`), {shouldDirty: true})
                methods.setValue("entry.responsiblepartysource",  e.target.value.split('_')[0], {shouldDirty: true})
                break
        }
    }

    const responsiblePartySelect = () => {
        if (methods.getValues("entry")) {
            const entryRiders = methods.watch("entry.EntryRiders")

            let entryRidersOptions;
            // let entryRiderOptions2;
            if (entryRiders) {
                let riderNumber = 0
                entryRidersOptions = entryRiders.map(
                    (er, idx) => {
                        // If rider is deleted don't populate in rp dropdown
                        if(!er.isDeleted){
                            riderNumber++
                            return <option key={riderNumber} value={'2_'+ er.rider_id}>Rider {riderNumber} ({ er.rider_name})</option>
                        }
                    }
                )
            }


            return (
                <select
                    ref={selectRef}
                    className='form-select form-select-sm fs-6 min-h-20px py-1' 
                    onChange={(e) => onResponsiblePartyChange(e)}
                    disabled={show_financials_locked}
                    >
                    <option value='0'>Select...</option>
                    <option value='1'>Owner</option>
                    <option value='3'>Trainer</option>
                    { entryRidersOptions }
                    <option value='5'>PMR</option>
                    <option value='4'>Other</option>
                </select>
            )
        }
    }

    // sets entry secured status label based on payment method
    const entrySecuredStatus = methods.getValues("paymentMethod") ?
            `Entry secured using ${methods.getValues("paymentMethod")['redacted'] ? "REDACTED" : ""} credit card ${methods.getValues("paymentMethod")['name_on_card']} - ENDING ${methods.getValues("paymentMethod")['card_lastfour']} - Exp: ${methods.getValues("paymentMethod")['card_exp'].substring(0, 2)}/${methods.getValues("paymentMethod")['card_exp'].substring(2, 4)}` : ""

    // Link text of secure credit card dialog link
    const entrySecuredCCChange = secured_payment_method_id > 0 ? "Change Card" : "Select Card to Secure Entry"

    const setSecurePaymentMethod = async (selectedPaymentMethod) => {
        if(selectedPaymentMethod && Object.keys(selectedPaymentMethod).length > 0 && selectedPaymentMethod['payment_method_id'] != methods.getValues('entry.secured_payment_method_id')){
            const response = await axios.post(`${NEST_API_URL}/entries/getShowSeriesEntries`, {
                customer_id: customer_id,
                entry_id: methods.getValues('entry.entry_id') ?? -1,
                show_id: methods.getValues('entry.show_id'),
                card_owner_id: selectedPaymentMethod.people_id,
                entry: methods.getValues('entry'),
                payment_method_id: selectedPaymentMethod['payment_method_id']
            })

            if(!response.data?.success && response.data?.message){
                alertDialog({ message: response.data?.message })
                return
            }

            if(response.data?.success && response.data?.show_series_entries_count > 0){
                let choice = await confirmDialog({
                    message: `Apply entry secured credit card changes for all shows in ${response.data?.show_series?.series_name}? ${response.data.show_series_entries_count} ${response.data.show_series_entries_count > 1 ? 'entries' : 'entry'} will be updated.`,
                    title: "warning",
                    icon: "warning",
                    okButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.OK'}),
                    cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'})
                })
                
                methods.setValue('update_cc_in_future_entries', choice, {shouldDirty: true})
            }

            methods.setValue('paymentMethod', selectedPaymentMethod)
            methods.setValue('entry.secured_payment_method_id', selectedPaymentMethod['payment_method_id'], {shouldDirty: true})
        }
    }

    // styling for priority order rows in entry fees
    const priorityOrderClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.priority_order',
        };
    }, []);

    const entryFeeColumnDefs = [
        { field: 'ordersupplyfromweb', headerName: "",  sortable: false, minWidth: 70, maxWidth: 70, filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressColumnsToolPanel: true, },
        { field: 'date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DATE' }), cellClass: 'text-center', cellRenderer: DateRenderer, filter: 'agDateColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'quantity', width: 60, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.QUANTITY' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: params => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'description', width: 300, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.FEEDESCRIPTION' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'category', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.CATEGORY' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'unitprice', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.UNITPRICE' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: (params) => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'totalprice', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TOTALPRICE' }), cellClass: 'text-center', filter: 'agNumberColumnFilter', cellRenderer: (params) => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'delivered', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DEL' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressMenu: false, suppressMovable: false },
        { field: 'invoice_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.INVOICENUM' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
        { field: 'entry_stall', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.I' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center', suppressMenu: false, suppressMovable: false },
        { field: 'ShowFee.ShowTaxRate.tax_abbreviation', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TX' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
    ]

    const entryFeeRowData = methods.getValues("entryFeesData.entryFees")?.filter(entryFee => !entryFee.isDeleted)

    const paymentxEntriesColumnDefs = [
        { field: 'amount_applied', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.AMOUNTAPPLIED' }), filter: 'agNumberColumnFilter', cellRenderer: params => DecimalPointRenderer(params, 2), suppressMenu: false, suppressMovable: false },
        { field: 'Payment.payment_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.PAYMENTDATE' }), cellClass: 'text-center', filter: 'agDateColumnFilter', cellRenderer: DateRenderer, suppressMenu: false, suppressMovable: false },
        { field: 'Payment.type', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TYPE' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false},
        { field: 'Payment.description', flex: 3, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.DESCRIPTION' }), filter: 'agTextColumnFilter', suppressMenu: false, suppressMovable: false },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), [])

    const [showAddFeeForm, setShowAddFeeForm] = useState(false)
    const [showDeleteFeeForm, setShowDeleteFeeForm] = useState(false)
    const [showAddPaymentForm, setShowAddPaymentForm] = useState(false)
    const [showTransferCreditForm, setShowTransferCreditForm] = useState(false)
    const [showRollForwardForm, setShowRollForwardForm] = useState(false)
    const [showPaymentDetailForm, setShowPaymentDetailForm] = useState(false)
    const [showFeeDetailForm, setShowFeeDetailForm] = useState(false)
    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const [searchDisabled, setSearchDisabled] = useState(true)
    const [selectedFee, setSelectedFee] = useState({})
    const [selectedPaymentxEntry, setSelectedPaymentxEntry] = useState({})
    const [transferCreditDetail, setTransferCreditDetail] = useState(null)

    const onBlur = (e) => {
        if(e.target.value && e.target.value !=''){
            callSearchPersonApi(e.target.value)
        }
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    const onCellDoubleClick = (params) => {
        setSelectedPaymentxEntry(params.data)
        setShowPaymentDetailForm(true)
    }

    const onCellClicked = (params) => {
        const entryFeeIndex = methods.getValues('entryFeesData.entryFees').findIndex((fee) => fee.entryfees_id === params.data.entryfees_id);
        setSelectedFee({...params.data, rowIndex: entryFeeIndex })
    }

    const onFeeCellDoubleClick = () => {
        setShowFeeDetailForm(true)
    }

    const onTransferCreditToAccount = async () => {
        
        let transferableCredit = parseFloat(methods.getValues('entry')?.balance)

        if(!methods.getValues('entry.responsibleparty_id')){ // show error if responsible party not set for the entry
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.MISSING.PMR.RP' }) });
            return;
        }

        if(!methods.getValues(`linked_people[${methods.getValues('entry')?.responsibleparty_id}].people_data`)){
            alertDialog({message: 'Responsible Party not found.'})
            return;
        }

        if(!methods.getValues('entry.prizemoneyrecipient_id')){ // show error if pmr not set for the entry
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.MISSING.PMR' }) });
            return;
        }

        if(!methods.getValues(`linked_people[${methods.getValues('entry')?.prizemoneyrecipient_id}].people_data`)){
            alertDialog({message: 'Prize Money Recipient not found.'})
            return;
        }

        if(transferableCredit <= -0.01){ // entry have some credit balance
            let message = ''
            let trips = methods.watch('trips')
            let tripswithUnAppliedPrizeMoney = trips.filter((trip) => trip.total_prize_money > 0 && !trip.prize_money_spent)

            if(tripswithUnAppliedPrizeMoney.length > 0){
                message = `Prize money is not applied to this entry. `
            }

            //TODO: ADD "OR" condition here to check if all prize money is applied in cloud or no legacy prize money then we need to execute if condition instead of going into hassle of extra call to distribute amount between RP and PMR
            if(methods.getValues('entry.responsibleparty_id') == methods.getValues('entry.prizemoneyrecipient_id')){
                message = message + `Are you sure you want to transfer ${currencyFormatter(Math.abs(transferableCredit))} to ${methods.getValues('entry')?.responsibleparty} (RP)?`
            }
            else {
                loadingOverlay({ show: true })
                let response = await axios.post(`${NEST_API_URL}/accounting/getUnTransferredCredit`, {
                    customer_id: customer_id,
                    entry_id: methods.getValues('entry.entry_id'),
                    entry: methods.getValues('entry')
                })
                loadingOverlay({ show: false })
            
                if(response.data.success){
                    if(response.data.balanceRefund > 0 && response.data.unTransferredLegacyPrizeMoney > 0){
                        message = `Are you sure you want to transfer ${currencyFormatter(Math.abs(response.data.balanceRefund))} to ${methods.getValues('entry')?.responsibleparty} (RP) and ${currencyFormatter(Math.abs(response.data.unTransferredLegacyPrizeMoney))} to ${methods.getValues('entry')?.prizemoneyrecipient} (PMR)?`
                    }else if(response.data.balanceRefund > 0){
                        message = `Are you sure you want to transfer ${currencyFormatter(Math.abs(response.data.balanceRefund))} to ${methods.getValues('entry')?.responsibleparty} (RP)?`
                    }else if(response.data.unTransferredLegacyPrizeMoney > 0){
                        message = `Are you sure you want to transfer ${currencyFormatter(Math.abs(response.data.unTransferredLegacyPrizeMoney))} to ${methods.getValues('entry')?.prizemoneyrecipient} (PMR)?`
                    } 
                }else{
                    alertDialog({message: response.data.error})
                    return;
                }
            }

            let choice = await confirmDialog({
                message,
                title: "warning",
                icon: "warning",
                okButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.OK'}),
                cancelButtonTitle: intl.formatMessage({id: 'DIALOG.COMMON.BUTTON.CANCEL'})
            })

            if(choice){
                loadingOverlay({show: true})
                await props.callFormSubmit('move-credit-to-account')// save entry data
                let res = await axios.post(`${NEST_API_URL}/accounting/transferCreditFromEntryToAR`, {
                    customer_id: customer_id,
                    entry_id: methods.getValues('entry.entry_id'),
                    transferCredit: false,
                    ignorePrizeFromBalTransfer: true
                });

                if(res?.data?.success){
                    // update the paymentxentries records
                    methods.setValue('paymentxEntries', res?.data?.paymentxentries) 
                    
                    //reload entry data
                    await props.reloadPageData(methods.getValues('entry.entry_id'))

                    let message = []
                    let totalCreditTransferred = addFloatingNumbers([-1*res?.data?.amountMovedToRP ?? 0, res?.data?.amountMovedToPMR ?? 0], 2)
                    if(methods.getValues('entry.responsibleparty_id') == methods.getValues('entry.prizemoneyrecipient_id') && totalCreditTransferred > 0){
                        message.push(`${currencyFormatter(Math.abs(totalCreditTransferred))} has been moved to Account ${methods.getValues('entry.responsibleparty')} from Entry #${methods.getValues('entry.number')}.`)
                    }else{
                        if(res?.data?.amountMovedToRP){
                            message.push(`${currencyFormatter(Math.abs(res?.data?.amountMovedToRP))} has been moved to Account ${methods.getValues('entry.responsibleparty')} from Entry #${methods.getValues('entry.number')}.`)
                        }
                        
                        if(res?.data?.amountMovedToPMR){
                            message.push(`${currencyFormatter(Math.abs(res?.data?.amountMovedToPMR))} has been moved to Account ${methods.getValues('entry.prizemoneyrecipient')} from Entry #${methods.getValues('entry.number')}.`)
                        }
                    }

                    if(message.length > 0){
                        alertDialog({message: message.join('<br/>')})
                    }
                }else{
                    alertDialog({ message: res?.data?.error })
                }
                loadingOverlay({show: false})
            }

        }else{
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.ERRORMESSAGE.NO.CREDIT.FOUND' }) });
        }
    }

    //NOT USED: TEST THIS THOROUGHLY IN CASE NEED TO REUSE THIS
    const onMoveCreditFromAccount = async () => {
        if(methods.getValues('entry.balance') <= 0.01){
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.ACCOUNT.ERROR' }) });
            return;
        }

        if(!methods.getValues('entry.prizemoneyrecipient_id') && !methods.getValues('entry.responsibleparty_id')){
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.MISSING.PMR.RP' }) });
            return;
        }

        await props.callFormSubmit('move-credit-from-account')// save entry data
        
        // get the amount present in Account for PMR and RP
        await axios.post(`${NEST_API_URL}/accounting/getTransferableAmountForEntry`, {
            customer_id: customer_id,
            entry_id: methods.getValues('entry.entry_id'),
            entry: methods.getValues('entry')
        }).then((response) => {

            if(response?.data?.totalCredit && response?.data?.totalCredit > 0.01){
                setTransferCreditDetail(response?.data)
                setShowTransferCreditForm(true)
            }else{
                alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.TRANSFER.CREDIT.FROM.ACCOUNT.BALANCE.ERROR' }) });
            }
        })
    }

    const missingEntryStallMessage = () => {
        let message = ""
        let className = ""
        if(methods.getValues('checkMissingEntryStall.displayEntryStallMessage')){
            if (methods.getValues("checkMissingEntryStall.stableFeeCount") > 0) { //Entry has stable fee
                let stallsRemaining = methods.getValues("checkMissingEntryStall.stallsRemaining") >= 0? methods.getValues("checkMissingEntryStall.stallsRemaining"): '0'
                message = "Entry using 0 Trainer Stall. Remaining: " + stallsRemaining
            } else if (methods.getValues("checkMissingEntryStall.stallsRemaining") >= 0) { //Trainer inventory for stall can be used?
                message = "Entry using 1 Trainer Stall. Remaining: " + methods.getValues("checkMissingEntryStall.stallsRemaining")
                className = "text-success"
            } else {
                message = "Entry using 0 Trainer Stall. Remaining: 0"
                className = "text-danger"
            }
        }

        return (
            <label className={`col-form-label fs-5 py-1 fw-bold ${className}`} data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRYSTALL">
                { message }
            </label>
        )
    }

    const handleDeleteMultipleFees = () => {
        const selectedRows = entryFeeGridRef.getSelectedRows();
        if (selectedRows.length > 0) {
          let hasRefundFee = false;
          let hasRefundedFee = false;
          let hasSplitFee = false;
      
          for (const row of selectedRows) {
            if (row.refund) {
              hasRefundFee = true;
            } else if (row.refund_id && row.refund_id !== 0) {
              hasRefundedFee = true;
            } else if (row.fee_splitted) {
              hasSplitFee = true;
            }
          }
      
          if (hasRefundFee) {
            alertDialog({ message: "Selected fees are refund fees and cannot be deleted.", title: "error", icon: "ban", });
          } else if (hasRefundedFee) {
            alertDialog({ message: "Some of the selected fees have already been refunded.", title: "error", icon: "ban", });
          } else if (hasSplitFee) {
            alertDialog({ message: "Fees transferred as trainer split cannot be deleted.", title: "error", icon: "ban", });
          } else {
            setSelectedRowData(selectedRows)
            setShowDeleteFeeForm(true)
          }
        }
      };

    const markAsViewed = () => {
        if (entryFeeGridRef.getSelectedRows().length > 0) {
            
            const selectedRow = entryFeeGridRef.getSelectedRows()[0]
            if (selectedRow.ordersupplyfromweb) {
                const entryFees = methods.getValues("entryFeesData.entryFees")
                const index = entryFees.findIndex(ef => ef.entryfees_id == selectedRow.entryfees_id)

                methods.setValue(`entryFeesData.entryFees[${index}].ordersupplyfromweb`, 0)
                methods.setValue(`entryFeesData.entryFees[${index}].isUpdated`, true)

                setRowDataOne(methods.getValues("entryFeesData.entryFees")) // WA - Row Selector
                entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
            }
        }
    }

    //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
    
        let firstName = props.getFirstNameFromSearchString(search_term)
        let lastName = props.getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    async function callSearchPersonApi(search_term){
        let search_response = await props.callSearchPersonApi(search_term, 'PERSON')
        if (search_response.showPersonLookup){
            setDialogData(search_response.dialog_data)
            setPeopleSearchList(search_response.search_list)
            setSearchCountStatus(search_response.people_count_status)
            setDefaultOrg(search_response.default_org)
            setShowPersonLookup(search_response.showPersonLookup)
        } else {
            setPersonSearchStatus(search_response.search_status)
        }      
    }

    async function getSelectedPeople(people, organization, person_role){
        let search_status = await props.getSelectedPeople(people, organization, person_role)
        if(search_status){
            setPersonSearchStatus(search_status)
        }
    }

    async function onAddPayment() { 
        props.getMembershipsUpdatedRecords()
        let form_data = methods.getValues()
        let validate_response = await props.onSubmitData(form_data, 'payments')
        if(validate_response.verified){
            setShowAddPaymentForm(true)
        }
    }

    let changeEntryRP = async() => {

        let balanceRefundRolledback = false
        if(oldRpId && methods.getValues('entry.entry_id') > 0){
            await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/rollbackBalanceRefundForEntry', {
                customer_id: customer_id,
                entry_id: methods.getValues('entry.entry_id'),
                save_record: false,
                responsibleparty_id: oldRpId
            }).then( async response => {
                if(response?.data?.success){
    
                    // update payment records
                    if(response.data.payments && response.data.payments.length > 0){
                        let new_payments = methods.getValues('new_payments')?methods.getValues('new_payments'):[]
                        new_payments.push(...response.data.payments)
                        methods.setValue('new_payments', new_payments, {shouldDirty: true})
                    }
    
                    // update paymentxentries record
                    if(response.data.paymentxEntries && response.data.paymentxEntries.length > 0){
                        let paymentxEntries = methods.getValues('paymentxEntries') ? methods.getValues('paymentxEntries') : []
                        let newPaymentxEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id === methods.getValues('entry')?.entry_id)
                        paymentxEntries.push(...newPaymentxEntries)
                        let newAmount = newPaymentxEntries.reduce((val, pxe) => val + pxe.amount_applied, 0)
                        methods.setValue('entry.balance', methods.getValues('entry.balance') ? methods.getValues('entry.balance') + newAmount : newAmount)
                        methods.setValue('paymentxEntries', paymentxEntries, {shouldDirty: true})
                        let paymentxEntriesForOtherEntries = methods.getValues('paymentxEntriesForOtherEntries') ? methods.getValues('paymentxEntriesForOtherEntries') : []
                        let newPaymentxEntriesForOtherEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id !== methods.getValues('entry')?.entry_id)
                        paymentxEntriesForOtherEntries.push(...newPaymentxEntriesForOtherEntries)
                        methods.setValue('paymentxEntriesForOtherEntries', newPaymentxEntriesForOtherEntries, {shouldDirty: true})
                    }
    
                    // update account register records
                    if(response.data.accountRegisters && response.data.accountRegisters.length > 0){
                        let updated_account_registers = methods.getValues('updated_account_registers')?methods.getValues('updated_account_registers'):[]
                        updated_account_registers = updated_account_registers.concat(response.data.accountRegisters)
                        methods.setValue('updated_account_registers', updated_account_registers, {shouldDirty: true})
                    }
    
                    // update the account register distributions
                    if(response.data.accountRegisterDistributions && response.data.accountRegisterDistributions.length > 0){
                        let updated_account_register_distributions = methods.getValues('updated_account_register_distributions')?methods.getValues('updated_account_register_distributions'):[]
                        updated_account_register_distributions = updated_account_register_distributions.concat(response.data.accountRegisterDistributions)
                        methods.setValue('updated_account_register_distributions', updated_account_register_distributions, {shouldDirty: true})
                    }   

                    balanceRefundRolledback = true
                } else {
                    alertDialog({message: response.data.error, title: 'Error'})
                }
            })
        }
        return balanceRefundRolledback
    }

    useEffect(() => {
        if(!methods.getValues('RTO Action') && !methods.getValues('balanceRefunded')){
            validateRpChange()
        }
    }, [methods.watch("entry.responsibleparty_id"), methods.getValues('RTO Action')])

    const validateRpChange = async() => {
        if(
            oldRpId && 
            methods.getValues('entry')?.entry_id > 0 && //not new entry
            methods?.formState?.dirtyFields?.entry?.hasOwnProperty('responsibleparty_id') && // pmr is changed by user
            oldRpId != methods.getValues('entry.responsibleparty_id') // there is some actual change in pmr
        ){
            // Check if some balance refund transaction was done from the entry
            const response = await axios.get(`${NEST_API_URL}/accounting/entryHasTransferredBalanceRefund?customer_id=${customer_id}&entry_id=${methods.getValues('entry.entry_id')}&people_id=${oldRpId}`)
            if(response.data){
                methods.setValue('RTO Action', 'RP is updating')
                setDisplayRpChangeConfirmDialog(true)
                return
            }
        }

        setOldRpId(methods.getValues('entry.responsibleparty_id'))
        setOldRpSource(methods.getValues('entry.responsiblepartysource'))
    }

    onkeydown = (e) => {
        const activeTab = document.querySelector('.nav-link.active');
        if (activeTab?.id?.includes('ACCOUNT')) {
            if ((e.ctrlKey || e.metaKey) && (e.code === 'KeyM')) { // Ctrl + M
              e.preventDefault();
              setShowAddFeeForm(true);
            }
        }
    }

    const [os, setOS] = useState("");

    useEffect(() => {
        setOS(getOS());
    }, []);

    const getOS = () => {
        const userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Win") !== -1) return "Windows";
        if (userAgent.indexOf("Mac") !== -1) return "MacOS";
        if (userAgent.indexOf("X11") !== -1) return "UNIX";
        if (userAgent.indexOf("Linux") !== -1) return "Linux";
        return "Unknown";
    }

        // RP sign related functions

    const handleInvalidSignature = async () => {
        let choice = await confirmDialog({message: `Are you sure you want to invalidate signature for ${methods.getValues(`linked_people[${responsiblePartyId}].people_data.fl_name`)}?`})
        if(choice){
            digitalSignatureAction('Invalidate Signature', '', 'RP')
        }
    }


    const digitalSignatureAction = async (action, phoneNumber = '', role = '', replicateAuthRequest = false, byPassShowCheck = false) => {
        let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/entries/digitalSignatureAction', {
            params: {
                people_id: responsiblePartyId,
                entry_id: methods.getValues('entry.entry_id') ?? -1,
                show_id: methods.getValues('entry.show_id'),
                phone_number: phoneNumber,
                action,
                customer_id,
                role,
                replicate_auth_requests: replicateAuthRequest,
                bypass_show_check: byPassShowCheck
            }
        })

        if(response.data.success){
            let entryAuthorizationRequestsList = methods.getValues('entryAuthorizationRequests')
            let updatedRequest = response.data.entryAuthorizationRequests
            if (updatedRequest) {
                // entryAuthorizationRequestsList = entryAuthorizationRequestsList.map((request) => 
                //   request.people_id == updatedRequest.people_id && request.role?.toUpperCase() == "RP"
                //     ? { ...request, ...updatedRequest } // Merge updated fields into the existing object
                //     : request
                // );
                entryAuthorizationRequestsList = entryAuthorizationRequestsList.some(
                    (request) =>
                      request.people_id == updatedRequest.people_id &&
                      request.role?.toUpperCase() == "RP"
                  )
                    ? entryAuthorizationRequestsList.map((request) =>
                        request.people_id == updatedRequest.people_id &&
                        request.role?.toUpperCase() == "RP"
                          ? { ...request, ...updatedRequest } // Merge updated fields into the existing object
                          : request
                      )
                    : [...entryAuthorizationRequestsList, updatedRequest]; 
                setRpEntryAuthorizationRequest(updatedRequest)
                methods.setValue('entryAuthorizationRequests', entryAuthorizationRequestsList, {shouldDirty: true});
            }
            // methods.setValue(`linked_people[${responsiblePartyId}].entryAuthorizationRequest`, response.data.entryAuthorizationRequests)
            // methods.setValue(`linked_people[${responsiblePartyId}].updateSeriesAuthorizationRequests`, response.data.updateSeriesAuthorizationRequests)
            // setRpEntryAuthorizationRequest(response.data.entryAuthorizationRequests)
        }else if(response.data.warning){
            let choice = await confirmDialog({message: `This show is already completed. Do you still want to Send Signature Auth Request for ${methods.getValues(`linked_people[${responsiblePartyId}].people_data.fl_name`)}?`})
            if(choice){
                await digitalSignatureAction(action, phoneNumber, role, replicateAuthRequest, true)
            }
        }else{
            if(response.data.message){
                alertDialog({message: response.data.message})
            }
        }

        if(!byPassShowCheck){
            if(action == 'Send Signature Auth Request'){
                await props.callFormSubmit('digital-signature')
            }
        }

    }

    const getRTOMobilePhone = () => {
        let person = rpEntryAuthorizationRequest?.agent_will_sign && methods.getValues('entry.trainer_id') ? methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}]`) : methods.getValues(`linked_people[${responsiblePartyId}]`)

        if(rpEntryAuthorizationRequest?.phone_number){
            return rpEntryAuthorizationRequest.phone_number
        }
        else if(person?.people_data?.sgl_mobile && person?.people_data?.sgl_mobile_verified){
            return person.people_data.sgl_mobile
        }
        else if(person?.people_data?.mobile_phone){
            return person?.people_data?.mobile_phone_country_code + person.people_data.mobile_phone
        }

        return ''
    }

    const getRTONameForEntryAuthorization = () => {
        let person = methods.getValues(`linked_people[${responsiblePartyId}]`)
 
        if(person?.people_data?.isfarmrto){
            return person?.people_data?.company_name
        }else{
            return `${person?.people_data?.first} ${person?.people_data?.last_name}`
        }
    }

    const sendEntryAuthorizationReq = () => {
        if(rpEntryAuthorizationRequest?.agent_will_sign && !methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}]`)){
            // if agent will sign is true and there is no trainer added for the entry display error
            alertDialog({message: 'Please add trainer for the entry.'})
        }else{
            // display send authorization dialog
            setShowSignAuthRequestForm(true)
        }
    }

    const onRollForwardClick = async() => {
        // save entry data
        props.getMembershipsUpdatedRecords()
        let form_data = methods.getValues()
        let validate_response = await props.onSubmitData(form_data, 'rollforward-balances')
        if(!validate_response.verified){ return; }
    
        loadingOverlay({ show: true })
        // entries from past shows that have credits or balances available
        axios.post(`${process.env.REACT_APP_NEST_API_URL}/entries/getPastBalancesForEntry`, {
            customer_id: customer_id,
            entry_id: methods.getValues('entry.entry_id'),
            entry: methods.getValues('entry'),
            show_start_date: currentShowStartDate
        }).then((response) => {
            if(response.data.success){
                if(response.data.rollableEntries.length > 0){
                    setRollableEntries(response.data.rollableEntries)
                    setRollableEntriesColumnToBeDisplayed(response.data.columnsToDisplay)
                    setShowRollForwardForm(true)
                }else{
                    setRollableEntries([])
                    setRollableEntriesColumnToBeDisplayed([])
                    // no entries with credit or balances available
                    alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.ROLLFORWARD.NO.ENTRY' })})
                }
            }else{
                setRollableEntries([])
            }
        }).finally(() => {
            loadingOverlay({ show: false })
        })
    }

    useEffect(() => {
        if(methods.getValues('show.require_rp_signature')){
            let entryAuthorizationRequestsList = methods.getValues('entryAuthorizationRequests')

            let entryAuthorizationRequest = entryAuthorizationRequestsList.find(e => e.people_id == responsiblePartyId && e.role?.toUpperCase() == "RP")
            // setEntryAuthorizationRequest(methods.watch(`linked_people[${props.person_id}].entryAuthorizationRequest`))
            setRpEntryAuthorizationRequest(entryAuthorizationRequest)

            
        }
    }, [methods.watch('entryAuthorizationRequests'), methods.watch('entry.responsibleparty_id')]);


    
    return (
        <>
            {showAddFeeForm && <AddFeeForm 
                show={showAddFeeForm} 
                handleClose={() => setShowAddFeeForm(false)} 
                formMeta={props.formMeta}
                entryFeeGridRef={entryFeeGridRef}
                setEntryFeeGridRowData={setRowDataOne}
                callFormSubmit={() => props.callFormSubmit('add-fee')}
            />}
            {showAddPaymentForm && <AddPaymentForm show={showAddPaymentForm} handleClose={() => setShowAddPaymentForm(false)} getSubmitData={props.getSubmitData} getMembershipsUpdatedRecords={props.getMembershipsUpdatedRecords} setLockPrizeMoneyAction={props.setLockPrizeMoneyAction}/>}
            {showTransferCreditForm && <TransferCreditForm show={showTransferCreditForm} handleClose={() => setShowTransferCreditForm(false)} transferCreditDetail={transferCreditDetail} setTransferCreditDetail={setTransferCreditDetail} updateCallbackFromParent={() => props.updateCallbackFromParent()} callFormSubmit={() => props.callFormSubmit('move-credit-from-account')}/>}
            {showRollForwardForm && <RollForwardForm show={showRollForwardForm} handleClose={() => setShowRollForwardForm(false)} rollableEntries={rollableEntries} columnsToDisplay={rollableEntriesColumnToBeDisplayed}/>}
            {showPaymentDetailForm && <PaymentDetailForm 
                show={showPaymentDetailForm} 
                handleClose={() => setShowPaymentDetailForm(false)} 
                selectedPaymentxEntry={selectedPaymentxEntry}
            />}
            {showFeeDetailForm && <FeeDetailForm 
                show={showFeeDetailForm} 
                handleClose={() => setShowFeeDetailForm(false)} 
                rowData={selectedFee} 
                entryFeeGridRef={entryFeeGridRef}
                setEntryFeeGridRowData={setRowDataOne}
                />
            }
            {showDeleteFeeForm && <DeleteFeeForm 
                show={showDeleteFeeForm} 
                handleClose={() => setShowDeleteFeeForm(false)}
                // rowData={selectedFee}
                rowData={selectedRowData}
                entryFeeGridRef={entryFeeGridRef}
                setEntryFeeGridRowData={setRowDataOne}
                />
            }
            {/* <PersonLookupForm show={showPersonLookup} handleClose={() => setShowPersonLookup(false)} fieldName={'responsible_party_search'} /> */}
            {showPersonLookup && <PersonLookupForm 
                    show={showPersonLookup} 
                    handleClose={() => {methods.setValue('responsible_party_search', methods.getValues('entry.responsibleparty'));setShowPersonLookup(false);}} 
                    customer_id={customer_id} 
                    fieldName={'responsible_party_search'} 
                    dialogData={dialogData}    
                    setDialogData={setDialogData}                 
                    person_search_list={people_search_list}
                    default_org={default_org}
                    person_search_status={search_count_status}
                    type = {'PERSON'}
                    show_id = {currentShowID}
                    handleSearchTerm = {handleSearchTerm}
                    getSelectedPeople = {getSelectedPeople}
                    generateNewRTOId = {props.generateNewRTOId}
                    updatePeople = {props.updatePeople}
            />}
            {showChangeCCForm && <ChangeCreditCardForm 
                show={showChangeCCForm} 
                handleClose={() => setShowChangeCCForm(false)}
                handleSave={setSecurePaymentMethod}
            />}
            {showSignAuthRequestForm && 
            <SignatureAuthRequestForm 
                show={showSignAuthRequestForm} 
                handleClose={() => setShowSignAuthRequestForm(false)} 
                role={'RP'} 
                name={getRTONameForEntryAuthorization()} 
                digitalSignatureAction={digitalSignatureAction} 
                mobileNumber={getRTOMobilePhone()} 
                agentWillSign={rpEntryAuthorizationRequest?.agent_will_sign ?? false}
            />}
            {showManualSignatureForm && 
            <PaperEntryConfirmationForm 
                show={showManualSignatureForm} 
                handleClose={() => setShowManualSignatureForm(false)} 
                role={'RP'} 
                name={methods.getValues(`linked_people[${responsiblePartyId}].people_data.isfarmrto`)?methods.getValues(`linked_people[${responsiblePartyId}].people_data.company_name`) : methods.getValues(`linked_people[${responsiblePartyId}].people_data.first`) +' '+ methods.getValues(`linked_people[${responsiblePartyId}].people_data.last_name`)} 
                digitalSignatureAction={digitalSignatureAction} 
                futureUnsignedEntries={methods.getValues(`linked_people[${responsiblePartyId}].futureUnsignedEntries`)}
            />}

            { (displayRpChangeConfirmDialog) ? <PmrRpChangeDialog show={displayRpChangeConfirmDialog} handleClose={() => {methods.setValue('RTO Action', ''); setDisplayRpChangeConfirmDialog(false)}} prevPeopleId={oldRpId} setPrevPeopleId={setOldRpId} prevPeopleSource={oldRpSource} setPrevPeopleSource={setOldRpSource} setDisplayEntriesChangesDetail={setDisplayBalanceRefundRollbackDialog} setEntriesAndARChangesDetail={setRolledbackBalanceRefundDetails} entriesAndARChangesDetail={rolledbackBalanceRefundDetail} calledFor={'RP'} rollbackPeoplePayments={changeEntryRP}/> : null }

            { displayBalanceRefundRollbackDialog && <RolledbackPeoplePaymentsDetail show={displayBalanceRefundRollbackDialog} handleClose={() => setDisplayBalanceRefundRollbackDialog(false)} data={rolledbackBalanceRefundDetail} calledFor={'RP'}/> }
            <div className='form-group mb-2'>
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold d-flex mb-5 entries-search-legend">
                        <div className='d-flex align-self-center min-w-lg-150px'><label data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.RESPONSIBLEPARTY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.GROUPLABEL.RESPONSIBLEPARTY' })}</label></div>
                        <div className='d-flex align-items-center my-1 w-150px mx-3'>
                           { responsiblePartySelect() }
                        </div>
                        <SearchInput 
                            containerClass='me-2 w-225px'
                            onSearch={onSearch} 
                            onBlur={onBlur} 
                            disabled={searchDisabled || show_financials_locked} 
                            fieldName={'responsible_party_search'}
                            id={'responsible_party_search'}
                            autoComplete='off'
                        />
                        <div className='col-lg-1 d-flex align-items-center w-lg-25px'>
                            {methods.getValues('entry.responsibleparty_id') > 0 ?<i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: 'white' }}  onClick ={() =>{props.callbackFromParent(methods.getValues('entry.responsibleparty_id'), methods.getValues(`linked_people[${methods.getValues('entry.responsibleparty_id')}]`), "People", "PeopleDetail")}}></i>: null}    
                        </div>
                        <b></b>
                        {/* Digi sign for RP */}
                        <div className={'col d-flex align-items-center my-1 justify-content-end' + (methods.getValues('show.require_rp_signature') ? '': ' d-none')}>
                            <div className='col ps-0'>
                                <div className='row'>
                                    {rpEntryAuthorizationRequest?.status == 'Signed' ?
                                        <div className='col d-flex align-items-center justify-content-end'>
                                            <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                                <i className="fa-sharp fa-solid fa-check fa-xl py-3 text-success" style={{ fontSize: "1.2em" }}></i>
                                            </div>
                                            <label className='fs-6 py-1 no-transform'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREOBTAINED`}>Signature Obtained</label>
                                        </div>
                                    : (rpEntryAuthorizationRequest?.phone_number != '' || rpEntryAuthorizationRequest?.agent_will_sign ) && rpEntryAuthorizationRequest?.status == 'Sent' ? 
                                    <div className='col d-flex align-items-center justify-content-end'>
                                        <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                            <i className="fa-sharp fa-solid fa-clock fa-xl py-3" style={{ fontSize: "1.2em" }}></i>
                                        </div>
                                        <label className='fs-6 py-1 no-transform'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREREQUESTSENT`}>{`Signature Request Sent${rpEntryAuthorizationRequest?.agent_will_sign?" (Agent)":""}`}</label>
                                    </div>
                                    :
                                    <div className='col d-flex align-items-center justify-content-end'>
                                        <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                            <i className="fa-sharp fa-solid fa-circle-exclamation fa-xl py-3" style={{ color: '#e62721', fontSize: "1.2em" }}></i>
                                        </div>
                                        {rpEntryAuthorizationRequest?.phone_number || 
                                        methods.getValues(`linked_people[${responsiblePartyId}].people_data.sgl_mobile`) ||
                                        methods.getValues(`linked_people[${responsiblePartyId}].people_data.sgl_mobile_verified`) ||
                                        methods.getValues(`linked_people[${responsiblePartyId}].people_data.mobile_phone`) ? 
                                        <label className='no-transform fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREMISSING`}>Signature Missing</label>
                                        :
                                        <label className='no-transform fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.NONUMBERAVAILABLE`}>No Number Available</label>}
                                    </div>
                                    }                            
                                </div>
                            </div>
                            <div className='col-lg-1 w-20px ps-2 d-flex py-1 align-items-center dropdown'>
                                <div className="dropdown">
                                    <a className="fa-sharp fa-solid fa-caret-down fa-lg text-dark" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                    {rpEntryAuthorizationRequest?.status == 'Signed'?
                                        <ul className="dropdown-menu dropdown-menu-end mt-2" style={{zIndex: '1021'}}>
                                            <li className='dropdown-item cursor-pointer no-transform' onClick={handleInvalidSignature}>Invalidate Signature</li>
                                        </ul>
                                        :
                                        <ul className="dropdown-menu dropdown-menu-end mt-2" style={{zIndex: '1021'}}>
                                            {
                                                ( hasSpecialPermissionToAccess('cloud_sgadmin:has_access') && 
                                                    rpEntryAuthorizationRequest?.phone_number != '' && 
                                                    rpEntryAuthorizationRequest?.status == 'Sent'
                                                )? 
                                                <li className='dropdown-item cursor-pointer no-transform' onClick={() => alertDialog({message: rpEntryAuthorizationRequest.sms_text, icon: 'info', title: 'SMS Message', okButtonTitle: 'Close'})}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.VIEWSIGAUTHREQUESTSMS' })}</li>
                                                :<></>
                                            }
                                            <li className='dropdown-item cursor-pointer no-transform' onClick={sendEntryAuthorizationReq}>{`${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.SENDSIGAUTHREQUEST' })} ${rpEntryAuthorizationRequest?.agent_will_sign ? '(Agent)' : ''}`}</li>
                                            <li className='dropdown-item cursor-pointer no-transform' onClick={() => setShowManualSignatureForm(true)}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MANUALSIGNATURERECIEVED' })}</li>
                                        </ul>
                                    }
                                </div>
                            </div>

                        </div>
                    </legend>
                    <div className='row mb-2'>
                        <div className='col-auto'>
                            <button type="button" disabled={show_financials_locked} onClick={() => setShowAddFeeForm(true)}  className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.ADDFEE">
                                { props?.id === undefined
                                    ? os === 'MacOS'
                                        ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.LABEL.ADDFEEMAC' })
                                        : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.LABEL.ADDFEEWIN' })
                                    : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDFEE' })
                                }
                            </button>
                        </div>
                        <div className='col-auto'>
                            <button 
                                className="btn btn-sm btn-secondary fw-bold text-uppercase"
                                type="button"
                                onClick={markAsViewed}
                                data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.MARKASVIEWED"
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MARKASVIEWED' })}
                            </button>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>
                                {
                                    onCreditHold ?
                                        <div className='col'>
                                            <div className='row'>
                                                <div className='col-lg-1 w-lg-30px d-flex align-items-center'>
                                                    <i className="fas fa-2xl fa-regular fa-triangle-exclamation me-1 text-warning"></i>
                                                </div>
                                                <label className='col fs-6 py-1 pt-2 text-danger'>Responsible Party is on credit hold.</label>
                                            </div>
                                        </div>
                                    :
                                        ""
                                }
                                { hasSpecialPermissionToAccess('cloud_entries:delete-fee-from-entries') &&
                                <div className='col-auto'>
                                    <button type="button" disabled={show_financials_locked} onClick={handleDeleteMultipleFees} className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.DELETEFEE">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.DELETEFEE' })}
                                    </button>
                                </div> }
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-2'> {/* WA - Row Selector */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <RowSelector
                            value={visibleRowsOne}
                            onChange={setVisibleRowsOne}
                            area={"Entries - Account Fees"}
                            />
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic 
                                area ={"Entries - Account Fees"} 
                                columnDefs={entryFeeColumnDefs} 
                                rowData={rowDataOne} 
                                containerStyle={containerStyle} 
                                onCellDoubleClick={onFeeCellDoubleClick} 
                                onCellClicked={onCellClicked}
                                rowClassRules={priorityOrderClassRules}
                                setGridRef={setEntryFeeRef}
                                selection={'multiple'}
                                visibleRows={visibleRowsOne}
                                />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRYFEESCOUNT">
                        { entryFeeRowData?.length > 0? entryFeeRowData.length : 0} {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.FEES' })}
                        </label>
                        <div className='d-flex'>
                            <label className='col-form-label fs-5 py-1 fw-bold' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.RP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.RP' })}
                                <span className='fw-normal'>
                                    &nbsp;
                                    {  
                                        currencyFormatter(methods.getValues(`linked_people[${responsiblePartyId}].rtoBalance`))  
                                    }
                                </span>
                            </label>
                            <label className='col-form-label fs-5 py-1 fw-bold ms-5' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.PMR">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.PMR'})}
                                <span className='fw-normal'>
                                    &nbsp;
                                    {  
                                        currencyFormatter(
                                            methods.getValues(`linked_people[${(methods.getValues("entry.prizemoneyrecipient_id"))}].rtoBalance`)
                                        )
                                    }
                                </span>
                            </label>
                        </div>
                        { missingEntryStallMessage() }
                    </div>
                    <div className='row mb-2'>
                        <div className='col-lg-1 w-lg-150px'>
                            <button type="button" disabled={show_financials_locked} onClick={() => onAddPayment()} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ADDPAYMENT">
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ADDPAYMENT' })}
                            </button>
                        </div>
                        <div className='col'>
                            <div className='row justify-content-end'>
                                <div className='col-lg-1 w-lg-150px'>
                                    {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                                    {/* <button type="button" disabled onClick={onRollForwardClick} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ROLLFORWARD' })}
                                    </button> */}
                                </div>
                                {
                                    hasSpecialPermissionToAccess('cloud_entries:transfer-balances') && 
                                    (
                                        props?.formMeta?.EntryBalForwardOwnerHorse ||
                                        props?.formMeta?.EntryBalForwardOwner ||
                                        props?.formMeta?.EntryBalForwardTrainerAcct
                                    ) &&
                                    <div className='col-lg-1 w-lg-150px mb-2 p-0'>
                                        <button type="button" onClick={onRollForwardClick} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase">
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.ROLLFORWARD' })}
                                        </button>
                                    </div>
                                }
                                <div className='col-lg-1 w-lg-225px'>
                                    {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                                    <button type='button' onClick={onTransferCreditToAccount} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.MOVECREDITTOACCOUNT">
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MOVECREDITTOACCOUNT' })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-2'> {/* WA - Row Selector */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <RowSelector
                            value={visibleRowsTwo}
                            onChange={setVisibleRowsTwo}
                            area={"Entries - Account 2"}
                            />
                        </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic 
                                area ={"Entries - Account 2"} 
                                columnDefs={paymentxEntriesColumnDefs} 
                                rowData={rowDataTwo} 
                                containerStyle={containerStyle} 
                                setGridRef={setPaymentxEntriesGridRef}
                                onCellDoubleClick={onCellDoubleClick}
                                visibleRows={visibleRowsTwo}
                            >                                
                            </OutputListingGridStatic>
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.PAYMENTSCOUNT">
                            { rowDataTwo.length + " " + intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.PAYMENTS' })}
                        </label>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px fw-bold' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.WHTAX">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.WHTAX' })}
                            <span className='fw-normal'>
                                &nbsp;
                                { currencyFormatter(methods.getValues("withHoldingTax")) }
                            </span>
                        </label>
                        <label onClick={() => setShowChangeCCForm(true)} className={`col col-form-label fs-5 py-1 text-${methods.getValues("paymentMethod.redacted") ? "danger" : "success"} text-end`} data-tooltip-id="">
                            { entrySecuredStatus }
                        </label>
                        <div className='col-auto'>
                            <button type="button" onClick={() => setShowChangeCCForm(true)} className="btn btn-sm btn-secondary fw-bold px-4 py-2 col-12 text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.BUTTON.SELECTCARDTOSECUREENTRY">
                            {entrySecuredCCChange}
                            </button>
                        </div>
                    </div>
                </fieldset>
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.GROUPLABEL.RESERVATIONPAYMENT' })} <b></b></legend>
                    <div className='row mb-2'>
                        <div className='col-lg-1 form-check-sm form-check-custom w-lg-125px'>
                            <label className='col-form-label mx-3 fs-5 py-1' htmlFor='RECIEVED' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.RECEIVED"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.RECIEVED' })}</label>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='RECIEVED'
                            />
                        </div>
                        <label className='col-form-label mx-2 fs-5 py-1 col-lg-1 w-lg-40px' htmlFor='DATE' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.DATE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.DATE' })}</label>
                        <div className='col-lg-2'>
                            <div className="input-group date">
                                <Flatpickr
                                    className="form-control form-control-sm mb-lg-0  fs-6 min-h-20px py-1"
                                    id="DATE"
                                    placeholder="Select Date"
                                    options={date_options}
                                    autoComplete={"off"}
                                />
                                <div className="input-group-append">
                                    <label htmlFor='DATE' className='date-label'
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            document.getElementById('DATE')._flatpickr.open(); 
                                        }}
                                    >
                                        <span className="input-group-text">
                                            <i className="la la-calendar"></i>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label className='col-form-label mx-2 fs-5 py-1 col-lg-1 w-lg-40px' htmlFor='NOTES' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.NOTES"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.NOTES' })}</label>
                        <div className='col'>
                            <input
                                id='NOTES'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.NOTES' })}
                            />
                        </div>
                    </div>
                    {/* <div className='row mb-2'>
                        <label className='col col-form-label fs-5 py-1 fw-bold text-end' data-tooltip-id="ENTRIES.DETAIL.TAB.ACCOUNT.SECTION.RESERVATIONPAYMENT.LABEL.TRANSFERABLECREDIT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.LABEL.TRANSFERABLECREDIT' })} <span className='fw-normal'>$100.00</span></label>
                    </div> */}
                </fieldset>
            </div>
        </>
    );
}

export { AccountTab }