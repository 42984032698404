import {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls'
import {useFormContext, Controller} from 'react-hook-form'
import {useConfirm, useAlert, useLoadingOverlay} from '../../../modules/sgl-utils/DialogsProvider'
import {AgGridReact} from 'ag-grid-react'
import {useAppSelector} from '../../../redux/hooks'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import axios from 'axios'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import useAccessChecker from '../../../modules/hooks/use-access-checker'
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions'

const RingsTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const showRings = methods.getValues('associated_rings')
  const [rowData, setRowData] = useState([])
  const confirmDialog = useConfirm()
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [addedRings, setAddedRings] = useState([])
  const [deletedRings, setDeletedRings] = useState([])
  const [ringxFacilityGridApi, setRingxFacilityGridApi] = useState(null)
  const [ringxFacilityColumnApi, setRingxFacilityColumnApi] = useState(null)
  const [selectedFacility, setSelectedFacility] = useState(0)
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const [showDialog, setShowDialog] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [availableToAddRings, setAvailableToAddRings] = useState([])
  const alertDialog = useAlert()
  const loadingOverlay = useLoadingOverlay()
  const { hasAreaWritePermission, hasAreaDeletePermission } = useAccessChecker()
  const propFacilityId = props?.showData?.show?.facility_id;

  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridClassName = "ag-theme-alpine";
  const rowHeight = getNarrowRowHeight;
  const headerHeight = getNarrowHeaderHeight;
  /* WA - Row Selector */

  const containerStyle = useMemo(() => ({width: '100%', height: '350px'}), [])

  useEffect(() => {
    const facilityId = props?.showData?.show?.facility_id;
    if (facilityId === 0) { //Called for new show from tab #1
      handleFacilityChange({ value: methods.getValues('show.facility_id') });
    }
  }, [methods.watch('show.facility_id')])

  useEffect(() => {
    if(!props?.showData?.show?.facility_id && props.form_meta?.facilities?.length == 1){
      methods.setValue('show.facility_id', props.form_meta.facilities[0].value, { shouldDirty: true})
    }
  }, [props.showData, props.form_meta]);

  //Hook to load show rings in list on load
  useEffect(() => {
    if(props?.showData?.show?.facility_id > 0){
      // rowData will be initialized by database saved records
      setRowData(showRings)
      setSelectedFacility(props?.showData?.show?.facility_id)

      //Get rings for show facility in order to check missing rings
      getShowFacilityRings(methods.getValues('show.facility_id'))
        .then((facilityRings) => {
          const ringsNotAddedInShow = facilityRings.filter((ring) => {
            return !showRings.some((showRing) => showRing.ring_id === ring.ring_id);
          });
          setAvailableToAddRings(ringsNotAddedInShow);
        });
    }
  }, [showRings, props?.showData?.show?.facility_id])

  const facilityRingsColumnDefs = [
    {
      field: '',
      checkboxSelection: true,
      minWidth: 30,
      maxWidth: 30,
    },
    {
      field: 'ring_name',
      flex: 2,
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.RINGS.LABEL.RINGNAME'}),
    },
  ]

  const columnDefs = [
    {
      field: 'ring_number',
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.RINGS.LABEL.NUMBER'}),
      minWidth: 110,
      maxWidth: 110,
    },
    {
      field: 'ring_name',
      flex: 2,
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.RINGS.LABEL.RINGNAME'}),
      editable: true,
      cellClass: 'editable-cell',
    },
  ]

  const handleButtonClick = () => {
    setShowDialog(true)
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleOnRowSelected = (event) => {
    if (event.node.selected) {
      setSelectedRowData(event.data);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 110,
      width: 110,
      resizable: true,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: function (params) {
        if (typeof params.value === 'number') {
          return {textAlign: 'center'}
        } else {
          return {textAlign: 'left'}
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: 'ag-center-aligned-header',
      singleClickEdit: true,
    }
  }, [])

  /* WA - Row Selector */
  const effectiveContainerStyle = useMemo(() => {
      // Get actual number of rows to display (min of visibleRows and actual data length)
      const actualVisibleRows = rowData?.length > 0 
        ? Math.min(visibleRows, rowData?.length)
        : visibleRows;
    
      // Calculate the computed height based on actual visible rows
      const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
      
      // If container style exists and visible rows is greater than 0, compare heights
      if (visibleRows > 0) {
        // Parse the container style height (removing 'px' if present)
        const containerHeight = parseInt(containerStyle?.height);

      // If row data is empty, we return the existing container height
      if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
      }
        
        // If container height is greater than computed height, use container height
        if (containerHeight > computedHeight) {
          return containerStyle;
        }
      } else if (containerStyle) {
        return containerStyle;
      }
      
      // Default to computed height based on visible rows
      return {
        width: '100%',
        height: `${computedHeight}px`
      };
    }, [containerStyle, visibleRows, rowData?.length]);
    /* WA - Row Selector */

  useEffect(() => {
    // update added_rings array
    methods.setValue('rings', addedRings, {shouldDirty: true})
  }, [addedRings])

  const onGridReady = (params) => {
    setRingxFacilityGridApi(params.api)
    setRingxFacilityColumnApi(params.columnApi)
  }

  const handleOnRowDragEnd = () => {
    // Get the total number of rows in the grid
    let totalRowCount = ringxFacilityGridApi.getDisplayedRowCount()

    // Iterate through each row index and retrieve the row data object
    const newOrderedAllRings = []
    for (let i = 0; i < totalRowCount; i++) {
      let displayedRowData = ringxFacilityGridApi.getDisplayedRowAtIndex(i).data
      displayedRowData.ring_number = i + 1
      newOrderedAllRings.push(displayedRowData)
    }

    // Add the new row to the addedRings array
    setAddedRings(newOrderedAllRings)

    setRowData(newOrderedAllRings)

    // clear column state (sort order)
    ringxFacilityColumnApi.resetColumnState()
  }

  const handleOnMinusClick = () => {
    if (!selectedRowData) {
      // no row is selected, do nothing
      return
    }

    //Allow ring delete only if ring has no related schedule
    if(props?.showData?.ring_ids_with_schedule.includes(selectedRowData.ring_id)){
      alertDialog({message: `${selectedRowData.ring_name} cannot be removed because it has scheduled classes in this show.`, title: 'Error'})
      return false;
    }

    setAvailableToAddRings([...availableToAddRings, selectedRowData])
    setDeletedRings([...deletedRings, selectedRowData])
    const newRowData = rowData.filter((row) => row.ring_id !== selectedRowData.ring_id)

    // Update the 'number' property of the remaining rows
    for (let i = 0; i < newRowData.length; i++) {
      newRowData[i].ring_number = i + 1
    }

    // Clear the selected row
    setSelectedRowData(null)

    // Add the new row to the addedRings array
    setAddedRings(newRowData)
    // Save the updated row data to state
    setRowData(newRowData)
  }

  useEffect(() => {
    methods.setValue('deleted_rings', deletedRings, {shouldDirty: true})
  }, [deletedRings])

  const onCellEditingStopped = (params) => {
    // Update the rowData with the edited value
    const {data, colDef, newValue} = params
    const updatedRowData = rowData.map((row) => {
      if (row.ring_id === data.ring_id) {
        return {
          ...row,
          [colDef.field]: newValue,
        }
      }
      return row
    })
    setAddedRings(updatedRowData)
    setRowData(updatedRowData)
  }

  async function getShowFacilityRings(facility_id) {
    // Show loading overlay
    if(!props.hideLoadingOverlay){ loadingOverlay({show: true}) }
    let facilityRings = await axios.get(process.env.REACT_APP_NEST_API_URL + '/facilities/facilityRings', {
      params: {
        facility_id: facility_id,
        customer_id: customer_id,
      },
    });

    //hide loading overlay  
    loadingOverlay({show: false})
    return facilityRings.data;
  }

  const handleFacilityChange = async (selectedOption) => {
    if (selectedOption.value == selectedFacility) {
      return;
    }

    //Get facility rings for selected facility
    let facilityRings = await getShowFacilityRings(selectedOption.value);

    //Validate all the rings with schedule exist in updated facility
    for(let schedule_ring_id of props?.showData?.ring_ids_with_schedule){
      //New facility is missing any of rings with schedule in this show
      if(!facilityRings.some((row) => row.ring_id === schedule_ring_id)){
        alertDialog({message: `Facility cannot be changed due to missing matching rings in new facility for already scheduled classes of this show.`, title: 'Error', icon: 'stop'})
        methods.setValue('show.facility_id', selectedFacility, {shouldDirty: true});
        return false;
      }
    }
    
    if (!selectedOption.value) {
      methods.setValue('show.facility_id', 0, {shouldDirty: true})
      setSelectedFacility(selectedOption.value)
      setDeletedRings([...deletedRings, ...rowData]);
      setRowData([])
      setAddedRings([])
      setAvailableToAddRings([])
    }
    else {
      let choice = selectedFacility <= 0 ? true : await confirmDialog({ message: 'Rings and Barns setup will be changed on facility change. Are you sure you want to continue?', icon: 'warning'});
      if (choice) {
        setAvailableToAddRings([])
        setSelectedFacility(selectedOption.value)
        methods.setValue('show.facility_id', selectedOption.value, {shouldDirty: true})

        if(rowData){
          setDeletedRings([...deletedRings, ...rowData]);
        }
        setRowData(facilityRings)
        setAddedRings(facilityRings)
        methods.setValue('facilityChangedFromRings', methods.getValues('show.facility_id'));
      }
      else{
        methods.setValue('show.facility_id', selectedFacility, {shouldDirty: true})
      }
    }
  }

  const addRow = () => {
    let newRowData = [...rowData]
    let ringNumber = newRowData.length + 1
    selectedRows.forEach((selectedRow) => {
      // Check if the selected row is already in the rowData array
      if (!newRowData.some((row) => row.ring_id === selectedRow.ring_id)) {
        let newRing = {
          ring_id: selectedRow.ring_id,
          ring_number: ringNumber,
          ring_name: selectedRow.ring_name,
        }
        newRowData.push(newRing)
        ringNumber++
      } else {
        // Show alert message if the selected row is already in the rowData array
        alertDialog({
          message: `The selected ring (${selectedRow.ring_name}) has already been added.`,
          title: 'Alert',
        })
      }
    })
    // Remove selected rows from facilityRingsRowData
    let newDeleteArray = deletedRings.filter((row) => {
      return !selectedRows.some((selectedRow) => selectedRow.ring_id === row.ring_id)
    })

    let newFacilityRingsRowData = availableToAddRings.filter((row) => {
      return !selectedRows.some((selectedRow) => selectedRow.ring_id === row.ring_id)
    })
    // Add the new row to the addedRings array
    setAddedRings(newRowData)
    setRowData(newRowData)
    setAvailableToAddRings(newFacilityRingsRowData)
    setDeletedRings(newDeleteArray)
    setSelectedRows([])
  }

  return (
    <>
      <div className='row mb-2'>
        <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='facility_id' data-tooltip-id="SHOW.DETAIL.TAB.RINGS.LABEL.FACILITY">
          {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FACILITY'})}
        </label>
        <div className='col-lg-4'>
          {props.form_meta.facilities && (
            <Controller
              name='show.facility_id'
              id='facility_id'
              render={({field: {onChange, value, name}}) => (
                <Select
                  options={props.form_meta.facilities}
                  value={ props.form_meta.facilities.find((ss) => ss.value === value) ?? ""}
                  name={name}
                  isSearchable={true}
                  onChange={(facilities) => {
                    onChange(facilities.value);
                    if(propFacilityId > 0){
                      handleFacilityChange(facilities)
                    }
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                  })}
                  styles={reactSelectStyles}
                  placeholder='Select'
                />
              )}
            />
          )}
        </div>
      </div>

      <div className='row mb-2'>
        <div className='col-lg d-flex align-items-center mb-2'>
          <div style={{width: '100%'}}> {/* WA - Row Selector */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Shows - Rings"}/>
            </div>
            <div style={effectiveContainerStyle}>
              <div style={gridStyle} className={gridClassName}>              
                <AgGridReact
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  rowDragEntireRow={true}
                  rowDragManaged={true}
                  suppressMoveWhenRowDragging={false}
                  animateRows={true}
                  onDragStopped={handleOnRowDragEnd}
                  onRowSelected={handleOnRowSelected}
                  rowSelection={'single'}
                  onCellEditingStopped={onCellEditingStopped}
                  headerHeight={getNarrowHeaderHeight}
                  rowHeight={getNarrowRowHeight}
                  suppressRowHoverHighlight={true}
                  stopEditingWhenCellsLoseFocus={true}
                ></AgGridReact>
              </div>
            </div> {/* WA - Row Selector */}
          </div>
        </div>

        <div className='col-lg-1'>
          { hasAreaWritePermission('rings') &&
          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleButtonClick}
            disabled={availableToAddRings.length === 0}
            data-tooltip-id="SHOW.DETAIL.TAB.RINGS.BUTTON.ADD"
          >
            <i className='fas fa-plus fs-5 px-1 py-3'></i>
          </button>
          }
          <div className='separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none'></div>

          { hasAreaDeletePermission('rings') &&
          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleOnMinusClick}
            data-tooltip-id="SHOW.DETAIL.TAB.RINGS.BUTTON.REMOVE"
          >
            <i className='fas fa-minus fs-5 px-1 py-3'></i>
          </button>
          }
        </div>
      </div>
      <Modal
        id='kt_modal_create_app'
        show={showDialog}
        onHide={handleDialogClose}
        tabIndex={-1}
        backdrop='static'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
        onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
          let isButtonFocused = false
          let activeElement = document.activeElement //gets the currently focussed element
          if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
              isButtonFocused = true;
          }
          if (event.key === "Enter" && !isButtonFocused) {
            addRow()
          }
        }}
      >
        <div className='modal-header py-0 px-4'>
          <h2 className='fs-4'>
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TITLE.FACILITYRINGS'})}
          </h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleDialogClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body py-3 px-4'>
          <div className='ag-theme-alpine' style={{height: '300px', width: '100%'}}>
            <AgGridReact
              headerHeight={getNarrowHeaderHeight}
              rowHeight={getNarrowRowHeight}
              rowData={availableToAddRings}
              columnDefs={facilityRingsColumnDefs}
              defaultColDef={defaultColDef}
              rowSelection="single"
              onSelectionChanged={(event) => {
                setSelectedRows(event.api.getSelectedRows())
              }}
            />
          </div>
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={handleDialogClose}>
              {intl.formatMessage({
                id: 'FORM.INPUT.SHOWS.FACILITYRINGS.BUTTON.CLOSE',
              })}
            </button>
            <button className='btn btn-sm btn-dark fw-bold' type='button' onClick={addRow} autoFocus data-tooltip-id="SHOW.DETAIL.MODAL.FACILITYRINGS.BUTTON.ADDTOSHOW">
              {intl.formatMessage({
                id: 'FORM.INPUT.SHOWS.FACILITYRINGS.BUTTON.ADDTOSHOW',
              })}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {RingsTab}
