/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../redux/hooks';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';

const ClassesList = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const currencyFormatter = useCurrencyFormatter()
    
  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'class_id', headerName: intl.formatMessage({ id: 'LIST.CLASSES.CLASSID' }), filter: 'agNumberColumnFilter', hide:true},
    { field: 'number', headerName: intl.formatMessage({ id: 'LIST.CLASSES.NUMBER' }), filter: 'agNumberColumnFilter'},
    { field: 'name', width: 400, headerName: intl.formatMessage({ id: 'LIST.CLASSES.NAME' }), filter: 'agTextColumnFilter', },
    { field: 'division_name', width: 400, headerName: intl.formatMessage({ id: 'LIST.CLASSES.DIVISIONNAME' }), filter: 'agTextColumnFilter', },
    { field: 'prize_template', width: 250, headerName: intl.formatMessage({ id: 'LIST.CLASSES.PLACINGTEMPLATE' }), filter: 'agTextColumnFilter', },
    // { field: 'show_name', width: 400, headerName: intl.formatMessage({ id: 'LIST.CLASSES.SHOWNAME' }), filter: 'agTextColumnFilter', },
    { field: 'sponsor', width: 250, headerName: intl.formatMessage({ id: 'LIST.CLASSES.SPONSOR' }), filter: 'agTextColumnFilter', },
    { 
        field: 'class_price', 
        headerName: intl.formatMessage({ id: 'LIST.CLASSES.CLASSPRICE' }), 
        filter: 'agNumberColumnFilter', 
        cellRenderer: params => currencyFormatter(params.value), 
        cellStyle: { textAlign: 'right' }
    },
    { field: 'entry_count', headerName: intl.formatMessage({ id: 'LIST.CLASSES.ENTRYCOUNT' }), filter: 'agNumberColumnFilter' },
    { field: 'max_size', headerName: intl.formatMessage({ id: 'LIST.CLASSES.MAXSIZE' }), filter: 'agNumberColumnFilter'},
    { field: 'scheduled_date', headerName: intl.formatMessage({ id: 'LIST.CLASSES.SCHEDULEDDATE' }), filter: 'agDateColumnFilter', cellRenderer: DateRenderer,  cellStyle: { textAlign: 'center' }, },
    { field: 'schedule_ringnumber', headerName: intl.formatMessage({ id: 'LIST.CLASSES.SCHEDULERINGNUMBER' }), filter: 'agNumberColumnFilter'},
    { field: 'results_verified', headerName: intl.formatMessage({ id: 'LIST.CLASSES.RESULTSVERIFIED' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, },
    { field: 'cancelled', headerName: intl.formatMessage({ id: 'LIST.CLASSES.CANCELLED' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer,cellStyle: { textAlign: 'center' }, },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CLASSES' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Classes'} columnDefs={columnDefs} apiEndPoint={'classes/list'} 
        onRowDoubleClicked={(row_data) => {navigate('/classes/detail/'+row_data.class_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/classes/detail/'+row_data.class_id+`?customer_id=${customer_id}`,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export { ClassesList }
