import { useMemo, useState } from "react";
import OutputListingGridStatic from "../../../modules/output-listing/OutputListingGridStatic";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import { CommunicationDetail } from "./CommunicationsTab/CommunicationDetail";
import { useAlert, useLoadingOverlay } from "../../../modules/sgl-utils/DialogsProvider";
import axios from "axios";
import DateRenderer from "../../../modules/output-listing/renderers/DateRenderer";
import TimeRenderer from "../../../modules/output-listing/renderers/TimeRenderer";
import {RowSelector, DEFAULT_VISIBLE_ROWS} from "../../../modules/output-listing/OutputListingRowOptions";

const defaultCommunicationDetail = {
    show: false,
    data: {
        sent_on: "",
        created_on: "",
        sgl_id: 0,
        status: "",
        from_email: "",
        to_email: "",
        to_name: "",
        subject: "",
        email_body: "",
        error: ""
    }
}
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
export const CommunicationsTab = () => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);
    const intl = useIntl()
    const methods = useFormContext()
    const [communicationDetail, setCommunicationDetail] = useState(defaultCommunicationDetail)
    const loadingOverlay = useLoadingOverlay()
    const alertDialog = useAlert()

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const columnDefs = [
        { 
            cellRenderer: DateRenderer,
            field: 'created_date',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONDATE' }),
            width: 140,
            cellClass: 'text-center'  
        },
        { 
            cellRenderer: TimeRenderer,
            field: 'created_time', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONTIME' }), 
            width: 140,
            cellClass: 'text-center'    
        },
        { 
            field: 'subject', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.SUBJECT' }), 
            flex: 1 
        },
        { 
            field: 'status', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.STATUS' }),
            width: 140  
        },
        { 
            field: 'to_email', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.COMMUNICATIONS.SENDTOEMAIL' }), 
            flex: 1
        },
    ]

    const getCommunicationDetail = async (node) => {
        loadingOverlay({ show: true, message: 'Loading...' })
        try {
            const response = await axios.get(`${NEST_API_URL}/people/communications/detail?customer_id=${node.data.customer_id}&email_queue_id=${node.data.sgl_id}`)

            if (response.data.success) {
                setCommunicationDetail({ show: true, data: response.data.communication})
            }
        } catch (reason) {
            alertDialog({ message: reason?.response?.data?.error ?? 'Something went wrong..'})
        } finally {
            loadingOverlay({ show: false, message: 'Loading...' })
        }
    }


    return (
        <div className='row align-items-center mb-2'>
            <div className='form-group mb-2'> {/* WA - Row Selector */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <RowSelector
                    value={visibleRows}
                    onChange={setVisibleRows}
                    area={"People - Communications"}
                    />
                </div>
                <div className='row mb-2'>
                    <OutputListingGridStatic 
                        rowData={methods.watch('communications') ?? []}
                        onCellDoubleClick={async (node) => {
                            await getCommunicationDetail(node)
                        }}
                        area ={"People - Communications"}
                        columnDefs={columnDefs} 
                        containerStyle={containerStyle} 
                        visibleRows={visibleRows}
                    />
                </div>
            </div> {/* WA - Row Selector */}
            { communicationDetail.show && <CommunicationDetail 
                {...communicationDetail}
                handleClose={() => setCommunicationDetail(defaultCommunicationDetail)}
            /> }
        </div>
    )
}