import React, {useState, useCallback, useEffect, useRef} from 'react'
import {Stage, Layer, Rect, Text, Line, Group, Transformer, Circle} from 'react-konva'
import Split from 'react-split'
import {
  Square,
  Circle as CircleIcon,
  Triangle,
  Type,
  Trash2,
  Copy,
  FlipHorizontal,
  FlipVertical,
  Minus,
  Save,
  SaveAll,
  Upload,
  Redo,
  Lock,
  FilePlus,
  LockOpen,
  ChevronUp,
  ChevronDown,
  ArrowUpToLine,
  ArrowDownToLine,
  Layers,
  FileEdit,
  Undo,
  RectangleHorizontal,
  RectangleVertical,
} from 'lucide-react'
import {Palette} from 'lucide-react'
import './barn-designer.css'
import axios from 'axios'
import {useAppSelector} from '../../redux/hooks'
import {useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider'
import {useParams} from 'react-router-dom'

const feetToPixelRatio = 10
const MAX_HISTORY_STATES = 50
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const BarnDesigner = () => {
  const customerId = useAppSelector((state) => state.showCompany.company_id)
  const [currentDesignId, setCurrentDesignId] = useState(null)
  const loadingOverlay = useLoadingOverlay()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const {barn_id} = useParams()
  const [isDesignActive, setIsDesignActive] = useState(true)

  const CANVAS_FONT_FAMILY = "'Helvetica Neue', Arial, sans-serif"

  const baseFormDetails = {
    structureLength: 500,
    structureWidth: 100,
    horizontalAisleCount: 1,
    verticalAisleCount: 1,
    horizontalAisleWidth: 15,
    verticalAisleWidth: 15,
    stallLength: 15,
    stallWidth: 15,
    exteriorStallRow: false,
  }
  // Original barn state
  const [formData, setFormData] = useState({
    structureLength: 500,
    structureWidth: 100,
    horizontalAisleCount: 1,
    verticalAisleCount: 1,
    horizontalAisleWidth: 15,
    verticalAisleWidth: 15,
    stallLength: 15,
    stallWidth: 15,
    exteriorStallRow: false,
  })

  const [customizations, setCustomizations] = useState({
    stalls: {},
    aisles: {},
  })

  const [selectedId, setSelectedId] = useState(null)
  const [isEditingLabel, setIsEditingLabel] = useState(false)
  const [tempLabel, setTempLabel] = useState('')

  // Canvas state
  // const [canvasElements, setCanvasElements] = useState([])
  const [canvasElements, setCanvasElementsState] = useState([])
  const [selectedElement, setSelectedElement] = useState(null)
  const [isDragging, setIsDragging] = useState(false)

  // UI state
  const [errors, setErrors] = useState({})
  const [barnLayout, setBarnLayout] = useState({})
  const [layoutErrors, setLayoutErrors] = useState([])
  const [stageScale, setStageScale] = useState(1)
  const [stagePosition, setStagePosition] = useState({x: 0, y: 0})
  const [isEditMode, setIsEditMode] = useState(false)

  const [isMultiSelect, setIsMultiSelect] = useState(false)
  const [draggedElements, setDraggedElements] = useState({
    mouseX: 0,
    mouseY: 0,
    elements: [],
  })

  const [lastStallNumber, setLastStallNumber] = useState(0)
  const [lastAisleNumber, setLastAisleNumber] = useState(0)
  const [totalHorizontalAisles, setTotalHorizontalAisles] = useState(0)
  const [totalVerticalAisles, setTotalVerticalAisles] = useState(0)

  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showLoadDialog, setShowLoadDialog] = useState(false)
  const [designName, setDesignName] = useState('')

  const [selectedElements, setSelectedElements] = useState([])

  const [showLayerPanel, setShowLayerPanel] = useState(false)

  const [history, setHistory] = useState([])
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1)

  // Refs
  const stageRef = useRef(null)
  const transformerRef = useRef(null)
  const selectedShapeRef = useRef(null)

  const shapeTemplates = {
    rectangle: {
      type: 'rectangle',
      width: 200,
      height: 200,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Square,
    },
    circle: {
      type: 'circle',
      radius: 50,
      width: 200, // Added for consistent sizing
      height: 200, // Added for consistent sizing
      fill: '#e9edef',
      stroke: '#333333',
      icon: CircleIcon,
    },
    triangle: {
      type: 'triangle',
      points: [50, 0, 0, 100, 100, 100], // Updated points for better triangle
      width: 250,
      height: 200,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Triangle,
    },
    'stall-top': {
      type: 'stall',
      orientation: 'top',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Square,
    },
    'stall-right': {
      type: 'stall',
      orientation: 'right',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Square,
    },
    'stall-bottom': {
      type: 'stall',
      orientation: 'bottom',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Square,
    },
    'stall-left': {
      type: 'stall',
      orientation: 'left',
      width: formData.stallLength * feetToPixelRatio,
      height: formData.stallWidth * feetToPixelRatio,
      fill: '#e9edef',
      stroke: '#333333',
      icon: Square,
    },
    'line-solid-h': {
      type: 'line',
      width: 400,
      height: 10,
      points: [0, 5, 400, 5],
      stroke: '#c5c5c5',
      strokeWidth: 5,
      lineCap: 'square',
      hitStrokeWidth: 60,
      icon: Minus,
    },
    'line-dashed-h': {
      type: 'line',
      width: 400,
      height: 10,
      points: [0, 5, 400, 5],
      stroke: '#c5c5c5',
      strokeWidth: 5,
      lineCap: 'square',
      dash: [15, 15],
      hitStrokeWidth: 60,
      icon: Minus,
    },
    // 'line-dotted-h': {
    //   type: 'line',
    //   width: 400,
    //   height: 10,
    //   points: [0, 5, 400, 5],
    //   stroke: '#c5c5c5',
    //   strokeWidth: 5,
    //   lineCap: 'round',
    //   dash: [2, 5],
    //   hitStrokeWidth: 60,
    //   icon: Minus,
    // },

    // Aisle templates
    'aisle-horizontal': {
      type: 'aisle',
      orientation: 'horizontal',
      height: formData.horizontalAisleWidth * feetToPixelRatio, // default width in pixels
      width: formData.structureLength * feetToPixelRatio, // default height in pixels
      fill: '#f6f6f6',
      stroke: '#cccccc',
      strokeWidth: 1,
      icon: RectangleHorizontal, // pick any icon you'd like
    },
    'aisle-vertical': {
      type: 'aisle',
      orientation: 'vertical',
      width: formData.verticalAisleWidth * feetToPixelRatio, // default width in pixels
      height: formData.structureWidth * feetToPixelRatio, // default height in pixels
      fill: '#f6f6f6',
      stroke: '#cccccc',
      strokeWidth: 1,
      icon: RectangleVertical, // or some other icon
    },
  }

  const saveToHistory = (newElements) => {
    // Skip if the new state is identical to the current state
    if (
      currentHistoryIndex >= 0 &&
      JSON.stringify(history[currentHistoryIndex]) === JSON.stringify(newElements)
    ) {
      return
    }

    setHistory((prev) => {
      // Remove any future states if we're not at the end
      const historyCut = prev.slice(0, currentHistoryIndex + 1)

      // Add new state
      const newHistory = [...historyCut, newElements]

      // Keep only the last MAX_HISTORY_STATES states
      if (newHistory.length > MAX_HISTORY_STATES) {
        newHistory.shift()
      }

      return newHistory
    })

    setCurrentHistoryIndex((prev) => {
      const newIndex = Math.min(prev + 1, MAX_HISTORY_STATES - 1)
      return newIndex
    })
  }

  const UndoRedoControls = ({canUndo, canRedo, onUndo, onRedo}) => {
    const canActuallyUndo = canUndo && history.length > 1 && currentHistoryIndex > 0

    return (
      <div
        className='undo-redo-controls'
        style={{
          position: 'absolute',
          right: '20px',
          bottom: '40px',
          display: 'flex',
          gap: '8px',
          backgroundColor: 'white',
          padding: '4px',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          zIndex: 1000,
        }}
      >
        <button
          onClick={onUndo}
          disabled={!canActuallyUndo}
          className='control-button'
          style={{
            padding: '8px',
            borderRadius: '4px',
            border: 'none',
            background: canUndo ? '#f0f0f0' : '#e0e0e0',
            cursor: canUndo ? 'pointer' : 'not-allowed',
            opacity: canUndo ? 1 : 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title='Undo'
        >
          <Undo size={16} />
        </button>
        <button
          onClick={onRedo}
          disabled={!canRedo}
          className='control-button'
          style={{
            padding: '8px',
            borderRadius: '4px',
            border: 'none',
            background: canRedo ? '#f0f0f0' : '#e0e0e0',
            cursor: canRedo ? 'pointer' : 'not-allowed',
            opacity: canRedo ? 1 : 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title='Redo'
        >
          <Redo size={16} />
        </button>
      </div>
    )
  }

  const handleUndo = useCallback(() => {
    if (currentHistoryIndex > 0) {
      const newIndex = currentHistoryIndex - 1
      const previousState = history[newIndex]
      if (previousState) {
        setCurrentHistoryIndex(newIndex)
        setCanvasElementsState(previousState) // Use setCanvasElementsState to avoid recursive history saving
      }
    }
  }, [currentHistoryIndex, history])

  const handleRedo = useCallback(() => {
    if (currentHistoryIndex < history.length - 1) {
      const newIndex = currentHistoryIndex + 1
      const nextState = history[newIndex]
      if (nextState) {
        setCurrentHistoryIndex(newIndex)
        setCanvasElementsState(nextState) // Use setCanvasElementsState to avoid recursive history saving
      }
    }
  }, [currentHistoryIndex, history])

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Add these conditions for undo/redo
      if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
        if (e.shiftKey) {
          // Ctrl/Cmd + Shift + Z = Redo
          handleRedo()
        } else {
          // Ctrl/Cmd + Z = Undo
          handleUndo()
        }
        return
      }

      // Your existing keyboard handler code...
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [selectedElement, history, currentHistoryIndex])

  // Create a wrapper function that handles both state update and history
  const setCanvasElements = useCallback(
    (newElements) => {
      // If newElements is a function, call it with current state to get the new value
      const updatedElements =
        typeof newElements === 'function' ? newElements(canvasElements) : newElements

      // Update the state
      setCanvasElementsState(updatedElements)

      // Save to history
      // Don't save if this is an undo/redo operation
      if (
        !history[currentHistoryIndex] ||
        JSON.stringify(history[currentHistoryIndex]) !== JSON.stringify(updatedElements)
      ) {
        saveToHistory(updatedElements)
      }
    },
    [canvasElements, history, currentHistoryIndex]
  )

  const moveElementUp = (elementIds) => {
    // Handle both single ID and array of IDs
    const ids = Array.isArray(elementIds) ? elementIds : [elementIds]

    setCanvasElements((prev) => {
      const newElements = [...prev]
      ids.forEach((elementId) => {
        const currentIndex = newElements.findIndex((el) => el.id === elementId)
        if (currentIndex < newElements.length - 1) {
          // Swap with the next element
          ;[newElements[currentIndex], newElements[currentIndex + 1]] = [
            newElements[currentIndex + 1],
            newElements[currentIndex],
          ]
        }
      })
      return newElements
    })
  }

  const moveElementDown = (elementIds) => {
    const ids = Array.isArray(elementIds) ? elementIds : [elementIds]

    setCanvasElements((prev) => {
      const newElements = [...prev]
      // Move in reverse order for downward movement to maintain relative positions
      ids.reverse().forEach((elementId) => {
        const currentIndex = newElements.findIndex((el) => el.id === elementId)
        if (currentIndex > 0) {
          // Swap with the previous element
          ;[newElements[currentIndex], newElements[currentIndex - 1]] = [
            newElements[currentIndex - 1],
            newElements[currentIndex],
          ]
        }
      })
      return newElements
    })
  }

  const moveElementToFront = (elementIds) => {
    const ids = Array.isArray(elementIds) ? elementIds : [elementIds]

    setCanvasElements((prev) => {
      // Remove all selected elements
      const remainingElements = prev.filter((el) => !ids.includes(el.id))
      // Get the selected elements in their current order
      const selectedElements = prev.filter((el) => ids.includes(el.id))
      // Add selected elements to the end (top)
      return [...remainingElements, ...selectedElements]
    })
  }

  const moveElementToBack = (elementIds) => {
    const ids = Array.isArray(elementIds) ? elementIds : [elementIds]

    setCanvasElements((prev) => {
      // Remove all selected elements
      const remainingElements = prev.filter((el) => !ids.includes(el.id))
      // Get the selected elements in their current order
      const selectedElements = prev.filter((el) => ids.includes(el.id))
      // Add selected elements to the beginning (bottom)
      return [...selectedElements, ...remainingElements]
    })
  }

  // Position menu component
  const PositionMenu = ({elementId, onClose}) => {
    const elementIds = Array.isArray(elementId) ? elementId : [elementId]

    const handleAction = (action, e) => {
      e.preventDefault()
      e.stopPropagation()

      switch (action) {
        case 'up':
          moveElementUp(elementIds)
          break
        case 'down':
          moveElementDown(elementIds)
          break
        case 'front':
          moveElementToFront(elementIds)
          break
        case 'back':
          moveElementToBack(elementIds)
          break
        case 'close':
          onClose()
          break
      }
    }

    return (
      <div
        className='position-menu'
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          padding: '4px',
          zIndex: 1000,
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <button
          onClick={(e) => handleAction('up', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ChevronUp size={16} />
          <span>Up</span>
        </button>

        <button
          onClick={(e) => handleAction('down', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ChevronDown size={16} />
          <span>Down</span>
        </button>

        <button
          onClick={(e) => handleAction('front', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ArrowUpToLine size={16} />
          <span>To Forward</span>
        </button>

        <button
          onClick={(e) => handleAction('back', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ArrowDownToLine size={16} />
          <span>To Bottom</span>
        </button>

        <button
          onClick={(e) => handleAction('close', e)}
          className='menu-item'
          style={{
            borderTop: '1px solid #eee',
            marginTop: '4px',
            paddingTop: '4px',
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <span>Close</span>
        </button>
      </div>
    )
  }

  const LayerPanel = ({elements, onReorder}) => {
    const [draggedItem, setDraggedItem] = useState(null)

    const handleDragStart = (e, index) => {
      setDraggedItem(index)
      e.dataTransfer.effectAllowed = 'move'
    }

    const handleDragOver = (e, index) => {
      e.preventDefault()
      if (draggedItem === null) return

      onReorder(draggedItem, index)
      setDraggedItem(index)
    }

    return (
      <div className='layer-panel'>
        <div className='layer-panel-header'>
          <h3>Layers</h3>
        </div>
        <div className='layer-list'>
          {elements.map((element, index) => (
            <div
              key={element.id}
              className='layer-item'
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
            >
              <span className='layer-icon'>{/* Add appropriate icon based on element type */}</span>
              <span className='layer-name'>{element.name || element.id}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const MultiSelectToolbar = ({
    selectedElements,
    onDuplicate,
    onGroup,
    onUngroup,
    onDelete,
    isGrouped,
  }) => {
    const handleClick = (e, action) => {
      e.stopPropagation()
      switch (action) {
        case 'duplicate':
          onDuplicate()
          break
        case 'group':
          onGroup()
          break
        case 'ungroup':
          onUngroup()
          break
        case 'delete':
          onDelete()
          break
        default:
          break
      }
    }

    return (
      <div
        className='multi-select-toolbar'
        style={{
          position: 'absolute',
          left: '50%',
          top: '16px',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '4px',
          padding: '4px',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          zIndex: 1000,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={(e) => handleClick(e, 'duplicate')}
          style={{
            padding: '8px',
            border: 'none',
            borderRadius: '4px',
            background: '#f0f0f0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title='Duplicate'
        >
          <Copy size={16} />
        </button>
        <button
          disabled={onGroup == null}
          onClick={(e) => handleClick(e, isGrouped ? 'ungroup' : 'group')}
          style={{
            padding: '8px',
            border: 'none',
            borderRadius: '4px',
            background: '#f0f0f0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title={isGrouped ? 'Ungroup' : 'Group'}
        >
          {isGrouped ? <LockOpen size={16} /> : <Lock size={16} />}
        </button>

        {selectedElements?.length <= 1 && (
          <button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowPositionMenu(!showPositionMenu)
            }}
            style={{
              padding: '8px',
              border: 'none',
              borderRadius: '4px',
              background: '#f0f0f0',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            title='Position'
          >
            <Layers size={16} />
          </button>
        )}
        {showPositionMenu && (
          <PositionMenu elementId={selectedElements} onClose={() => setShowPositionMenu(false)} />
        )}

        {/* Add before the delete button */}
        <button
          style={{
            padding: '0px',
            border: 'none',
            borderRadius: '4px',
            background: '#f0f0f0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ColorPicker
            initialColor='#e9edef'
            onColorChange={(color) => {
              setCanvasElements((prev) =>
                prev.map((el) => {
                  if (selectedElements.includes(el.id)) {
                    if (el.isGroup) {
                      // Handle grouped elements
                      return {
                        ...el,
                        children: el.children.map((groupChild) => ({
                          ...groupChild,
                          children: groupChild.children?.map((child) => {
                            if (child.type === 'line' && child.closed) {
                              return {...child, fill: color}
                            }
                            if (['rectangle', 'circle'].includes(child.type)) {
                              return {...child, fill: color}
                            }
                            return child
                          }),
                        })),
                      }
                    } else {
                      // Handle non-grouped elements
                      return {
                        ...el,
                        children: el.children.map((child) => {
                          if (child.type === 'line' && child.closed) {
                            return {...child, fill: color}
                          }
                          if (['rectangle', 'circle'].includes(child.type)) {
                            return {...child, fill: color}
                          }
                          return child
                        }),
                      }
                    }
                  }
                  return el
                })
              )
            }}
          />
        </button>

        <button
          onClick={(e) => handleClick(e, 'delete')}
          style={{
            padding: '8px',
            border: 'none',
            borderRadius: '4px',
            background: '#f0f0f0',
            color: '#ff4444',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title='Delete'
        >
          <Trash2 size={16} />
        </button>
      </div>
    )
  }

  const handleElementClick = (elementId, e) => {
    if (!isEditMode) return

    setShowPositionMenu(false)

    // Get the clicked node
    const clickedNode = stageRef.current?.findOne(`#${elementId}`)
    if (!clickedNode) return

    console.log('Clicked Element ID:', elementId)
    // Get the element data
    const element = canvasElements.find((el) => el.id === elementId)
    const isGroupElement = element?.isGroup || false

    e.cancelBubble = true

    if (e.evt.shiftKey) {
      // Shift is pressed - handle multi-select
      setSelectedElements((prev) => {
        let newSelection = []
        if (isGroupElement) {
          newSelection = prev.includes(elementId)
            ? prev.filter((id) => id !== elementId)
            : [...prev, elementId]
        } else {
          newSelection = prev.includes(elementId)
            ? prev.filter((id) => id !== elementId)
            : [...prev, elementId]
        }

        // If only one element is selected, set it as the selected element
        // This will initiate the transformer for the single element
        if (newSelection.length === 1) {
          setSelectedElements([elementId])
          setSelectedElement(elementId)
        }

        // Update transformer immediately
        requestAnimationFrame(() => {
          if (transformerRef.current && stageRef.current) {
            const nodes = newSelection
              .map((id) => stageRef.current.findOne(`#${id}`))
              .filter(Boolean)
            transformerRef.current.nodes(nodes)
            transformerRef.current.getLayer().batchDraw()
          }
        })

        return newSelection
      })

      setIsMultiSelect(true)
    } else {
      // No shift - single select
      const isDeselect = selectedElements.length === 1 && selectedElements[0] === elementId

      if (isDeselect) {
        setSelectedElements([])
        setSelectedElement(null)
        if (transformerRef.current) {
          transformerRef.current.nodes([])
          transformerRef.current.getLayer().batchDraw()
        }
      } else {
        if (isGroupElement) {
          setSelectedElements([elementId])
          // fix when the group is selected then transformer is not working
          setSelectedElement(elementId)
          setIsMultiSelect(true)
        } else {
          setSelectedElements([elementId])
          setSelectedElement(elementId)
        }

        requestAnimationFrame(() => {
          if (transformerRef.current && clickedNode) {
            transformerRef.current.nodes([clickedNode])
            transformerRef.current.getLayer().batchDraw()
          }
        })
      }

      if (!isGroupElement) {
        setIsMultiSelect(false)
      }
    }

    e.cancelBubble = true
  }

  const handleGroupElements = () => {
    if (selectedElements.length < 2) return

    const elementsToGroup = canvasElements.filter((el) => selectedElements.includes(el.id))

    const nodes = selectedElements.map((id) => stageRef.current?.findOne(`#${id}`)).filter(Boolean)

    // Calculate group bounds
    const groupBounds = elementsToGroup.reduce(
      (bounds, el) => {
        const minX = Math.min(bounds.minX, el.x)
        const minY = Math.min(bounds.minY, el.y)
        const maxX = Math.max(bounds.maxX, el.x + (el.width || 0))
        const maxY = Math.max(bounds.maxY, el.y + (el.height || 0))
        return {minX, minY, maxX, maxY}
      },
      {minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity}
    )

    const groupId = `group-${Date.now()}`
    const groupElement = {
      id: groupId,
      type: 'group',
      x: groupBounds.minX,
      y: groupBounds.minY,
      width: groupBounds.maxX - groupBounds.minX,
      height: groupBounds.maxY - groupBounds.minY,
      draggable: true,
      isGroup: true,
      // Keep children with positions relative to group
      children: elementsToGroup.map((el) => {
        // find the node for the element
        return {
          ...el,
          x: el.x - groupBounds.minX, // Make position relative to group
          y: el.y - groupBounds.minY,
          width: el.width,
          height: el.height,
          rotation: el.rotation || 0,
          scaleX: el.scaleX || 1,
          scaleY: el.scaleY || 1,
          children: el.isLine
            ? el.children.map((child, index) => {
                // Adjust line points based on group position
                if (index === 0) {
                  // index 0 : because line is the first child of the group
                  return {
                    ...child,
                    x: 0,
                    y: 0,
                  }
                }
                return child
              })
            : el.children,
        }
      }),
    }

    setCanvasElements((prev) => {
      const remaining = prev.filter((el) => !selectedElements.includes(el.id))
      return [...remaining, groupElement]
    })

    setSelectedElements([groupId])
    setSelectedElement(groupId)
  }

  const handleUngroup = (groupId) => {
    const group = canvasElements.find((el) => el.id === groupId)
    if (!group || !group.isGroup) return

    const groupNode = stageRef.current?.findOne(`#${groupId}`)
    if (!groupNode) return

    // Extract elements from group with proper positioning
    const ungroupedElements = group.children.map((child, index) => {
      // Calculate absolute position based on group's position and transformation
      // const absPos = {
      //   x: group.x + child.x,
      //   y: group.y + child.y,
      // }
      const absPos = {
        x: group.x + child.x * (group.scaleX || 1),
        y: group.y + child.y * (group.scaleY || 1),
      }

      // For each child in the group

      const finalScaleX = (group.scaleX || 1) * (child.scaleX || 1)
      const finalScaleY = (group.scaleY || 1) * (child.scaleY || 1)
      const finalRotation = (group.rotation || 0) + (child.rotation || 0)

      return {
        ...child,
        x: absPos.x,
        y: absPos.y,
        rotation: finalRotation,
        scaleX: finalScaleX,
        scaleY: finalScaleY,
        children: child.isLine
          ? child.children.map((child, index) => {
              // Adjust line points based on group position
              if (index === 0) {
                // index 0 : because line is the first child of the group
                return {
                  ...child,
                  x: absPos.x,
                  y: absPos.y,
                }
              }
              return child
            })
          : child.children,
        draggable: true,
        id: child.isStall
          ? child.id
          : `${child.id.split('-')[0]}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      }
    })

    // make all the lines draggable again if they were part of a group that was ungrouped
    ungroupedElements.forEach((el) => {
      el.children.forEach((child) => {
        if (child.type === 'line') {
          child.draggable = true
        }
      })
    })

    // Update canvas elements - remove group and add ungrouped elements
    setCanvasElements((prev) => {
      const remaining = prev.filter((el) => el.id !== groupId)
      return [...remaining, ...ungroupedElements]
    })

    // Update selection to include all ungrouped elements
    //setSelectedElements(ungroupedElements.map((el) => el.id))
    setSelectedElement(null)
    setSelectedElements([])

    // Clear transformer
    if (transformerRef.current) {
      transformerRef.current.nodes([])
      transformerRef.current.getLayer().batchDraw()
    }
  }

  const handleDuplicateGroup = () => {
    const elementsToClone =
      selectedElements.length > 0
        ? canvasElements.filter((el) => selectedElements.includes(el.id))
        : [canvasElements.find((el) => el.id === selectedElement)]

    if (elementsToClone.length === 0) return

    const clonedElements = elementsToClone
      .map((el) => {
        const node = stageRef.current?.findOne(`#${el.id}`)
        if (!node) return null

        const clonedNode = node.clone()
        const attrs = clonedNode.attrs
        const newId = `${el.id.split('-')[0]}-${Date.now()}-${Math.random()
          .toString(36)
          .substr(2, 9)}`

        if (el.isLine) {
          // making sure deep copy of the line points
          return JSON.parse(
            JSON.stringify({
              ...el,
              id: newId,
              x: attrs.x + 20,
              y: attrs.y + 20,
              rotation: attrs.rotation,
              scaleX: attrs.scaleX,
              scaleY: attrs.scaleY,
              children: el.children?.map((child) => ({
                ...child,
                rotation: child.rotation,
                scaleX: child.scaleX,
                scaleY: child.scaleY,
                x: attrs.x + 20,
                y: attrs.y + 20,
              })),
            })
          )
        }

        // making sure deep copy of the elements
        return JSON.parse(
          JSON.stringify({
            ...el,
            id: newId,
            x: attrs.x + 20,
            y: attrs.y + 20,
            rotation: attrs.rotation,
            scaleX: attrs.scaleX,
            scaleY: attrs.scaleY,
            children: el.children?.map((child) => ({
              ...child,
              rotation: child.rotation,
              scaleX: child.scaleX,
              scaleY: child.scaleY,
            })),
          })
        )
      })
      .filter(Boolean)

    // Update stall numbers if needed
    let newStallNumber = lastStallNumber
    // Update aisle numbers if needed
    let newAisleNumber = lastAisleNumber
    let newTotalHorizontalAisles = totalHorizontalAisles
    let newTotalVerticalAisles = totalVerticalAisles
    clonedElements.forEach((el) => {
      if (el.isAisle) {
        const nextAisleNumber = newAisleNumber + 1
        newAisleNumber = newAisleNumber + 1
        // now update the id
        const newAisleId = `aisle-${nextAisleNumber}`
        // now update the aisle name
        const newAisleName =
          el.orientation === 'vertical'
            ? `Aisle ${nextAisleNumber}`
            : `Aisle ${Math.abs(nextAisleNumber - totalVerticalAisles)}`.toUpperCase()
        // now update the aisle text
        const newAisleTextLabel = `Aisle ${Math.abs(
          nextAisleNumber - totalVerticalAisles
        )}`.toUpperCase()

        // check the orientation of the aisle
        if (el.orientation === 'vertical') {
          // increment the total vertical aisles
          // setTotalVerticalAisles(totalVerticalAisles + 1)
          newTotalVerticalAisles = newTotalVerticalAisles + 1
        } else if (el.orientation === 'horizontal') {
          // setTotalHorizontalAisles(totalHorizontalAisles + 1)
          newTotalHorizontalAisles = newTotalHorizontalAisles + 1
        }

        el.id = newAisleId
        el.name = newAisleName
        el.children.forEach((child) => {
          if (child.type === 'text' && el.isAisle) {
            child.text = `${newAisleTextLabel}`
          }
        })

        if (el.sgl_id) {
          delete el.sgl_id
        }
      } else if (el.isStall) {
        newStallNumber += 1
        const newStallId = `stall-${newStallNumber}`
        const newStallName = `Stall ${newStallNumber}`
        const newStallTextLabel = `Stall ${newStallNumber}`
        el.id = newStallId
        el.name = newStallName
        // el has key sgl id remove it from the cloned element
        if (el.sgl_id) {
          delete el.sgl_id
        }
        el.children.forEach((child) => {
          if (child.type === 'text' && el.isStall) {
            child.text = `${newStallTextLabel}`
          }
        })
      } else if (el.isGroup) {
        el.children.forEach((stall) => {
          if (stall.isStall) {
            // Update stall details
            newStallNumber += 1
            const newStallId = `stall-${newStallNumber}`
            const newStallName = `Stall ${newStallNumber}`
            const newStallTextLabel = `Stall ${newStallNumber}`
            stall.id = newStallId
            stall.name = newStallName
            // el has key sgl id remove it from the cloned element
            // el has key sgl id remove it from the cloned element
            if (stall.sgl_id) {
              delete stall.sgl_id
            } // updating the text label
            stall.children.forEach((child) => {
              if (child.type === 'text' && stall.isStall) {
                child.text = `${newStallTextLabel}`
              }
            })
          }
        })
      }
    })
    setLastStallNumber(newStallNumber)
    setLastAisleNumber(newAisleNumber)
    setTotalHorizontalAisles(newTotalHorizontalAisles)
    setTotalVerticalAisles(newTotalVerticalAisles)

    // Add cloned elements to the canvas and select them
    setCanvasElements((prev) => {
      const updatedCanvasElements = [...prev, ...clonedElements]

      // Select the new elements
      const newIds = clonedElements.map((el) => el.id)
      setSelectedElements(newIds)

      // Update the Transformer with all the newly selected elements
      requestAnimationFrame(() => {
        if (transformerRef.current && stageRef.current) {
          const nodes = newIds.map((id) => stageRef.current.findOne(`#${id}`)).filter(Boolean)
          transformerRef.current.nodes(nodes)
          transformerRef.current.getLayer().batchDraw()
        }
      })

      return updatedCanvasElements
    })
  }

  const handleDeleteSelected = () => {
    const confirmed = window.confirm('Are you sure you want to delete the selected elements?')
    if (!confirmed) return

    setCanvasElements((prev) => prev.filter((el) => !selectedElements.includes(el.id)))
    setSelectedElements([])
    setSelectedElement(null)
  }

  // Helper function to get stall points based on position and orientation
  const getStallPoints = (width, height, orientation) => {
    const openingSize = height * 0.2
    const strokeWidth = 1 // Add stroke width consideration

    switch (orientation) {
      case 'top':
        return [
          strokeWidth,
          height - strokeWidth, // bottom left
          width - strokeWidth,
          height - strokeWidth, // bottom right
          width - strokeWidth,
          strokeWidth, // top right
          width - openingSize - strokeWidth,
          strokeWidth, // top right inner
          width - openingSize - strokeWidth,
          openingSize + strokeWidth, // right inner
          openingSize + strokeWidth,
          openingSize + strokeWidth, // left inner
          openingSize + strokeWidth,
          strokeWidth, // top left inner
          strokeWidth,
          strokeWidth, // top left
        ]

      case 'right':
        return [
          strokeWidth,
          strokeWidth, // top left
          strokeWidth,
          height - strokeWidth, // bottom left
          width - strokeWidth,
          height - strokeWidth, // bottom right
          width - strokeWidth,
          height - openingSize - strokeWidth, // bottom right inner
          width - openingSize - strokeWidth,
          height - openingSize - strokeWidth, // bottom inner
          width - openingSize - strokeWidth,
          openingSize + strokeWidth, // top inner
          width - strokeWidth,
          openingSize + strokeWidth, // top right inner
          width - strokeWidth,
          strokeWidth, // top right
        ]

      case 'bottom':
        return [
          strokeWidth,
          strokeWidth, // top left
          width - strokeWidth,
          strokeWidth, // top right
          width - strokeWidth,
          height - strokeWidth, // bottom right
          width - openingSize - strokeWidth,
          height - strokeWidth, // bottom right inner
          width - openingSize - strokeWidth,
          height - openingSize - strokeWidth, // right inner
          openingSize + strokeWidth,
          height - openingSize - strokeWidth, // left inner
          openingSize + strokeWidth,
          height - strokeWidth, // bottom left inner
          strokeWidth,
          height - strokeWidth, // bottom left
        ]

      case 'left':
        return [
          strokeWidth,
          strokeWidth, // top left
          width - strokeWidth,
          strokeWidth, // top right
          width - strokeWidth,
          height - strokeWidth, // bottom right
          strokeWidth,
          height - strokeWidth, // bottom left
          strokeWidth,
          height - openingSize - strokeWidth, // bottom left inner
          openingSize + strokeWidth,
          height - openingSize - strokeWidth, // bottom inner
          openingSize + strokeWidth,
          openingSize + strokeWidth, // top inner
          strokeWidth,
          openingSize + strokeWidth, // top left inner
        ]
    }
  }

  const getStallTextArea = (width, height, orientation) => {
    const openingSize = height * 0.2
    const padding = 10 // Reduced padding for better fit

    switch (orientation) {
      case 'top':
        return {
          width: width - padding * 2,
          height: height - openingSize - padding * 2,
          x: width / 2,
          y: height - (height - openingSize) / 2, // Position in bottom area
          offsetX: (width - padding * 2) / 2,
          offsetY: (height - openingSize - padding * 2) / 2,
        }
      case 'bottom':
        return {
          width: width - padding * 2,
          height: height - openingSize - padding * 2,
          x: width / 2,
          y: (height - openingSize) / 2, // Position in top area
          offsetX: (width - padding * 2) / 2,
          offsetY: (height - openingSize - padding * 2) / 2,
        }
      case 'left':
        return {
          width: width - openingSize - padding * 2,
          height: height - padding * 2,
          x: width - (width - openingSize) / 2, // Position in right area
          y: height / 2,
          offsetX: (width - openingSize - padding * 2) / 2,
          offsetY: (height - padding * 2) / 2,
        }
      case 'right':
        return {
          width: width - openingSize - padding * 2,
          height: height - padding * 2,
          x: (width - openingSize) / 2, // Position in left area
          y: height / 2,
          offsetX: (width - openingSize - padding * 2) / 2,
          offsetY: (height - padding * 2) / 2,
        }
    }
  }

  const [showPositionMenu, setShowPositionMenu] = useState(false)
  const ElementToolbar = ({selectedElement, canvasElements, setCanvasElements}) => {
    // Add state for font size input
    const [isEditingFontSize, setIsEditingFontSize] = useState(false)
    const [tempFontSize, setTempFontSize] = useState('')

    const [isEditingLineWidth, setIsEditingLineWidth] = useState(false)
    const [tempLineWidth, setTempLineWidth] = useState('')

    if (!selectedElement) return null

    const element = canvasElements.find((el) => el.id === selectedElement)
    if (!element) return null

    const stage = stageRef.current
    if (!stage) return null

    // Try to find either the group or its first child
    const elementNode =
      stage.findOne(`#${selectedElement}`) ||
      stage.findOne(`#${selectedElement}-rect-0`) ||
      stage.findOne(`#${selectedElement}-line-0`) ||
      stage.findOne(`#${selectedElement}-circle-0`)

    if (!elementNode) return null

    const toolbarStyle = {
      position: 'absolute',
      left: '50%',
      top: '16px',
      transform: 'translateX(-50%)',
      display: 'flex',
      gap: '4px',
      padding: '4px',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      zIndex: 1000,
      pointerEvents: 'auto',
    }

    const buttonStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '28px',
      height: '28px',
      padding: '4px',
      border: 'none',
      borderRadius: '4px',
      background: '#f0f0f0',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      pointerEvents: 'auto',
    }

    const handleFontSizeInputChange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setTempFontSize(e.target.value) // Only update the temporary value, don't apply changes yet
    }

    const handleFontSizeStep = (step) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      const currentSize =
        parseInt(tempFontSize) ||
        element.children?.find((child) => child.type === 'text')?.fontSize ||
        40
      setTempFontSize((currentSize + step).toString()) // Only update the temporary value
    }

    // Add a new handler for applying font size changes
    const applyFontSizeChange = () => {
      const newSize = parseInt(tempFontSize)
      if (!isNaN(newSize) && newSize > 0) {
        setCanvasElements((prev) =>
          prev.map((el) => {
            if (el.id === selectedElement) {
              const scaleX = el.scaleX || 1
              const scaleY = el.scaleY || 1
              const textArea = el.isStall
                ? getStallTextArea(el.width, el.height, el.orientation)
                : el.isText
                ? {
                    x: el.width / 2,
                    y: el.height / 2,
                    // width: el.width - 20,
                    // height: el.height - 20,
                    width: undefined,
                    height: undefined,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }
                : {
                    x: el.width / 2,
                    y: el.height / 2,
                    width: el.width - 20,
                    height: el.height - 20,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }

              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type === 'text') {
                    return {
                      ...child,
                      ...textArea,
                      fontSize: newSize,
                      // scaleX: 1 / scaleX,
                      // scaleY: 1 / scaleY,
                    }
                  }
                  return child
                }),
              }
            }
            return el
          })
        )
        setIsEditingFontSize(false)
      }
    }

    const handleDuplicate = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const elementToDuplicate = canvasElements.find((el) => el.id === selectedElement)
      if (!elementToDuplicate) return

      // Get the current node to access its transformation properties
      const node = selectedShapeRef.current

      if (!node) return

      let newId = `element-${Date.now()}`

      let newElement = {}

      // if the duplicated element is a stall, we need to update the stall number
      if (elementToDuplicate.isAisle) {
        const nextAisleNumber = lastAisleNumber + 1
        setLastAisleNumber(nextAisleNumber)
        // now update the id
        const newAisleId = `aisle-${nextAisleNumber}`
        // now update the aisle name
        const newAisleName = `Aisle ${nextAisleNumber}`
        // now update the aisle text
        const newAisleTextLabel = `Aisle ${Math.abs(
          nextAisleNumber - totalVerticalAisles
        )}`.toUpperCase()

        newId = newAisleId

        // check the orientation of the aisle
        if (elementToDuplicate.orientation === 'vertical') {
          // increment the total vertical aisles
          setTotalVerticalAisles(totalVerticalAisles + 1)
        } else if (elementToDuplicate.orientation === 'horizontal') {
          setTotalHorizontalAisles(totalHorizontalAisles + 1)
        }

        // Get the current node to access its transformation properties
        newElement = {
          ...elementToDuplicate,
          id: newAisleId,
          x: elementToDuplicate.x + 20,
          y: elementToDuplicate.y + 20,
          name: newAisleName,
          // Copy transformation properties from the current state
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          children: elementToDuplicate.children.map((child) => {
            // Handle text elements for aisles
            if (child.type === 'text' && elementToDuplicate.isAisle) {
              return {...child, text: `${newAisleTextLabel}`}
            }

            // If it's a line element with points (like for aisles or triangles)
            if (child.type === 'line' && child.points) {
              // We need to transform the points based on current scale
              const transformedPoints = [...child.points]
              return {...child, points: transformedPoints}
            }

            return child
          }),
        }
        // el has key sgl id remove it from the cloned element
        if (newElement.sgl_id) {
          delete newElement.sgl_id
        }
      } else if (elementToDuplicate.isStall) {
        const nextStallNumber = lastStallNumber + 1
        setLastStallNumber(nextStallNumber)
        // now update the id
        const newStallId = `stall-${nextStallNumber}`
        // now update the stall name
        const newStallName = `Stall ${nextStallNumber}`
        // now update the stall text
        const newStallTextLabel = `Stall ${nextStallNumber}`

        newId = newStallId

        // Get the current node to access its transformation properties
        newElement = {
          ...elementToDuplicate,
          id: newStallId,
          x: elementToDuplicate.x + 20,
          y: elementToDuplicate.y + 20,
          name: newStallName,
          // Copy transformation properties from the current state
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          children: elementToDuplicate.children.map((child) => {
            // Handle text elements for stalls
            if (child.type === 'text' && elementToDuplicate.isStall) {
              return {...child, text: `${newStallTextLabel}`}
            }

            // If it's a line element with points (like for stalls or triangles)
            if (child.type === 'line' && child.points) {
              // We need to transform the points based on current scale
              const transformedPoints = [...child.points]
              return {...child, points: transformedPoints}
            }

            return child
          }),
        }
        // el has key sgl id remove it from the cloned element
        if (newElement.sgl_id) {
          delete newElement.sgl_id
        }
      } else {
        newElement = {
          ...elementToDuplicate,
          id: newId,
          x: elementToDuplicate.x + 20,
          y: elementToDuplicate.y + 20,
          // Copy transformation properties from the current state
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          children: elementToDuplicate.children.map((child) => {
            // Handle text elements for stalls
            if (child.type === 'text' && elementToDuplicate.isStall) {
              return {...child, text: `${child.text}`}
            }

            // If it's a line element with points (like for stalls or triangles)
            if (child.type === 'line' && child.points) {
              // We need to transform the points based on current scale
              const transformedPoints = [...child.points]
              // return {...child, points: transformedPoints}

              // if we have a stand alone line, we need to update the points
              if (elementToDuplicate.isLine) {
                return {
                  ...child,
                  // points: transformedPoints.map((p, index) => (index % 2 === 0 ? p + 20 : p + 20)),
                  x: elementToDuplicate.x + 20,
                  y: elementToDuplicate.y + 20,
                }
              } else {
                // if line is used to make some other componenets like rectangle, circle, triangle etc
                return {...child, points: transformedPoints}
              }
            }

            return child
          }),
        }
      }

      setCanvasElements((prev) => [...prev, newElement])

      // Give React a chance to render the new element
      requestAnimationFrame(() => {
        // Find and simulate click on the new element
        const shape = stageRef.current?.findOne(`#${newId}`)
        if (shape) {
          // Trigger the click handler directly
          handleSelect(newId)
        }
      })
    }

    const handleFlipHorizontal = (e) => {
      e.preventDefault()
      e.stopPropagation()

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // For stalls, handle orientation change
            if (el.id.includes('stall')) {
              const orientation = el.orientation || 'top'

              // Only allow horizontal flip for left/right oriented stalls
              if (orientation !== 'left' && orientation !== 'right') {
                return el // Return unchanged if not left/right oriented
              }

              let newOrientation = orientation === 'left' ? 'right' : 'left'
              const points = getStallPoints(
                // el.width / (el.scaleX || 1),
                // el.height / (el.scaleY || 1),
                el.width,
                el.height,
                newOrientation
              )

              // Get new text area based on new orientation
              const textArea = getStallTextArea(
                // el.width / (el.scaleX || 1),
                // el.height / (el.scaleY || 1),
                el.width,
                el.height,
                newOrientation
              )

              return {
                ...el,
                orientation: newOrientation,
                children: el.children.map((child) => {
                  if (child.type === 'line') {
                    return {...child, points}
                  }
                  if (child.type === 'text') {
                    return {
                      ...child,
                      ...textArea, // Apply all text area properties
                      text: child.text,
                      fontSize: child.fontSize,
                      fill: child.fill,
                      align: 'center',
                      verticalAlign: 'middle',
                      wrap: 'word',
                    }
                  }
                  return child
                }),
                rotation: el.rotation || 0, // Preserve element rotation
              }
            }

            // For other elements
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  const adjustedWidth = el.width / (el.scaleX || 1)
                  return {
                    ...child,
                    points: child.points.map((p, index) =>
                      index % 2 === 0 ? adjustedWidth - p : p
                    ),
                  }
                }
                if (child.type === 'text') {
                  return {
                    ...child,
                    x: el.width / (el.scaleX || 1) / 2,
                    width: el.width / (el.scaleX || 1),
                    rotation: el.rotation || 0,
                  }
                }
                return child
              }),
              rotation: el.rotation || 0,
            }
          }
          return el
        })
      )
    }

    const handleFlipVertical = (e) => {
      e.preventDefault()
      e.stopPropagation()

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // For stalls, handle orientation change
            if (el.id.includes('stall')) {
              const orientation = el.orientation || 'top'

              // Only allow vertical flip for top/bottom oriented stalls
              if (orientation !== 'top' && orientation !== 'bottom') {
                return el // Return unchanged if not top/bottom oriented
              }

              let newOrientation = orientation === 'top' ? 'bottom' : 'top'
              const points = getStallPoints(
                // el.width / (el.scaleX || 1),
                el.width,
                // el.height / (el.scaleY || 1),
                el.height,
                newOrientation
              )

              // Get new text area based on new orientation
              const textArea = getStallTextArea(
                // el.width / (el.scaleX || 1),
                el.width,
                // el.height / (el.scaleY || 1),
                el.height,
                newOrientation
              )

              return {
                ...el,
                orientation: newOrientation,
                children: el.children.map((child) => {
                  if (child.type === 'line') {
                    return {...child, points}
                  }
                  if (child.type === 'text') {
                    return {
                      ...child,
                      ...textArea, // Apply all text area properties
                      text: child.text,
                      fontSize: child.fontSize,
                      fill: child.fill,
                      align: 'center',
                      verticalAlign: 'middle',
                      wrap: 'word',
                    }
                  }
                  return child
                }),
                rotation: el.rotation || 0, // Preserve element rotation
              }
            }

            // For other elements
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  const adjustedHeight = el.height / (el.scaleY || 1)
                  return {
                    ...child,
                    points: child.points.map((p, index) =>
                      index % 2 === 1 ? adjustedHeight - p : p
                    ),
                  }
                }
                if (child.type === 'text') {
                  return {
                    ...child,
                    y: el.height / (el.scaleY || 1) / 2,
                    height: el.height / (el.scaleY || 1),
                    rotation: el.rotation || 0,
                  }
                }
                return child
              }),
              rotation: el.rotation || 0,
            }
          }
          return el
        })
      )
    }

    const handleDelete = (e) => {
      e.preventDefault()
      e.stopPropagation()
      deleteElement(selectedElement)
    }

    const startLabelEdit = (e) => {
      e.preventDefault()
      e.stopPropagation()
      const currentLabel = element.children?.find((child) => child.type === 'text')?.text || ''
      setTempLabel(currentLabel)
      setIsEditingLabel(true)
    }

    const handleLabelChange = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            const scaleX = el.scaleX || 1
            const scaleY = el.scaleY || 1
            const textArea = el.isStall
              ? getStallTextArea(el.width, el.height, el.orientation)
              : el.isText
              ? {
                  x: el.width / 2,
                  y: el.height / 2,
                  // width: el.width - 20,
                  // height: el.height - 20,
                  width: undefined,
                  height: undefined,
                  offsetX: (el.width - 20) / 2,
                  offsetY: (el.height - 20) / 2,
                }
              : {
                  x: el.width / 2,
                  y: el.height / 2,
                  width: el.width - 20,
                  height: el.height - 20,
                  offsetX: (el.width - 20) / 2,
                  offsetY: (el.height - 20) / 2,
                }

            return {
              ...el,
              ...(el.isStall ? {name: tempLabel} : {}),
              children: el.children.map((child) =>
                child.type === 'text'
                  ? {
                      ...child,
                      ...textArea,
                      text: tempLabel,
                      // scaleX: 1 / scaleX,
                      // scaleY: 1 / scaleY,
                      wrap: 'word',
                      verticalAlign: 'middle',
                      align: 'center',
                    }
                  : child
              ),
            }
          }
          return el
        })
      )
      setIsEditingLabel(false)
    }

    const isStandaloneLine =
      element?.children?.[0]?.type === 'line' &&
      !element.isStall &&
      element.shapeType !== 'triangle'
    const isTextElement = element.children?.length === 1 && element.children[0].type === 'text'
    const isBasicShape =
      element.children?.[0]?.type === 'rectangle' ||
      element.children?.[0]?.type === 'circle' ||
      (element.children?.[0]?.type === 'line' && element.shapeType === 'triangle')
    const isTopBottomStall =
      element.isStall && (element.orientation === 'top' || element.orientation === 'bottom')
    const isLeftRightStall =
      element.isStall && (element.orientation === 'left' || element.orientation === 'right')

    return (
      <div
        className='element-toolbar'
        style={toolbarStyle}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        {isEditingLabel ? (
          <form
            onSubmit={handleLabelChange}
            style={{
              display: 'flex',
              gap: '4px',
              padding: '2px',
              background: 'white',
              borderRadius: '8px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              type='text'
              value={tempLabel}
              onChange={(e) => setTempLabel(e.target.value)}
              style={{
                padding: '8px 12px',
                borderRadius: '6px',
                border: '1px solid #4B8CEF',
                width: '160px',
                outline: 'none',
                fontSize: '14px',
              }}
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <button
              type='submit'
              style={{
                padding: '6px 12px',
                border: 'none',
                borderRadius: '6px',
                background: '#68B984',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                fontSize: '16px',
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✓
            </button>
            <button
              type='button'
              style={{
                padding: '6px 12px',
                border: 'none',
                borderRadius: '6px',
                background: '#f5f5f5',
                color: '#666',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                fontSize: '16px',
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsEditingLabel(false)
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✕
            </button>
          </form>
        ) : isEditingFontSize ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              padding: '2px',
              background: 'white',
              borderRadius: '8px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              type='number'
              value={tempFontSize}
              onChange={handleFontSizeInputChange}
              style={{
                width: '80px',
                padding: '8px 12px',
                borderRadius: '6px',
                border: '1px solid #4B8CEF',
                outline: 'none',
                fontSize: '14px',
              }}
              min='1'
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <div style={{display: 'flex', gap: '4px'}}>
              <button
                style={{
                  padding: '6px 12px',
                  border: 'none',
                  borderRadius: '6px',
                  background: '#68B984',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '36px',
                  fontSize: '16px',
                }}
                onClick={handleFontSizeStep(1)}
                onMouseDown={(e) => e.stopPropagation()}
              >
                ↑
              </button>
              <button
                style={{
                  padding: '6px 12px',
                  border: 'none',
                  borderRadius: '6px',
                  background: '#68B984',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '36px',
                  fontSize: '16px',
                }}
                onClick={handleFontSizeStep(-1)}
                onMouseDown={(e) => e.stopPropagation()}
              >
                ↓
              </button>
              <button
                style={{
                  padding: '6px 12px',
                  border: 'none',
                  borderRadius: '6px',
                  background: '#68B984',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '36px',
                  fontSize: '16px',
                }}
                onClick={applyFontSizeChange}
                onMouseDown={(e) => e.stopPropagation()}
              >
                ✓
              </button>
              <button
                style={{
                  padding: '6px 12px',
                  border: 'none',
                  borderRadius: '6px',
                  background: '#f5f5f5',
                  color: '#666',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '36px',
                  fontSize: '16px',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsEditingFontSize(false)
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                ✕
              </button>
            </div>
          </div>
        ) : isEditingLineWidth ? (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              const width = Math.max(1, Math.min(20, parseInt(tempLineWidth) || 2))

              setCanvasElements((prev) =>
                prev.map((el) => {
                  if (el.id === selectedElement) {
                    // Get the initial line template to use its base dash pattern
                    const initialDash = [15, 15] // Original dash pattern from shapeTemplates
                    const baseWidth = 5 // Original stroke width from shapeTemplates

                    return {
                      ...el,
                      children: el.children.map((child) =>
                        child.type === 'line'
                          ? {
                              ...child,
                              strokeWidth: width,
                              // Scale dash pattern proportionally to width changes
                              dash: child.dash
                                ? initialDash.map((d) => d * (width / baseWidth))
                                : undefined,
                            }
                          : child
                      ),
                    }
                  }
                  return el
                })
              )
              setIsEditingLineWidth(false)
            }}
            style={{
              display: 'flex',
              gap: '4px',
              padding: '2px',
              background: 'white',
              borderRadius: '8px',
              boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            }}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <input
              type='number'
              value={tempLineWidth}
              onChange={(e) => {
                const width = Math.max(1, Math.min(20, parseInt(e.target.value) || 1))
                setTempLineWidth(width.toString())
              }}
              style={{
                padding: '8px 12px',
                borderRadius: '6px',
                border: '1px solid #4B8CEF',
                width: '80px',
                outline: 'none',
                fontSize: '14px',
              }}
              min='1'
              max='20'
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <button
              type='submit'
              style={{
                padding: '6px 12px',
                border: 'none',
                borderRadius: '6px',
                background: '#68B984',
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                fontSize: '16px',
              }}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✓
            </button>
            <button
              type='button'
              style={{
                padding: '6px 12px',
                border: 'none',
                borderRadius: '6px',
                background: '#f5f5f5',
                color: '#666',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '36px',
                fontSize: '16px',
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsEditingLineWidth(false)
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              ✕
            </button>
          </form>
        ) : (
          <>
            {/* Always show Duplicate button */}
            <button
              style={buttonStyle}
              onClick={handleDuplicate}
              onMouseDown={(e) => e.stopPropagation()}
              title='Duplicate'
            >
              <Copy size={16} />
            </button>

            {/* Show Label Edit button for everything except lines */}
            {!isStandaloneLine && (
              <button
                style={buttonStyle}
                onClick={startLabelEdit}
                onMouseDown={(e) => e.stopPropagation()}
                title='Edit Label'
              >
                <Type size={16} />
              </button>
            )}

            {/* Show Font Size button for everything except lines */}
            {!isStandaloneLine && (
              <button
                style={buttonStyle}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  const currentFontSize =
                    element.children?.find((child) => child.type === 'text')?.fontSize || '40'
                  setTempFontSize(currentFontSize.toString())
                  setIsEditingFontSize(true)
                }}
                onMouseDown={(e) => e.stopPropagation()}
                title='Edit Font Size'
              >
                <span style={{fontSize: '16px'}}>Aa</span>
              </button>
            )}

            {/* Show Line Width button only for lines */}
            {isStandaloneLine && (
              <button
                style={buttonStyle}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  const currentWidth = element.children?.[0]?.strokeWidth || '2'
                  setTempLineWidth(currentWidth.toString())
                  setIsEditingLineWidth(true)
                }}
                onMouseDown={(e) => e.stopPropagation()}
                title='Edit Line Width'
              >
                <Minus size={16} />
              </button>
            )}

            {/* Show Flip buttons only for stalls with correct orientation */}
            {!isStandaloneLine && !isTextElement && !isBasicShape && !isTopBottomStall && (
              <button
                style={buttonStyle}
                onClick={handleFlipHorizontal}
                onMouseDown={(e) => e.stopPropagation()}
                title='Flip Horizontal'
              >
                <FlipHorizontal size={16} />
              </button>
            )}

            {!isStandaloneLine && !isTextElement && !isBasicShape && !isLeftRightStall && (
              <button
                style={buttonStyle}
                onClick={handleFlipVertical}
                onMouseDown={(e) => e.stopPropagation()}
                title='Flip Vertical'
              >
                <FlipVertical size={16} />
              </button>
            )}

            <button
              style={buttonStyle}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowPositionMenu(!showPositionMenu)
              }}
              onMouseDown={(e) => e.stopPropagation()}
              title='Position'
            >
              <Layers size={16} />
            </button>
            {showPositionMenu && (
              <PositionMenu
                elementId={selectedElements}
                onClose={() => setShowPositionMenu(false)}
              />
            )}

            {/* Add before the delete button */}
            <button
              style={buttonStyle}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <ColorPicker
                initialColor={
                  element.isGroup
                    ? element.children?.[0]?.children?.[0]?.fill || '#e9edef'
                    : element.children?.[0]?.fill || '#e9edef'
                }
                onColorChange={(color) => {
                  setCanvasElements((prev) =>
                    prev.map((el) => {
                      if (el.id === selectedElement) {
                        if (el.isGroup) {
                          // Handle grouped elements
                          return {
                            ...el,
                            children: el.children.map((groupChild) => ({
                              ...groupChild,
                              children: groupChild.children?.map((child) => {
                                if (child.type === 'line' && child.closed) {
                                  return {...child, fill: color}
                                }
                                if (['rectangle', 'circle'].includes(child.type)) {
                                  return {...child, fill: color}
                                }
                                return child
                              }),
                            })),
                          }
                        } else {
                          // Handle non-grouped elements
                          return {
                            ...el,
                            children: el.children.map((child) => {
                              if (child.type === 'line' && child.closed) {
                                return {...child, fill: color}
                              }
                              if (['rectangle', 'circle'].includes(child.type)) {
                                return {...child, fill: color}
                              }
                              return child
                            }),
                          }
                        }
                      }
                      return el
                    })
                  )
                }}
              />
            </button>
            {/* Always show Delete button */}
            <button
              style={{...buttonStyle, color: '#ff4444'}}
              onClick={handleDelete}
              onMouseDown={(e) => e.stopPropagation()}
              title='Delete'
            >
              <Trash2 size={16} />
            </button>
          </>
        )}
      </div>
    )
  }

  // Convert barn layout to canvas elements
  // Update the convertBarnToCanvasElements function
  const convertBarnToCanvasElements = useCallback(() => {
    if (!barnLayout.structure) return []

    let elements = []

    // Add barn structure (non-draggable)
    elements.push({
      id: 'barn-structure',
      type: 'group',
      x: 50,
      y: 50,
      width: barnLayout.structure.width,
      height: barnLayout.structure.height,
      draggable: false,
      name: 'Barn Structure',
      isBarnStructure: true,
      children: [
        {
          type: 'rectangle',
          x: 0,
          y: 0,
          width: barnLayout.structure.width,
          height: barnLayout.structure.height,
          fill: '#ffffff',
          stroke: 'black',
          strokeWidth: 1,
        },
      ],
    })

    // Add aisles
    let horizontalAisleCounter = 1
    barnLayout.aisles?.forEach((aisle) => {
      const customData = customizations.aisles[aisle.id] || {}
      if (!customData.deleted) {
        const isHorizontal = aisle.type === 'horizontal'
        const aisleName = isHorizontal ? `AISLE ${horizontalAisleCounter}` : ''
        if (isHorizontal) horizontalAisleCounter++

        elements.push({
          id: aisle.id,
          type: 'group',
          x: 50 + aisle.x,
          y: 50 + aisle.y,
          width: aisle.width,
          height: aisle.height,
          orientation: aisle.type,
          draggable: true,
          name: customData.name || aisle.name || aisleName,
          isAisle: true,
          children: [
            {
              type: 'rectangle',
              x: 0,
              y: 0,
              width: aisle.width,
              height: aisle.height,
              fill: '#f6f6f6',
              stroke: '#cccccc',
              strokeWidth: 1,
            },
            ...(isHorizontal
              ? [
                  {
                    type: 'text',
                    x: aisle.width / 2,
                    y: aisle.height / 2,
                    width: aisle.width,
                    height: aisle.height,
                    text: customData.name || aisleName,
                    fontSize: 48,
                    fill: '#000000',
                    align: 'center',
                    verticalAlign: 'middle',
                    wrap: 'word',
                    offsetX: aisle.width / 2,
                    offsetY: aisle.height / 2,
                    fontFamily: CANVAS_FONT_FAMILY,
                  },
                ]
              : []),
          ],
        })
      }
    })

    // Add stalls with correct orientation
    barnLayout.stalls?.forEach((stall) => {
      const customData = customizations.stalls[stall.id] || {}
      if (!customData.deleted) {
        const textArea = getStallTextArea(stall.width, stall.height, stall.orientation)

        elements.push({
          id: stall.id,
          type: 'group',
          x: 50 + stall.x,
          y: 50 + stall.y,
          width: stall.width,
          height: stall.height,
          draggable: true,
          name: customData.name || stall.name || `Stall ${stall.id.split('-')[1]}`,
          isStall: true,
          orientation: stall.orientation,
          children: [
            {
              type: 'line',
              points: getStallPoints(stall.width, stall.height, stall.orientation),
              fill: '#e9edef',
              stroke: '#333333',
              strokeWidth: 1,
              closed: true,
            },
            {
              type: 'text',
              x: textArea.x,
              y: textArea.y,
              width: textArea.width,
              height: textArea.height,
              text: customData.name || stall.name || `Stall ${stall.id.split('-')[1]}`,
              fontSize: 40,
              fill: '#000000',
              align: 'center',
              verticalAlign: 'middle',
              wrap: 'word',
              offsetX: textArea.offsetX,
              offsetY: textArea.offsetY,
              fontFamily: CANVAS_FONT_FAMILY,
            },
          ],
        })
      }
    })

    return elements
  }, [barnLayout, customizations, draggedElements])

  useEffect(() => {
    if (!isEditMode) {
      // Check if we're currently loading a design
      const checkExistingDesign = async () => {
        if (currentDesignId) {
          loadingOverlay({show: true})
          try {
            const response = await axios.get(`${NEST_API_URL}/barns/getBarnDesign`, {
              params: {
                barnId: currentDesignId,
                customerId: customerId,
              },
            })

            if (response.data) {
              // Don't reset canvas elements if we're loading a design
              return
            }
          } catch (error) {
            console.error('Error checking design:', error)
          } finally {
            loadingOverlay({show: false})
          }
        }

        // If no design is being loaded, reset everything
        setCanvasElements([])
        setSelectedElement(null)
        setDraggedElements({})
        setSelectedId(null)

        // Force recalculation of barn layout
        const {layout, errors} = calculateBarnLayout()
        setBarnLayout(layout)
        setLayoutErrors(errors)
      }

      checkExistingDesign()
    } else {
      // When switching to edit mode, if we have existing canvasElements, don't override them
      if (canvasElements.length === 0) {
        const barnElements = convertBarnToCanvasElements()
        setCanvasElements(barnElements)
      }
    }
  }, [isEditMode])

  // Add this effect to handle mode switching
  useEffect(() => {
    // Only calculate layout if not in edit mode or if no canvas elements exist
    if (!isEditMode || canvasElements.length === 0) {
      const {layout, errors} = calculateBarnLayout()

      // Only update layout if not in edit mode
      if (!isEditMode) {
        setBarnLayout(layout)
        setLayoutErrors(errors)

        const stallNumbers =
          layout.stalls?.map((stall) => parseInt(stall.id.split('-')[1]) || 0) || []
        setLastStallNumber(Math.max(0, ...stallNumbers))
      }
    }
  }, [formData, isEditMode, canvasElements.length])

  // Canvas element management functions
  const addCanvasElement = (elementType, template = null) => {
    if (!isEditMode) return

    const baseElement = {
      id: elementType.startsWith('line-')
        ? `${elementType}-${Date.now()}`
        : `element-${Date.now()}`,
      x: 150,
      y: 150,
      draggable: true,
    }

    let newElement

    // 1. Check if it is an aisle
    if (elementType === 'aisle-horizontal') {
      const newAisleId = lastAisleNumber + 1
      setLastAisleNumber(newAisleId)
      setTotalHorizontalAisles(totalHorizontalAisles + 1)
      // Could also incorporate a naming system for aisles
      // e.g. "Aisle #1", etc.
      const aisle_id = `aisle-${newAisleId}`
      newElement = {
        ...baseElement,
        id: aisle_id,
        type: 'group',
        isAisle: true,
        orientation: 'horizontal',
        width: template.width || 400,
        height: template.height || 50,
        children: [
          {
            type: 'rectangle',
            x: 0,
            y: 0,
            width: template.width || 400,
            height: template.height || 50,
            fill: template.fill || '#f6f6f6',
            stroke: template.stroke || '#cccccc',
            strokeWidth: template.strokeWidth || 1,
          },
          {
            // Optionally add a text label inside the aisle
            type: 'text',
            x: (template.width || 400) / 2,
            y: (template.height || 50) / 2,
            width: template.width || 400,
            height: template.height || 50,
            text: `AISLE ${Math.abs(newAisleId - totalVerticalAisles)}`,
            fontSize: 48,
            fill: '#000000',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: (template.width || 400) / 2,
            offsetY: (template.height || 50) / 2,
            fontFamily: CANVAS_FONT_FAMILY, // or your preferred font
          },
        ],
      }
    } else if (elementType === 'aisle-vertical') {
      const newAisleId = lastAisleNumber + 1
      setLastAisleNumber(newAisleId)
      setTotalVerticalAisles(totalVerticalAisles + 1)
      const aisle_id = `aisle-${newAisleId}`
      newElement = {
        ...baseElement,
        id: aisle_id,
        type: 'group',
        isAisle: true,
        orientation: 'vertical',
        width: template.width || 50,
        height: template.height || 400,
        children: [
          {
            type: 'rectangle',
            x: 0,
            y: 0,
            width: template.width || 50,
            height: template.height || 400,
            fill: template.fill || '#f6f6f6',
            stroke: template.stroke || '#cccccc',
            strokeWidth: template.strokeWidth || 1,
          },
          // {
          //   // Optionally add a text label
          //   type: 'text',
          //   x: (template.width || 50) / 2,
          //   y: (template.height || 400) / 2,
          //   width: template.width || 50,
          //   height: template.height || 400,
          //   text: 'AISLE',
          //   fontSize: 24,
          //   fill: '#000',
          //   align: 'center',
          //   verticalAlign: 'middle',
          //   offsetX: (template.width || 50) / 2,
          //   offsetY: (template.height || 400) / 2,
          //   fontFamily: CANVAS_FONT_FAMILY,
          // },
        ],
      }
    } else if (elementType.startsWith('line-')) {
      const isVertical = elementType.endsWith('-v')
      const lineWidth = isVertical ? 20 : 400
      const lineHeight = isVertical ? 400 : 20
      const linePoints = isVertical ? [10, 0, 10, 400] : [0, 10, 400, 10]

      newElement = {
        ...baseElement,
        type: 'group',
        width: template?.width || lineWidth,
        height: template?.height || lineHeight,
        isLine: true,
        children: [
          {
            type: 'line',
            x: 150, // Initial position
            y: 150,
            draggable: true,
            points: linePoints,
            stroke: template?.stroke || '#333333',
            strokeWidth: template?.strokeWidth || 2,
            lineCap: template?.lineCap || 'round',
            dash: template?.dash,
            hitStrokeWidth: 20,
          },
          {
            type: 'rect',
            x: 0,
            y: 0,
            width: template?.width || lineWidth,
            height: template?.height || lineHeight,
            stroke: '#333333',
            strokeWidth: 0.5,
            opacity: 0,
            fillEnabled: false,
          },
        ],
      }
    } else if (elementType.startsWith('stall-')) {
      const width = template?.width || formData.stallLength * feetToPixelRatio
      const height = template?.height || formData.stallWidth * feetToPixelRatio
      const nextStallNumber = lastStallNumber + 1
      setLastStallNumber(nextStallNumber)

      const orientation = elementType.split('-')[1]
      const points = getStallPoints(width, height, orientation)
      const textArea = getStallTextArea(width, height, orientation)

      newElement = {
        ...baseElement,
        // id: `custom-stall-${nextStallNumber}`,
        id: `stall-${nextStallNumber}`,
        type: 'group',
        width,
        height,
        name: `Stall ${nextStallNumber}`,
        orientation,
        isStall: true,
        children: [
          {
            type: 'line',
            points,
            fill: '#e9edef',
            stroke: '#333333',
            strokeWidth: 1,
            closed: true,
          },
          {
            type: 'text',
            ...textArea,
            text: `Stall ${nextStallNumber}`,
            fontSize: 40,
            fill: '#000000',
            align: 'center',
            verticalAlign: 'middle',
            wrap: 'word',
            fontFamily: CANVAS_FONT_FAMILY,
          },
        ],
      }
    } else {
      switch (elementType) {
        case 'rectangle':
          newElement = {
            ...baseElement,
            type: 'group',
            width: template?.width || 100,
            height: template?.height || 100,
            children: [
              {
                type: 'rectangle',
                x: 0,
                y: 0,
                width: template?.width || 100,
                height: template?.height || 100,
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333333',
                strokeWidth: 1,
              },
              {
                type: 'text',
                x: (template?.width || 100) / 2,
                y: (template?.height || 100) / 2,
                width: (template?.width || 100) - 20,
                height: (template?.height || 100) - 20,
                text: 'Rectangle',
                fontSize: 36,
                fill: '#000000',
                align: 'center',
                verticalAlign: 'middle',
                wrap: 'word',
                offsetX: ((template?.width || 100) - 20) / 2,
                offsetY: ((template?.height || 100) - 20) / 2,
                fontFamily: CANVAS_FONT_FAMILY,
              },
            ],
          }
          break

        case 'circle':
          const circleSize = template?.width || 100
          newElement = {
            ...baseElement,
            type: 'group',
            width: circleSize,
            height: circleSize,
            children: [
              {
                type: 'circle',
                x: circleSize / 2,
                y: circleSize / 2,
                radius: circleSize / 2,
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333333',
                strokeWidth: 1,
              },
              {
                type: 'text',
                x: circleSize / 2,
                y: circleSize / 2,
                width: circleSize * 0.7,
                height: circleSize * 0.7,
                text: 'Circle',
                fontSize: 40,
                fill: '#000000',
                align: 'center',
                verticalAlign: 'middle',
                wrap: 'word',
                offsetX: (circleSize * 0.7) / 2,
                offsetY: (circleSize * 0.7) / 2,
                fontFamily: CANVAS_FONT_FAMILY,
              },
            ],
          }
          break

        case 'triangle':
          const triWidth = template?.width || 100
          const triHeight = template?.height || 100
          newElement = {
            ...baseElement,
            type: 'group',
            shapeType: 'triangle',
            width: triWidth,
            height: triHeight,
            children: [
              {
                type: 'line',
                points: [triWidth / 2, 0, 0, triHeight, triWidth, triHeight],
                fill: template?.fill || '#e9edef',
                stroke: template?.stroke || '#333333',
                strokeWidth: 1,
                closed: true,
              },
              {
                type: 'text',
                x: triWidth / 2,
                y: triHeight * 0.6,
                width: triWidth * 0.6,
                height: triHeight * 0.4,
                text: 'Triangle',
                fontSize: 36,
                fill: '#000000',
                align: 'center',
                verticalAlign: 'middle',
                wrap: 'word',
                offsetX: (triWidth * 0.6) / 2,
                offsetY: (triHeight * 0.4) / 2,
                fontFamily: CANVAS_FONT_FAMILY,
              },
            ],
          }
          break

        case 'text':
          newElement = {
            ...baseElement,
            type: 'group',
            width: 200,
            height: 40,
            isText: true,
            children: [
              {
                type: 'text',
                x: 100,
                y: 20,
                width: 400,
                height: 40,
                text: 'Sample Text',
                fontSize: 40,
                fill: '#000000',
                align: 'center',
                verticalAlign: 'middle',
                wrap: 'word',
                offsetX: 100,
                offsetY: 20,
                fontFamily: CANVAS_FONT_FAMILY,
              },
            ],
          }
          break

        default:
          return
      }
    }

    if (newElement) {
      setCanvasElements((prev) => [...prev, newElement])

      // Clear any existing selection before selecting the new element
      setSelectedElements([])
      setSelectedElement(null)

      // Select the new element
      requestAnimationFrame(() => {
        const shape = stageRef.current?.findOne(`#${newElement.id}`)
        if (shape) {
          setSelectedElements([newElement.id])
          setSelectedElement(newElement.id)
          if (transformerRef.current) {
            transformerRef.current.nodes([shape])
            transformerRef.current.getLayer().batchDraw()
          }
        }
      })
    }
  }

  const applyCustomization = useCallback((original, customData) => {
    if (!customData) return original
    return {
      ...original,
      ...customData,
      x: customData.x ?? original.x,
      y: customData.y ?? original.y,
      width: customData.width ?? original.width,
      height: customData.height ?? original.height,
      name: customData.name ?? original.name,
    }
  }, [])

  // Selection handlers
  const handleSelect = useCallback(
    (id) => {
      if (!isEditMode) return

      // If clicking the same element, deselect it
      if (selectedElement === id) {
        setSelectedElement(null)
        if (transformerRef.current) {
          transformerRef.current.nodes([])
          transformerRef.current.getLayer().batchDraw()
        }
        return
      }

      setSelectedElement(id)

      // Important: Use requestAnimationFrame to ensure DOM is ready
      requestAnimationFrame(() => {
        // Find the shape - look for both group and individual elements
        const shape = stageRef.current?.findOne(`#${id}`) || stageRef.current?.findOne(`.${id}`)

        if (shape && transformerRef.current) {
          // Detach from any previous nodes
          transformerRef.current.nodes([])
          // Attach to new node
          transformerRef.current.nodes([shape])
          // Force update
          transformerRef.current.getLayer().batchDraw()
        }
      })
    },
    [isEditMode, selectedElement]
  )

  // Delete handler
  const handleDelete = useCallback(
    (id) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...prev[type][id], deleted: true},
        },
      }))
      setSelectedId(null)
    },
    [isEditMode]
  )

  // Name change handler
  const handleNameChange = useCallback(
    (id, newName) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...(prev[type][id] || {}), name: newName},
        },
      }))
    },
    [isEditMode]
  )

  // Dimension change handler
  const handleDimensionChange = useCallback(
    (id, dimensions) => {
      if (!isEditMode) return
      const type = id.startsWith('stall-') ? 'stalls' : 'aisles'
      setCustomizations((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: {...(prev[type][id] || {}), ...dimensions},
        },
      }))
    },
    [isEditMode]
  )

  const deleteElement = (id) => {
    if (!id) return

    const confirmed = window.confirm('Are you sure you want to delete this element?')

    if (confirmed) {
      // Remove the element from canvas elements
      setCanvasElements((prev) => prev.filter((el) => el.id !== id))

      // Clear selection
      setSelectedElement(null)
      if (transformerRef.current) {
        transformerRef.current.nodes([])
        transformerRef.current.getLayer().batchDraw()
      }
    }
  }

  // Render the editor sidebar
  const renderEditorSidebar = () => {
    if (!isEditMode) return null

    // Filter templates by type
    const shapeOnlyTemplates = Object.entries(shapeTemplates).filter(
      ([key]) => !key.startsWith('stall-') && !key.startsWith('line-') && !key.startsWith('aisle-')
    )

    const stallTemplates = Object.entries(shapeTemplates).filter(([key]) =>
      key.startsWith('stall-')
    )

    const LineTemplates = Object.entries(shapeTemplates).filter(
      ([key]) => key.startsWith('line-') && key.endsWith('-h')
    )

    const aislesTemplate = Object.entries(shapeTemplates).filter(([key]) =>
      key.startsWith('aisle-')
    )

    return (
      <div className='editor-sidebar'>
        {/* A new block in the Editor Sidebar for Aisles */}
        <div className='editor-section'>
          <h3>Aisles</h3>
          <div className='shape-grid'>
            {/* Horizontal aisle button */}
            {aislesTemplate.map(([key, template]) => {
              const IconComponent = template.icon
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                >
                  <IconComponent size={24} />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Stalls</h3>
          <div className='shape-grid'>
            {stallTemplates.map(([key, template]) => {
              const IconComponent = template.icon
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                >
                  <IconComponent size={24} />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Shapes</h3>
          <div className='shape-grid'>
            {shapeOnlyTemplates.map(([key, template]) => {
              const IconComponent = template.icon
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                >
                  <IconComponent size={24} />
                  <span>{key}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Lines</h3>
          <div className='shape-grid'>
            {LineTemplates.map(([key, template]) => {
              return (
                <button
                  key={key}
                  className='shape-button'
                  onClick={() => addCanvasElement(key, template)}
                  style={{height: '48px'}}
                >
                  <div
                    style={{
                      width: '24px',
                      height: '2px',
                      backgroundColor: template.stroke,
                      borderTop: template.dash
                        ? key.includes('dotted')
                          ? '2px dotted #333333'
                          : '2px dashed #333333'
                        : '2px solid #333333',
                    }}
                  />
                  <span>{key.split('-')[1]}</span>
                </button>
              )
            })}
          </div>
        </div>

        <div className='editor-section'>
          <h3>Text</h3>
          <button className='text-button' onClick={() => addCanvasElement('text')}>
            <Type size={24} />
            <span>Add Text</span>
          </button>
        </div>
      </div>
    )
  }

  // Effect to handle transformer
  useEffect(() => {
    if (selectedElement && transformerRef.current) {
      requestAnimationFrame(() => {
        const shape =
          stageRef.current?.findOne(`#${selectedElement}`) ||
          stageRef.current?.findOne(`.${selectedElement}`)

        if (shape) {
          transformerRef.current.nodes([shape])
          transformerRef.current.getLayer().batchDraw()
        }
      })
    }
  }, [selectedElement])

  const handleWheel = (e) => {
    e.evt.preventDefault()

    const scaleBy = 1.05
    const stage = stageRef.current
    const oldScale = stage.scaleX()
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy

    setStageScale(newScale)
    setStagePosition({
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    })
  }

  const calculateBarnLayout = useCallback(() => {
    let {
      structureWidth,
      structureLength,
      verticalAisleCount,
      verticalAisleWidth,
      horizontalAisleCount,
      horizontalAisleWidth,
      stallLength,
      stallWidth,
    } = formData

    // Convert all inputs to numbers
    structureWidth = parseFloat(structureWidth)
    structureLength = parseFloat(structureLength)
    verticalAisleCount = parseFloat(verticalAisleCount)
    verticalAisleWidth = parseFloat(verticalAisleWidth)
    horizontalAisleCount = parseFloat(horizontalAisleCount)
    horizontalAisleWidth = parseFloat(horizontalAisleWidth)
    stallLength = parseFloat(stallLength)
    stallWidth = parseFloat(stallWidth)

    // Convert dimensions from feet to pixels
    const structureWidthPx = structureWidth * feetToPixelRatio
    const structureLengthPx = structureLength * feetToPixelRatio
    const verticalAisleWidthPx = verticalAisleWidth * feetToPixelRatio
    const horizontalAisleWidthPx = horizontalAisleWidth * feetToPixelRatio
    const stallWidthPx = stallLength * feetToPixelRatio // Using stall length as width
    const stallHeightPx = stallWidth * feetToPixelRatio // Using stall width as height

    // Initialize layout
    const layout = {
      structure: {width: structureLengthPx, height: structureWidthPx},
      aisles: [],
      stalls: [],
      overflowInfo: null,
    }

    let errors = []

    if (stallLength < 5 || stallWidth < 5) {
      return {
        layout,
        errors,
      }
    }

    // Calculate total height needed for each aisle group (aisle + top and bottom stalls)
    const aisleGroupHeight = horizontalAisleWidthPx + stallHeightPx * 2
    const totalAisleGroupsHeight = aisleGroupHeight * horizontalAisleCount

    // Calculate vertical center of the barn
    const barnCenterY = structureWidthPx / 2

    // Calculate starting Y position for first aisle group (centered in barn)
    let startY = barnCenterY - totalAisleGroupsHeight / 2

    // Calculate overflow if any
    let overflowAmount = 0
    if (totalAisleGroupsHeight > structureWidthPx) {
      overflowAmount = totalAisleGroupsHeight - structureWidthPx
      layout.overflowInfo = {
        requiredWidth: Math.ceil((structureWidth + overflowAmount / feetToPixelRatio) * 100) / 100,
        currentWidth: structureWidth,
        additionalWidthNeeded: Math.ceil((overflowAmount / feetToPixelRatio) * 100) / 100,
      }
    }

    // Calculate vertical aisle positions (evenly spaced horizontally)
    const verticalAislePositions = []
    if (verticalAisleCount > 0) {
      const totalVerticalAisleSpace = verticalAisleCount * verticalAisleWidthPx
      const remainingHorizSpace = structureLengthPx - totalVerticalAisleSpace
      const spaceBetweenVerticalAisles = remainingHorizSpace / (verticalAisleCount + 1)

      let currentPos = spaceBetweenVerticalAisles
      for (let i = 0; i < verticalAisleCount; i++) {
        verticalAislePositions.push(currentPos)
        currentPos += verticalAisleWidthPx + spaceBetweenVerticalAisles
      }
    }

    // Helper function to create an aisle with customizations
    const createAisle = (baseAisle) => {
      const aisleId = `aisle-${layout.aisles.length + 1}`
      const customData = customizations.aisles[aisleId]

      if (customData?.deleted) return null

      return applyCustomization(
        {
          ...baseAisle,
          id: aisleId,
          name: `AISLE ${layout.aisles.length + 1}`,
          orientation: baseAisle.type === 'horizontal' ? 'horizontal' : 'vertical',
        },
        customData
      )
    }

    setLastAisleNumber((prev) => verticalAisleCount + horizontalAisleCount) // Update last aisle number
    setTotalHorizontalAisles(horizontalAisleCount) // Update total horizontal aisles
    setTotalVerticalAisles(verticalAisleCount) // Update total vertical aisles
    // Plot vertical aisles
    verticalAislePositions.forEach((pos) => {
      const aisle = createAisle({
        x: pos,
        y: 0,
        width: verticalAisleWidthPx,
        height: structureWidthPx,
        type: 'vertical',
      })
      if (aisle) layout.aisles.push(aisle)
    })

    // Create sections between vertical aisles
    const sections = []
    let currentX = 0

    // Add first section (from left edge to first aisle)
    if (verticalAislePositions.length > 0) {
      sections.push({
        start: 0,
        end: verticalAislePositions[0],
      })
    }

    // Add middle sections
    for (let i = 0; i < verticalAislePositions.length - 1; i++) {
      sections.push({
        start: verticalAislePositions[i] + verticalAisleWidthPx,
        end: verticalAislePositions[i + 1],
      })
    }

    // Add last section
    if (verticalAislePositions.length > 0) {
      sections.push({
        start: verticalAislePositions[verticalAislePositions.length - 1] + verticalAisleWidthPx,
        end: structureLengthPx,
      })
    } else {
      sections.push({
        start: 0,
        end: structureLengthPx,
      })
    }

    // Calculate how many stalls can fit in the smallest section
    const sectionWidths = sections.map((section) => section.end - section.start)
    const minSectionWidth = Math.min(...sectionWidths)
    const stallsPerSection = Math.floor(minSectionWidth / stallWidthPx)

    // Helper function to create a stall with customizations
    const createStall = (baseStall) => {
      const stallId = `stall-${layout.stalls.length + 1}`
      const customData = customizations.stalls[stallId]

      if (customData?.deleted) return null

      return applyCustomization(
        {
          ...baseStall,
          id: stallId,
          name: `Stall ${layout.stalls.length + 1}`,
        },
        customData
      )
    }

    // Function to place stalls in a row
    const placeStallRow = (startY, section, orientation) => {
      const availableWidth = section.end - section.start
      const totalStallsWidth = stallsPerSection * stallWidthPx
      const leftOffset = (availableWidth - totalStallsWidth) / 2

      for (let i = 0; i < stallsPerSection; i++) {
        const stallX = section.start + leftOffset + i * stallWidthPx
        const stallY = startY

        // Check if stall is partially or fully outside barn boundaries
        const isOutOfBounds = stallY < 0 || stallY + stallHeightPx > structureWidthPx

        // Create points relative to the stall's own coordinate system (0,0)
        const points = getStallPoints(stallWidthPx, stallHeightPx, orientation)

        const stall = createStall({
          points: points, // Use the points directly, don't offset them
          x: stallX, // Position will be handled by the group
          y: stallY,
          width: stallWidthPx,
          height: stallHeightPx,
          orientation: orientation, // Add orientation to the stall data
          isOutOfBounds,
        })

        if (stall) layout.stalls.push(stall)
      }
    }

    // Plot horizontal aisles and their stalls
    for (let i = 0; i < horizontalAisleCount; i++) {
      const currentAisleY = startY + i * aisleGroupHeight
      const isAisleOutOfBounds =
        currentAisleY < 0 || currentAisleY + horizontalAisleWidthPx > structureWidthPx

      // Add horizontal aisle
      const aisle = createAisle({
        x: 0,
        y: currentAisleY + stallHeightPx, // Position after top stalls
        width: structureLengthPx,
        height: horizontalAisleWidthPx,
        type: 'horizontal',
        isAisleOutOfBounds,
      })
      if (aisle) layout.aisles.push(aisle)

      // Check for boundary issues and add to errors
      if (currentAisleY < 0 || currentAisleY + aisleGroupHeight > structureWidthPx) {
        if (errors.length == 0) {
          errors.push({
            type: 'boundary',
            severity: 'error',
            message: `Barn width is too small. Current: ${structureWidth}ft. Required: ${layout.overflowInfo.requiredWidth}ft. Please increase width by at least ${layout.overflowInfo.additionalWidthNeeded}ft.`,
          })
        }
      }

      // Place top row stalls
      sections.forEach((section) => {
        placeStallRow(currentAisleY, section, 'bottom')
      })

      // Place bottom row stalls
      sections.forEach((section) => {
        placeStallRow(currentAisleY + stallHeightPx + horizontalAisleWidthPx, section, 'top')
      })
    }

    return {
      layout,
      errors,
    }
  }, [formData, customizations, applyCustomization])

  // Update the useEffect that handles layout calculation
  useEffect(() => {
    if (!isEditMode || (isEditMode && canvasElements.length === 0)) {
      const {layout, errors} = calculateBarnLayout()
      setBarnLayout(layout)
      setLayoutErrors(errors)

      // Update last stall number only if we're not in edit mode
      if (!isEditMode) {
        const stallNumbers =
          layout.stalls?.map((stall) => parseInt(stall.id.split('-')[1]) || 0) || []
        setLastStallNumber(Math.max(0, ...stallNumbers))
      }
    }
  }, [formData, isEditMode])

  const renderEditForm = () => {
    if (!selectedId || !isEditMode) return null

    const item = selectedId.startsWith('stall-')
      ? barnLayout.stalls.find((s) => s.id === selectedId)
      : barnLayout.aisles.find((a) => a.id === selectedId)

    if (!item) return null

    const type = selectedId.startsWith('stall-') ? 'stalls' : 'aisles'
    const customization = customizations[type][selectedId] || {}

    return (
      <div className='edit-form-overlay'>
        <div className='edit-form'>
          <h3>
            {customization.name || item.name || (type === 'stalls' ? 'Stall' : 'Aisle')} Settings
          </h3>

          <div className='form-group'>
            <label>Name</label>
            <input
              type='text'
              value={customization.name || item.name || ''}
              onChange={(e) => handleNameChange(selectedId, e.target.value)}
            />
          </div>

          <div className='form-group'>
            <label>Width (ft)</label>
            <input
              type='number'
              value={(customization.width || item.width) / feetToPixelRatio}
              onChange={(e) =>
                handleDimensionChange(selectedId, {
                  width: parseFloat(e.target.value) * feetToPixelRatio,
                })
              }
            />
          </div>

          <div className='form-group'>
            <label>Length (ft)</label>
            <input
              type='number'
              value={(customization.height || item.height) / feetToPixelRatio}
              onChange={(e) =>
                handleDimensionChange(selectedId, {
                  height: parseFloat(e.target.value) * feetToPixelRatio,
                })
              }
            />
          </div>

          <button onClick={() => handleDelete(selectedId)} className='delete-btn'>
            <i className='fas fa-trash'></i> Delete
          </button>
        </div>
      </div>
    )
  }

  const validateField = (name, value) => {
    let error = ''
    switch (name) {
      case 'structureLength':
        if (!value) {
          error = 'Please enter a valid structure length.'
        }
        break
      case 'structureWidth':
        if (!value) {
          error = 'Please enter a valid structure width.'
        }
        break
      case 'horizontalAisleCount':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid number for Length Wise Aisles.'
        }
        break
      case 'verticalAisleCount':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid number for Width Wise Aisles.'
        }
        break
      case 'horizontalAisleWidth':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid aisle width for Length Wise Aisles.'
        }
        break
      case 'verticalAisleWidth':
        if (!value || isNaN(value)) {
          error = 'Please enter a valid aisle width for Width Wise Aisles.'
        }
        break
      case 'stallLength':
        if (!value || isNaN(value) || value < 5) {
          error = 'Please enter a valid stall length. It should be equal or more than 5 feet.'
        }
        break
      case 'stallWidth':
        if (!value || isNaN(value) || value < 5) {
          error = 'Please enter a valid stall width. It should be equal or more than 5 feet.'
        }
        break
      default:
        break
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }))
  }

  const zoomIn = () => {
    setStageScale((prevScale) => prevScale * 1.5) // Increase scale
  }

  const zoomOut = () => {
    setStageScale((prevScale) => prevScale / 1.5) // Decrease scale
  }

  // const fitView = () => {
  //   const stage = stageRef.current
  //   if (!stage || !barnLayout.structure) return // Guard against missing stage or structure

  //   const padding = 30

  //   // Get the available space
  //   const containerWidth = stage.width()
  //   const containerHeight = stage.height()

  //   // Get the barn dimensions
  //   const layoutWidth = barnLayout.structure?.width || 1
  //   const layoutHeight = barnLayout.structure?.height || 1

  //   // Calculate scaling factors with padding
  //   const scaleX = (containerWidth - padding * 2) / layoutWidth
  //   const scaleY = (containerHeight - padding * 2) / layoutHeight

  //   // Use the smaller scale to maintain aspect ratio
  //   const newScale = Math.min(scaleX, scaleY)

  //   // Calculate center position, accounting for the existing x:50 offset in the structure
  //   const offsetX = (containerWidth - layoutWidth * newScale) / 2 - 50 * newScale
  //   const offsetY = (containerHeight - layoutHeight * newScale) / 2

  //   setStageScale(newScale)
  //   setStagePosition({
  //     x: offsetX,
  //     y: offsetY,
  //   })
  // }

  const fitView = () => {
    const stage = stageRef.current
    if (!stage || !barnLayout.structure) return // Guard against missing stage or structure

    const padding = 30

    // Get the available space
    const containerWidth = stage.width()
    const containerHeight = stage.height()

    // Get the barn dimensions and position
    const layoutWidth = barnLayout.structure?.width || 1
    const layoutHeight = barnLayout.structure?.height || 1
    const structureX = barnLayout.structure?.x || 50
    const structureY = barnLayout.structure?.y || 50

    // Calculate scaling factors with padding
    const scaleX = (containerWidth - padding * 2) / layoutWidth
    const scaleY = (containerHeight - padding * 2) / layoutHeight

    // Use the smaller scale to maintain aspect ratio
    const newScale = Math.min(scaleX, scaleY)

    // Calculate center position considering structure's current position
    const offsetX = (containerWidth - layoutWidth * newScale) / 2 - structureX * newScale
    const offsetY = (containerHeight - layoutHeight * newScale) / 2 - structureY * newScale

    setStageScale(newScale)
    setStagePosition({
      x: offsetX,
      y: offsetY,
    })
  }

  const [shouldFitToScreen, setShouldFitToScreen] = useState(true)

  useEffect(() => {
    if (barnLayout.structure && shouldFitToScreen) {
      fitView()
    }
  }, [barnLayout.structure, shouldFitToScreen])

  useEffect(() => {
    const handleResize = () => {
      if (stageRef.current) {
        stageRef.current.width(window.innerWidth - 210 - 75 - 5)
        stageRef.current.height(window.innerHeight - 54 - 50 - 40)
        fitView()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target
    const updatedValue = type === 'checkbox' ? checked : value

    // Set the field data
    setFormData({
      ...formData,
      [name]: updatedValue,
    })

    // Validate the field being updated
    validateField(name, updatedValue)
  }

  const handleModeSwitch = () => {
    if (isEditMode) {
      // When switching from edit to view mode
      const confirmed = window.confirm(
        'Switching to view mode will reset all changes. Are you sure you want to continue?'
      )
      if (confirmed) {
        setDesignName('')
        setCurrentDesignId(null)
        setIsEditMode(false)
        setShouldFitToScreen(true)
        setHistory([])
        setCurrentHistoryIndex(-1)
      }
    } else {
      // When switching to edit mode, convert current barn layout to canvas elements
      const barnElements = convertBarnToCanvasElements()
      setCanvasElements(barnElements)
      setIsEditMode(true)
      // Initialize history with initial state only
      setHistory([barnElements])
      setCurrentHistoryIndex(0) // Set to 0 to indicate this is the initial state
    }
  }

  // Add this useEffect for keyboard controls
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Delete') {
        if (selectedElement) {
          deleteElement(selectedElement)
        }
      } else if (e.key === 'Escape') {
        setSelectedElement(null)
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [selectedElement])

  const SaveDialog = ({isOpen, onClose}) => {
    const inputRef = useRef(null) // Moved outside the conditional
    const [isSaving, setIsSaving] = useState(false)

    // Focus input when dialog opens
    useEffect(() => {
      if (isOpen && inputRef.current) {
        // Small delay to ensure DOM is ready
        setTimeout(() => {
          inputRef.current.focus()
        }, 50)
      }
    }, [isOpen])

    // Update the save handler
    const handleSave = async (e) => {
      e.preventDefault()

      if (!designName.trim()) {
        alert('Please enter a design name')
        return
      }
      const designThumbnail = captureStageStateForThumbnail()

      //loadingOverlay({show: true})
      try {
        const serializedCanvasElements = canvasElements
          .filter((element) => !element.isBarnStructure)
          .map(serializeElement)

        const designData = {
          name: designName,
          timestamp: new Date().toISOString(),
          formData,
          customizations,
          barnLayout,
          canvasElements: serializedCanvasElements,
          lastStallNumber,
          isEditMode: true,
          customerId: customerId,
          barnId: parseInt(barn_id),
          designThumbnail: designThumbnail,
        }

        setIsSaving(true)
        const response = await axios.post(`${NEST_API_URL}/barns/saveBarnDesign`, designData)
        setIsSaving(false)
        // const existingDesigns = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
        // localStorage.setItem('barnDesigns', JSON.stringify([...existingDesigns, designData]))

        // setCurrentDesignId(response.data.id)

        // load the design from the server

        setDesignName(designName)
        setShowSaveDialog(false)

        handleLoad(response.data.id, false)
      } catch (error) {
        console.error('Error saving design:', error)
        alert('Failed to save design. Please try again.')
      } finally {
        setIsSaving(false)

        //loadingOverlay({show: false})
      }
    }

    if (!isOpen) return null

    // Use a form to handle submit properly
    return (
      <div
        className='modal-overlay'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2000, // Higher than zoom controls
        }}
      >
        <div
          className='modal-content'
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <div className='d-flex align-items-center justify-content-between p-3'>
            <h2 className='mb-0'>Save Design</h2>
            {isSaving && (
              <div className='spinner-border text-dark' role='status'>
                <span className='sr-only'></span>
              </div>
            )}
          </div>
          <form onSubmit={handleSave}>
            <div className='modal-body'>
              <input
                ref={inputRef}
                type='text'
                placeholder='Enter design name'
                value={designName}
                onChange={(e) => setDesignName(e.target.value)}
                className='design-name-input'
                style={{
                  width: '100%',
                  padding: '8px',
                  marginBottom: '0px',
                  border: '1px solid #ccc !important',
                  borderRadius: '4px',
                  outline: 'none',
                }}
              />
            </div>
            <div
              className='modal-footer'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '8px',
              }}
            >
              <button
                disabled={isSaving}
                type='button'
                onClick={onClose}
                style={{
                  padding: '8px 16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  background: 'white',
                }}
              >
                Cancel
              </button>
              <button
                disabled={isSaving}
                type='submit'
                className='primary'
                style={{
                  padding: '8px 16px',
                  border: 'none',
                  borderRadius: '4px',
                  background: `${isSaving ? '#A5D6A7' : '#4CAF50'}`,
                  color: 'white',
                }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  // Update the load handler
  // const handleLoad = (designId) => {
  //   const loadStates = async () => {

  //     const response = await axios.get(`${NEST_API_URL}/barns/getBarnDesign`, {
  //       params: {
  //         barnId: designId,
  //         customer_id: customerId,
  //       }
  //     });

  //     const design = response.data;
  //     setCurrentDesignId(design.id);

  //     setIsEditMode(true)
  //     await new Promise((resolve) => setTimeout(resolve, 0))

  //     // Set form data and customizations
  //     setFormData(design.formData)
  //     setCustomizations(design.customizations || {})
  //     setBarnLayout(design.barnLayout)

  //     // Clear existing elements and selection
  //     setCanvasElements([])
  //     setSelectedElement(null)
  //     setSelectedElements([])
  //     setSelectedId(null)
  //     setDraggedElements({})

  //     await new Promise((resolve) => setTimeout(resolve, 0))

  //     if (design.canvasElements?.length > 0) {
  //       const validElements = design.canvasElements
  //         .filter(
  //           (element) =>
  //             element &&
  //             element.id &&
  //             typeof element.x === 'number' &&
  //             typeof element.y === 'number' &&
  //             typeof element.width === 'number' &&
  //             typeof element.height === 'number'
  //         )
  //         .map(deserializeElement)

  //       // Create the complete canvas elements array with barn structure
  //       const completeCanvasElements = [
  //         {
  //           id: 'barn-structure',
  //           type: 'group',
  //           x: 50,
  //           y: 50,
  //           width: design.barnLayout.structure.width,
  //           height: design.barnLayout.structure.height,
  //           draggable: false,
  //           name: 'Barn Structure',
  //           isBarnStructure: true,
  //           children: [
  //             {
  //               type: 'rectangle',
  //               x: 0,
  //               y: 0,
  //               width: design.barnLayout.structure.width,
  //               height: design.barnLayout.structure.height,
  //               fill: '#ffffff',
  //               stroke: 'black',
  //               strokeWidth: 1,
  //             },
  //           ],
  //         },
  //         ...validElements,
  //       ]

  //       setCanvasElements(completeCanvasElements)
  //     }

  //     setLastStallNumber(design.lastStallNumber || 0)

  //     // Reset view and transform states
  //     setStageScale(1)
  //     setStagePosition({x: 0, y: 0})

  //     // Fit view after everything is loaded
  //     setTimeout(fitView, 200)
  //   }

  //   loadStates().then(() => {
  //     setShowLoadDialog(false)
  //   })
  // }

  const handleLoad = async (designId, fitToScreenAfterLoading = true) => {
    loadingOverlay({show: true})
    // todo : remove the customer id from the params and change to barn_id
    try {
      const response = await axios.get(`${NEST_API_URL}/barns/getBarnDesign`, {
        params: {
          barnId: barn_id,
          customerId: customerId,
          barnDesignId: designId,
        },
      })

      setShouldFitToScreen(fitToScreenAfterLoading)

      const design = response.data
      setCurrentDesignId(design.id)
      // set the current design active status
      setIsDesignActive(design.active)

      await new Promise((resolve) => setTimeout(resolve, 0))

      // Set form data and customizations
      setFormData(design.formData)
      setCustomizations(design.customizations || {})
      setBarnLayout(design.barnLayout)
      setDesignName(design.name)

      // Clear existing elements and selection
      setCanvasElements([])
      setSelectedElement(null)
      setSelectedElements([])
      setSelectedId(null)
      setDraggedElements({})

      await new Promise((resolve) => setTimeout(resolve, 0))

      if (design.canvasElements?.length > 0) {
        const validElements = design.canvasElements
          .filter(
            (element) =>
              element &&
              element.id &&
              typeof element.x === 'number' &&
              typeof element.y === 'number' &&
              typeof element.width === 'number' &&
              typeof element.height === 'number'
          )
          .map(deserializeElement)

        const completeCanvasElements = [
          // {
          //   id: 'barn-structure',
          //   type: 'group',
          //   x: 50,
          //   y: 50,
          //   width: design.barnLayout.structure.width,
          //   height: design.barnLayout.structure.height,
          //   draggable: false,
          //   name: 'Barn Structure',
          //   isBarnStructure: true,
          //   children: [
          //     {
          //       type: 'rectangle',
          //       x: 0,
          //       y: 0,
          //       width: design.barnLayout.structure.width,
          //       height: design.barnLayout.structure.height,
          //       fill: '#ffffff',
          //       stroke: 'black',
          //       strokeWidth: 1,
          //     },
          //   ],
          // },
          {
            id: 'barn-structure',
            type: 'rectangle',
            x: design.barnLayout.structure.x ?? 50,
            y: design.barnLayout.structure.y ?? 50,
            width: design.barnLayout.structure.width,
            height: design.barnLayout.structure.height,
            draggable: false,
            name: 'Barn Structure',
            isBarnStructure: true,
            fill: '#ffffff',
            stroke: 'black',
            strokeWidth: 1,
          },
          ...validElements,
        ]

        setCanvasElements(completeCanvasElements)
      }

      setLastStallNumber(design.lastStallNumber || 0)

      // Reset view and transform states

      if (fitToScreenAfterLoading) {
        setStageScale(1)
        setStagePosition({x: 0, y: 0})

        // Fit view after everything is loaded
        // setTimeout(fitView, 200)
        fitView()
      }
      setIsEditMode(true)

      setShowLoadDialog(false)
    } catch (error) {
      console.error('Error loading design:', error)
      alert('Failed to load design. Please try again.')
    } finally {
      loadingOverlay({show: false})
    }
  }

  const LoadDialog = ({isOpen, onClose}) => {
    const [designs, setDesigns] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //   if (isOpen) {
    //     // Load saved designs from localStorage
    //     try {
    //       const savedDesigns = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
    //       setDesigns(savedDesigns)
    //     } catch (error) {
    //       console.error('Error loading designs:', error)
    //       setDesigns([])
    //     }
    //   }
    // }, [isOpen])

    useEffect(() => {
      const fetchDesigns = async () => {
        if (!isOpen) return

        //loadingOverlay({show: true})
        setError(null)

        try {
          setIsLoading(true)
          const response = await axios.get(`${NEST_API_URL}/barns/getBarnDesignsList`, {
            params: {
              customerId: customerId,
              barnId: barn_id,
            },
          })
          setDesigns(response.data)
          setIsLoading(false)
        } catch (error) {
          console.error('Error loading designs:', error)
          setError('Failed to load designs. Please try again.')
        } finally {
          //loadingOverlay({show: false})
        }
      }

      fetchDesigns()
    }, [isOpen])

    // If dialog is not open, don't render anything
    if (!isOpen) return null

    return (
      <div className='modal-overlay'>
        <div className='modal-content'>
          <h2>Load Design</h2>
          <div className='modal-body'>
            {isLoading ? (
              <div className='card-body d-flex flex-row align-items-end justify-content-center'>
                <div className='row'>
                  <div className='spinner-border text-dark' role='status'>
                    <span className='sr-only'></span>
                  </div>
                </div>
              </div>
            ) : designs.length === 0 ? (
              <>
                <p>No saved designs found</p>
              </>
            ) : (
              <div className='designs-list'>
                {designs.map((design, index) => (
                  <div key={index} className='design-item'>
                    {design.isActive ? (
                      <div className='active-bar' />
                    ) : (
                      <div className='default-bar' />
                    )}
                    <div className='design-info'>
                      <h3>
                        {design.name}
                        {design.isActive && <span className='active-badge'>Active</span>}
                      </h3>
                      <p>{new Date(design.timestamp).toLocaleString()}</p>
                    </div>
                    <button
                      className='fw-bold'
                      onClick={() => {
                        handleLoad(design.id)
                        onClose() // Ensure dialog closes after loading
                      }}
                    >
                      Load
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className='modal-footer'>
            <button onClick={onClose} className='close-button'>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }

  // Helper function to ensure all element properties are captured
  const serializeElement = (element) => {
    // First, get the actual node to capture current transforms
    const node = stageRef.current?.findOne(`#${element.id}`)
    const currentTransform = node
      ? {
          rotation: node.rotation(),
          scaleX: node.scaleX(),
          scaleY: node.scaleY(),
          x: node.x() || 0,
          y: node.y() || 0,
        }
      : {
          rotation: element.rotation || 0,
          scaleX: element.scaleX || 1,
          scaleY: element.scaleY || 1,
          x: element.x || 0,
          y: element.y || 0,
        }

    const serializedElement = {
      id: element.id,
      type: element.type || 'group',
      // x: element.x,
      // y: element.y,
      x: currentTransform.x,
      y: currentTransform.y,
      width: element.width,
      height: element.height,
      draggable: element.draggable,
      // Use actual transforms from the node
      rotation: currentTransform.rotation,
      scaleX: currentTransform.scaleX,
      scaleY: currentTransform.scaleY,
      // Preserve element type flags
      isGroup: element.isGroup || false,
      isStall: element.isStall || false,
      isAisle: element.isAisle || false,
      isLine: element.isLine || false,
      isText: element.isText || false,
      isBarnStructure: element.isBarnStructure || false,
      // Preserve stall-specific properties
      orientation: element.orientation,
      name: element.name,
      ...(element.isStall && element.sgl_id && {sgl_id: element.sgl_id}),
    }

    // Handle children
    if (element.children) {
      serializedElement.children = element.children.map((child) => {
        const childNode = node?.findOne(
          `#${element.id}-${child.type}-${element.children.indexOf(child)}`
        )
        const childTransform = childNode
          ? {
              rotation: childNode.rotation(),
              scaleX: childNode.scaleX(),
              scaleY: childNode.scaleY(),
            }
          : {
              rotation: child.rotation || 0,
              scaleX: child.scaleX || 1,
              scaleY: child.scaleY || 1,
            }

        return {
          ...child,
          rotation: childTransform.rotation,
          scaleX: childTransform.scaleX,
          scaleY: childTransform.scaleY,
          // Preserve specific properties based on child type
          ...(child.type === 'line' && {
            points: child.points,
            stroke: child.stroke,
            strokeWidth: child.strokeWidth,
            lineCap: child.lineCap,
            dash: child.dash,
            hitStrokeWidth: child.hitStrokeWidth,
            fill: child.fill,
            closed: child.closed,
          }),
          ...(child.type === 'text' && {
            text: child.text,
            fontSize: child.fontSize,
            fill: child.fill,
            align: child.align,
            verticalAlign: child.verticalAlign,
            wrap: child.wrap,
            offsetX: child.offsetX,
            offsetY: child.offsetY,
          }),
          ...(child.type === 'rectangle' && {
            fill: child.fill,
            stroke: child.stroke,
            strokeWidth: child.strokeWidth,
          }),
          ...(child.type === 'circle' && {
            ...child,
            radius: child.radius,
            fill: child.fill,
            stroke: child.stroke,
            strokeWidth: child.strokeWidth,
          }),
        }
      })
    }
    return serializedElement
  }

  // Helper function to deserialize elements
  const deserializeElement = (element) => {
    const deserializedElement = {
      ...element,
      draggable: true,
      // Ensure transforms are numbers
      rotation: Number(element.rotation) || 0,
      scaleX: Number(element.scaleX) || 1,
      scaleY: Number(element.scaleY) || 1,
    }

    // Handle children with proper transform inheritance
    if (element.children) {
      deserializedElement.children = element.children.map((child) => {
        const baseChild = {
          ...child,
          // Ensure all numeric properties are properly converted
          x: Number(child.x) || 0,
          y: Number(child.y) || 0,
          // width: Number(child.width),
          // height: Number(child.height),
          ...(child.width != undefined && {width: Number(child.width)}),
          ...(child.height != undefined && {height: Number(child.height)}),
          // Handle transforms relative to parent
          rotation: Number(child.rotation) || 0,
          scaleX: Number(child.scaleX) || 1,
          scaleY: Number(child.scaleY) || 1,
        }

        // Add type-specific properties
        switch (child.type) {
          case 'line':
            return {
              ...baseChild,
              points: Array.isArray(child.points) ? child.points.map(Number) : [],
              strokeWidth: Number(child.strokeWidth) || 1,
            }
          case 'text':
            return {
              ...baseChild,
              fontSize: Number(child.fontSize) || 40,
            }
          case 'circle':
            return {
              ...baseChild,
              radius: Number(child.radius),
              fill: child.fill,
              stroke: child.stroke,
              strokeWidth: Number(child.strokeWidth) || 1,
            }
          default:
            return baseChild
        }
      })
    }

    return deserializedElement
  }

  // Update the quick save handler
  // const handleQuickSave = async() => {
  //   // const designs = JSON.parse(localStorage.getItem('barnDesigns') || '[]')
  //   // const currentDesign = designs[designs.length - 1]

  //   // if (!currentDesign) {
  //   //   setShowSaveDialog(true)
  //   //   return
  //   // }

  //   if (!currentDesignId) {
  //     setShowSaveDialog(true)
  //     return
  //   }

  //   // Serialize all canvas elements
  //   const serializedCanvasElements = canvasElements.map(serializeElement)

  //   // const designData = {
  //   //   ...currentDesign,
  //   //   timestamp: new Date().toISOString(),
  //   //   formData,
  //   //   customizations,
  //   //   barnLayout,
  //   //   canvasElements: serializedCanvasElements,
  //   //   lastStallNumber,
  //   //   isEditMode: true,
  //   // }

  //   const designData = {
  //     id: currentDesignId,
  //     name: designName,
  //     timestamp: new Date().toISOString(),
  //     formData,
  //     customizations,
  //     barnLayout,
  //     canvasElements: serializedCanvasElements,
  //     lastStallNumber,
  //     isEditMode: true,
  //     customer_id: customerId
  //   }

  //   const response = await axios.post(`${NEST_API_URL}/barns/saveBarnDesign`, designData)

  //   setCurrentDesignId(response.data.id)

  //   // designs[designs.length - 1] = designData
  //   // localStorage.setItem('barnDesigns', JSON.stringify(designs))
  // }

  const captureStageStateForThumbnail = () => {
    if (!stageRef?.current?.getStage()) return
    const dataUrl = stageRef.current.getStage().toDataURL()
    // downloadURI(dataUrl, 'stage.png');
    return dataUrl
  }

  // function downloadURI(uri, name) {
  //   var link = document.createElement('a');
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  const handleQuickSave = async () => {
    if (!currentDesignId) {
      setShowSaveDialog(true)
      return
    }

    loadingOverlay({show: true})
    try {
      // const serializedCanvasElements = canvasElements.map(serializeElement)

      const serializedCanvasElements = canvasElements
        .filter((element) => !element.isBarnStructure)
        .map(serializeElement)

      const designThumbnail = captureStageStateForThumbnail()

      const designData = {
        id: currentDesignId,
        name: designName,
        timestamp: new Date().toISOString(),
        formData,
        customizations,
        barnLayout,
        canvasElements: serializedCanvasElements,
        lastStallNumber,
        isEditMode: true,
        customerId: customerId,
        barnId: parseInt(barn_id),
        designThumbnail: designThumbnail,
      }

      const response = await axios.post(`${NEST_API_URL}/barns/saveBarnDesign`, designData)
      // setCurrentDesignId(response.data.id)

      handleLoad(response.data.id, false)
    } catch (error) {
      console.error('Error quick saving design:', error)
      alert('Failed to save design. Please try again.')
    } finally {
      loadingOverlay({show: false})
    }
  }

  // Add this useEffect for keyboard controls
  // Add these variables outside the useEffect
  const initialMoveStep = 5
  let moveStep = initialMoveStep
  let intervalId = null

  // Update the useEffect for keyboard controls
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedElement) return

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          e.preventDefault()
          if (!intervalId) {
            intervalId = setInterval(() => {
              setCanvasElements((prev) =>
                prev.map((el) =>
                  el.id === selectedElement
                    ? {
                        ...el,
                        x:
                          e.key === 'ArrowLeft'
                            ? el.x - moveStep
                            : e.key === 'ArrowRight'
                            ? el.x + moveStep
                            : el.x,
                        y:
                          e.key === 'ArrowUp'
                            ? el.y - moveStep
                            : e.key === 'ArrowDown'
                            ? el.y + moveStep
                            : el.y,
                      }
                    : el
                )
              )
              moveStep += 1 // Increase move step gradually
            }, 30) // Adjust the interval duration as needed
          }
          break
        default:
          break
      }
    }

    const handleKeyUp = (e) => {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          if (intervalId) {
            clearInterval(intervalId)
            intervalId = null
            moveStep = initialMoveStep // Reset move step to initial value
          }
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [selectedElement])

  // Add this function before the return statement in BarnDesigner
  const renderChild = (child, index, elementId, canvasElements) => {
    switch (child.type) {
      case 'rectangle':
        return <Rect key={`${elementId}-rect-${index}`} {...child} />
      case 'circle':
        return <Circle key={`${elementId}-circle-${index}`} {...child} />
      case 'line':
        return <Line key={`${elementId}-line-${index}`} {...child} />
      case 'text':
        return <Text key={`${elementId}-text-${index}`} {...child} />
      default:
        return null
    }
  }

  const handleLineDragStart = (e, lineId) => {
    e.cancelBubble = true
  }

  const handleLineDragMove = (e, lineId) => {
    e.cancelBubble = true
  }

  const handleLineDragEnd = (e, lineId) => {
    e.cancelBubble = true
    const node = e.target
    const newX = node.x()
    const newY = node.y()

    // while dragging if the line is not selected then select it also
    if (!selectedElements.includes(lineId)) {
      handleElementClick(lineId, e)
    }

    setCanvasElements((prevElements) =>
      prevElements.map((el) => {
        if (el.id === lineId) {
          return {
            ...el,
            x: newX,
            y: newY,
            children: el.children.map((child, index) => {
              if (index === 0) {
                return {
                  ...child,
                  x: newX,
                  y: newY,
                }
              } else {
                return child
              }
            }),
          }
        }
        return el
      })
    )
  }

  const handleDeleteDesign = async () => {
    try {
      loadingOverlay({show: true})

      const confirmed = window.confirm('Are you sure you want to delete this design?')
      if (!confirmed) return
      const payload = {
        sgl_id: currentDesignId,
        customerId: customerId,
      }
      const response = await axios.delete(`${NEST_API_URL}/barns/deleteBarnDesign`, {
        params: payload,
      })
      if (response.data['success']) {
        setFormData(baseFormDetails)
        setIsEditMode(false)
      } else {
        alert('Failed to delete design. Please try again.')
      }
    } catch (error) {
      console.error('Error deleting design:', error)
      alert('Failed to delete design. Please try again.')
    } finally {
      loadingOverlay({show: false})
    }
  }

  const handleToggleActiveInActiveBarnDesign = async (activeInActiveState) => {
    try {
      const confirmed = window.confirm('Are you sure you want to change the status of this design?')
      if (!confirmed) return

      loadingOverlay({show: true})
      setIsDesignActive(activeInActiveState)
      const payload = {
        sgl_id: currentDesignId,
        customerId: customerId,
        active: activeInActiveState,
        barnId: parseInt(barn_id),
      }
      const response = await axios.post(
        `${NEST_API_URL}/barns/toggleActiveInActiveBarnDesign`,
        payload
      )
      if (response.data['success']) {
      } else {
        alert('Failed to toggle active/inactive design. Please try again.')
      }
    } catch (error) {
      console.error('Error toggling active/inactive design:', error)
      alert('Failed to toggle active/inactive design. Please try again.')
    } finally {
      loadingOverlay({show: false})
    }
  }

  const ColorPicker = ({onColorChange, initialColor = '#333333'}) => {
    const [showPicker, setShowPicker] = useState(false)
    const [selectedColor, setSelectedColor] = useState(initialColor)

    const colors = [
      '#333333', // Dark gray
      '#e9edef', // Default gray
      '#FFB6C1', // Light pink
      '#ADD8E6', // Light blue
      '#98FB98', // Pale green
      '#DDA0DD', // Plum
      '#F0E68C', // Khaki
      '#E6E6FA', // Lavender
      '#FFDAB9', // Peach
      '#D3D3D3', // Light gray
      '#FFFFFF', // White
      '#90EE90', // Light green
      '#FFA07A', // Light salmon
      '#f6f6f6', // Very Light gray
      '#86dcc2', // Alt Green
      '#cbc8f7', // Light Purple
    ]

    const handleColorClick = (color) => {
      setSelectedColor(color)
      onColorChange(color)
      setShowPicker(false)
    }

    return (
      <div style={{position: 'relative', display: 'inline-block'}}>
        <button
          onClick={() => setShowPicker(!showPicker)}
          style={{
            padding: '8px',
            border: 'none',
            borderRadius: '4px',
            background: '#f0f0f0',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title='Change Background Color'
        >
          <Palette size={16} />
        </button>

        {showPicker && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: '0',
              marginTop: '4px',
              padding: '8px',
              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '4px',
              width: '170px',
              zIndex: 1000,
            }}
          >
            {colors.map((color) => (
              <button
                key={color}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleColorClick(color)
                }}
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: color,
                  border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                title={color}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {loading && (
        <div id='barn-designer' style={{height: '100%', width: '100%'}}>
          <SaveDialog isOpen={showSaveDialog} onClose={() => setShowSaveDialog(false)} />
          <LoadDialog isOpen={showLoadDialog} onClose={() => setShowLoadDialog(false)} />
          {/* Toolbar */}
          <div className='barn-designer-toolbar'>
            <button
              className={`mode-toggle ${isEditMode ? 'active' : ''}`}
              onClick={handleModeSwitch}
            >
              <i className={`fas fa-${isEditMode ? 'eye' : 'edit'}`}></i>
              {isEditMode ? ' View Mode' : ' Edit Mode'}
            </button>

            <button onClick={() => setShowLoadDialog(true)}>
              <Upload size={16} />
              Load Design
            </button>

            {isEditMode && (
              <>
                <button onClick={() => setShowSaveDialog(true)}>
                  <SaveAll size={16} />
                  Save Design As...
                </button>
                <button onClick={handleQuickSave}>
                  <Save size={16} />
                  Save Design
                </button>

                {isEditMode && currentDesignId && (
                  <div className='d-flex align-items-center gap-3 '>
                    <button
                      onClick={handleDeleteDesign}
                      className='d-flex align-items-center gap-2'
                    >
                      <Trash2 size={16} style={{color: '#ff4444'}} />
                      Delete Design
                    </button>

                    <label
                      className={`toggle-switch ${isDesignActive ? 'checked' : ''}`}
                      style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                      <input
                        type='checkbox'
                        checked={isDesignActive}
                        onChange={() => handleToggleActiveInActiveBarnDesign(!isDesignActive)}
                      />
                      &nbsp; <div className='slider'></div>{' '}
                      <span className='fw-bolder fs-5 label'>Active</span>
                    </label>
                  </div>
                )}

                {isEditMode && (
                  <div className='d-flex align-items-center gap-3 ms-auto'>
                    {designName && currentDesignId ? (
                      <div className='design-status-label'>
                        <FileEdit size={16} />
                        <span>
                          Current Design: <strong>{designName}</strong>
                        </span>
                      </div>
                    ) : (
                      <div className='design-status-label'>
                        <FilePlus size={16} />
                        <span>New Barn Design</span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <Split
            className='split-horizontal split-bottom'
            minSize={[210, 800]}
            maxSize={[210, Infinity]}
            gutterSize={5}
            snapOffset={0}
          >
            {/* Left Panel - Either Editor Sidebar or Parameters Form */}
            {isEditMode ? (
              renderEditorSidebar()
            ) : (
              <div className='parameters'>
                <h1 className='text-dark fw-bolder mb-3 fs-3 text-center'> Barn Designer </h1>
                <form>
                  <div className='field-container'>
                    <div className='field'>
                      <label>Structure Length (feet)</label>
                      <input
                        type='number'
                        name='structureLength'
                        value={formData.structureLength}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.structureLength && (
                        <span className='error-msg'>{errors.structureLength}</span>
                      )}
                    </div>

                    <div className='field'>
                      <label>Structure Width (feet)</label>
                      <input
                        type='number'
                        name='structureWidth'
                        value={formData.structureWidth}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.structureWidth && (
                        <span className='error-msg'>{errors.structureWidth}</span>
                      )}
                    </div>
                  </div>

                  <div className='field-container'>
                    <div className='field'>
                      <label>Horizontal Aisles Count</label>
                      <input
                        type='number'
                        name='horizontalAisleCount'
                        value={formData.horizontalAisleCount}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.horizontalAisleCount && (
                        <span className='error-msg'>{errors.horizontalAisleCount}</span>
                      )}
                    </div>

                    <div className='field'>
                      <label>Horizontal Aisle Width (feet)</label>
                      <input
                        type='number'
                        name='horizontalAisleWidth'
                        value={formData.horizontalAisleWidth}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.horizontalAisleWidth && (
                        <span className='error-msg'>{errors.horizontalAisleWidth}</span>
                      )}
                    </div>
                  </div>

                  <div className='field-container'>
                    <div className='field'>
                      <label>Vertical Aisles Count</label>
                      <input
                        type='number'
                        name='verticalAisleCount'
                        value={formData.verticalAisleCount}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.verticalAisleCount && (
                        <span className='error-msg'>{errors.verticalAisleCount}</span>
                      )}
                    </div>

                    <div className='field'>
                      <label>Vertical Aisle Width (feet)</label>
                      <input
                        type='number'
                        name='verticalAisleWidth'
                        value={formData.verticalAisleWidth}
                        onChange={handleChange}
                        min='0'
                      />
                      {errors.verticalAisleWidth && (
                        <span className='error-msg'>{errors.verticalAisleWidth}</span>
                      )}
                    </div>
                  </div>

                  <div className='field-container'>
                    <div className='field'>
                      <label>Stall Length</label>
                      <input
                        type='number'
                        name='stallLength'
                        value={formData.stallLength}
                        onChange={handleChange}
                        min='5'
                      />
                      {errors.stallLength && (
                        <span className='error-msg'>{errors.stallLength}</span>
                      )}
                    </div>

                    <div className='field'>
                      <label>Stall Width</label>
                      <input
                        type='number'
                        name='stallWidth'
                        value={formData.stallWidth}
                        onChange={handleChange}
                        min='5'
                      />
                      {errors.stallWidth && <span className='error-msg'>{errors.stallWidth}</span>}
                    </div>
                  </div>
                </form>
              </div>
            )}

            {/* Right Panel - Canvas */}
            <div id='canvas' style={{position: 'relative'}}>
              {/* Error messages */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 50,
                  padding: '8px',
                  maxHeight: '128px',
                  overflowY: 'auto',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  textAlign: 'center',
                }}
              >
                {layoutErrors.map((error, index) => (
                  <div
                    key={index}
                    style={{
                      color: error.severity === 'error' ? 'red' : 'orange',
                      display: 'block',
                      marginBottom: '4px',
                    }}
                  >
                    {error.message}
                  </div>
                ))}
              </div>

              {/* Zoom controls */}
              <div className='zoom-controls'>
                <button onClick={zoomIn}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
                    <path d='M32 18.133H18.133V32h-4.266V18.133H0v-4.266h13.867V0h4.266v13.867H32z'></path>
                  </svg>
                </button>
                <button onClick={zoomOut}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 5'>
                    <path d='M0 0h32v4.2H0z'></path>
                  </svg>
                </button>
                <button onClick={fitView}>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 30'>
                    <path d='M3.692 4.63c0-.53.4-.938.939-.938h5.215V0H4.708C2.13 0 0 2.054 0 4.63v5.216h3.692V4.631zM27.354 0h-5.2v3.692h5.17c.53 0 .984.4.984.939v5.215H32V4.631A4.624 4.624 0 0027.354 0zm.954 24.83c0 .532-.4.94-.939.94h-5.215v3.768h5.215c2.577 0 4.631-2.13 4.631-4.707v-5.139h-3.692v5.139zm-23.677.94c-.531 0-.939-.4-.939-.94v-5.138H0v5.139c0 2.577 2.13 4.707 4.708 4.707h5.138V25.77H4.631z'></path>
                  </svg>
                </button>
              </div>

              <UndoRedoControls
                canUndo={currentHistoryIndex > 0}
                canRedo={currentHistoryIndex < history.length - 1}
                onUndo={handleUndo}
                onRedo={handleRedo}
              />

              {/* Stage */}
              <Stage
                width={window.innerWidth - 210 - 75 - 5}
                height={window.innerHeight - 54 - 50 - 40}
                ref={stageRef}
                draggable={!isDragging}
                onWheel={handleWheel}
                scaleX={stageScale}
                scaleY={stageScale}
                x={stagePosition.x}
                y={stagePosition.y}
                onDragStart={(e) => {
                  if (e.target === e.target.getStage()) {
                    e.evt.preventDefault()
                    e.evt.stopPropagation()
                  }
                }}
                onDragMove={(e) => {
                  if (!isDragging && e.target === e.target.getStage()) {
                    setStagePosition({
                      x: e.target.x(),
                      y: e.target.y(),
                    })
                  }
                }}
                onClick={(e) => {
                  // Check if click is on toolbar
                  const targetElement = e.evt.target
                  if (targetElement.closest('.element-toolbar')) {
                    return // Don't handle stage clicks if clicking toolbar
                  }

                  if (targetElement.closest('.multi-select-toolbar')) {
                    return // Don't handle stage clicks if clicking toolbar
                  }

                  // Regular stage click handling
                  if (e.target === e.target.getStage()) {
                    setSelectedElement(null)
                    // Clear selection if clicking on empty space
                    setSelectedElements([])
                    if (transformerRef.current) {
                      transformerRef.current.nodes([])
                      transformerRef.current.getLayer().batchDraw()
                    }
                  }
                }}
              >
                <Layer>
                  {/* Render either barn layout or canvas elements based on mode */}
                  {isEditMode ? (
                    // Edit mode - render canvas elements
                    <>
                      {/* Render barn structure */}
                      {/* <Group
                        id='barn-structure'
                        x={50}
                        y={50}
                        onClick={(e) => {
                          e.cancelBubble = true
                          handleElementClick('barn-structure', e)
                        }}
                      >
                        <Rect
                          width={barnLayout.structure?.width}
                          height={barnLayout.structure?.height}
                          fill='#ffffff'
                          stroke={selectedElement === 'barn-structure' ? '#00ff00' : 'black'}
                          strokeWidth={selectedElement === 'barn-structure' ? 2 : 1}
                        />
                      </Group> */}
                      {/* <Rect
                        id='barn-structure'
                        x={50}
                        y={50}
                        onClick={(e) => {
                          e.cancelBubble = true
                          handleElementClick('barn-structure', e)
                        }}
                        width={barnLayout.structure?.width}
                        height={barnLayout.structure?.height}
                        fill='#ffffff'
                        stroke={selectedElement === 'barn-structure' ? '#00ff00' : 'black'}
                        strokeWidth={selectedElement === 'barn-structure' ? 2 : 1}
                      /> */}
                      {canvasElements.map((element) => {
                        if (element.isBarnStructure) {
                          return (
                            <Rect
                              id='barn-structure'
                              x={element.x}
                              y={element.y}
                              onClick={(e) => {
                                e.cancelBubble = true
                                handleElementClick('barn-structure', e)
                              }}
                              width={element.width}
                              height={element.height}
                              fill='#ffffff'
                              stroke={selectedElement === 'barn-structure' ? '#00ff00' : 'black'}
                              strokeWidth={selectedElement === 'barn-structure' ? 2 : 1}
                            />
                          )
                        }
                        const isSelected = selectedElement === element.id

                        if (element.isGroup) {
                          return (
                            <Group
                              key={element.id}
                              id={element.id}
                              x={element.x}
                              y={element.y}
                              rotation={element.rotation || 0}
                              scaleX={element.scaleX || 1}
                              scaleY={element.scaleY || 1}
                              draggable={isEditMode}
                              onClick={(e) => handleElementClick(element.id, e)}
                              onTap={(e) => handleElementClick(element.id, e)}
                              onDragStart={(e) => {
                                e.cancelBubble = true
                                setIsDragging(true)

                                // If element isn't selected, select it now
                                if (!selectedElements.includes(element.id)) {
                                  handleElementClick(element.id, e)
                                }

                                const stage = e.target.getStage()
                                const mousePos = stage.getPointerPosition()
                                const initialData = {
                                  mouseX: mousePos.x,
                                  mouseY: mousePos.y,
                                  elements: selectedElements.map((id) => {
                                    const el = canvasElements.find((e) => e.id === id)
                                    return {
                                      id,
                                      initialX: el.x,
                                      initialY: el.y,
                                    }
                                  }),
                                  draggedX: element.x,
                                  draggedY: element.y,
                                }
                                setDraggedElements(initialData)
                              }}
                              onDragMove={(e) => {
                                e.cancelBubble = true
                              }}
                              onDragEnd={(e) => {
                                e.cancelBubble = true

                                setCanvasElements((prev) =>
                                  prev.map((el) => {
                                    if (selectedElements.includes(el.id)) {
                                      const node = stageRef.current?.findOne(`#${el.id}`)
                                      if (node) {
                                        return {
                                          ...el,
                                          x: node.x(),
                                          y: node.y(),
                                        }
                                      }
                                    }
                                    return el
                                  })
                                )

                                setIsDragging(false)
                                setDraggedElements({})

                                // Maintain selection after drag
                                if (transformerRef.current && stageRef.current) {
                                  const nodes = selectedElements
                                    .map((id) => stageRef.current.findOne(`#${id}`))
                                    .filter(Boolean)
                                  transformerRef.current.nodes(nodes)
                                  transformerRef.current.getLayer().batchDraw()
                                }
                              }}
                            >
                              {/* Render each child of the group */}
                              {element.children.map((childElement) => (
                                <Group
                                  key={childElement.id}
                                  x={childElement.x}
                                  y={childElement.y}
                                  width={childElement.width}
                                  height={childElement.height}
                                  rotation={childElement.rotation}
                                  scaleX={childElement.scaleX}
                                  scaleY={childElement.scaleY}
                                >
                                  {childElement.children?.map((child, index) => {
                                    // Disable dragging for lines when they are part of the group
                                    if (child.type === 'line') {
                                      child.draggable = false
                                    }
                                    return renderChild(child, index, element.id, canvasElements)
                                  })}
                                </Group>
                              ))}
                            </Group>
                          )
                        } else if (element.isLine) {
                          const isSelected =
                            selectedElement === element.id && selectedElements.length === 1

                          return (
                            <Group
                              key={element.id}
                              id={element.id}
                              x={element.children[0].x}
                              y={element.children[0].y}
                              draggable={element.children[0].draggable && isEditMode}
                              onDragStart={(e) => handleLineDragStart(e, element.id)}
                              onDragMove={(e) => handleLineDragMove(e, element.id)}
                              onDragEnd={(e) => handleLineDragEnd(e, element.id)}
                              onClick={(e) => {
                                e.cancelBubble = true // Prevent the click event from propagating further
                                handleElementClick(element.id, e)
                              }}
                              onTap={(e) => {
                                e.cancelBubble = true
                                handleElementClick(element.id, e)
                              }}
                              ref={isSelected ? selectedShapeRef : null}
                            >
                              {/* Line */}
                              <Line
                                points={element.children[0].points}
                                stroke={element.children[0].stroke}
                                strokeWidth={element.children[0].strokeWidth}
                                lineCap={element.children[0].lineCap}
                                dash={element.children[0].dash}
                                hitStrokeWidth={element.children[0].hitStrokeWidth}
                                onClick={(e) => {
                                  e.cancelBubble = true // Ensure the click doesn't propagate past the line
                                  handleElementClick(element.id, e)
                                }}
                              />
                              {/* Handles */}
                              {isSelected && (
                                <>
                                  {/* Start Handle */}
                                  <Rect
                                    x={element.children[0].points[0] - 20}
                                    y={element.children[0].points[1] - 20}
                                    width={40}
                                    height={40}
                                    fill='white'
                                    // stroke="#00BFFF"
                                    stroke='#00BFFF'
                                    strokeWidth={5}
                                    draggable
                                    onDragMove={(e) => {
                                      e.cancelBubble = true
                                      const {x, y} = e.target.position()

                                      // Update the line points on the canvas only when dragging the handles
                                      // Here we are not keeping the history of each movement of the handle
                                      setCanvasElementsState((prevElements) =>
                                        prevElements.map((el) => {
                                          if (el.id === element.id) {
                                            const newPoints = [...el.children[0].points]
                                            newPoints[0] = x + 20
                                            newPoints[1] = y + 20
                                            return {
                                              ...el,
                                              children: [
                                                {
                                                  ...el.children[0],
                                                  points: newPoints,
                                                },
                                              ],
                                            }
                                          }
                                          return el
                                        })
                                      )
                                    }}
                                    onDragEnd={(e) => {
                                      e.cancelBubble = true
                                      const {x, y} = e.target.position()

                                      // Update the line points on the canvas only when dragging the handles end
                                      // Here we are keeping the history of last movement of the handle
                                      setCanvasElements((prevElements) =>
                                        prevElements.map((el) => {
                                          if (el.id === element.id) {
                                            const newPoints = [...el.children[0].points]
                                            newPoints[0] = x + 20
                                            newPoints[1] = y + 20
                                            return {
                                              ...el,
                                              children: [
                                                {
                                                  ...el.children[0],
                                                  points: newPoints,
                                                },
                                              ],
                                            }
                                          }
                                          return el
                                        })
                                      )
                                    }}
                                    onClick={(e) => (e.cancelBubble = true)}
                                    onTap={(e) => (e.cancelBubble = true)}
                                  />
                                  {/* End Handle */}
                                  <Rect
                                    x={element.children[0].points[2] - 20}
                                    y={element.children[0].points[3] - 20}
                                    width={40}
                                    height={40}
                                    fill='white'
                                    stroke='#00BFFF'
                                    strokeWidth={5}
                                    draggable
                                    onDragMove={(e) => {
                                      e.cancelBubble = true
                                      const {x, y} = e.target.position()

                                      // Update the line points on the canvas only when dragging the handles
                                      // Here we are not keeping the history of each movement of the handle
                                      setCanvasElementsState((prevElements) =>
                                        prevElements.map((el) => {
                                          if (el.id === element.id) {
                                            const newPoints = [...el.children[0].points]
                                            newPoints[2] = x + 20
                                            newPoints[3] = y + 20
                                            return {
                                              ...el,
                                              children: [
                                                {
                                                  ...el.children[0],
                                                  points: newPoints,
                                                },
                                              ],
                                            }
                                          }
                                          return el
                                        })
                                      )
                                    }}
                                    onDragEnd={(e) => {
                                      e.cancelBubble = true
                                      const {x, y} = e.target.position()

                                      // Update the line points on the canvas only when dragging the handles end
                                      // Here we are keeping the history of last movement of the handle
                                      setCanvasElements((prevElements) =>
                                        prevElements.map((el) => {
                                          if (el.id === element.id) {
                                            const newPoints = [...el.children[0].points]
                                            newPoints[2] = x + 20
                                            newPoints[3] = y + 20
                                            return {
                                              ...el,
                                              children: [
                                                {
                                                  ...el.children[0],
                                                  points: newPoints,
                                                },
                                              ],
                                            }
                                          }
                                          return el
                                        })
                                      )
                                    }}
                                    onClick={(e) => (e.cancelBubble = true)}
                                    onTap={(e) => (e.cancelBubble = true)}
                                  />
                                </>
                              )}
                            </Group>
                          )
                        }

                        return (
                          <Group
                            key={element.id}
                            id={element.id}
                            x={element.x}
                            y={element.y}
                            width={element.width}
                            height={element.height}
                            draggable={element.draggable && isEditMode}
                            rotation={element.rotation || 0}
                            scaleX={element.scaleX || 1}
                            scaleY={element.scaleY || 1}
                            onClick={(e) => handleElementClick(element.id, e)}
                            onTap={(e) => handleElementClick(element.id, e)}
                            ref={isSelected ? selectedShapeRef : null}
                            onDragStart={(e) => {
                              e.cancelBubble = true
                              setIsDragging(true)

                              // If element isn't selected, select it now
                              if (!selectedElements.includes(element.id)) {
                                handleElementClick(element.id, e)
                              }

                              const stage = e.target.getStage()
                              const mousePos = stage.getPointerPosition()
                              const initialData = {
                                mouseX: mousePos.x,
                                mouseY: mousePos.y,
                                elements: selectedElements.map((id) => {
                                  const el = canvasElements.find((e) => e.id === id)
                                  return {
                                    id,
                                    initialX: el.x,
                                    initialY: el.y,
                                  }
                                }),
                                draggedX: element.x,
                                draggedY: element.y,
                              }
                              setDraggedElements(initialData)
                            }}
                            onDragMove={(e) => {
                              e.cancelBubble = true
                            }}
                            onDragEnd={(e) => {
                              e.cancelBubble = true

                              setCanvasElements((prev) =>
                                prev.map((el) => {
                                  if (selectedElements.includes(el.id)) {
                                    const node = stageRef.current?.findOne(`#${el.id}`)
                                    if (node) {
                                      return {
                                        ...el,
                                        x: node.x(),
                                        y: node.y(),
                                      }
                                    }
                                  }
                                  return el
                                })
                              )

                              setIsDragging(false)
                              setDraggedElements({})

                              // Maintain selection after drag
                              if (transformerRef.current && stageRef.current) {
                                const nodes = selectedElements
                                  .map((id) => stageRef.current.findOne(`#${id}`))
                                  .filter(Boolean)
                                transformerRef.current.nodes(nodes)
                                transformerRef.current.getLayer().batchDraw()
                              }
                            }}
                          >
                            {element.children?.map((child, index) =>
                              renderChild(child, index, element.id)
                            )}
                          </Group>
                        )
                      })}
                      {/* Render all canvas elements */}
                      {selectedElement &&
                        (selectedElements.length > 1
                          ? true
                          : !canvasElements.find((el) => el.id === selectedElement)?.isLine) && (
                          // Inside the Transformer component props
                          <Transformer
                            ref={transformerRef}
                            nodes={stageRef.current?.find((node) =>
                              selectedElements.includes(node.id())
                            )}
                            rotateEnabled={selectedElement !== 'barn-structure'}
                            enabledAnchors={(() => {
                              if (
                                selectedElements.length > 1 ||
                                canvasElements.find((el) => el.id === selectedElement)?.isGroup
                              ) {
                                return ['top-left', 'top-right', 'bottom-right', 'bottom-left']
                              }

                              const element = canvasElements.find((el) => el.id === selectedElement)
                              const isStandaloneLine =
                                element?.children?.[0]?.type === 'line' &&
                                !element.isStall &&
                                typeof element?.id === 'string' &&
                                element.id.startsWith('line-')

                              const isStandaloneText = element?.children?.[0]?.type === 'text'

                              return isStandaloneLine
                                ? ['middle-left', 'middle-right']
                                : isStandaloneText
                                ? ['top-left', 'top-right', 'bottom-right', 'bottom-left']
                                : [
                                    'top-left',
                                    'top-center',
                                    'top-right',
                                    'middle-right',
                                    'bottom-right',
                                    'bottom-center',
                                    'bottom-left',
                                    'middle-left',
                                  ]
                            })()}
                            keepRatio={({nodeType, node}) => {
                              const activeAnchor = transformerRef.current?.getActiveAnchor()
                              return [
                                'top-center',
                                'bottom-center',
                                'middle-right',
                                'middle-left',
                              ].includes(activeAnchor)
                            }}
                            boundBoxFunc={(oldBox, newBox) => {
                              const minSize = 20
                              if (
                                Math.abs(newBox.width) < minSize ||
                                Math.abs(newBox.height) < minSize
                              ) {
                                return oldBox
                              }
                              return newBox
                            }}
                            // onTransform={(e) => {
                            //   // Only update visual representation during transform
                            //   // Don't save state here

                            //   // CHECK IF TRANSFORMATION IS APPLIED VIA THE MIDDLE ANCHORS ONLY
                            //   const activeAnchor = transformerRef.current?.getActiveAnchor()
                            //   const isMiddleAnchor = ['middle-left', 'middle-right'].includes(
                            //     activeAnchor
                            //   )
                            //   if (isMiddleAnchor) {
                            //     // CHECK IF SELECTED ELEMENT IS AISLE OR NOT
                            //     if (selectedElement.startsWith('aisle-')) {
                            //       const node = stageRef.current.findOne(`#${selectedElement}`)
                            //       const canvasElement = canvasElements.find(
                            //         (el) => el.id === selectedElement
                            //       )
                            //       console.log('node', node)
                            //       console.log('canvasElement', canvasElement)
                            //       const scaleX = node.scaleX()

                            //       // Find the text node within the group
                            //       const textNode = node.findOne('Text')

                            //       if (textNode) {
                            //         // Apply inverse scale to the text to maintain its original size
                            //         textNode.scaleX(1 / scaleX)

                            //         // Adjust text position to maintain centering
                            //         const newWidth = node.width() * scaleX
                            //         textNode.width(newWidth)
                            //         textNode.x(newWidth / 2)
                            //         textNode.offsetX(newWidth / 2)
                            //       }
                            //     }
                            //   }
                            // }}

                            // onTransform={(e) => {
                            //   const activeAnchor = transformerRef.current?.getActiveAnchor()
                            //   const isMiddleAnchor = ['middle-left', 'middle-right'].includes(activeAnchor)

                            //   if (isMiddleAnchor) {
                            //     if (selectedElement.startsWith('aisle-')) {
                            //       const node = stageRef.current.findOne(`#${selectedElement}`)
                            //       const scaleX = node.scaleX()

                            //       // Find both rectangle and text nodes
                            //       const rectNode = node.findOne('Rect')
                            //       const textNode = node.findOne('Text')

                            //       // Calculate new width based on scale
                            //       const newWidth = node.width() * scaleX

                            //       if (rectNode) {
                            //         // Update rectangle width
                            //         rectNode.width(newWidth)
                            //         rectNode.scaleX(1) // Reset scale to prevent double scaling
                            //       }

                            //       if (textNode) {
                            //         // Reset text properties
                            //         textNode.scaleX(1)
                            //         textNode.width(newWidth)
                            //         textNode.x(newWidth / 2)
                            //         textNode.offsetX(newWidth / 2)
                            //       }

                            //       // Reset group scale
                            //       node.scaleX(1)
                            //       node.width(newWidth)
                            //     }
                            //   }
                            // }}

                            onTransformEnd={(e) => {
                              const canvasElementsCopy = structuredClone(canvasElements)
                              selectedElements.forEach((selectedElementId) => {
                                const node = stageRef.current.findOne(`#${selectedElementId}`)
                                const scaleX = node.scaleX()
                                const scaleY = node.scaleY()
                                const rotation = node.rotation()

                                const newX = node.x() - (node.width() * scaleX - node.width()) / 2
                                const newY = node.y() - (node.height() * scaleY - node.height()) / 2

                                canvasElementsCopy.forEach((el) => {
                                  if (
                                    el.id === 'barn-structure' &&
                                    selectedElement === 'barn-structure' &&
                                    el.isBarnStructure
                                  ) {
                                    node.scaleX(1)
                                    node.scaleY(1)

                                    setShouldFitToScreen(false)

                                    setBarnLayout({
                                      ...barnLayout,
                                      structure: {
                                        ...barnLayout.structure,
                                        width: node.width() * scaleX,
                                        height: node.height() * scaleY,
                                        // x: newX,
                                        // y: newY,
                                        x: node.x(),
                                        y: node.y(),
                                      },
                                    })

                                    const element= {
                                      ...el,
                                      width: node.width() * scaleX,
                                      height: node.height() * scaleY,
                                    }
                                    Object.assign(el, element)

                                  } else if (selectedElements.includes(el.id)) {
                                    if (el.id.startsWith('group-')) {
                                      const element ={
                                        ...el,
                                        // x: node.x(),
                                        // y: node.y(),
                                        x: newX,
                                        y: newY,
                                        rotation: rotation,
                                        scaleX,
                                        scaleY,
                                      }
                                      Object.assign(el, element)

                                    } else if(selectedElementId === el.id) {
                                      const element = {
                                        ...el,
                                        rotation: rotation,
                                        scaleX,
                                        scaleY,
                                        x: node.x(),
                                        y: node.y(),
                                        width: node.width() * scaleX,
                                        height: node.height() * scaleY,
                                      }
                                      console.log('after element',element)
                                      Object.assign(el, element)
                                    }
                                  }

                                  return el
                                })

                                
                              })

                              setCanvasElements(canvasElementsCopy)
                            }}
                          />
                        )}
                    </>
                  ) : (
                    // View mode - render barn layout
                    <>
                      {/* Barn structure */}
                      <Rect
                        x={50}
                        y={50}
                        width={barnLayout.structure?.width}
                        height={barnLayout.structure?.height}
                        fill='#ffffff'
                        stroke='black'
                        strokeWidth={1}
                      />

                      {/* Clipping group for stalls and aisles */}
                      <Group
                        clipFunc={(ctx) => {
                          ctx.beginPath()
                          ctx.rect(
                            50,
                            50,
                            barnLayout.structure?.width,
                            barnLayout.structure?.height
                          )
                          ctx.closePath()
                        }}
                      >
                        {/* Draw the aisles */}
                        {(() => {
                          let horizontalAisleNumber = 1
                          return barnLayout.aisles?.map((aisle) => (
                            <Group
                              key={`aisle-group-${aisle.id}`}
                              onClick={() => handleSelect(aisle.id)}
                              onTap={() => handleSelect(aisle.id)}
                            >
                              <Rect
                                x={50 + aisle.x}
                                y={50 + aisle.y}
                                width={aisle.width}
                                height={aisle.height}
                                fill={selectedId === aisle.id ? '#e0e0e0' : '#f6f6f6'}
                                stroke={selectedId === aisle.id ? '#333333' : '#cccccc'}
                                strokeWidth={selectedId === aisle.id ? 2 : 1}
                              />
                              {aisle.type === 'horizontal' && (
                                <Text
                                  x={50 + aisle.x}
                                  y={50 + aisle.y}
                                  width={aisle.width}
                                  height={aisle.height}
                                  text={
                                    customizations.aisles[aisle.id]?.name ||
                                    `AISLE ${horizontalAisleNumber++}`
                                  }
                                  fontSize={48}
                                  align='center'
                                  verticalAlign='middle'
                                  fontFamily={CANVAS_FONT_FAMILY}
                                />
                              )}
                            </Group>
                          ))
                        })()}

                        {/* Draw stalls */}
                        {barnLayout.stalls?.map((stall) => {
                          const textArea = getStallTextArea(
                            stall.width,
                            stall.height,
                            stall.orientation
                          )
                          return (
                            <Group
                              key={`stall-group-${stall.id}`}
                              onClick={() => handleSelect(stall.id)}
                              onTap={() => handleSelect(stall.id)}
                            >
                              <Line
                                points={stall.points}
                                x={50 + stall.x}
                                y={50 + stall.y}
                                closed={true}
                                fill={selectedId === stall.id ? '#d4d6dd' : '#e9edef'}
                                stroke={selectedId === stall.id ? '#1a237e' : '#333333'}
                                strokeWidth={selectedId === stall.id ? 2 : 1}
                              />
                              <Text
                                x={50 + stall.x + textArea.x}
                                y={50 + stall.y + textArea.y}
                                width={textArea.width}
                                height={textArea.height}
                                text={
                                  customizations.stalls[stall.id]?.name ||
                                  stall.name ||
                                  `${stall.id.split('-')[1]}`
                                }
                                fontSize={40}
                                fill='#000000'
                                align='center'
                                verticalAlign='middle'
                                wrap='word'
                                ellipsis={true}
                                offsetX={textArea.width / 2}
                                offsetY={textArea.height / 2}
                                fontFamily={CANVAS_FONT_FAMILY}
                              />
                            </Group>
                          )
                        })}
                      </Group>
                    </>
                  )}
                </Layer>
              </Stage>
              <div className='toolbar-container'>
                {isEditMode && (
                  <>
                    {selectedElements.length > 1 ||
                    (selectedElements.length === 1 &&
                      canvasElements.find((el) => el.id === selectedElements[0])?.isGroup) ? (
                      // Show MultiSelectToolbar when multiple elements are selected or when a group is selected
                      <MultiSelectToolbar
                        selectedElements={selectedElements}
                        onDuplicate={handleDuplicateGroup}
                        onGroup={
                          selectedElements.length > 1 &&
                          selectedElements.some((element) => {
                            // check if the element is already grouped
                            return canvasElements.find((el) => el.id === element)?.isGroup
                          })
                            ? null
                            : handleGroupElements
                        }
                        onUngroup={() => handleUngroup(selectedElements[0])}
                        onDelete={handleDeleteSelected}
                        isGrouped={
                          selectedElements.length === 1 &&
                          canvasElements.find((el) => el.id === selectedElements[0])?.isGroup
                        }
                      />
                    ) : selectedElement &&
                      !canvasElements.find((el) => el.id === selectedElement)?.isGroup ? (
                      // Show ElementToolbar only for single, non-group elements
                      <ElementToolbar
                        selectedElement={selectedElement}
                        canvasElements={canvasElements}
                        setCanvasElements={setCanvasElements}
                      />
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </Split>

          {/* Edit form overlay */}
          {renderEditForm()}
        </div>
      )}
    </>
  )
}

export default BarnDesigner
