
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from 'react-hook-form';
import { KTSVG } from '../../../../_metronic/helpers';
import axios from 'axios';
import { useAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';

type Props = {
    show: boolean
    handleClose: () => void
    updateCredentials: any 
}
// Add this useEffect to fetch data when dialog opens

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateFeiCredentialsDialog = ({ show, updateCredentials, handleClose }: Props) => {
    const intl = useIntl()
    const methods = useForm({
        defaultValues: {
            fei_username: '',
            fei_password: '', 
            logged_in_user_id: '',           
            error_message: '',
        },
    });
    const { register, getValues, watch, reset } = methods
    const loadingOverlay = useLoadingOverlay()
    const [metaData, setMetaData] = useState({})
    const customer_id = useAppSelector(state => state.showCompany.company_id)
    const alertDialog = useAlert()
    const error_message = watch('error_message')
    const [passwordValidationError, setPasswordValidationError] = useState('')
    useEffect(() => {
        loadingOverlay({ show: true });
    
        axios.get(process.env.REACT_APP_NEST_API_URL + '/users/getLoggedInUser', {
            params: { customer_id: customer_id },
        })
            .then(({ data: currentUser }) => {
                // setMetaData(metaData);
                if (currentUser?.user?.fei_username != "" && currentUser?.user?.fei_password != "" ) {                    
                    // alert(currentUser?.user?.fei_username)
                    reset({
                        fei_username: currentUser?.user?.fei_username ,       // Set the `fei_user` value
                        fei_password: currentUser?.user?.fei_password, // Set the `fei_password` value
                        logged_in_user_id: currentUser?.user?.sgl_id
                    });
                } else {
                    reset({
                        fei_username: "", // Set the `fei_user` value
                        fei_password: "", // Set the `fei_password` value
                        logged_in_user_id: currentUser?.user?.sgl_id
                    });
                }
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            })
            .finally(() => {
                loadingOverlay({ show: false });
            });
    }, []);
    
    const onClose = () => {
        reset()
        handleClose()
    }

    const onSave = () => {
        loadingOverlay({ show: true })
        let data = getValues()
        axios.post(process.env.REACT_APP_NEST_API_URL + '/fei/saveFEICredentials', {
            fei_username: data.fei_username,
            fei_password: data.fei_password,
            customer_id: customer_id,
            logged_in_userid: data.logged_in_user_id

            })
            .then((res) => {
                if(res.data.success) {
                    loadingOverlay({ show: false })
                    alertDialog({ title:  intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.UPDATEFEICREDENTIALS' }), message: res.data.message, icon: "info" })
                    handleClose()
                } else {
                    loadingOverlay({ show: false })
                    alertDialog({ title:  intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.UPDATEFEICREDENTIALS' }), message: res.data.message, icon: "stop" })
                }
                updateCredentials(data.fei_username,data.fei_password)

            })
            .catch((e) => {
                loadingOverlay({ show: false })
                alertDialog({ title: 'Update FEI Credentials', message: intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.UPDATEFEICREDENTIALS' }), icon: "stop" })
            })
            
        onClose()
        
    }        
    
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered modal-xl search-form mw-550px'
            show={show}
            onHide={handleClose}
            onKeyPress={(event :any) => {
                if (event.key === "Enter") {
                    onSave()
                }
            }}
        >
            <div className='modal-header py-0 px-4' >
                <h2 className="fs-4" >{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.UPDATEFEICREDENTIALS' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <FormProvider {...methods}>
                    <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group"> {/* Column Group */}
                                <div className='row mb-2'>                                      
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='FEIUSERNAME'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.LABEL.FEIUSERNAME' })}</label>
                                    <div className='col'>                                    
                                        <input
                                            id='FEIUSERNAME'
                                            type='text'
                                            defaultValue = ""
                                            {...register('fei_username') } 
                                            placeholder = "FEI User Name"
                                            autoComplete="new-password"
                                            data-lpignore="true"
                                            data-form-type="other"
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-145px pe-0' htmlFor='FEIPASSWORD'>{intl.formatMessage({ id: 'FORM.INPUT.MYPREFERENCES.PROFILE.MODAL.CHANGEPASSWORD.LABEL.FEIPASSWORD' })}</label>
                                    <div className='col'>
                                        <input
                                            id='FEIPASSWORD'
                                            type='password'
                                            autoComplete="new-password"
                                            data-lpignore="true"
                                            data-form-type="other"
                                            defaultValue = ""                                            
                                            {...register('fei_password')}
                                            placeholder = "FEI Password"
                                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        />
                                         <div className={`row ${passwordValidationError? '' : 'd-none'}`}>                                            
                                        </div>                                        
                                    </div>
                                </div>                                
                            </div> {/* End Column Group */}
                        </div>{/* End Card Body */}

                        <div className='card-footer d-flex justify-content-end py-3 px-0'>

                            <button type="button" className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={onClose}>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                            </button>

                            <button type="submit" className='btn btn-sm btn-dark fw-bold' onClick={onSave} autoFocus>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.UPDATE' })}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { UpdateFeiCredentialsDialog }