import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { LoadingOverlay } from "./LoadingOverlay";
import { useIntl } from "react-intl";

type Props = {
  show: boolean;
  eventID: string;
  showMessage: boolean;
  showProgress: string;
  timeElapsed: boolean;
  handleClose: () => void;
  title: string;
  showProgressMessageOnly: boolean;
  showCancelButton: boolean;
  handleCancel: () => void;
  clientSideProgress?: number;
  clientSideMessage: string;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const ProgressBar = ({
  show,
  eventID,
  handleClose,
  showMessage = true,
  showProgress,
  timeElapsed,
  title = "",
  showProgressMessageOnly = false,
  showCancelButton = false,
  handleCancel,
  clientSideProgress = 0,
  clientSideMessage = "",
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [time, setTime] = useState("00:00:00");
  const sseRef = useRef<EventSource | null>(null); // Use ref for sse
  const intervalRef = useRef<NodeJS.Timer | null>(null); // Use ref for interval
  const intl = useIntl();

  useEffect(() => {
    if (clientSideProgress > 0) {
      setProgress(clientSideProgress);
    }
  }, [clientSideProgress]);

  useEffect(() => {
    if (clientSideMessage !== "") {
      setMessage(clientSideMessage);
    }
  }, [clientSideMessage]);

  useEffect(() => {
    if (eventID && show) {
      setShowModal(show);

      if (!sseRef.current) {
        sseRef.current = new EventSource(
          `${process.env.REACT_APP_NEST_API_URL}/utility/getProgress?event_id=${eventID}`
        );

        sseRef.current.addEventListener("progress", (e: any) => {
          const data = JSON.parse(e.data);
          if (data?.event_id === eventID) {
            setProgress(data.progress ?? 0);
            setMessage(data.message ?? "");
          }
        });

        sseRef.current.onerror = () => {
          handleSseClose();
        };
      }

      const startTime = Date.now();
      if (!intervalRef.current && timeElapsed) {
        intervalRef.current = setInterval(() => {
          const elapsed = Date.now() - startTime;
          setTime(new Date(elapsed).toISOString().substr(11, 8));
        }, 1000);
      }
    } else {
      handleSseClose();
    }

    return () => {
      handleSseClose();
    };
  }, [show, eventID, timeElapsed]);

  const handleSseClose = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (sseRef.current) {
      sseRef.current.close();
      sseRef.current = null;
    }

    setShowModal(false);
    setProgress(0);
    setMessage("");

    if (handleClose) {
      handleClose();
    }

    return true
  };

  if (showProgressMessageOnly) {
    return <LoadingOverlay show={showModal} message={message} handleClose={handleSseClose} />;
  }

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      backdrop="static"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px search-form"
      show={showModal}
      onHide={handleSseClose}
    >
      <div className="modal-header py-0 px-4">
        <h2 className="fs-4 py-2">{title}</h2>
      </div>
      <div className="modal-body py-3 px-4 shadow-lg">
        <form noValidate className="form" onSubmit={(e) => e.preventDefault()}>
          <div className="card-body p-0">
            <div className="d-flex flex-column w-100 me-2">
              <div className="row p-0 m-0 mb-2">
                <div className={"col-lg-10 ps-0" + (showMessage ? "" : " d-none")}>
                  <span className="fs-6 fw-bold">{message}</span>
                </div>
                <div
                  className={"col-lg-2 pe-0 d-flex justify-content-end" + (message && timeElapsed ? "" : " d-none")}
                >
                  <span className="fs-6">{time}</span>
                </div>
              </div>
              {showProgress !== "none" && (
                <>
                  <div
                    className={
                      "d-flex flex-stack justify-content-end mb-2" + (showProgress === "above" ? "" : " d-none")
                    }
                  >
                    <span className="text-muted me-2 fs-7 fw-semibold">{progress}%</span>
                  </div>
                  <div className="d-flex align-items-center p-0 m-0">
                    <div className="progress border border-1 border-secondary w-100">
                      <div
                        className="progress-bar text-white fw-bold bg-success"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                      >
                        {showProgress === "inside" ? `${progress}%` : ""}
                      </div>
                    </div>
                    <div
                      className={"text-muted ms-1 fs-7 fw-semibold" + (showProgress === "inline" ? "" : " d-none")}
                    >
                      {progress}%
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className={"col-lg-12 pe-0 d-flex justify-content-end mt-4" + (showCancelButton ? "" : " d-none")}
            >
              <button
                type="button"
                id={"confirm_dialog_cancel_button"}
                className="btn btn-sm btn-secondary fw-bold"
                onClick={handleCancel}
              >
                {intl.formatMessage({ id: "DIALOG.COMMON.BUTTON.CANCEL" })}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

export { ProgressBar };
