import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'



// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import { useEffect, useRef, useState } from 'react';
import { useAlert, useFlashAlert, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { KTSVG } from '../../../../_metronic/helpers';
import EntriesGrid from './BalanceTransfer/EntriesGrid';
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import { NumericFormat } from 'react-number-format';
import { addFloatingNumbers, createArrayOfNumbersFromText, loadingSpinnerBtnRelease, loadingSpinnerBtnWait, roundFloatingNumber } from '../../../modules/sgl-utils/SglFunctions';
import { getRowData, updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const BalanceTransfer = ({ show, handleClose }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID =  useAppSelector(state => state.currentShow.show_id)
    const currencyCode = useAppSelector(state => state.showCompany.currency_code) 
    const {getOutputSelectionAreaIDs} = useOutputContext()
    const outputContextUpdater = useOutputContextUpdater()
    const currencyFormatter = useCurrencyFormatter()

    const [errorMessage, setErrorMessage] = useState('')
    const [runningBalanceMismatchError, setRunningBalanceMismatchError] = useState('')
    const [gridRef, setGridRef] = useState()
    const [entries, setEntries] = useState([]) // list of entries to which balance will be transferred
    const [selectedEntry, setSelectedEntry] = useState(null) // entry from which balance/ credit will be transferred
    const [amountToBeTransferred, setAmountToBeTransferred] = useState(0) // amount that will be transferred to selected entries
    const [entryNoEntered, setEntryNoEntered] = useState(0)
    const entryNoRef = useRef(null)
    const [description, setDescription] = useState('')

    const alertDialog = useAlert()
    const flashAlert = useFlashAlert()
    const loadingOverlay = useLoadingOverlay()

    // get the entries among which credit/ balance will be distributed
    useEffect(() => {
        if (show) {//Get Entry Ids in selection
            loadingOverlay({ show: true })
            getOutputSelectionAreaIDs(false)
            .then(res => {
                if(res.length <= 0){
                    loadingOverlay({ show: false })
                    handleClose(true)
                }else{
                    getEntriesData(res)
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
                loadingOverlay({ show: false })
            }).finally(() => {
                
            })
        }
    },[])

    useEffect(() => {
        // Automatically the cursor should go to input field
        if (entryNoRef?.current) {
            entryNoRef.current.focus();
            entryNoRef.current.select();
        }
    }, [])

    useEffect(() => {
        calculateTotals()
    }, [gridRef])

    useEffect(() => {
        if(gridRef){
            checkSourceEntryInDestinationEntries()
        }
    }, [selectedEntry, entries])

    // get the selected entries data
    const getEntriesData = async (ids) => {
        let response = await getEntries(ids, 'Entry Id')
        if(!response.data.success) { 
            alertDialog({ message: response.data.error })
            return
        }

        for(let entry of response.data.entries){
            entry['apply'] = true
        }

        setEntries(response.data.entries)
        loadingOverlay({ show: false })
    }

    // update selected entry when entry no is changed
    const getSelectedEntry = async() => {
        if(!entryNoEntered || entryNoEntered <= 0 || entryNoEntered == selectedEntry?.number){ return }

        const response = await getEntries([entryNoEntered])
        if(response.data.success && response.data.entries.length > 0){
            setSelectedEntry(response.data.entries[0])
            setErrorMessage('')
            return
        }

        setSelectedEntry(null)
        setErrorMessage('Please enter a valid Entry Number from this show.')
    }

    // add the entered entry number in entries list
    const addEntries = async(e) => {
        if(e.target.value){
            let entryNumbers = createArrayOfNumbersFromText(e.target.value)
            if(!entryNumbers || entryNumbers.length == 0){ return }

            let response = await getEntries(entryNumbers)
            let entriesNotFound = []

            if(response.data.success){
                entriesNotFound = entryNumbers.filter((entryNumber) => !response.data.entries.find((entry) => entry.number == entryNumber))
                let updatedEntries = getRowData(gridRef)
                for(let entry of response.data.entries){
                    if(!updatedEntries.find((data) => data.entry_id == entry.entry_id)){
                        entry['apply'] = true
                        updatedEntries.push(entry)
                    }
                }

                updateAGGridWithoutFlickering(({ current: { api : gridRef}}), updatedEntries)
                checkSourceEntryInDestinationEntries()
            }

            entriesNotFound = entriesNotFound.filter((entry) => entry > 0)
            if(entriesNotFound.length > 0){
                alertDialog({message: `Entry ${entriesNotFound.join(', ')} not found in the show.`})
            }
        }

        e.target.value = ""
        applyBalanceTransferAmount(amountToBeTransferred, true)
    }

    // load entries from BE
    const getEntries = async(ids, type='Entry Number') => {
        loadingOverlay({ show: true })
        const response = await axios.post(NEST_API_URL + "/entries/getEntriesForBalanceTransfer", {
            ids: ids,
            customer_id: customerID,
            show_id: currentShowID,
            type: type
        })
        loadingOverlay({ show: false })

        return response
    }

    //!4D -> ent_AplyBalanceTransferAmount
    const applyBalanceTransferAmount = (amountToUse, recalculateAmount=true) => {
        let entries = getRowData(gridRef)

        //Amount to be distributed among entries
        let amountToBeDistributed = amountToUse

        // total number of entries among which amount will be distributed
        let entriesToDistributeAmount = entries.filter((entry) => entry['apply']).length

        // amount given to single entry
        let amountGivenToSingleEntry = roundFloatingNumber(amountToBeDistributed/entriesToDistributeAmount, 2)

        let entriesDistributedAmount = 0
        let latestEntryUpdated = 0
        let previousEntryRunningBalance = 0

        //Fill payment column for entries
        for(let entry of entries){
            if(recalculateAmount){
                if(!entry['apply']){
                    entry['amount_to_be_transferred'] = 0
                }else{
                    entry['amount_to_be_transferred'] = amountGivenToSingleEntry
                    // update the amount to be distributed
                    amountToBeDistributed = addFloatingNumbers([amountToBeDistributed, -1*entry['amount_to_be_transferred']], 2)
                    latestEntryUpdated = entry.entry_id
                }
            }

            // update total amount distributed among entries
            entriesDistributedAmount  = addFloatingNumbers([entriesDistributedAmount, entry['amount_to_be_transferred']], 2)

            // populate running balance for entries
            if(previousEntryRunningBalance == 0){
                entry['running_balance'] = entry['amount_to_be_transferred']
                previousEntryRunningBalance = entry['amount_to_be_transferred']
            }else{
                entry['running_balance'] = addFloatingNumbers([previousEntryRunningBalance, entry['amount_to_be_transferred']], 2)
                previousEntryRunningBalance = entry['running_balance']
            }
        }
        
        //Set remaining amount to last entry updated
        if(latestEntryUpdated > 0 && amountToBeDistributed != 0){
            let entry = entries.find((entry) => entry.entry_id == latestEntryUpdated)
            if(entry){
                entry['amount_to_be_transferred'] = addFloatingNumbers([entry['amount_to_be_transferred'], amountToBeDistributed], 2)
            }
        }
        
        // update running balance error
        let _runningBalanceMismatchError = ''
        if(entriesDistributedAmount != amountToUse){
            _runningBalanceMismatchError = `Difference: ${currencyFormatter(addFloatingNumbers([entriesDistributedAmount, -1*amountToUse], 2))}`
        }
        setRunningBalanceMismatchError(_runningBalanceMismatchError)

        updateAGGridWithoutFlickering(({ current: { api : gridRef}}), entries)
        calculateTotals()

    }

    // !4D -> [Entry].Dialog_ApplyBalanceTransfer.MoveObject_Btn_Apply
    const transferBalanceToEntries = async(event) => {
        let entries = getRowData(gridRef)
        let entriesDistributedAmount = 0
        let entriesToDistributeAmount = 0
        let selectedEntries = []
        
        for(let entry of entries){
            if(entry['apply']){
                entriesDistributedAmount = addFloatingNumbers([entriesDistributedAmount, entry['amount_to_be_transferred']], 2)
                entriesToDistributeAmount++
                selectedEntries.push({
                    entry_id: entry.entry_id,
                    amount: entry['amount_to_be_transferred']
                })
            }
        }
        
        let error = ''
        if(!selectedEntry){
            error = "Please select a valid entry of this show."
        }else if(amountToBeTransferred == 0){
            error = "Please enter balance transfer amount to distribute among entries."
        }else if(entriesToDistributeAmount <= 0 || entriesDistributedAmount == 0){
            error = "Please select entries with amount greater than zero."
        }else if(amountToBeTransferred != entriesDistributedAmount){
            error = "Balance transfer amount does not match with sum of selected entries distribution amount."
        }else if(!selectedEntry.ResponsibleParty){
            error = 'Source entry has no responsible party.'
        }

        setErrorMessage(error)
        if(error){ return; }

        if(event){
            loadingSpinnerBtnWait(event)
        }

        axios.post(`${process.env.REACT_APP_NEST_API_URL}/accounting/transferBalanceFromEntry`, {
            customer_id: customerID,
            entry_id: selectedEntry.entry_id,
            selected_entries: selectedEntries,
            amount_to_be_transferred: amountToBeTransferred,
            description
        }).then((response) => {
            if(response.data.success){
                // display flash alert
                flashAlert({ type: 'success', message: `Balance transferred successfully.`});
                outputContextUpdater({action: 'refresh'})
                handleClose()
            }else{
                alertDialog({message: response.data.error})
            }
        }).finally(() => {
            if(event){
                loadingSpinnerBtnRelease(event)
            }
            handleClose()
        })
    }

    const calculateTotals = () => {
        const rowData = getRowData(gridRef)
        let totalAmountTransferredToEntries = 0

        rowData.forEach(row => {           
            if (row.apply) {   
                totalAmountTransferredToEntries = addFloatingNumbers([row.amount_to_be_transferred, totalAmountTransferredToEntries], 2)
            }
        })

        gridRef?.setPinnedBottomRowData([{
            amount_to_be_transferred: totalAmountTransferredToEntries
        }])
    }

    const checkSourceEntryInDestinationEntries = () => {
         // check if current entry exists in destination entries. if it does disable it
         let updatedEntries = getRowData(gridRef)
         let sourceEntry = updatedEntries?.find((entry) => entry.entry_id == selectedEntry?.entry_id)
         if(sourceEntry){
             sourceEntry['apply'] = false
         }

         applyBalanceTransferAmount(amountToBeTransferred, true)
         updateAGGridWithoutFlickering(({ current: { api : gridRef}}), updatedEntries)
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered transfer-balance search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => { //replace onKeyPress with onKeyDown
                let activeElement = document.activeElement
                let isButtonFocused = false
                if (activeElement && activeElement.tagName === 'BUTTON') {
                    isButtonFocused = true
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    transferBalanceToEntries()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                    <form noValidate className='form set-membership-dates-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className="d-flex mb-2">
                                <div className='col'>
                                    {/* Entry Number */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.ENTRY" }) }
                                        </label>
                                        <div className='col-lg-7 mw-200px px-0'>
                                            <NumericFormat
                                                id='entry'
                                                allowNegative={false}
                                                decimalScale={0}
                                                value={Number(entryNoEntered)}
                                                type='text'
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                onValueChange={(e) => {
                                                    setEntryNoEntered(e.value)
                                                }}
                                                onBlur={getSelectedEntry}
                                            />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.ENTRY.MESSAGE" }) }
                                            </label>
                                        </div>
                                    </div>

                                    {/* Horse */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.HORSE" }) }
                                        </label>
                                        <div className='col-lg-7 px-0'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { selectedEntry ? selectedEntry.horse : '' }
                                            </label>
                                        </div>
                                    </div>

                                    {/* Owner */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.OWNER" }) }
                                        </label>
                                        <div className='col-lg-7 px-0'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { selectedEntry ? selectedEntry.owner : '' }
                                            </label>
                                        </div>
                                    </div>

                                    {/* RP */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.RESPONSIBLEPARTY" }) }
                                        </label>
                                        <div className='col-lg-7 px-0'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { selectedEntry ? selectedEntry.responsibleparty : '' }
                                            </label>
                                        </div>
                                    </div>

                                    {/* Balance */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.BALANCE" }) }
                                        </label>
                                        <div className='col-lg-7 px-0'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { currencyFormatter (selectedEntry ? selectedEntry.balance : 0) }
                                            </label>
                                        </div>
                                    </div>

                                    {/* Transfer Amount */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.TRANSFER.AMOUNT" }) }
                                        </label>
                                        <div className='col-lg-4 mw-200px px-0'>
                                            <NumericFormat
                                                prefix={intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}`})}
                                                id='amount_to_be_transferred'
                                                allowNegative={true}
                                                decimalScale={3}
                                                value={Number(amountToBeTransferred)}
                                                type='text'
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                onValueChange={(e) => {
                                                    setAmountToBeTransferred((prevState) => e.value)
                                                }}
                                                onBlur={() => {
                                                    applyBalanceTransferAmount(amountToBeTransferred)
                                                }}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <label className='col-form-label fs-6 py-1'>
                                                { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.TRANSFER.AMOUNT.MESSAGE" }) }
                                            </label>
                                        </div>
                                    </div>

                                    {/* Description */}
                                    <div className='row mb-2'>
                                        <label className='col-lg-2 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.TRANSFER.DESCRIPTION" }) }
                                        </label>
                                        <div className='col-lg-6 px-0'>
                                            <input 
                                                id="transfer_amount"
                                                type="text"
                                                className ='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Error */}
                                    {
                                        errorMessage ? 
                                            <div className='row mb-0'>
                                                <label className='col-form-label fs-6 py-1 text-danger'>
                                                    { errorMessage }
                                                </label>
                                            </div>
                                        : null
                                    }
                                </div> 
                            </div>

                            {/* Entries Grid */}
                            <EntriesGrid gridRef={gridRef} setGridRef={setGridRef} entries={entries} applyBalanceTransferAmount={applyBalanceTransferAmount} amountToBeTransferred={amountToBeTransferred} selectedEntry={selectedEntry}/>

                            {/* Running Balance Error */}
                            {
                                runningBalanceMismatchError ?
                                <div className="row m-0">
                                    <label className='col-lg-12 col-form-label fs-6 py-0 text-danger text-end pe-0 mt-1'>
                                        { runningBalanceMismatchError }
                                    </label>
                                </div>
                                : null
                            }

                            <div className='card-footer d-flex justify-content-end py-3 px-0 align-items-center'>
                                <div className='col'>
                                    {/* Add Entries */}
                                    <div className='row'>
                                        <label className='w-lg-100px col-form-label fs-6 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.LABEL.ADD.ENTRIES" }) }
                                        </label>
                                        <div className="col-lg-4 px-0 mw-200px">
                                            <input 
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                onBlur={addEntries}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Cancel Button */}
                                <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={handleClose} tabIndex={9}>
                                    {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                                </button>

                                {/* Apply Button */}
                                <button 
                                    id='transferBalanceButton'
                                    type="button" 
                                    className='btn btn-sm btn-dark fw-bold text-uppercase' 
                                    onClick={transferBalanceToEntries}
                                >
                                    <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({ id: "FORM.INPUT.COMMON.BUTTON.APPLY" })}
                                </button>
                            </div>
                        </div>
                    </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default BalanceTransfer


