import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { reactSelectStyles, time_options_12hours, setTime } from '../../../modules/sgl-utils/fieldControls';
import moment from "moment"
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { NumericFormat } from 'react-number-format';

const JudgesAndScheduleTab = (props) => {
    const containerStyle = useMemo(() => ({ width: '100%', height: '475px' }), [])
    const scheduleContainerStyle = useMemo(() => ({ width: '100%', height: '150px' }), [])

    const intl = useIntl();
    const methods = useFormContext();
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const [rowData, setRowData] = useState();
    const [gridRef, setGridRef] = useState();
    const [webAddDropStatus, setWebAddDropStatus] = useState("");

    // col defs for class judges
    const columnDefs = [
        { field: 'fl_name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.NAME' }), minWidth: 200, maxWidth: 200},
        { field: 'membership_number', flex:1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.MEMBERSHIPNUMBER' })},
        { field: 'officialType', flex:1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.OFFICIALTYPE' }), minWidth: 150, maxWidth: 150}
    ]

    const scheduleColumnDefs = [
        { field: 'group_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.CLASSGROUP' }), width: 300 },
        { field: 'ring', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.RING' }) },
        { field: 'day', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.DATE' }), cellRenderer: DateRenderer, cellStyle: { textAlign: 'center' } },
        { field: 'status', headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.STATUS' }), width: 300 },
    ]

    const scheduleRowData = methods.getValues("class_groups")

    useEffect(() => {
        if(gridRef){
            gridRef.setRowData(methods.getValues('class_judges_modifiable'))
            webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
        }
    }, [gridRef, methods.getValues('class_judges_modifiable')]);
    
    // Function to clear order of go values when clear button is pressed
    const clearOrder = async () => {
        const choice = await confirmDialog({message: intl.formatMessage({id: 'FORM.INPUT.CLASSES.CLEAR.LABEL.CLEARCLASSES'})})
        
        if(choice){
            methods.setValue("classes.order_of_go_set", 0, {shouldDirty:true});
            methods.setValue("classes.order_set_date", "00/00/0000", {shouldDirty:true})
            methods.setValue("classes.order_set_time", "00:00:00", {shouldDirty:true})
    
            // Setting entryxclasses order_of_go to "0" for each values if order of go is cleared
            let entryxclasses = methods.getValues('entryxclasses').map((data) =>{
                data.order_of_go = 0
                return data
            })
            methods.setValue("entryxclasses", entryxclasses)
        }
    }

    // Function when OpenCard checkbox is unchecked
    const openCard = async (checkboxStatus) => {

        if(checkboxStatus){
            methods.setValue("classes.warmup_class", 1, {shouldDirty: true});
            methods.setValue("classes.classic_class", 0, {shouldDirty: true});
            methods.setValue("open_card_class_type","warmup_class", {shouldDirty: true});
        }
        else{
            if(methods.getValues('class_groups').length > 1){
                // if class groups are greater than 1 display confirmation box
                let confirmMsg = `Class  ${methods.getValues("classes.number")} ${intl.formatMessage({id: 'FORM.INPUT.CLASSES.OPENCARD.LABEL.OPENCARD'})}`
                const choice = await confirmDialog({message: confirmMsg, title: 'warning', icon: 'warning'})

                if(choice){
                    // removed all class_groups except the first one
                    let classGroup = methods.getValues('class_groups')
                    methods.setValue('class_groups', classGroup.slice(0,1), {shouldDirty: true})
                    // class_groups_remove will hold the value of class_groups which needs to be removed from database
                    methods.setValue('class_groups_remove', classGroup.slice(1), {shouldDirty: true})
                    document.getElementById('warmup_class').checked = false
                    document.getElementById('classic_class').checked = false
                    methods.setValue("classes.warmup_class", 0, {shouldDirty:true});
                    methods.setValue("classes.classic_class", 0, {shouldDirty:true});
                    methods.setValue("open_card_class_type", "", {shouldDirty: true});
                }
                else{
                    methods.setValue('open_card', true, {shouldDirty:true})
                }
            }
            else{
                document.getElementById('warmup_class').checked = false
                document.getElementById('classic_class').checked = false
                methods.setValue("classes.warmup_class", 0, {shouldDirty:true});
                methods.setValue("classes.classic_class", 0, {shouldDirty:true});
                methods.setValue("open_card_class_type", "", {shouldDirty: true});
            }
        }
    }

    // Function to delete judge
    const deleteJudges = () => {

        var selectedRow = gridRef.getSelectedRows()
        let judges = methods.getValues('class_judges_modifiable')

        if(selectedRow.length > 0){
            // get the index of judges from class_judges_modifiable which needs to be removed
            let index = judges.findIndex(index => (index.people_id == selectedRow[0].people_id) && (index.officialType === selectedRow[0].officialType))

            if(index >= 0){
                judges.splice(index,1)
            }
            let class_judges_deleted = methods.getValues('class_judges_deleted') ?? []
            let judgeAddedInDeletedJudge = class_judges_deleted.find((judge) => judge.sgl_id == selectedRow[0].sgl_id)
            if(!judgeAddedInDeletedJudge){
                class_judges_deleted.push(selectedRow[0])
            }
            methods.setValue('class_judges_deleted', class_judges_deleted, {shouldDirty: true}) 
            methods.setValue('class_judges_modifiable', judges, {shouldDirty: true})
            // document.getElementById('judgesCount').innerHTML = judges.length
            gridRef.setRowData(methods.getValues('class_judges_modifiable'))     
        }
        else{
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.OFFICIAL.NOT.SELECTED.ERROR' })})
        }

    }

    // Function to add new judge
    const addJudges = (class_judge, officialType) => {
        if(!class_judge){ return } //When nothing selected
        let judges = methods.getValues('class_judges_modifiable') ?? []

        // Check if the selected judge is already in the judges array with the same role
        const isRolePresent = judges.some((judge) => {
            return (judge.people_id == class_judge) &&
                   ((judge.iscoursedesigner || judge.isjudge) && (judge?.officialType == officialType));
        });

        if (!isRolePresent) {
            let judge = props.form_meta.showJudges.find((judge) => (judge.people_id == class_judge && judge.officialType === officialType));
            if (judge) {
                judge['sgl_id'] = 0
                judges.push(judge);
            }
        } else {
            alertDialog({ message: intl.formatMessage({ id: 'CLASSES.ADD.NEW.OFFICIALS.DIALOG.OFFICIAL.ALREADY.PRESENT.ERROR' }) });
        }

        methods.setValue('class_judges_modifiable', judges, {shouldDirty: true})
        methods.setValue('show_judges_uuid', '')
        gridRef.setRowData(methods.getValues('class_judges_modifiable'))
    }

    // Will get the edited cell value and passed it to the addJudges function
    const Editing = () => {
        let rowIndex = gridRef.getLastDisplayedRow()
        let class_judge = gridRef.getDisplayedRowAtIndex(rowIndex)
        class_judge = {"first": class_judge.data.f_name, "last": class_judge.data.l_name}
        addJudges(class_judge)
    }

    // add a new row in the grid for judges
    const addJudgeRow = () => {
        gridRef.applyTransaction({add: [{f_name: "f_name", l_name: "l_name"}]})
        Editing()
    }

    // WebAddDrop status function
    const webAddDrop = (checked) => {
        let webAddDropLabel;
        let disabled_adddrops = checked
        methods.setValue('classes.web_adddrops_disabled', checked, {shouldDirty:true})
        if(!disabled_adddrops){ //Web Add/Drops are not disabled
            let stopAddBeforeDays = methods.getValues('classes.no_adds_days_before') || 0;  // In case of new class add, this will be undefined that's why setting it to 0
            let stopAddTime = methods.getValues('classes.no_adds_time_of_day') || "00:00:00"; // In case of new class add, this will be undefined that's why setting it to 00:00:00
            let stopDropBeforeDays = methods.getValues('classes.no_drops_days_before') || 0;
            let stopDropTime = methods.getValues('classes.no_drops_time_of_day') || "00:00:00";
            
            let scheduleDate = methods.getValues('classes.scheduled_date') ? methods.getValues('classes.scheduled_date') : "0000-00-00"

            if(stopAddBeforeDays == 0 && stopAddTime == "00:00:00"){ //Class has no add time/limit set then get show settings
                stopAddBeforeDays = methods.getValues('show_tables.no_adds_days_before')
                stopAddTime = methods.getValues('show_tables.no_adds_time_of_day')?methods.getValues('show_tables.no_adds_time_of_day'):'00:00:00'
            }

            if(stopAddBeforeDays <= 0 && stopAddTime == "00:00:00"){ //if class and show both have not cut off time set then set to 1 day previous
                stopAddBeforeDays = 1
            }

            if(stopDropBeforeDays == 0 && stopDropTime == "00:00:00"){ // Class has no add time/limit set then get show settings
                stopDropBeforeDays = methods.getValues('show_tables.no_drops_days_before')
                stopDropTime = methods.getValues('show_tables.no_drops_time_of_day')?methods.getValues('show_tables.no_drops_time_of_day'):'00:00:00'
            }

            if(stopDropBeforeDays <= 0 && stopDropTime == "00:00:00"){ // if class and show both have not cut off time set then set to 1 day previous
                stopDropBeforeDays = 1
            }

            if(scheduleDate && scheduleDate == "0000-00-00"){
                scheduleDate = methods.getValues('show_tables.start_date')?methods.getValues('show_tables.start_date'):'00/00/0000'
            }

            scheduleDate = moment(scheduleDate, ["YYYY-MM-DD", "MM/DD/YYYY"])

            stopAddTime = stopAddTime ? stopAddTime : "00:00:00";
            stopDropTime = stopDropTime ? stopDropTime : "00:00:00";

            const formattedStopAddTime = moment(stopAddTime, "HH:mm:ss").isValid() 
                ? moment(stopAddTime, "HH:mm:ss").format('h:mm A') 
                : 'Invalid time';

            const formattedStopDropTime = moment(stopDropTime, "HH:mm:ss").isValid() 
                ? moment(stopDropTime, "HH:mm:ss").format('h:mm A') 
                : 'Invalid time';

            if(stopAddBeforeDays == stopDropBeforeDays && stopAddTime == stopDropTime){
                webAddDropLabel = `This class may be added/dropped up until ${scheduleDate.isValid()?scheduleDate.clone().subtract(stopAddBeforeDays, 'days').format("MM/DD/YYYY"):'00/00/0000'} at ${formattedStopAddTime}.`
            }
            else{
                webAddDropLabel = `This class may be added until ${scheduleDate.isValid() ? scheduleDate.clone().subtract(stopAddBeforeDays, 'days').format("MM/DD/YYYY") : '00/00/0000'} at ${formattedStopAddTime} and dropped until ${scheduleDate.isValid() ? scheduleDate.clone().subtract(stopDropBeforeDays, 'days').format("MM/DD/YYYY") : '00/00/0000'} at ${formattedStopDropTime}.`
            }   
        }
        else{
            webAddDropLabel = intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.DISABLEDWEBDROP' })
        }
        setWebAddDropStatus(webAddDropLabel)
    }

    useEffect(() => {
        methods.setValue('show_judges_uuid', '')
    }, [methods.watch("classes.class_id")])

    return (
        <>
            <div className='form-group row mb-2'>
                <div className='col-lg-4'> {/* Column 1 */}
                    <div className='row mb-2'>
                        <div className='col-lg-10'>
                                {
                                    props.form_meta.showJudgesDropDownList && 
                                    <Controller
                                        name="show_judges_uuid"
                                        render={({
                                            field: { onChange, value, name },
                                        }) => (
                                        <Select
                                            options = {props.form_meta.showJudgesDropDownList}
                                            value = {props.form_meta.showJudgesDropDownList.find((ss) => ss.value === value) ?? ''}
                                        
                                            id = "show_judges_uuid"
                                            name={name}
                                            isSearchable={true}
                                            onChange={(showJudgesDropDownList) => {
                                                onChange(showJudgesDropDownList.value);
                                                addJudges(showJudgesDropDownList.value, showJudgesDropDownList?.officialType)
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                            })}
                                            styles={reactSelectStyles}
                                            placeholder="Select an official to add.."
                                        />    
                                        )}
                                    />
                                }
                        </div>
                        {/* <label className='col-lg-1 col-form-label align-self-center fs-5 py-1' id="judgesCount">{methods.getValues('class_judges') && methods.getValues('class_judges').length }</label> */}
                    </div>
                    <div className='row mb-2'>
                        <div className='col-lg-10 d-flex align-items-center mb-2'>
                            <OutputListingGridStatic  area ={"Classes - JudgesAndSchedule 1"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle}></OutputListingGridStatic>
                        </div>

                        <div className='col-lg-2'>
                            {/* <button type='button' className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" onClick={() => addJudgeRow()}>
                                <i className="fas fa-plus fs-5 px-1 py-3"></i>
                            </button> */}
                            <div className="separator border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                            <button type='button' className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'onClick={() => deleteJudges()} data-tooltip-id="CLASS.DETAIL.TAB.JUDGESAMDSCHEDULES.BUTTON.REMOVE">
                                <i className='fas fa-minus fs-5 px-1 py-3'></i>
                            </button>
                        </div>
                    </div>
                </div> {/* End Column 1 */}
                <div className='col-lg-8'>  {/* Column 2 */}
                    <div className='row mb-2'>
                        <p className='fs-6'>{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.SCHEDULINGINFO' })}</p>
                        <fieldset className='mb-5'>
                            <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.GROUPLABEL.CLASSPECIFICATIONS' })} <b></b></legend>
                            <div className='row mb-2'>
                                <div className='col-lg-12 d-flex align-items-center'>
                                    <div className='form-check-sm form-check-custom  me-2'>
                                        <input
                                            {...methods.register( 'open_card', 
                                                {
                                                required: false
                                                })
                                            }
                                            className='form-check-input'
                                            type='checkbox'
                                            id='open_card'
                                            onClick={(e) => openCard(e.target.checked)}
                                        />
                                    </div>
                                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='open_card' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.OPENCARD"> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.OPENCARD' })}</label>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-12 d-flex align-items-center ps-10'>
                                    <input className="form-check-sm me-1" 
                                        {...methods.register( 'open_card_class_type', 
                                            {
                                            required: false
                                            })
                                        }
                                    disabled={!methods.watch("open_card")}
                                    type="radio" value="warmup_class" id="warmup_class"  
                                    onClick={() => {methods.setValue("classes.warmup_class","1",{shouldDirty:true});methods.setValue("classes.classic_class","0",{shouldDirty:true});methods.setValue("open_card_class_type","warmup_class")}}/>
                                    <label className='col-form-label fs-5 py-1 ignore-max-width me-5' htmlFor='warmup_class' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.WARMUP"> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.WARMUP' })}</label>

                                    <input className="form-check-sm me-1"
                                        {...methods.register( 'open_card_class_type', 
                                            {
                                            required: false
                                            })
                                        }
                                        disabled={!methods.watch("open_card")}
                                    type="radio" value="classic_class" id="classic_class" 
                                    onClick={() => {methods.setValue("classes.classic_class","1",{shouldDirty:true});methods.setValue("classes.warmup_class","1",{shouldDirty:true});methods.setValue("open_card_class_type","classic_class")}}/>
                                    <label className='col-form-label fs-5 py-1 ignore-max-width' htmlFor='classic_class' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.CLASSIC"> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.CLASSIC' })}</label>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3 d-flex align-items-center w-225px'>
                                    <div className='form-check-sm form-check-custom  me-2'>
                                        <input
                                            {...methods.register( 'classes.web_adddrops_disabled', 
                                                {
                                                required: false
                                                })
                                            }
                                            className='form-check-input'
                                            type='checkbox'
                                            id='web_adddrops_disabled'
                                            onChange={(e) => {webAddDrop(e.target.checked);}}
                                        />
                                    </div>
                                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='web_adddrops_disabled'  data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.DISABLEWEBADDDROPS"> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.DISABLEWEBADDDROP' })}</label>
                                </div>
                                {methods.getValues("classes.combined_class")?"":
                                <div className='col-lg-3 d-flex align-items-center w-225px'>
                                    <div className='form-check-sm form-check-custom  me-2'>
                                        <input
                                            {...methods.register( 'classes.suppress_web_results', 
                                                {
                                                required: false
                                                })
                                            }
                                            className='form-check-input'
                                            type='checkbox'
                                            id='suppress_web_results'
                                        />
                                    </div>
                                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='suppress_web_results'  data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.SUPPRESSWEBRESULTS"> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.SUPPRESSWEBRESULTS' })}</label>
                                </div>
                                }
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1' style={{ width: '130px' }} htmlFor='no_adds_days_before' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.STOPWEBADDS">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.STOPWEBADDS' })}</label>
                                <div className='col-lg-1 w-100px'>
                                    {/* <input
                                        {...methods.register( 'classes.no_adds_days_before', 
                                            {
                                            required: false
                                            })
                                        }
                                        id='no_adds_days_before'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    /> */}
                                    <Controller
                                        control={methods.control}
                                        name="classes.no_adds_days_before"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat
                                                id='no_adds_days_before'
                                                name={name}
                                                value={Number(value)}
                                                decimalScale={0}
                                                allowNegative={false}
                                                onValueChange={(e) => {
                                                    onChange(e.value)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                            />
                                        )}
                                    />
                                </div>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-125px' htmlFor='no_adds_time_of_day' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.STOPWEBADDSDAYSBEFOREAT">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.STOPWEBADDSDBA' })}</label>
                                <div className='col-lg-2 w-130px'>
                                    <div className="input-group date">
                                        <Controller
                                            name="classes.no_adds_time_of_day"
                                            render={({
                                                field: { onChange, value, name },
                                            }) => (

                                            <Flatpickr 
                                                className="form-control form-control-sm  fs-6 min-h-20px py-1"
                                                id = "no_adds_time_of_day"
                                                name = {name}
                                                value = {value}
                                                placeholder="00 : 00"
                                                options={time_options_12hours}
                                                autoComplete={"off"}
                                                onClose={(value) => {
                                                    setTime(value, onChange)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                                onChange={(value) => {
                                                    setTime(value, onChange)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                            />
                                            )}
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor='no_adds_time_of_day' className='date-label'
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    document.getElementById('no_adds_time_of_day')._flatpickr.open(); 
                                                }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-clock-o"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1' style={{ width: '130px' }} htmlFor='no_drops_days_before' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.STOPWEBDROPS">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.STOPWEBDROPS' })}</label>
                                <div className='col-lg-1 w-100px'>
                                    {/* <input
                                        {...methods.register( 'classes.no_drops_days_before', 
                                            {
                                            required: false
                                            })
                                        }
                                        id='no_drops_days_before'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    /> */}
                                    <Controller
                                        control={methods.control}
                                        name="classes.no_drops_days_before"
                                        render={({ field: { onChange, name, value } }) => (
                                            <NumericFormat
                                                id='no_drops_days_before'
                                                name={name}
                                                value={Number(value)}
                                                decimalScale={0}
                                                allowNegative={false}
                                                onValueChange={(e) => {
                                                    onChange(e.value)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                            />
                                        )}
                                    />
                                </div>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-125px' htmlFor='no_drops_time_of_day' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.STOPWEBDROPSDAYSBEFOREAT">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.STOPWEBDROPSDBA' })}</label>
                                <div className='col-lg-2 w-130px'>
                                    <div className="input-group date">
                                
                                        <Controller
                                            name="classes.no_drops_time_of_day"
                                            render={({
                                                field: { onChange, value, name },
                                            }) => (

                                            <Flatpickr 
                                                className="form-control form-control-sm  fs-6 min-h-20px py-1"
                                                id = "no_drops_time_of_day"
                                                name = {name}
                                                value = {value}
                                                placeholder="00 : 00"
                                                autoComplete={"off"}
                                                options={time_options_12hours}
                                                onClose={(value) => {
                                                    setTime(value, onChange)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                                onChange={(value) => {
                                                    setTime(value, onChange)
                                                    webAddDrop(methods.getValues('classes.web_adddrops_disabled'))
                                                }}
                                            />
                                            )}
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor='no_drops_time_of_day' className='date-label'
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    document.getElementById('no_drops_time_of_day')._flatpickr.open(); 
                                                }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-clock-o"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-2 col-form-label fs-5 py-1' style={{ width: '130px' }} htmlFor='order_of_go_set' data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.ORDEROFGO">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.ORDEROFGO' })}</label>
                                <div className='col-lg-4 d-flex align-items-center'>
                                    <div className='form-check-sm form-check-custom  me-2'>
                                        <input
                                            {...methods.register( 'classes.order_of_go_set', 
                                                {
                                                required: false
                                                })
                                            }
                                            className='form-check-input'
                                            type='checkbox'
                                            disabled = {true}
                                            id='order_of_go_set'
                                        />
                                    </div>
                                    <label className='col-lg-1 col-form-label fs-5 py-1' style={{ width: '130px' }} data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.LABEL.SET">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.LABEL.SET' })}</label>
                                    {moment(methods.getValues('classes.order_set_date')).isValid()?
                                    <label className='col-lg-2 col-form-label fs-5 py-1 w-100px'>{moment(methods.getValues('classes.order_set_date')).format("MM/DD/YY")}</label>
                                    :""}
                                    {moment(methods.getValues('classes.order_set_time'),"kk:mm:ss").isValid()?<label className='col-lg-1 col-form-label fs-5 py-1 w-125px'>{moment(methods.getValues("classes.order_set_time"),"HH:mm:ss").format('h:mm A')}</label>:""}
                                    {/* <label className='col-lg-1 col-form-label fs-5 py-1 w-125px'>{methods.getValues("order_set_time")}</label> */}  
                                    <div className='col-lg-3'>
                                    <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase" onClick={()=>clearOrder()} data-tooltip-id="CLASS.DETAIL.TAB.JUDGESANDSCHEDULES.SECTION.CLASSSPECIFICATIONS.BUTTON.CLEAR">
                                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.JUDGESANDSCHEDULE.CLEAR' })}
                                    </button>
                                </div>
                                </div>
                            </div>
                            {/* <div className='row mb-2'>
                                
                            </div> */}
                            <p className='text-danger fs-6 mt-4'>{webAddDropStatus}</p>
                            <div className='row mb-2'>
                                <div className='col-lg-12 d-flex align-items-center mb-2'>
                                    <OutputListingGridStatic  area ={"Classes - JudgesAndSchedule 2"} columnDefs={scheduleColumnDefs} rowData={scheduleRowData} containerStyle={scheduleContainerStyle}></OutputListingGridStatic>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className='col-lg-4'>{/* Leave Empty */}</div>
                </div>
            </div> {/* End Column 2 */}
        </>
    );
}

export { JudgesAndScheduleTab }