import moment from 'moment';
import {useIntl} from 'react-intl'
import { OrderOfGoContextProvider, useOrderOfGoContext } from './OrderOfGoContext';
import ManualGrid from './SetOrder/ManualGrid';
import OrderedTripsGrid from './SetOrder/OrderedTripsGrid';
import RiderGrid from './SetOrder/RiderGrid';
import TrainerGrid from './SetOrder/TrainerGrid';
import { useAlert, useConfirm, useFlashAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait, logSiteActivity } from '../../../modules/sgl-utils/SglFunctions';
import axios from 'axios';
import { time_options_12hours } from '../../../modules/sgl-utils/fieldControls';
import "./style.css"
import HelpLink from '../../../modules/components/HelpLink';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const OrderOfGoWrapper = () => {
    return (
        <OrderOfGoContextProvider>
            <OrderOfGo />
        </OrderOfGoContextProvider>
    )
}

const OrderOfGo = () => {
    const intl = useIntl();
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const tripsInBetweenOptions = Array.from({ length: 21 }, (_, index) => index);
    const manualEntryNumberRef = useRef()
    const [reverseOrder, setReverseOrder] = useState(false)
    const flashAlert = useFlashAlert()

    const { showDates, showRings, selectedDate, setSelectedDate, selectedRing, setSelectedRing, classGroups, selectedClassGroup, setSelectedClassGroup, orderBy, setOrderBy, allTrips, orderType, manualOrderedTrips, setOrderType, fetchTripsAndRelatedData, OOGStatus, editOOG, setEditOOG, setPublishedOnWeb, publishedOnWeb, isOrderOfGoSet, tripsInBetween, setTripsInBetween, orderedTripGridApi, setOOGStatus, customerID, currentShowID, orderedTripColumnApi, setAllTrips, manualRowData, setManualRowData, setManualOrderedTrips,  riderRowData, selectedHorses, minsPerTrip, classSetupTime, plannedTime, setMinsPerTrip, setClassSetupTime, setPlannedTime, collapseMultipleTrips, setCollapseMultipleTrips } = useOrderOfGoContext()
    const rowData = orderBy !== "Manual" ? allTrips : manualOrderedTrips

    const events =  classGroups.map(cg => 
        <option key={cg.class_group_id} value={cg.class_group_id}>
            { cg.class_list !== "" ? `[${cg.class_list}] ${cg.group_name}` : cg.group_name }
        </option>
    )

    const setManualOrder = () => {
        let message = ''
        const manualEntryNumber = manualEntryNumberRef.current.value
        if (isOrderOfGoSet && !editOOG) // Don't allow a re-ordering when order is already set, Allow editing OOG when order unlocked
            message = 'Order of Go for selected Class Group is already set. Please clear the order first.'
        else if (orderType !== "SetOrder")
            message = 'Order of Go for selected Class Group cannot be set. Please select correct Order Type.'
        else if (manualEntryNumber === "" || Number(manualEntryNumber) <= 0 || isNaN(manualEntryNumber))
            message = 'Please enter a valid Entry No. to order.'

        if (message) {
            alertDialog({ message })
            manualEntryNumberRef.current.value = '' // reset value
            manualEntryNumberRef.current.focus() // bring focus back to input
            return
        }
 
        // Find Trip with Entry Number entered by User
        const foundTrip = manualRowData.find(trip => trip.Entry.number == manualEntryNumber)
        if (foundTrip) {
            let tripsToOrder = [foundTrip]
            if (collapseMultipleTrips) {
                // Get all trips with Entry number same as selected trip
                tripsToOrder = manualRowData.filter(trip => trip.Entry.number === foundTrip.Entry.number)
            } 
    
            let newOrder = rowData.length + 1
            for (const trip of tripsToOrder) {
                trip.order_of_go = newOrder++
            }

            // Remove row(s) from Manual (unordered)
            setManualRowData(prevManualRowData => {
                let newManualRowData = [...prevManualRowData]

                if (collapseMultipleTrips) {
                    newManualRowData = newManualRowData.filter(trip => trip.Entry.number != manualEntryNumber)
                } else {
                    newManualRowData = newManualRowData.filter(trip => trip.entryxclasses_id != foundTrip.entryxclasses_id)
                }

                return newManualRowData
            })

            // Add row(s) to ordered
            setManualOrderedTrips(prevManualOrderedTrips => {
                return [...prevManualOrderedTrips, ...tripsToOrder]
            })

            // Display Flash Message
            const orderForFlashMessage = collapseMultipleTrips ? tripsToOrder.map(trip => trip.order_of_go).join(',') : foundTrip.order_of_go
            flashAlert({ type: 'success', message: `Entry #${manualEntryNumber} ordered at ${orderForFlashMessage}.`});

            setOOGStatus("Order of Go is set temporarily and will not be set until saved")
        } else {
            alertDialog({ message: "Entry No. " + manualEntryNumber + " is not in the un-ordered trip list." })
        }

        manualEntryNumberRef.current.value = '' // reset value
        manualEntryNumberRef.current.focus() // bring focus back to input
    }

    const executeSetOrder = (event) => {

        if (isOrderOfGoSet && !editOOG) { // Don't allow a re-ordering when order is already set, Allow editing OOG when order unlocked
            alertDialog({ message: "Order of Go for selected Class Group is already set. Please clear the order first.", icon: "stop" })
        } else if (orderType !== "SetOrder") {
            alertDialog({ message: "Order of Go for selected Class Group cannot be set. Please select correct Order Type.", icon: "stop" })
        } else if (selectedClassGroup) { // There is at least one class group (event) in Show Ring
            // Show Ag Grids Loading Overlay
            orderedTripGridApi?.showLoadingOverlay()

            setOOGStatus("Order of Go is set temporarily and will not be set until saved")

            let riderPointHorseData = {}
            for (let counter = 0; counter <riderRowData.length; counter++) {
                riderPointHorseData[riderRowData[counter].rider_id] = selectedHorses[counter]
            }

            loadingSpinnerBtnWait(event)

            // Log in site_monitoring_activity_log table
            logSiteActivity(currentShowID, customerID, {
                source: "Tools",
                tab_name: 'Order of Go',
                activity: 'Set Order',
                description: `Order is set for Class Group (ID: ${selectedClassGroup}) by ${orderBy} with ${tripsInBetween} trips in between.`
            })

            axios.post(`${NEST_API_URL}/order-of-go/setOrder`, {
                customer_id: customerID,
                show_id: currentShowID,
                class_group_id: selectedClassGroup,
                order_by: orderBy,
                set_reverse_order: reverseOrder,
                trips_in_between: tripsInBetween,
                rider_point_horse_data: riderPointHorseData
            })
            .then(response => {
                setAllTrips(response.data.trips)

                // clear column state (sort order)
                orderedTripColumnApi.resetColumnState()

                // Hide Ag Grids Loading Overlay
                orderedTripGridApi?.hideOverlay()
            })
            .catch((reason) => {
                // Hide Ag Grids Loading Overlay
                orderedTripGridApi?.hideOverlay()

                alertDialog({message: reason?.response?.data?.error ?? 'Something went wrong..'});
            })
            .finally(() => loadingSpinnerBtnRelease(event))
        }
    }

    const renderStepThree = () => {
        
        if (orderBy === "Manual") {
            return (
                <div className='row'>
                    <label className='w-lg-250px col-form-label fs-5 py-1' htmlFor="manualEntryNumber" data-tooltips-id="ORDEROFGO.DETAIL.SECTION.SETORDER.LABEL.PROVIDEENTRYNOTOSETORDER">
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.INPUT.LABEL.ENTRYNUMBER" }) }
                        </span>
                    </label>
                    <div className='w-lg-175px'>
                        <input
                            type="number"
                            ref={manualEntryNumberRef}
                            className='col form-control form-control-sm fs-6 min-h-20px py-1'
                            id="manualEntryNumber" 
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    setManualOrder()
                                }
                            }}
                        />
                    </div>
                    <div className='col-lg-2 w-150px me-3'>
                        <button
                            type="button" 
                            className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px align-items-center d-flex justify-content-center"
                            onClick={setManualOrder}
                            data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SETORDERMANUAL.BUTTON.SETORDER"
                        >
                            { intl.formatMessage({id: 'ORDEROFGO.BUTTON.ORDERTRIP'}) }
                        </button>
                    </div>
                    <div className='form-check-sm form-check-custom col-lg-2 ms-2'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='collapseMultipleTrips'
                            checked={collapseMultipleTrips}
                            onChange={e => setCollapseMultipleTrips(e.target.checked)}
                        />
                        <label 
                            className='col-form-label mx-2 fs-5 py-1' 
                            htmlFor='collapseMultipleTrips'                            
                            data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SETORDER.LABEL.COLLAPSEMULTIPLETRIPS"
                        >
                            { intl.formatMessage({ id: "ORDEROFGO.INPUT.LABEL.COLLAPSEMULTIPLETRIPS" }) }
                        </label>
                    </div>
                </div>
            )
        } else if (orderBy === "Rider" || orderBy === "Trainer") {
            return (
                <div className='row'>
                    <label className='col-form-label fs-5 py-1 w-150px' htmlFor="tripsInBetween" data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SETORDER.LABEL.TRIPSINBETWEEN">
                        <span>
                            Trips in Between
                        </span>
                    </label>
                    <div className='col-lg-1 w-lg-100px me-7'>
                        <select
                            id="tripsInBetween"
                            className='form-select  form-select-sm fs-6 min-h-20px py-1'
                            onChange={(e) => setTripsInBetween(e.target.value)}
                            value={tripsInBetween}
                            >
                            {
                                tripsInBetweenOptions.map(current =>
                                    <option key={current} value={current}>{ current }</option>
                                )
                            }
                        </select>
                    </div>
                    <div className='form-check-sm form-check-custom w-lg-150px'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='reverseOrder'
                            checked={reverseOrder}
                            onChange={e => setReverseOrder(e.target.checked)}
                            />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='reverseOrder' data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SETORDER.LABEL.REVERSEORDER">
                            Reverse Order
                        </label>
                    </div>
                    <div className='col-lg-2 w-150px me-3'>
                        <button
                            type="button" 
                            className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px align-items-center d-flex justify-content-center"
                            onClick={executeSetOrder}
                            data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SETORDER.BUTTON.SETORDER"
                        >
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({id: 'ORDEROFGO.BUTTON.ORDERTRIP'}) }s
                        </button>
                    </div>
                </div>
            )
        }
    }

    const saveOrder = async (event) => {

        // check there are class groups and ordered data (rowData) 
        if (classGroups.length >0 && rowData.length >0) {
            let goAhead = false
            // Check Group is ordered
            let isGroupOrdered = true
            for (let trip of rowData) {
                if (trip.order_of_go <= 0) {
                    isGroupOrdered = false
                    break
                }
            }

            // ! Code commented as we are not using saddle
            // Case of 
            // : (vb_canSetOrder=False)  //UBN 02/18/2014 - Added code to check Order status before save
            // Alert2 (vt_oogStatus;<>StopIconPicture)

            if (isOrderOfGoSet && !editOOG) { // Don't allow a re-ordering when order is already set, Allow editing OOG when order unlocked
                alertDialog({ message: "Order of Go for selected Class Group is already set. Please clear the order first.", icon: "stop" })
            } else if (orderType === "None") {
                alertDialog({ message: "Order of Go for selected Class Group cannot be set. Please specify Check-In or Set Order.", icon: "stop" })
            } else if (orderType === "CheckIn") { // check Order status before save
                goAhead = true
                if (isGroupOrdered) {
                    goAhead = await confirmDialog({ message: "Selecting Order type as Check-In will clear all orders. Are you sure that you want to set order type as Check-In?" })
                }
            } else {
                goAhead = isGroupOrdered
                // Incase of manual ordering check if all trips have been ordered. If there are trips remaining i.e., manualRowData.length > 0, don't allow save.
                if (!isGroupOrdered || (orderBy === 'Manual' && manualRowData.length > 0)) {
                    goAhead = false
                    alertDialog({ message: "Order of Go for selected Class Group is not set. Please set the order first.", icon: "stop" })
                }
            }

            if (goAhead) {
                let tripOOGData = {}
                for (let trip of rowData) {
                    tripOOGData[trip.entryxclasses_id] = { order_of_go: trip.order_of_go }
                }

                let riderPointHorseData = []
                for (let counter = 0; counter <riderRowData.length; counter++) {
                    riderPointHorseData.push({
                        rider_id: riderRowData[counter].rider_id,
                        point_horse_id: selectedHorses[counter]
                    })
                }

                // Log in site_monitoring_activity_log table
                logSiteActivity(currentShowID, customerID, {
                    source: "Tools",
                    tab_name: 'Order of Go',
                    activity: 'Save Order',
                    description: `Order is saved ${publishedOnWeb ? 'and published on Web ' : ''}for Class Group (ID: ${selectedClassGroup}).`
                })

                loadingSpinnerBtnWait(event)
                axios.post(`${NEST_API_URL}/order-of-go/saveOrder`, {
                    customer_id: customerID,
                    show_id: currentShowID,
                    class_group_id: selectedClassGroup,
                    order_by: orderBy,
                    order_type: orderType,
                    published_on_web: publishedOnWeb,
                    trips_in_between: tripsInBetween,
                    trips_oog_data: tripOOGData,
                    rider_point_horse_data: riderPointHorseData
                })
                .then(() => {
                    fetchTripsAndRelatedData(false)

                    // clear column state (sort order)
                    orderedTripColumnApi.resetColumnState()
                    flashAlert({ type: 'success', message: `Order of go saved.`});
                })
                .catch((error) => {})
                .finally(() => loadingSpinnerBtnRelease(event))
            }
        }
    }
    
    const clearOrder = async (event) => {
        // check there are class groups and ordered data (rowData) 
        if (classGroups.length >0 && rowData.length >0) {
            let choice = await confirmDialog({ message: "Are you sure that you want to clear Order of Go for selected Class Group?"})
            
            if (choice) {
                loadingSpinnerBtnWait(event)

                // Log in site_monitoring_activity_log table
                logSiteActivity(currentShowID, customerID, {
                    source: "Tools",
                    tab_name: 'Order of Go',
                    activity: 'Save Order',
                    description: `Order is cleared for Class Group (ID: ${selectedClassGroup}).`
                })

                axios.post(`${NEST_API_URL}/order-of-go/clearOrder`, {
                    customer_id: customerID,
                    show_id: currentShowID,
                    class_group_id: selectedClassGroup,
                })
                .then(() => {
                    fetchTripsAndRelatedData(false)

                    // clear column state (sort order)
                    orderedTripColumnApi.resetColumnState()
                    flashAlert({ type: 'success', message: `Order of go cleared.`});
                })
                .finally(() => loadingSpinnerBtnRelease(event))
            }
        }
    }

    const setSchedule = async (event) => {  
        loadingSpinnerBtnWait(event)
        const response = await axios.post(`${NEST_API_URL}/order-of-go/set-schedule`,{
            customer_id: customerID,
            show_id: currentShowID,
            day: selectedDate,
            ring_id: selectedRing,
            class_group_id: selectedClassGroup,
            planned_time: plannedTime,
            mins_per_trip: minsPerTrip,
            class_setup_time: classSetupTime
        })

        if (response.data.error) {
            alertDialog({ message: response.data.error })
        }

        loadingSpinnerBtnRelease(event)
    }

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
        sessionStorage.setItem("selectedDate", newDate);
    };

    const handleRingChange = (e) => {
        const newRing = e.target.value;
        setSelectedRing(newRing);
        sessionStorage.setItem("selectedRing", newRing);
    };

    const handleClassGroupChange = (e) => {
        const newClassGroup = e.target.value;
        setSelectedClassGroup(newClassGroup);
        sessionStorage.setItem("selectedClassGroup", newClassGroup);
    };

    return(
        <div className='modal-body py-0 px-4 scheduler bg-white'>
            <form noValidate className='form' onSubmit={e => e.preventDefault()}>
            <HelpLink tooltip="ORDEROFGO.DETAIL.LINK.HELP" classname="top" positionType="absolute" positionTop='1.9rem' />
                <div className='card-body p-0'> {/* Card Body */}
                    <div className="form-group row"> {/* Column Group */}
                        <div className='col'>
                            <div className='col d-flex'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-125px fw-bold' data-tooltip-id="ORDEROFGO.DETAIL.LABEL.ORDERTYPE">
                                        {intl.formatMessage({id: 'ORDEROFGO.LABEL.ORDERTYPE'})}
                                </label>
                                <div className='col-lg-2 form-check-sm form-check-custom me-2 w-100px'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderTypeNone"
                                        checked={orderType === "None"} 
                                        onChange={() => setOrderType("None")}
                                        name="setOrderType"
                                    />
                                    <label className='form-check-label fs-6 py-1' htmlFor="orderTypeNone" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.NONE">
                                        {intl.formatMessage({id: 'ORDEROFGO.LABEL.ORDERTYPE.NONE'})}
                                    </label>
                                </div>
                                <div className='col-lg-2 form-check-sm form-check-custom me-2 w-100px'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderTypeSetOrder" 
                                        checked={orderType === "SetOrder"} 
                                        onChange={() => setOrderType("SetOrder")}
                                        name="setOrderType"
                                    />
                                    <label className='form-check-label fs-6 py-1' htmlFor="orderTypeSetOrder" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.SETORDER">
                                    {intl.formatMessage({id: 'ORDEROFGO.LABEL.ORDERTYPE.SETORDER'})}
                                </label>
                                </div>
                                <div className='form-check-sm form-check-custom  me-2'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderTypeCheckIn"  
                                        checked={orderType === "CheckIn"} 
                                        onChange={() => setOrderType("CheckIn")}
                                        name="setOrderType"
                                    />
                                </div>
                                <label className='col-lg-1 form-check-label fs-6 py-1' htmlFor="orderTypeCheckIn" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.CHECKIN">
                                    {intl.formatMessage({id: 'ORDEROFGO.LABEL.ORDERTYPE.CHECKIN'})}
                                </label>
                            </div>
                            <div className='col d-flex'>
                                <label className='col-lg-2 col-form-label fs-5 py-1 w-125px fw-bold' data-tooltip-id="ORDEROFGO.DETAIL.LABEL.SETORDERBY">
                                    { intl.formatMessage({id: 'ORDEROFGO.LABEL.SETORDERBY'}) }
                                </label>
                                <div className='col-lg-2 form-check-sm form-check-custom me-2 w-100px'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderByTrainer"
                                        name="setOrderBy"
                                        checked={orderBy === "Trainer"} 
                                        onChange={() => setOrderBy("Trainer")}
                                    />
                                    <label className='form-check-label fs-6 py-1' htmlFor="orderByTrainer" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.TRAINER">
                                        { intl.formatMessage({id: "ORDEROFGO.LABEL.SETORDERBY.TRAINER"}) }
                                    </label>
                                </div>
                                <div className='col-lg-2 form-check-sm form-check-custom me-2 w-100px'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderByRider" 
                                        name="setOrderBy"
                                        checked={orderBy === "Rider"} 
                                        onChange={() => setOrderBy("Rider")}
                                    />
                                    <label className='form-check-label fs-6 py-1' htmlFor="orderByRider" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.RIDER">
                                        { intl.formatMessage({id: "ORDEROFGO.LABEL.SETORDERBY.RIDER"}) }
                                    </label>
                                </div>
                                
                                <div className='form-check-sm form-check-custom  me-2'>
                                    <input
                                        className='form-check-input'
                                        type="radio"
                                        id="orderByManually"
                                        name="setOrderBy" 
                                        checked={orderBy === "Manual"} 
                                        onChange={() => setOrderBy("Manual")} 
                                    />
                                </div>
                                <label className='col-lg-1 form-check-label fs-6 py-1' htmlFor="orderByManually" data-tooltip-id="ORDEROFGO.DETAIL.LABEL.MANUALLY">
                                    {intl.formatMessage({id: "ORDEROFGO.LABEL.SETORDERBY.MANUALLY"})}
                                </label>
                            </div>
                            <div className="col">
                                <fieldset>
                                    <legend className='mt-2 fs-5 py-1 fw-bold'>
                                        { intl.formatMessage({id: "ORDEROFGO.LABEL.STEP.ONE"}) }
                                    </legend>
                                </fieldset>
                            </div>
                            <div className='row mb-2 mt-2'>
                                <label className='col-form-label fs-5 py-1 w-100px' htmlFor="showDate" data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTCLASSGROUP.LABEL.SHOWDATE">
                                    <span>{intl.formatMessage({id: "ORDEROFGO.LABEL.SHOWDATE"})}</span>
                                </label>
                                <div className='col-lg-3 '>
                                    <select
                                        id="showDate"
                                        className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                        onChange={(e) => handleDateChange(e)}
                                        value={selectedDate}
                                        >
                                        {
                                            showDates.map(date =>
                                            <option key={date} value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>)
                                        }
                                    </select>
                                </div>

                                <label className='col-form-label fs-5 py-1 w-75px' htmlFor='ring' data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTCLASSGROUP.LABEL.RING">
                                    <span>{intl.formatMessage({id: 'ORDEROFGO.LABEL.RING'})}</span>
                                </label>
                                <div className='col-lg-3 '>
                                    <select
                                        id="ring"
                                        className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                        onChange={(e) => handleRingChange(e)}
                                        value={selectedRing}
                                        >
                                        {
                                            showRings.map(ring =>
                                                <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                                        }
                                    </select>
                                </div>

                                <label className='col-form-label fs-5 py-1 w-75px' htmlFor="event" data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTCLASSGROUP.LABEL.EVENT">
                                    <span>{intl.formatMessage({id: "ORDEROFGO.LABEL.EVENT"})}</span>
                                </label>
                                <div className='col-lg-3 '>
                                    <select
                                        id="event"
                                        className='form-select  form-select-sm fs-6 min-h-20px py-1'
                                        onChange={(e) => handleClassGroupChange(e)}
                                        value={selectedClassGroup}
                                        >
                                        { events }
                                    </select>
                                </div>
                                <div className='col w-lg-75px mt-1'>
                                    <i onClick={() => fetchTripsAndRelatedData(false)} className='far fa-solid fa-rotate text-success fs-1 fw-bold cursor-pointer'></i>
                                </div>
                            </div>
                            <div className="col">
                                <fieldset>
                                    <legend className='mt-2 fs-5 py-1 fw-bold'>
                                        { intl.formatMessage({id: "ORDEROFGO.LEGEND.SETSCHEDULE"}) }
                                    </legend>
                                </fieldset>
                            </div>
                            <div className='row mb-2'>
                                <label 
                                    className='col-form-label fs-5 py-1 w-150px' 
                                    htmlFor="minsPerTrip"
                                    data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTSCHEDULE.LABEL.MINUTESPERTRIP"
                                >
                                    { intl.formatMessage({ id: 'SCHEDULER.LABEL.MINPERTRIP' })}
                                </label>
                                <div className='w-lg-125px'>
                                    <input
                                        type="number"
                                        value={minsPerTrip}
                                        onChange={e => setMinsPerTrip(e.target.value)}
                                        className='col form-control form-control-sm fs-6 min-h-20px py-1'
                                        id="minsPerTrip" 
                                    />
                                </div>
                                <label className='col-form-label fs-5 py-1 w-150px' htmlFor="classSetupTime" data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTSCHEDULE.LABEL.CLASSSETUPTIME">
                                    { intl.formatMessage({ id: 'SCHEDULER.LABEL.CLASSSETUPTIME' })}
                                </label>
                                <div className='w-lg-125px'>
                                    <input
                                        type="number"
                                        value={classSetupTime}
                                        onChange={e => setClassSetupTime(e.target.value)}
                                        className='col form-control form-control-sm fs-6 min-h-20px py-1'
                                        id="classSetupTime" 
                                    />
                                </div>
                                <label className='col-form-label fs-5 py-1 w-150px' htmlFor="plannedStartTime" data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTSCHEDULE.LABEL.PLANNEDSTARTTIME">
                                    { intl.formatMessage({ id: 'SCHEDULER.LABEL.PLANNEDSTART' })}
                                </label>
                                <div className='w-lg-150px'>
                                    <div className="input-group date">
                                        <Flatpickr 
                                            className="form-control form-control-sm fs-6 min-h-20px py-1"
                                            id="plannedStartTime"
                                            placeholder="00 : 00"
                                            value={plannedTime}
                                            options={{ ...time_options_12hours, minuteIncrement: 5 }}
                                            onChange={([date]) => setPlannedTime(moment(date).format('HH:mm:ss')) }
                                            onClose={([date]) => setPlannedTime(moment(date).format('HH:mm:ss')) }
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor='plannedStartTime' className='date-label'
                                                    onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        document.getElementById('plannedStartTime')._flatpickr.open(); 
                                                    }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-clock"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <label className='w-100px col-form-label fs-5 py-1'>
                                    { <span className=''>{ plannedTime && plannedTime !='00:00:00' ? moment(plannedTime, "HH:mm:ss").format('h:mm A') : ''}</span> }
                                </label>
                                <div className='col'>
                                    <button
                                        className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-175px" 
                                        onClick={setSchedule}
                                        type="button"
                                        data-tooltip-id="ORDEROFGO.DETAIL.SECTION.SELECTSCHEDULE.BUTTON.UPDATESCHEDULE"
                                    >
                                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                        Update Schedule
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <fieldset>
                                    <legend className='mt-2 fs-5 py-1 fw-bold'>
                                        { intl.formatMessage({id: "ORDEROFGO.LEGEND.SETORDER"}) }
                                    </legend>
                                </fieldset>
                            </div>
                            <div className='row mb-2'>
                                { renderStepThree() }
                            </div>
                            <div className='row mb-2'>
                                <div className='col col-lg-5'>
                                    { 
                                        orderBy === "Trainer" ? 
                                            <TrainerGrid /> 
                                        : orderBy === "Rider" ? 
                                            <RiderGrid /> 
                                        : 
                                            <ManualGrid 
                                                rowData={
                                                    orderBy !== "Manual" ? allTrips : manualOrderedTrips
                                                } 
                                            /> 
                                    }
                                </div>
                                <div className='col col-lg-7'>
                                    <OrderedTripsGrid 
                                        orderBy={orderBy} 
                                        rowData={
                                            orderBy !== "Manual" ? allTrips : manualOrderedTrips
                                        } 
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col col-lg-5'>
                                    <label className='col-form-label fs-5 py-0 text-danger'>
                                        { OOGStatus }
                                    </label>
                                </div>
                                <div className='col col-lg-7'>
                                    <div className="col d-flex justify-content-between">
                                        <div className='d-flex align-items-center'>
                                            <div className='me-2' style={{width:'35px',height:'7px', backgroundColor: "#00FF00"}}>   
                                            </div>
                                            <label className='col-form-label fs-5 py-1' data-tooltip-id="ORDEROFGO.DETAIL.SECTION.ORDEREDTRIPS.LABEL.INDICATESENTRIESPICKEDUPONLINE">
                                                { intl.formatMessage({ id: "ORDEROFGO.ORDEREDTRIPSGRID.LABEL.ENTRIESPICKEDONLINE"}) }
                                            </label>
                                        </div>  
                                        <div className='form-check-sm form-check-custom'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id="publishedOnWeb"
                                                checked={publishedOnWeb}
                                                onChange={e => setPublishedOnWeb(e.target.checked)}
                                            />
                                            <label className='col-form-label mx-2 fs-5 py-1' htmlFor='publishedOnWeb' data-tooltip-id="ORDEROFGO.DETAIL.SECTION.ORDEREDTRIPS.LABEL.PUBLISHONWEB">
                                                { intl.formatMessage({ id: "ORDEROFGO.ORDEREDTRIPSGRID.LABEL.PUBLISHONWEB"}) }
                                            </label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col d-flex align-items-center">
                                    <i 
                                        className={`fa-solid fa-lock${editOOG ? "-open" : ""} py-3`}
                                        onClick={() => { setEditOOG(prevIsOOGLocked => !prevIsOOGLocked) }}
                                    ></i>
                                    <label className='col-form-label fs-5 py-1 mx-2'>
                                        { !editOOG ? 
                                            <>
                                                This Class is Locked for Ordering. <label className="fw-bold cursor-pointer link-color" onClick={() => { setEditOOG(prevIsOOGLocked => !prevIsOOGLocked) }} data-tooltip-id="ORDEROFGO.DETAIL.SECTION.TRAINERWITHMULTIPLETRIPS.LINK.UNLOCK">Click to Unlock.</label> 
                                            </>
                                            :
                                            <>
                                            This Class is Available for Ordering. <label className="fw-bold cursor-pointer link-color" onClick={() => { setEditOOG(prevIsOOGLocked => !prevIsOOGLocked) }} data-tooltip-id="ORDEROFGO.DETAIL.SECTION.TRAINERWITHMULTIPLETRIPS.LINK.LOCK">Click to Lock.</label>
                                            </>
                                        }
                                    </label>
                                </div>
                                <div className='d-flex'>
                                    <button
                                        className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px align-items-center d-flex justify-content-center me-2" 
                                        onClick={clearOrder}
                                        type="button"
                                        data-tooltip-id="ORDEROFGO.DETAIL.SECTION.ORDEREDTRIPS.BUTTON.CLEARORDER"
                                    >
                                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                        Clear Order
                                    </button>
                                    <button 
                                        type='button'
                                        className="btn btn-sm btn-dark fw-bold h-30px py-1 w-150px align-items-center d-flex justify-content-center"
                                        onClick={saveOrder}
                                        data-tooltip-id="ORDEROFGO.DETAIL.SECTION.ORDEREDTRIPS.BUTTON.SAVEORDER"
                                    >
                                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                        Save Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export { OrderOfGoWrapper as OrderOfGo }