import { useIntl } from 'react-intl'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useUpdateEntryCount = () => {
    const intl = useIntl()
    const {progressBar} = useProgress()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const outputContextUpdater = useOutputContextUpdater()

    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()

    const updateEntryCount = async () => {
        loadingOverlay({ show: true })
        //Get selected class ids
        getOutputSelectionAreaIDs(false)
        .then(async (res) => { 
            if (res.length === 0) {
                loadingOverlay({ show: false })
                // error message if no classes selected
                alertDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.UPDATE.ENTRY.COUNT.NO.RECORD.SELECTED"}) })
            }
            else{
                let choice = await confirmDialog({ message: intl.formatMessage({ id: "CLASSES.QUICKACTION.UPDATE.ENTRY.COUNT.CONFIRM"})})
                if(!choice){ 
                    loadingOverlay({ show: false })
                    return 
                }

                //Set progress bar
                const progressEventId = getEventID(`update_entry_count_${customerID}`)
                progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: "Updating Entry Count", timeElapsed: true })
                
                axios.post( NEST_API_URL + `/classes/updateEntryCountOfClassesQuickAction`, {
                    customer_id: customerID,
                    show_id: currentShowID,
                    class_ids: res,
                    eventId: progressEventId
                })
                .then((response) => {
                    if(response?.data?.success){
                        let message = response?.data?.processedClasses == 1 
                            ? `Entry count updated for ${response?.data?.processedClasses} class.`
                            : `Entry count updated for ${response?.data?.processedClasses} classes.`
                        alertDialog({message: message, title: 'Success', icon: 'info'});
                        //refresh list
                        outputContextUpdater({action: 'refresh'})
                    }else{
                        alertDialog({message: response.data.error});
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        alertDialog({message: error.response.data.error});
                    }
                })
                .finally( () => {
                    loadingOverlay({ show: false })
                    progressBar({ show: false, eventID: progressEventId})
                })
            }
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return updateEntryCount
    
}

export default useUpdateEntryCount


