import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../output-listing/OutputListingGridStatic';
import DateRenderer from '../output-listing/renderers/DateRenderer';
import TimeRenderer from '../output-listing/renderers/TimeRenderer';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../output-listing/OutputListingRowOptions';

const HistoryTab = ({ area, rowData, gridStyles = { height: '300px' }, setGridRef = null, useGridStyles = false }) => {
    const intl = useIntl()
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);

    const columnDefs = [
        { 
            field: 'date', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.DATE' }),
            cellRenderer: DateRenderer
        },
        { 
            field: 'time', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.TIME' }),
            cellRenderer: TimeRenderer
        },
        { 
            field: 'user',
            width: 150, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.USER' }), 
        },
        { 
            field: 'change_description', 
            flex:1, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.CHANGE' }),
            wrapText: true, autoHeight: true
        },
    ]

    const toolsContainerStyle = useMemo(() => {
        if (!useGridStyles) return null;
        return { width: '100%', height: gridStyles.height };
      }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: gridStyles.height }), []);

    return (
        <>
            <div className='form-group mb-2'>
                { !useGridStyles &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}> {/* WA - Row Selector */}
                        <RowSelector value={visibleRows} onChange={setVisibleRows} area={`${area} - History`}/>
                    </div>  /* WA - Row Selector */
                }
                <div className='row mb-2'>
                    <div className='col-lg-12 align-items-center mb-2'>
                        <OutputListingGridStatic 
                            area ={area + " - History"}
                            columnDefs={columnDefs} 
                            rowData={rowData} 
                            toolsContainerStyle={toolsContainerStyle}
                            containerStyle={containerStyle}
                            setGridRef={setGridRef}
                            visibleRows={visibleRows}
                        >
                        </OutputListingGridStatic>
                    </div>
                </div>
            </div>
        </>
    );
}

export { HistoryTab }