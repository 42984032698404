import {useMemo, useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls'
import {useFormContext} from 'react-hook-form'
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import {useAppSelector} from '../../../redux/hooks'
import {AgGridReact} from 'ag-grid-react'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { getNarrowHeaderHeight,getNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import useAccessChecker from '../../../modules/hooks/use-access-checker'
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions'

const BarnsTab = (props) => {
  const intl = useIntl()
  const [rowData, setRowData] = useState([])
  const [selectedBarn, setSelectedBarn] = useState(null)
  const alertDialog = useAlert()
  const [selectedRowData, setSelectedRowData] = useState(null)
  const methods = useFormContext()
  const [addedBarns, setAddedBarns] = useState([])
  const [deletedBarns, setDeletedBarns] = useState([])
  const [barnxFacilityGridApi, setBarnxFacilityGridApi] = useState(null)
  const [barnxFacilityColumnApi, setBarnxFacilityColumnApi] = useState(null)
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const [isMounted, setIsMounted] = useState(false)

  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridClassName = "ag-theme-alpine";
  const rowHeight = getNarrowRowHeight;
  const headerHeight = getNarrowHeaderHeight;
  /* WA - Row Selector */

  useEffect(() => {
    // Set isMounted to true after the first render
    setIsMounted(true)
  }, [])

  const containerStyle = useMemo(() => ({width: '100%', height: '300px'}), [])
  useEffect(() => {
    setRowData(methods.getValues('associated_barns'))
  }, [methods.getValues('associated_barns')])

  const handleOnRowSelected = (event) => {
    if (event.node.selected) {
      setSelectedRowData(event.data);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 110,
      width: 110,
      resizable: true,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: function (params) {
        if (typeof params.value === 'number') {
          return {textAlign: 'center'}
        } else {
          return {textAlign: 'left'}
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: 'ag-center-aligned-header',
      singleClickEdit: true,
    }
  }, [])

  const addRow = (barn) => {
    if (barn && barn.value !== '') {
      // Check if barn is defined and its value is not an empty stbarn
      const newId = rowData.length + 1
      const newRow = {
        barn_id: barn.value,
        barn_number: newId,
        barn_name: barn.label,
      }

      // Check if row with same barn_id already exists
      const existingRow = rowData.find((row) => row.barn_id === barn.value)
      if (existingRow) {
        alertDialog({message: `The selected barn (${barn.label}) has already been added.`, title: 'Alert'})
        return
      }

      // Add the new row to the addedBarns array
      setAddedBarns([...addedBarns, newRow])
      setRowData([...rowData, newRow])
    }
  }

  useEffect(() => {
      // Skip the effect on the initial render
      if (!isMounted) return;
      // update added_barns array
      methods.setValue('barns', addedBarns, {shouldDirty: true})
  }, [addedBarns])

  const columnDefs = [
    {
      field: 'barn_number',
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.BARNS.LABEL.NUMBER'}),
    },
    {
      field: 'barn_name',
      flex: 2,
      headerName: intl.formatMessage({id: 'FORM.INPUT.FACILITIES.TAB.BARNS.LABEL.BARNNAME'}),
      sortable: true,
      sortingOrder: ['asc', 'desc'] 
    },
  ]

  const onGridReady = (params) => {
    setBarnxFacilityGridApi(params.api)
    setBarnxFacilityColumnApi(params.columnApi)
  }

  const handleOnRowDragEnd = () => {
    // Get the total number of rows in the grid
    let totalRowCount = barnxFacilityGridApi.getDisplayedRowCount()

    // Iterate through each row index and retrieve the row data object
    const newOrderedAllbarns = []
    for (let i = 0; i < totalRowCount; i++) {
      let displayedRowData = barnxFacilityGridApi.getDisplayedRowAtIndex(i).data
      displayedRowData.barn_number = i + 1
      newOrderedAllbarns.push(displayedRowData)
    }

    // Add the new row to the addedBarns array
    setAddedBarns(newOrderedAllbarns)

    setRowData(newOrderedAllbarns)

    // clear column state (sort order)
    barnxFacilityColumnApi.resetColumnState()
  }

  const handleOnMinusClick = async () => {
    if (!selectedRowData) {
      // no row is selected, do nothing
      return;
    }

    setDeletedBarns([...deletedBarns, selectedRowData]);
    const newRowData = rowData.filter((row) => row.barn_id !== selectedRowData.barn_id);

    // Update the 'number' property of the remaining rows
    for (let i = 0; i < newRowData.length; i++) {
      newRowData[i].barn_number = i + 1;
    }

    // Clear the selected row
    setSelectedRowData(null);

    // // Add the new row to the addedBarns array
    setAddedBarns(newRowData)

    // Save the updated row data to state
    setRowData(newRowData);
  };

  useEffect(() => {
    if(deletedBarns.length > 0){
      methods.setValue('deleted_barns', deletedBarns, {shouldDirty: true})
    }
  }, [deletedBarns])

    // Enable Alpha sorting 
    const onSortChanged = useCallback((gridParams) => {
      const sortModel =  gridParams.columnApi.getColumnState().find(s => s.sort != null)
      if (sortModel && Object.keys(sortModel)?.length) {
        const sortedColumn = sortModel?.colId
        if (sortedColumn === 'barn_name') {
          // Get the total number of rows in the grid
          let totalRowCount = gridParams.api.getDisplayedRowCount()
  
          // Iterate through each row index and retrieve the row data object
          const sortedNodes = []
          for (let i = 0; i < totalRowCount; i++) {
            let displayedRowData = gridParams?.api?.getDisplayedRowAtIndex(i)?.data
            displayedRowData.barn_number = i + 1
            sortedNodes.push(displayedRowData)
          }
  
          setAddedBarns(sortedNodes)
          setRowData(sortedNodes)
        }
      }
    }, [])
  
    // Removes sorting to enable drag/drop
    const onRowDragEnter = useCallback((gridParams) => {
      const sortModel =  gridParams.columnApi.getColumnState().find(s => s.sort != null)
      if (sortModel && Object.keys(sortModel)?.length) {
        const sortedColumn = sortModel?.colId
        if (sortedColumn === 'barn_name') {
          gridParams.columnApi.resetColumnState()
        }
      }
    }, [])

    /* WA - Row Selector */
    const effectiveContainerStyle = useMemo(() => {
      // Get actual number of rows to display (min of visibleRows and actual data length)
      const actualVisibleRows = rowData?.length > 0 
        ? Math.min(visibleRows, rowData?.length)
        : visibleRows;
    
      // Calculate the computed height based on actual visible rows
      const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
      
      // If container style exists and visible rows is greater than 0, compare heights
      if (visibleRows > 0) {
        // Parse the container style height (removing 'px' if present)
        const containerHeight = parseInt(containerStyle?.height);

      // If row data is empty, we return the existing container height
      if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
      }
        
        // If container height is greater than computed height, use container height
        if (containerHeight > computedHeight) {
          return containerStyle;
        }
      } else if (containerStyle) {
        return containerStyle;
      }
      
      // Default to computed height based on visible rows
      return {
        width: '100%',
        height: `${computedHeight}px`
      };
    }, [containerStyle, visibleRows, rowData?.length]);
    /* WA - Row Selector */

  return (
    <>
      <div className='row mb-2'>
        <div className='col-lg-4'>
          {props.form_meta.active_barns && (
            <Select
              options={props.form_meta.active_barns}
              id='barn'
              isSearchable={true}
              onChange={(selectedBarn) => {
                setSelectedBarn(selectedBarn)
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              styles={reactSelectStyles}
              placeholder='Select'
            />
          )}
        </div>
        <button
          className='btn btn-sm btn-secondary fw-bold text-uppercase h-120 py-1 d-flex align-items-center mx-1'
          style={{width: 'fit-content'}}
          type='button'
          onClick={() => {
            addRow(selectedBarn)
          }}
          disabled={!selectedBarn}
          data-tooltip-id="FACILITIES.DETAIL.TAB.BARN.BUTTON.ADDTOFACILITY"
        >
          Add to Facility
        </button>
      </div>

      <div className='row mb-2'>
        <div className='col-lg d-flex align-items-center mb-2'>
          <div style={{width: '100%'}}> {/* WA - Row Selector */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Facilities - Barns"}/>
            </div>
            <div style={effectiveContainerStyle}>
              <div className={gridClassName} style={gridStyle}>
                <AgGridReact
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  containerStyle={containerStyle}
                  rowDragEntireRow={true}
                  rowDragManaged={true}
                  suppressMoveWhenRowDragging={false}
                  animateRows={true}
                  onDragStopped={handleOnRowDragEnd}
                  onRowSelected={handleOnRowSelected}
                  rowSelection={'single'}
                  headerHeight={getNarrowHeaderHeight}
                  rowHeight={getNarrowRowHeight}
                  suppressRowHoverHighlight={true}
                  onSortChanged={onSortChanged}
                  onRowDragEnter={onRowDragEnter}
                ></AgGridReact>
              </div>
            </div> {/* WA - Row Selector */}
          </div>
        </div>

        <div className='col-lg-1'>
          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleOnMinusClick}
            data-tooltip-id="FACILITIES.DETAIL.TAB.BARN.BUTTON.REMOVE"
          >
            <i className='fas fa-minus fs-5 px-1 py-3'></i>
          </button>
        </div>
      </div>
    </>
  )
}

export {BarnsTab}
