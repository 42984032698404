import {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import {useFormContext} from 'react-hook-form'
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions';

const ShowsTab = (props) => {
  const intl = useIntl();
  const [gridRef, setGridRef] = useState(false)
  const methods = useFormContext()
  const rowData = methods.getValues('linkedShows')

  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
  /* WA - Row Selector */

  const columnDefs = [
    { field: 'show_name', flex : 1 , headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.SHOWNAME' })},
    { field: 'total_barns_count', width: 100, headerName: intl.formatMessage({ id: 'FORM.INPUT.BARNS.TAB.SHOWS.NUMBEROFBARNS' }),  cellStyle: {textAlign: 'center'}},
    { field: 'start_date', width : 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.STARTDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
    { field: 'end_date', width : 200 , headerName: intl.formatMessage({ id: 'FORM.INPUT.SHOWSERIES.ENDDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
  ]


  const containerStyle = useMemo(() => ({ width: '100%', height: '320px' }), []);

  return (
    <>
        <div className='row mb-2'>
            <div className='col-lg d-flex align-items-center mb-2'>
              <div className='form-group mb-2 w-100'> {/* WA - Row Selector */}
                <div className="d-flex justify-content-end align-items-center">
                  <div className='me-6' style={{display: "flex", justifyContent: 'right'}}>{rowData !== undefined ? rowData.length : ""}</div>
                    <div>
                      <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Barns - Shows Tab"}/>
                    </div>
                  </div>
                  <OutputListingGridStatic area ={"Barns - Shows Tab"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic>
                </div>
            </div>
        </div>
    </>
    )
  }
  
export {ShowsTab}