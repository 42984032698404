import {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import {useFormContext, Controller} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useAppSelector} from '../../../redux/hooks'
import {useAlert, useConfirm} from '../../../modules/sgl-utils/DialogsProvider'
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions'

const CheckListTab = () => {
  const intl = useIntl()
  const methods = useFormContext()
  const confirmDialog = useConfirm()
  const {show_id} = useParams()
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const alertDialog = useAlert()
  const [gridRef, setGridRef] = useState(false)
  const [rowData, setRowData] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [addedRows, setAddedRows] = useState([]);
  const [deletedRows, setDeletedRows] = useState([]);
  const checklist = methods.watch('check_list')

  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
  /* WA - Row Selector */

  const columnDefs = [
    { field: 'abbreviation', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.CHECKLIST.LABEL.ABBR'}), editable: true, maxWidth: 140, rowDrag: true,  cellClass: 'editable-cell', sortable:false},
    { field: 'check_list_item', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.CHECKLIST.LABEL.ITEM'}), editable: true,  cellClass: 'editable-cell', sortable:false, flex: 1 },
  ]

  useEffect(() => {
    if(!rowData?.length && checklist?.length){
      setRowData(checklist)
    }
  }, [checklist])

  useEffect(() => {
    methods.setValue('check_list', rowData, {shouldDirty: true})
  }, [rowData])

  async function deleteSelectedCheckList() {
    if (gridRef) {
      const choice = await confirmDialog({message: `Are you sure you want to remove the selected item?`})
      if(choice){
        let selectedRowData = gridRef?.getSelectedRows()
        if (selectedRowData?.length > 0) {
          gridRef.applyTransaction({ remove: [selectedRowData[0]] });
          setRowDataFromGrid()
        }
      }
    }
  }

  const containerStyle = useMemo(() => ({width: '100%', height: '250px'}), [])

  function onNewButtonClick() {
    let obj={
      abbreviation: '',
      check_list_item: '',
      customer_id: customer_id,
      show_id: methods.getValues('show.show_id'),
      order: rowData.length + 1
    }
    let found_abbr = rowData.some(el => el.abbreviation == obj["abbreviation"]);
    let found_item = rowData.some(el => el.check_list_item == obj["check_list_item"]);
    if (!found_abbr || !found_item) {
      methods.setValue('check_list_changed', true, {shouldDirty: true})
      setRowData(rowData => [...rowData, obj])
    }else {
      alertDialog({message: 'That item already exists.', title: 'warning'})
    }
  }

  const editChecklistItem = (params) => {
    let checklistData = [], itemExists = false
    gridRef.forEachNode(node => {
      let found_abbr = rowData.some((el, index) => el.abbreviation == node.data?.abbreviation && index != node.rowIndex);
      let found_item = rowData.some((el, index) => el.check_list_item == node.data?.check_list_item && index != node.rowIndex);
      if ((!found_abbr || !found_item) && !itemExists){
        checklistData.push(node.data)
      }else if(!itemExists){
        itemExists = true
        alertDialog({message: 'That item already exists.', title: 'warning'})
        gridRef.undoCellEditing()
      }
    });

    if(!itemExists){
      methods.setValue('check_list_changed', true, {shouldDirty: true})
      setRowData(checklistData)
    }
  }

  const setRowDataFromGrid = () => {
    let checklistData = []
    gridRef.forEachNode(node => checklistData.push({...node.data, order: node.rowIndex +1}));
    setRowData(checklistData)
    methods.setValue('check_list_changed', true, {shouldDirty: true})
  }

  return (
    <>
      <fieldset className='mb-1'>
        <legend className='fs-5 fw-bold d-flex'>
          {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.CHECKLIST.GROUPLABEL.SHOWCHECKLIST'})}{' '}
          <b></b>
        </legend>

        <div className='row mb-2'>
          <div className='col-lg-11 d-flex align-items-center mb-2'>
            <div className='form-group mb-2 w-100'> {/* WA - Row Selector */}
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <RowSelector value={visibleRows} onChange={setVisibleRows} area ={"Shows - CheckList"}/>
              </div>
              <OutputListingGridStatic
                // area ={"Shows - CheckList"}
                setGridRef={setGridRef}
                columnDefs={columnDefs}
                rowData={rowData}
                containerStyle={containerStyle}
                rowDragManaged={true}
                stopEditing={editChecklistItem}
                onRowDragEnd={setRowDataFromGrid}
                undoRedoCellEditing={true}
                stopEditingWhenCellsLoseFocus={true}
                visibleRows={visibleRows}
              ></OutputListingGridStatic>
            </div> {/* WA - Row Selector */}
          </div>

          <div className='col-lg-1'>
            <button 
              type='button'
              className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2"
              onClick={(e) => {
                onNewButtonClick()
                e.preventDefault()
              }}
              data-tooltip-id="SHOW.DETAIL.TAB.CHECKLIST.BUTTON.ADD"
            >
              <i className="fas fa-plus fs-5 px-1 py-3"></i>
            </button>
            <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
            <button
              type='button'
              className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
              onClick={(e) => {
                deleteSelectedCheckList()
                e.preventDefault()
              }}
              data-tooltip-id="SHOW.DETAIL.TAB.CHECKLIST.BUTTON.REMOVE"
            >
              <i className='fas fa-minus fs-5 px-1 py-3'></i>
            </button>
          </div>
        </div>
      </fieldset>
    </>
  )
}

export {CheckListTab}
