import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { SelectShowForm } from './ShowsTab/SelectShowForm';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const ShowsTab = () => {
    const intl = useIntl();
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState();
    const methods = useFormContext()
    const [showSelectShowForm, setShowSelectShowForm] = useState(false)
    const { hasAreaWritePermission } = useAccessChecker()
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const customerId = useAppSelector(state => state.showCompany.company_id)

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const columnDefs = [
        { 
            field: 'show_name', 
            flex: 1,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.SHOWNAME' })
        },
        { 
            field: 'start_date', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.STARTDATE' }),  
            cellRenderer: DateRenderer , 
            cellStyle: { textAlign: 'center' },
            width: 130
        },
        { 
            field: 'end_date', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.ENDDATE' }), 
            cellRenderer: DateRenderer , 
            cellStyle: { textAlign: 'center' }, 
            width: 100
        },
        { 
            field: 'city', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.CITY' })
        },
        { 
            field: 'state', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.STATE' }), 
            cellStyle: { textAlign: 'center' }, 
        },
        { 
            field: 'show_number', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.SHOWNUM' }), 
            cellStyle: { textAlign: 'center' },
            width: 160
        },
        { 
            field: 'zone', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.ZONE' }) 
        },
        { 
            field: 'manager_name', 
            width: 200, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.SHOWS.MANAGERNAME' })
        },
    ]


    useEffect ( ()=>{
        let circuitxShow_data  = methods.getValues ('circuitxShow_data')
        if (circuitxShow_data && circuitxShow_data.length > 0) {
            setRowData (circuitxShow_data)
        }
    })

    useEffect(() => {
        if(gridRef){
            let colDefs = gridRef.getColumnDefs()
            if(methods.getValues('circuitxShow_data')){    
                gridRef.setColumnDefs(colDefs)
            }
            gridRef.setRowData(methods.getValues('circuitxShow_data'))
            setRowData(methods.getValues('circuitxShow_data')) // WA - Row Selector
        }
    }, [gridRef, methods.getValues('circuitxShow_data')]);

    const removeShowFromCircuitMetadata = async (circuitXShowId) => {
        let userChoice = false
        try {
            loadingOverlay({ show: true, message: 'PROCESSING..' })
            const response = await axios.get(`${NEST_API_URL}/circuits/removeShowFromCircuitMetadata?customer_id=${customerId}&circuit_id=${methods.getValues('circuit.circuit_id')}&circuit_x_show_id=${circuitXShowId}`)

            if (response.data.success) {
                let message = 'Are you sure you want to remove selected show from circuit?'
                if (response.data.numCircuitDivisions > 0) {
                    message = `Deleting this show will result in ${response.data.numClassesInCircuitDivisions} classes being removed from ${response.data.numCircuitDivisions} Circuit Divisions. This can not be undone. Are you sure you want to remove selected show from circuit?`
                }
                userChoice = await confirmDialog({ message })
            }
        } catch (reason) {
            alertDialog({ message: reason?.response?.data?.error ?? 'Something went wrong..' })
            userChoice = false
        } finally {
            loadingOverlay({ show: false, message: 'PROCESSING..' })
            return userChoice
        }
    }

    const deleteSelectedShow = async () => {
        if ( gridRef ) {
          let selected_show = gridRef.getSelectedRows()
          if ( selected_show.length > 0 ) {
            
            // Add these deleted shows from circuitxShow_data to methods.getValues ('other_shows')
            const otherShows = methods.watch ('other_shows')

            if (selected_show[0].circuitxshow_id){ 
                // if seleted show was the show were we getting from backend, but deleting from front end, add a check for that show
                if (!await removeShowFromCircuitMetadata(selected_show[0].circuitxshow_id)) {
                    return
                }

                selected_show[0].deleted= true
            }

            otherShows.push(selected_show[0])

            // delete this selected show from circuitxShow_data list for circuitXShows
            let circuitXShows = methods.getValues('circuitxShow_data') 
            const filtered_shows = circuitXShows.filter((show) => !selected_show.includes(show));
            methods.setValue('circuitxShow_data', filtered_shows, {shouldDirty: true})
            methods.setValue('other_shows', otherShows, {shouldDirty: true})

            // add this selected show in deleted list
            let showsDeleted =  methods.getValues('show_deleted')?methods.watch('show_deleted'):[]
            showsDeleted.push(selected_show[0]) 
            methods.setValue('show_deleted', showsDeleted, {shouldDirty: true})
            
            const shows = methods.watch('circuitxShow_data')
            if(shows && shows.length > 0) {
                let rowData = shows.filter((shows) => {
                    let is_deleted = methods.getValues('show_deleted').find((show_deleted) => {
                        return (shows.show_id == show_deleted.show_id)
                    })
                    if(!is_deleted) {
                        return shows
                    }
                })  
                gridRef.setRowData(rowData)
                setRowData(rowData) // WA - Row Selector
            }
          }
        }
      }
    
    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 410px)' }), []);

    return (
        <>
            <div className='form-group'>
                {showSelectShowForm && <SelectShowForm  form_meta={methods} show={showSelectShowForm} setShowSelectShowForm = {setShowSelectShowForm} handleClose={(e) => {setShowSelectShowForm(false);}} />}
                <div className='row mb-2'>
                    <div className='col'>
                        <div className='form-group mb-2'> {/* WA - Row Selector */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <RowSelector
                                value={visibleRows}
                                onChange={setVisibleRows}
                                area={"Circuits - Shows"}
                                />
                            </div>
                            <div className='row mb-2'>
                                <OutputListingGridStatic
                                    columnDefs={columnDefs} 
                                    setGridRef={setGridRef}
                                    rowData={rowData}
                                    containerStyle={containerStyle}
                                    visibleRows={visibleRows}
                                    // onCellDoubleClick={onCellDoubleClick}
                                ></OutputListingGridStatic>
                            </div>
                        </div> {/* WA - Row Selector */}
                    </div>
                    <div className='col-lg-1 w-75px p-0'>
                        <label className='col col-form-label fs-5 py-1 text-end mb-2' data-tooltip-id="CIRCUITS.DETAIL.TAB.SHOWS.LABEL.TOTAL">Total: {methods.watch ('circuitxShow_data') !== undefined ? methods.watch ('circuitxShow_data').length : ""}</label>
                        { hasAreaWritePermission('circuits') && [
                        <button type='button' className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2"  onClick={(e)=> {setShowSelectShowForm(true);e.preventDefault()}} data-tooltip-id="CIRCUITS.DETAIL.TAB.SHOWS.BUTTON.ADD">
                            <i className="fas fa-plus fs-5 px-1 py-3"></i>
                        </button>
                        ,
                        <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                        ,
                        <button type='button'  className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' onClick={(e)=> { deleteSelectedShow();e.preventDefault()}} data-tooltip-id="CIRCUITS.DETAIL.TAB.SHOWS.BUTTON.REMOVE">
                            <i className='fas fa-minus fs-5 px-1 py-3'></i>
                        </button>
                        ]}
                    </div>
                </div>
            </div>
        </>
    );
}

export { ShowsTab }