import { useIntl } from 'react-intl'
import moment from 'moment';
import { PageTitle } from '../../../../_metronic/layout/core';

import { AnnouncerToolContextProvider, useAnnouncerToolContext } from './AnnouncerToolContext';
import {IngateAnnouncerTab} from './IngateAnnouncerTab';
import {ScoreBoardTab} from './ScoreBoardTab';
import { OrderOfGoTab } from './OrderOfGoTab';
import { HistoryTab } from '../../../modules/components/HistoryTab'; 
import './overrides.css'
import { SESSION_STORAGE_CURRENT_RING_ID } from '../../../modules/sgl-utils/sglConstants';
import HelpLink from '../../../modules/components/HelpLink';

const AnnouncerToolWrapper = () => {
    const intl = useIntl()
    return (
        <AnnouncerToolContextProvider>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ANNOUNCERTOOL' })}</PageTitle>
            <AnnouncerTool />
        </AnnouncerToolContextProvider>
    )
}

const AnnouncerTool = () => {
    const intl = useIntl();
    const { showDates, showRings, classGroups, selectedDate, setSelectedDate, selectedRing, setSelectedRing, selectedClassGroup, setSelectedClassGroup, refreshGrids, setLastIngateActivity, changeHistory } = useAnnouncerToolContext()

    const tabs = ["ORDEROFGO", "INGATEANNOUNCER", "SCOREBOARD", "HISTORY"];

    const componentTags = [
        <OrderOfGoTab />,
        <IngateAnnouncerTab/>, 
        <ScoreBoardTab />,
        <HistoryTab 
            area='Ingate Announcer'
            rowData={changeHistory}
            gridStyles={{ height: 'calc(100vh - 230px)' }}
            useGridStyles={true}
        />
    ]

    const events =  classGroups.map(cg => 
        <option key={cg.class_group_id} value={cg.class_group_id}>
            { cg.class_list !== "" ? `[${cg.class_list}] ${cg.group_name}` : cg.group_name }
        </option>
    )

    const handleOnChange = async (event, field) => {

        if(field == 'showDate')
        {
            setSelectedDate(event.target.value)
            setLastIngateActivity('Day Change')
            sessionStorage.setItem("selectedDate", event.target.value);            
        }else if(field == 'showRing')
        {
            setSelectedRing(event.target.value)
            setLastIngateActivity('Ring Change')

            // Set/Update Current Ring In Session Storage
            sessionStorage.setItem(SESSION_STORAGE_CURRENT_RING_ID, event.target.value)
            sessionStorage.setItem("selectedRing", event.target.value);
        }else if(field == 'showClassGroup')
        {
            setSelectedClassGroup(event.target.value)
            setLastIngateActivity('Group Change')
            sessionStorage.setItem("selectedClassGroup", event.target.value);
        }
    }

    return (
        <>
            <div className='announcer-interface-ui modal-body py-0 px-0' style={{ height: "100%" }}>
                <form noValidate className='form' onSubmit={e => e.preventDefault()} style={{ height: "100%" }}>
                <HelpLink tooltip="ANNOUNCERTOOL.DETAIL.LINK.HELP" classname="top" positionType="absolute" positionTop='-0.1rem' positionRight='1rem' />
                    <div className='card-body pt-0 pb-3' style={{ height: "100%" }}> {/* Card Body */}

                    <div className="top-form-group row"> {/* Row Group */}
                        <div className='row mb-0 mt-0 ps-4'>
                            <label className='col-form-label fs-5 py-1 w-75px pe-0 me-3 fw-bold' htmlFor="showDate" data-tooltip-id="ANNOUNCERTOOL.DETAIL.LABEL.SHOWDATE">
                                <span>{intl.formatMessage({ id: "ORDEROFGO.LABEL.SHOWDATE" })}</span>
                            </label>
                            <div className='col-lg-3 '>
                                <select
                                    id="showDate"
                                    className='form-select  form-select-sm fs-6 h-20px py-1'
                                    onChange={(e) => handleOnChange(e, 'showDate')}
                                    value={selectedDate}
                                >
                                    {
                                        showDates.map(date =>
                                            <option key={date} value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>)
                                    }
                                </select>
                            </div>

                            <label className='col-form-label fs-5 py-1 w-50px fw-bold' htmlFor='ring' data-tooltip-id="ANNOUNCERTOOL.DETAIL.LABEL.RING">
                                <span>{intl.formatMessage({ id: 'ORDEROFGO.LABEL.RING' })}</span>
                            </label>
                            <div className='col-lg-3 '>
                                <select
                                    id="ring"
                                    className='form-select  form-select-sm fs-6 h-20px py-1'
                                    onChange={(e) => handleOnChange(e, 'showRing')}
                                    value={selectedRing}
                                >
                                    {
                                        showRings.map(ring =>
                                                <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                                    }
                                </select>
                            </div>

                            <label className='col-form-label fs-5 py-1 w-50px fw-bold' htmlFor="event" data-tooltip-id="ANNOUNCERTOOL.DETAIL.LABEL.EVENT">
                                <span>{intl.formatMessage({ id: "ORDEROFGO.LABEL.EVENT" })}</span>
                            </label>
                            <div className='col-lg-3 '>
                                <select
                                    id="event"
                                    className='form-select  form-select-sm fs-6 h-20px py-1'
                                    onChange={(e) => handleOnChange(e, 'showClassGroup')}
                                    value={selectedClassGroup}
                                >
                                    {events}
                                </select>
                            </div>
                            <div className='w-lg-75px'>
                                <i className='far fa-solid fa-rotate text-success fs-2 fw-bold cursor-pointer' onClick={() => refreshGrids(true)}></i>
                            </div>
                        </div>
                    </div>
                    
                    <div className='d-flex flex-column my-0 border p-2 bg-white announcer-tool-content' style={{ height: "100%", position: "relative" }}>
                        <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            {
                                tabs.map((tab, index) => 
                                    <li className='nav-item' style={{lineHeight: "16px",fontSize: "13px", paddingBottom: "1px", backgroundColor:"#2274a5"}}>
                                        <a className={"nav-link text-active-dark px-8 py-1 text-gray-700" + (index == 1 ? ' active' : '') + ((index == 0 || index == 2) ? ' min-w-150px text-center' : (index == 1) ? ' min-w-200px text-center': '')}
                                            tabIndex="-1"
                                            id={tab + "-tab"}
                                            data-bs-toggle='tab'
                                            href={"#" + tab + "-AnnouncerTool" }
                                            data-tooltip-id={`ANNOUNCERTOOL.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.' + tab })}
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="tab-content" id="myTabContent" style={{ height: "100%" }}>
                            {    
                                tabs.map((tab, index) => {
                                    return (
                                        <div className={"tab-pane fade show" + (index == 1 ? " active" : "")} id={tab + "-AnnouncerTool"} role="tabpanel" style={{ height: "100%" }}>
                                            {componentTags[index]}
                                        </div>
                                    );
                                }) 
                            }
                        </div>
                    </div>
                        
                    </div>
                </form>
            </div>
        </>
    )
}

export { AnnouncerToolWrapper as AnnouncerTool }
