import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../../modules/output-listing/OutputListingRowOptions';

const ResponsibleParty = () => {
    const intl = useIntl();
    const methods = useFormContext()
    const [rowData, setRowData] = useState([]);
    const [gridRef, setGridRef] = useState();
    const [paymentListGrid, setPaymentListGrid] = useState();
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [entriesBalance, setEntriesBalance] = useState(0);
    const people_id = methods.getValues('people.people_id');
    const [entryBalanceTotal, setEntryBalanceTotal] = useState({});
    const currencyFormatter = useCurrencyFormatter()
    
    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const [rowDataOne, setRowDataOne] = useState([]);
    /* WA - Row Selector */

    useEffect(() => {
        if(gridRef && methods.getValues('responsiblePartyEntries')){
            gridRef.setRowData(methods.getValues('responsiblePartyEntries'))
            setRowDataOne(methods.getValues('responsiblePartyEntries')) // WA - Row Selector
            calculateBalance()
        }
    }, [gridRef, methods.watch('responsiblePartyEntries')]);

    let calculateBalance = () => {
        let responsiblePartyLists = methods.getValues('responsiblePartyEntries')
        let balance = 0
        responsiblePartyLists.map((responsibleParty) =>{
            balance = balance + responsibleParty['balance']
        })

        setEntriesBalance(balance)
    }

    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRY' }) },
        { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.SHOW' }),width:400,
            valueGetter: params => {
                if(params.data.Show){
                    let date = new Date(params.data.Show.end_date)
                    return params.data.Show.show_name.concat(' [',date.getFullYear(),']')
                }
            }
        },
        { field: 'horse', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.HORSE' }), width:200 },
        { field: 'total_fees', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.TOTAL' }), cellStyle: {textAlign: 'center'},  cellRenderer: params => currencyFormatter(params.value), valueFormatter: params => params.data?.total_fees?.toFixed(2)  },
        { field: 'total_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.PAID' }), cellStyle: {textAlign: 'center'},  cellRenderer: params => currencyFormatter(params.value), valueFormatter: params => params.data?.total_paid?.toFixed(2)  },
        { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.BALANCE' }), cellStyle: {textAlign: 'center'},valueFormatter: params => params.data?.balance?.toFixed(2),  cellRenderer: params => currencyFormatter(params.value), flex: 1 },
    ]

    const paymentsColumnDefs = [
        { field: 'Payment.payment_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.LISTOFPAYMENTS.DATE' }), maxWidth: 90, cellRenderer: DateRenderer , cellStyle: {textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'}},
        { field: 'Payment.type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.LISTOFPAYMENTS.TYPE' }), maxWidth: 90, cellStyle: {paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'}},
        { field: 'amount_applied', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.LISTOFPAYMENTS.AMOUNT' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center', paddingRight: '2px', paddingLeft: '2px', fontSize: '12px'}, flex: 1},
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);
    const paymentsContainerStyle = useMemo(() => ({ width: '100%', height: '110px' }), []);

    const onCellClicked = (params) => {
        axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/getEntryPaymentDetail', {
            params: {
                people_id,
                customer_id,
                entry_id: params.data.entry_id
            }
        }).then ((response) => {
            paymentListGrid.setRowData(response.data.PaymentxEntries)
            setEntryBalanceTotal(response.data)
        })
    }

    return (
        <>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-9'>
                        <div className='d-flex'>
                            <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRIESBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ENTRIESBALANCE' }) } : {currencyFormatter(methods.getValues('rtosTotalBalances.entriesBalance'))}</label>
                            <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ACCOUNTREGISTERBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTERBALANCE' }) } : {currencyFormatter(methods.getValues('rtosTotalBalances.accountRegisterBalance'))}</label>
                            <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ACCOUNTBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTBALANCE' }) } : {currencyFormatter(methods.getValues('rtosTotalBalances.accountBalance'))}</label>
                        </div>
                        <div className='form-group mb-2'> {/* WA - Row Selector */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <RowSelector
                                value={visibleRows}
                                onChange={setVisibleRows}
                                area={"People - Account ResponsibleParty 1"}
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-center mb-2'>
                            <OutputListingGridStatic area ={"People - Account ResponsibleParty 1"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowDataOne} containerStyle={containerStyle} onCellClicked={onCellClicked} visibleRows={visibleRows}></OutputListingGridStatic> {/* WA - Row Selector */}
                        </div>
                        <div className='row mb-2'>
                            <div className='col d-flex align-items-center'>
                                <label className='col fw-bold fs-5 pe-2 text-end' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.LABEL.ENTRIESBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.LABEL.ENTRIESBALANCE' }) } {currencyFormatter(entriesBalance)}</label>  
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div>
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex  mb-1">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE' })} <b></b></legend>
                                <div className='row d-flex align-items-center mb-2'>
                                    <div className='col-8 d-flex flex-column'>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.CLASSFEES">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.CLASSFEES' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.MISCFEES">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.MISCFEES' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.TAX">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.TAX' })}</label>
                                        {/* <br /> */}
                                        <label className='fw-bold fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.SUBTOTAL">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.SUBTOTAL' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.PAYMENTS">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.PAYMENTS' })}</label>
                                        {/* <br /> */}
                                        <label className='fw-bold fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.ENTRYBALANCE.LABEL.BALANCE">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.ENTRYBALANCE.BALANCE' })}</label>
                                    </div>
                                    <div className='col-4 d-flex flex-column ms-auto'>
                                        <label className='fs-7 pe-2 text-end' id='classFees'>{currencyFormatter(entryBalanceTotal?.class_fees_subtotal, true)}</label>
                                        <label className='fs-7 pe-2 text-end' id='miscFees'>{currencyFormatter(entryBalanceTotal?.entry_fee_subtotal, true)}</label>
                                        <label className='fs-7 pe-2 text-end border-bottom-black' id='tax'>{currencyFormatter(entryBalanceTotal?.tax, true)}</label>
                                        {/* <hr/> */}
                                        <label className='fw-bold fs-7 pe-2 text-end' id='subtotal' >{currencyFormatter(entryBalanceTotal?.total_fees, true)}</label>
                                        <label className='fs-7 pe-2 text-end border-bottom-black' id='payment' >{currencyFormatter(entryBalanceTotal?.total_paid, true)}</label>
                                        {/* <hr /> */}
                                        <label className='fw-bold fs-7 pe-2 text-end' id='balance' >{currencyFormatter(entryBalanceTotal?.balance, true)}</label>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.LISTOFPAYMENTS' })} <b></b></legend>
                                <div className='row d-flex align-items-center mb-2'>
                                    <OutputListingGridStatic area ={"People - Account ResponsibleParty 2"} setGridRef={setPaymentListGrid} columnDefs={paymentsColumnDefs} rowData={rowData} containerStyle={paymentsContainerStyle} headerHeight={20} rowHeight={20} cellWidth={90}></OutputListingGridStatic>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="fs-7 fw-bold d-flex  mb-1">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING' })} <b></b></legend>
                                <div className='row d-flex align-items-center mb-2'>
                                    <div className='col-7 d-flex flex-column'>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.PENDINGBALANCE">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.PENDINGBALANCE' })}</label>
                                        <label className='fs-7 pe-0 text-danger pl-5' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.CHARGES">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.CHARGES' })}</label>
                                        <label className='fs-7 pe-0 text-success pl-5' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.PAYMENTS">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.PAYMENTS' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.1-30DAYS">1-30 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.30-60DAYS">30-60 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.60-90DAYS">60-90 {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                        <label className='fs-7 pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.RESPONSIBLEPARTY.SECTION.AGING.LABEL.90+DAYS">90+ {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.RESPONSIBLEPARTY.AGING.DAYS' })}</label>
                                    </div>
                                    <div className='col-5 d-flex flex-column ms-auto'>
                                        <label className='fs-7 text-end me-2'>{ currencyFormatter(methods.getValues('agingInfo.pendingBalance'), currency)}</label>
                                        <label className='fs-7 text-danger text-end me-5'>{currencyFormatter(methods.getValues('agingInfo.currentCharges'), currency)}</label>
                                        <label className='fs-7 text-success text-end me-5'>{currencyFormatter(methods.getValues('agingInfo.currentPayment'), currency)}</label>
                                        <label className='fs-7 text-end me-2'>{currencyFormatter(methods.getValues('agingInfo.dueFrom1Months'), currency)}</label>
                                        <label className='fs-7 text-end me-2'>{currencyFormatter(methods.getValues('agingInfo.dueFrom2Months'), currency)}</label>
                                        <label className='fs-7 text-end me-2'>{currencyFormatter(methods.getValues('agingInfo.dueFrom3Months'), currency)}</label>
                                        <label className='fs-7 text-end me-2'>{currencyFormatter(methods.getValues('agingInfo.dueBefore3Months'), currency)}</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { ResponsibleParty }