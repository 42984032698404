import { useMemo, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import CurrencyRenderer from '../../../../modules/output-listing/renderers/CurrencyRenderer';
import NumberRenderer from '../../../../modules/output-listing/renderers/NumberRenderer';
import BooleanCheckmarkRenderer from '../../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import TimeRenderer from '../../../../modules/output-listing/renderers/TimeRenderer';
import { useAppSelector } from '../../../../redux/hooks';
import ClassAddDrop from './ClassAddDrop';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../../modules/output-listing/OutputListingRowOptions';

const AllClassesTab = (props) => {
    const intl = useIntl();
    const confirmDialog = useConfirm()
    const methods = useFormContext()
    const show_financials_locked = useAppSelector(state=> state.currentShow.islocked)
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState([]);
    const alertDialog = useAlert()
    const [scratchButton, setScratchButton] = useState(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
    const currency = intl.formatMessage({ id: 'CURRENCY'})
    
    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const entry_fees_watch = methods.watch('entryFeesData.entryFees')

    const [classRuleFailed, setClassRuleFailed] = useState('');
    const [personId, setPersonId] = useState(null);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [dropCounter, setDropCounter] = useState(0);
    const childRef = useRef(); 

    const dropTrip = async () => {
        var selected = gridRef.getSelectedRows()
        if(selected.length > 0){ 
            let choice
            if(selected[0].gone_in){
                choice = await confirmDialog({ message: "The trip is already gone. Are you sure you want to drop the selected trip?"})
            } else {
                choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPMESSAGE"})})        
            }
            // let choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPMESSAGE"})})        
            if(choice){
                setPersonId(selected[0].rider_id)
                setSelectedTrip(String(selected[0].Class.number))
                setDropCounter(prev => prev + 1); 
            }else{
                setSelectedTrip(null)
            }
        }else{
            alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SELECTION.ALERT"})})
        }
    }

    useEffect(()=>{
        if(selectedTrip){
            childRef.current.callConfirmDivisionDrop(selectedTrip)
        }
    },[dropCounter])

    const scratchEntry = async () => {
        var selected = gridRef.getSelectedRows()
        let trips = methods.getValues('trips')

        if(selected.length > 0){ 
            let index = trips.findIndex(data => data.entryxclasses_id ==selected[0].entryxclasses_id)
            if(selected[0].scratch_trip){
                let choice = await confirmDialog({message: intl.formatMessage({id:  "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.UNSCRATCHMESSAGE"}), icon: 'warning' })
                if(choice){
                    selected[0].scratch_trip = 0
                    selected = setScratchDisqualifyStatus(selected[0])
                    selected["scratch_by_user"] = 1
                    trips[index] = selected
                    setScratchButton(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
                }
            }
            else{
                if(selected[0].trip_verified){ //Checked for verified trips
                    alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYVERIFIED"})})
                }
                else if(selected[0].gone_in){ //check for gone trips
                    alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYGONE"})})
                }
                else if(selected[0].placing > 0){ //check for placed trips
                    alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHEDTRIPS.ALREADYPLACED"})})
                }
                else{
                    let choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SCRATCHMESSAGE"})})
                    if(choice){
                        selected[0].scratch_trip = 1
                        selected = setScratchDisqualifyStatus(selected[0])
                        selected["scratch_by_user"] = 1
                        trips[index] = selected
                        setScratchButton(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
                    }
                }
            }
            methods.setValue('trips', trips, {shouldDirty:true})
        }else{
            alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.SELECTION.ALERT"})})
        }
    }

    const setScratchDisqualifyStatus = (entry) => {
        if(entry.scratch_trip){//Set disqualify status as DNS on setting trip as scratched
            if(entry.disqualify_status_one == ""){ //disqualification status is not already set
                entry.disqualify_status_one = "DNS"
            }
        }
        else if(entry.disqualify_status_one == "DNS"){  //Clear Disqualify status on unscratch trip if its  DNS
            entry.disqualify_status_one = ""
        }
        return entry
    }

    // This function will changed the label of scratch button on the basis of scratch_trip
    const onCellClicked = () => {
        var selected = gridRef.getSelectedRows()
        if(selected[0]?.scratch_trip){
            setScratchButton(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.UNSCRATCH' }))
        }
        else{
            setScratchButton(intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.SCRATCH' }))
        }
    }

    const columnDefs = [
        { field: 'placing', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.PLACING' }), cellClass: 'text-end' },
        { field: 'Class.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CLASS' }), cellClass: 'text-end' },
        { field: 'Class.name', width: 400, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.NAME' })},
        { field: 'Class.scheduled_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.DATE' }), cellClass: 'text-center',  cellRenderer: DateRenderer },
        {
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.DAY' }),
            field: 'day_number',
            cellClass: 'text-center',
            valueGetter: (params) => {
              const classGroupxclass = params?.data?.Class?.ClassGroupxclass; 
              if (classGroupxclass && classGroupxclass.length > 0) {
                const classGroup = classGroupxclass[0].ClassGroup; 
                return classGroup ? classGroup.day_number : null;
              }
              return null; 
            }
        },
        { field: 'Class.class_price', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CLASSPRICE' }), cellRendererSelector: (params) => { return { component: CurrencyRenderer, params: { currency: currency, }, } }, cellClass: 'text-end' },
        { field: 'rider_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.RIDERNAME' })},
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.TOTALPRIZEMONEY' }), cellClass: 'text-end' ,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) { //Renderer for footer row
                return {
                    component: CurrencyRenderer,
                    params: {
                    currency: currency,
                    },
                }
                } else { //Renderer for other rows
                return {
                    component: CurrencyRenderer,
                    params: {
                    currency: currency,
                    },
                }
                }
            }
        },
        { field: 'points_earned', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.POINTSEARNED' }), cellClass: 'text-end' },
        { field: 'check_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CHECKPAID' }), cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center' },
        { field: 'prize_money_spent', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.PRIZEMONEYSPENT' }), cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center' },
        { field: 'scratch_trip', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.SCRATCHED' }), cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center' },
        { field: 'creation_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONDATE' }), cellClass: 'text-center',  cellRenderer: DateRenderer },
        { field: 'creation_time', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.CREATIONTIME' }), cellClass: 'text-center',  cellRenderer: TimeRenderer },
        { field: 'ActualClass.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.ACTUALCLASS' }), cellClass: 'text-center',  hide: true },
        
    ]

    useEffect(() => {
        if(gridRef && methods.getValues('trips')){
            if(methods.getValues('trips').filter(trip => trip?.Class?.combined_class)?.length > 0){
                let colDefs = gridRef.getColumnDefs()
                colDefs[13].hide = false
                gridRef.setColumnDefs(colDefs)
            }
            gridRef.setRowData(methods.getValues('trips'))
            setRowData(methods.getValues('trips')) /* WA - Row Selector */
            calculateTotalPrizeMoney(methods.getValues('trips'))
        }
    }, [gridRef, methods.getValues('trips')]);

    const calculateTotalPrizeMoney = (trips) => {
        let prizeMoneyTotal = 0

        trips.map(trip => {
            prizeMoneyTotal += trip.total_prize_money
        })

        gridRef.setPinnedBottomRowData([{
            total_prize_money: prizeMoneyTotal
        }])
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '425px' }), []);

    return (
        <>
            <ClassAddDrop 
                ref={childRef}
                person_id={personId}
                setErrors = {setClassRuleFailed}
                calledFrom="Entries"
            />
            <div className='form-group mb-2'> {/* WA - Row Selector */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <RowSelector
                    value={visibleRows}
                    onChange={setVisibleRows}
                    area={"Entries - All Classes"}
                    />
                </div>
                <div className='row mb-2'>
                    <OutputListingGridStatic
                        area ={"Entries - All Classes"}
                        onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.class_id ,{}, "Classes", "ClassDetail")}}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        containerStyle={containerStyle}
                        setGridRef={setGridRef}
                        onCellClicked={onCellClicked}
                        visibleRows={visibleRows}
                    ></OutputListingGridStatic>
                </div> {/* WA - Row Selector */}
            </div>
            <div className='row mb-2'>
                <label className='col align-items-center'>Classes: {methods.getValues('trips') ? methods.getValues('trips').length : 0}</label>
                <div className='col d-flex justify-content-end'>
                    <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase me-2" onClick={dropTrip} data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.ALLCLASSES.BUTTON.SCRATCH">
                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.ENTRIES.LABEL.DROP' })}
                    </button>
                    <button disabled={entry_fees_watch?.length && show_financials_locked} type='button' onClick={scratchEntry} className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.ALLCLASSES.BUTTON.SCRATCH">
                        {scratchButton}
                    </button>
                </div>
            </div>
        </>
    );
}

export { AllClassesTab }