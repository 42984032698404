import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import {useEffect, useState} from 'react'
import { useAppSelector } from '../../../redux/hooks';
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';

import { useFormContext } from 'react-hook-form';

import axios from 'axios'
import { AddClassesModal } from './ClassesTab/AddClassesModal';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions';

const ClassesTab = ({ shows }) => {
    const intl = useIntl();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const loadingOverlay = useLoadingOverlay()
    const [showAddClassesModal, setShowAddClassesModal] = useState(false)

    const methods = useFormContext()
    const [selectedShow, setSelectedShow] = useState(0)
    const [selectedDivision, setSelectedDivision] = useState(0)

    // this array contains all shows for the current circuit, and all shows option too
    const showOptions = [{ value: 0, label: 'All Shows'}, ...shows.map(s => ({ value: s.show_id, label: s.show_name}))]
  
    const [listOfDivisions, setListOfDivisions] = useState([])
    const [classDivision, setclassDivision] = useState([])
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState([]);
    const [checkAll, setCheckAll] = useState(false)
    const [classesRowData, setClassesRowData] = useState([]);

    const searchTerm = methods.watch('searchTerm');
    const [debounceTimer, setDebounceTimer] = useState(null);

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    useEffect(() => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        setDebounceTimer(setTimeout(() => {
            if (searchTerm !== '') {
                getClassRecords();
            }
        }, 2000))

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchTerm]);

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
        };
      }, []);

    const handleCDCSelectedClasses = (selected_row, handle_all ) => {
        let updated_row
        const updatedRowData = rowData.map((row) => {
            if (row.class_id === selected_row.class_id) {
              if(!row.selected){
                    row.selected = true
                    let cdc_selected_class = selected_row
                    cdc_selected_class.class_weight = 100
                    const updatedClassesRowData = classesRowData && classesRowData.length>0?[...classesRowData]:[];
                    updatedClassesRowData.unshift(cdc_selected_class);
                    // in case for all rows, in loop the state array cannot updated properly so that's why using updated row variable to store information 
                    updated_row = cdc_selected_class
                    setClassesRowData(updatedClassesRowData);
                    methods.setValue('circuit_division_classes',updatedClassesRowData)

                    // removing from deleted classes if recently deleted
                    if (methods.getValues('delete_circuit_division_classes')){
                        let deleteCDC = methods.getValues('delete_circuit_division_classes')
                        // const found = deleteCDC?.find(item => (item.class_id === row.class_id)); // check if itw as already in delete array
                        deleteCDC = deleteCDC.filter(item => item.class_id !== row.class_id);
                        methods.setValue('delete_circuit_division_classes', deleteCDC, {shouldDirty: true})

                    }
                
                    return {...row, selected:true}
              } else {
                    // check if this row was classesRowData (old circuit division class from database)
                    let deleteCDC = []
                    if (methods.getValues('delete_circuit_division_classes')){
                        deleteCDC = methods.getValues('delete_circuit_division_classes')
                    }
                    const found = classesRowData?.find(item => (item.class_id === row.class_id && !item.selected)); // check if itw as already in delete array
                    if(found){
                        deleteCDC.push (row)
                        methods.setValue('delete_circuit_division_classes', deleteCDC, {shouldDirty: true})
                    }

                    // remove this from classes row data
                    const updatedClassesRowData = classesRowData.filter(item => item.class_id !== selected_row.class_id);
                    methods.setValue('circuit_division_classes',updatedClassesRowData)
                    setClassesRowData(updatedClassesRowData);
                    return {...row, selected:false}
              }
            }
            return row;
        });
        if (handle_all){
            return updated_row 
        } 

        setRowData(updatedRowData);
       
    }
    
    const classesColumnDefs = [
        { field: 'name', flex:3,  headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.CLASSNAME' }) },
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.NUMBER' }), cellStyle: {textAlign: 'center'},},
        { field: 'class_weight',width:120, headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.WEIGHTAGE' }), cellStyle: {textAlign: 'center'},},
        { field: 'usef_section_code', headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.USEFSECTION' }), cellStyle: {textAlign: 'center'},},
        { field: 'division_name', flex:3,  headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.DIVISION' })},
        { field: 'type', flex:1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.TYPE' })},
        { field: 'show_name',  flex:2, headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.SHOW' }) },
        { field: 'start_date', width:120,headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.STARTDATE' }), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: {textAlign: 'center'},  },
        { field: 'end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.ENDDATE' }), filter: 'agDateColumnFilter', cellRenderer: DateRenderer, cellStyle: {textAlign: 'center'},  },
    ]   

    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);

    useEffect(()=>{
        // passing zero means, select division for the current shows of this circuit division
        if (methods.getValues('circuit.circuit_id') > 0){
            getDivisionsForSelectedShow(0)
        }
    },[methods.getValues('circuit.circuit_id')])

    useEffect(()=>{
        if(methods.getValues('circuit_division_classes')){
            const classes_data = methods.getValues('circuit_division_classes')
            setClassesRowData(classes_data)
        }
    },[methods.getValues('circuit_division_classes')])

    const getDivisionsForSelectedShow = async (show_id) => {     
        try {
            loadingOverlay({ show: true })
            const response = await axios.post( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/getShowDivisions', {
                show_ids: show_id ? [show_id] : showOptions.map(s => s.value), // send all show_ids if All Shows is selected.
                customer_id: customer_id,
            })

            const divisions = response.data.map(d => ({...d, label: `${d.name} -> ${d.Show.show_name}`, value: d.division_id }))
            setListOfDivisions (divisions)
            setRowData([])    
            setSelectedDivision(0) 
            setCheckAll(false)  
        } catch (reason) {}
        finally { loadingOverlay({ show: false }) }
    }

    const getClassRecords = (division_id) => {
        const searchTerm = methods.watch('searchTerm')
        const div_id = division_id?division_id: selectedDivision;
        if(div_id > 0 || searchTerm != ''){

            loadingOverlay({show: true})
            axios.get( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/classes', {
                    params: {
                        division_id: division_id?division_id: selectedDivision,
                        customer_id: customer_id,
                        show_id: selectedShow,
                        searchTerm: searchTerm,
                        circuit_id: methods.getValues('circuit.circuit_id')
                    }
            }).then ((response) =>{
                setCheckAll(false)
                setclassDivision (response.data)
                // add new field as selected in response.data and intially mark it as false for rows before setting it into row data
                const data = response.data.map(item => ({ ...item, selected: false }));
                // mark rowData'selected = true or false if they exist in classes row data or not
                let updatedRowData = data;
                if(classesRowData && classesRowData.length > 0){
                    updatedRowData = data?.map(item => ({
                        ...item,
                        selected: classesRowData?.some(obj => obj.class_id === item.class_id),
                    }));
                }

                setRowData(updatedRowData)
                methods.setValue('searchTerm', '')
                // Hide loading overlay
                loadingOverlay({show: false})
            }).catch((e) =>{
            })
        }
    }

    function deleteSelectedClassDivision () {
        if (gridRef){
            let selectedrow= gridRef.getSelectedRows()
            // remove this from circuit_division_classes, classrowData and add into delete_circuit_division_classes array (if this was old)
            const updated_classrowData = classesRowData.filter(item => item.class_id !== selectedrow[0].class_id);
            setClassesRowData (updated_classrowData)
            methods.setValue('circuit_division_classes', updated_classrowData)
            if (selectedrow[0].selected === undefined){
                let deleteCDC = []
                if (methods.getValues('delete_circuit_division_classes')){
                    deleteCDC = methods.getValues('delete_circuit_division_classes')
                }
                const found = deleteCDC?.find(item => item.class_id === selectedrow[0].class_id); // check if itw as already in delete array
                if(!found){
                    deleteCDC.push (selectedrow[0])
                    methods.setValue('delete_circuit_division_classes', deleteCDC, {shouldDirty: true})
                }
            }
            handleCDCSelectedClasses( selectedrow[0], false)
        }
    }

    return (
        <>
            <div className='form-group'>

                { showAddClassesModal && <AddClassesModal
                    show={showAddClassesModal}
                    handleClose={() => setShowAddClassesModal(false)}
                    // Passing data to be used in modal
                    listOfDivisions={listOfDivisions}
                    classDivision={classDivision}
                    showOptions={showOptions}
                    selectedShow={selectedShow}
                    selectedDivision={selectedDivision}
                    handleCDCSelectedClasses={handleCDCSelectedClasses}
                    setClassesRowData={setClassesRowData}
                    rowData={rowData}
                    setSelectedDivision={setSelectedDivision}
                    getClassRecords={getClassRecords}
                    setSelectedShow={setSelectedShow}
                    getDivisionsForSelectedShow={getDivisionsForSelectedShow}
                    setRowData={setRowData}
                    classesRowData={classesRowData}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                /> }
             
                <div className="d-flex justify-content-end align-items-center"> {/* WA - Row Selector */}
                    <div className='row me-6'>
                        <label className='col col-form-label fs-5 py-1 text-end'>Total: {methods.getValues('circuit_division_classes').length}</label>
                    </div>
                    <div>
                        <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Circuit Divisions - Classes 2"}/>
                        <label className='col-lg-1 w-50px'></label>
                    </div>
                </div>                
                <div className='row mb-2'>
                    <div className='col'>
                        <OutputListingGridStatic 
                            // area ={"Circuit Divisions - Classes 2"} 
                            columnDefs={classesColumnDefs} 
                            rowData={classesRowData} 
                            defaultColDef={defaultColDef}
                            setGridRef={setGridRef} 
                            containerStyle={containerStyle}
                            visibleRows={visibleRows} /* WA - Row Selector */
                        ></OutputListingGridStatic>
                    </div>
                    <div className='col-lg-1 w-50px ps-0'>
                        <button
                            type='button'
                            className='btn btn-sm btn-secondary fw-bold px-2 py-2 mb-2'
                            onClick={() => setShowAddClassesModal(true)}
                        >
                            <i className='fas fa-plus fs-5 px-1 py-3'></i>
                        </button>
                        <button type='button' className='btn btn-sm btn-secondary fw-bold px-2 py-2' onClick={(e)=> { deleteSelectedClassDivision();e.preventDefault()}} data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.CLASSES.BUTTON.REMOVE">
                            <i className='fas fa-minus fs-5 px-1 py-3'></i>
                        </button>
                    </div>
                </div>
                
            </div>
        </>
    );
}

export { ClassesTab }