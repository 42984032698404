import { useEffect, useMemo, useState } from "react"
import { getNarrowHeaderHeight, getNarrowRowHeight, defaultColDef, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers"
import DateRenderer from "../../../../modules/output-listing/renderers/DateRenderer"
import { AgGridReact } from "ag-grid-react"
import { useIntl } from "react-intl"
import { useFormContext } from "react-hook-form"
import axios from "axios"
import { useAlert, useLoadingOverlay } from "../../../../modules/sgl-utils/DialogsProvider"
import { useAppSelector } from "../../../../redux/hooks"
import DecimalPointRenderer from "../../../../modules/output-listing/renderers/DecimalPointRenderer"
import useAccessChecker from "../../../../modules/hooks/use-access-checker"
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters"
import { RowSelector, DEFAULT_VISIBLE_ROWS } from "../../../../modules/output-listing/OutputListingRowOptions"
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CircuitPointsGrid = ({ setCircuitPointsGridRef, loadCircuitPointsOfSelectedStandings, rowData }) => {
    const intl = useIntl()
    const methods = useFormContext()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const currencyFormatter = useCurrencyFormatter()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const [hideRiderName, setHideRider] = useState(true)
    const [hideOwnerName, setHideOwner] = useState(true)
    const [hideTrainerName, setHideTrainer] = useState(true)
    const [hideHorseName, setHideHorseName] = useState(true)
    const { hasAreaWritePermission } = useAccessChecker()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []); 
    const containerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
    const rowHeight = getNarrowRowHeight;
    const headerHeight = getNarrowHeaderHeight;
    /* WA - Row Selector */

    async function handleHideStandingPointsChange(params, excludePoints){
        const circuitPoints = params.node.data
        try {
            loadingOverlay({ show: true, message: 'Updating Circuit Points..' })

            const response = await axios.patch(`${NEST_API_URL}/circuit-divisions/excludeCircuitPoints`, {
                customer_id: customerId,
                circuit_points: circuitPoints,
                exclude_points: excludePoints
            })

            await loadCircuitPointsOfSelectedStandings()
            if (response.data.message) {
                methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
            } 
        } catch (reason) {
            // revert checkbox to original value in case of error.
            params.node.setDataValue('exclude_points', !excludePoints)

            alertDialog({ message: reason?.response?.data.error ? reason.response.data.error : reason })
        } finally{
            loadingOverlay({ show: false, message: 'Updating Circuit Points..' })
        } 
    }

    function showCircuitpointsData(){
        if( methods.getValues('circuit_division.standingsselectionmethod') === 'Horse'){
            // show horse name, rider name and owner name in this case
            setHideHorseName(false)
            setHideRider(false)
            setHideOwner(false)
            setHideTrainer(true)
        } else if( methods.getValues('circuit_division.standingsselectionmethod') === 'Owner'){
            // show horse name, rider name and trainer name in this case
            setHideHorseName(false)
            setHideTrainer(false)
            setHideOwner(true)
            setHideRider(false)
        } else if( methods.getValues('circuit_division.standingsselectionmethod') === 'Trainer'){
            // show horse name, rider name and owner name in this case
            setHideHorseName(false)
            setHideOwner(false)
            setHideRider(false)
            setHideTrainer(true)
        } else if( methods.getValues('circuit_division.standingsselectionmethod') === 'Rider'){
            // show horse name, trainer name and owner name in this case
            setHideHorseName(false)
            setHideOwner(false)
            setHideTrainer(false)
            setHideRider(true)
        } else if( methods.getValues('circuit_division.standingsselectionmethod') === 'Horse/Rider'){
            // dont show horse name and show only following:  trainer name and owner name in this case
            setHideHorseName(true)
            setHideRider(true)
            setHideOwner(false)
            setHideTrainer(false)
        }
    }

    useEffect(()=>{
        // first time page renders
        showCircuitpointsData()
    },[])


    const columnDefs = [
        { 
            field: 'exclude_points', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.EXCLUDED' }), 
            cellRenderer: params => renderCheckBox(params, (checked) => handleHideStandingPointsChange(params, checked)), 
            width: 110,
            hide: !hasAreaWritePermission('circuits')
        },
        { 
            field: 'original_points_earned', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.POINTSEARNED' }),
            width: 100,
            cellRenderer: params => DecimalPointRenderer(params),
            sortable: true
        },
        { 
            field: 'calculated_points',
            width: 120,  
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.CALCULATEDPOINTS' }),
            cellRenderer: params => DecimalPointRenderer(params) ,
            sortable: true
        },
        { 
            field: 'original_prize_money', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.PRIZEMONEY' }),
            width: 100,
            cellRenderer: params => currencyFormatter(params.value),
            sortable: true
        },
        { 
            field: 'calculated_prize_money',
            width: 150,
            sortable: true,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.CALCULATEDPRIZEMONEY' }),
            cellRenderer: params => currencyFormatter(params.value) 
        },
        { 
            field: 'CR', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.CR' }),
            width: 80,
            sortable: true
        },
        { 
            field: 'class_number', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.CLASSNUMBER' }),
            width: 100 ,
            sortable: true
        },
        { 
            field: 'class_name',
            width: 250,  
            sortable: true,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.CLASSNAME' })
        },
        { 
            field: 'placing', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.PLACING' }), 
            width: 100,
            sortable: true
        },
        {
            field: 'show_name',
            width: 250,   
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.SHOWNAME' }) ,
            sortable: true
        },
        { 
            field: 'start_date', 
            width: 120,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.DATE' }), 
            filter: 'agDateColumnFilter', 
            cellRenderer: DateRenderer,
            sortable: true
        },
        { 
            field: 'entry_number', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.ENTRYNUMBER' }),
            width: 100,
            sortable: true
        },
        { 
            field: 'horse_name',
            hide: hideHorseName, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.HORSENAME' }),
            width: 150,
            sortable: true
        },
        { 
            field: 'horse_rider_status', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.HORSERIDERSTATUS' }),
            width: 120,
            sortable: true
        },
        { 
            field: 'rider',
            hide:hideRiderName, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.RIDER' }),
            width: 150,
            sortable: true
        },
        { 
            field: 'owner', 
            hide:hideOwnerName,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.OWNER' }),
            width: 150,
            sortable: true
        },
        { 
            field: 'trainer',
            hide: hideTrainerName, 
            width: 150,
            sortable: true,
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.TRAINER' })
        },
    ]

    /* WA - Row Selector */
    const effectiveContainerStyle = useMemo(() => {
        // Get actual number of rows to display (min of visibleRows and actual data length)
        const actualVisibleRows = rowData?.length > 0 
        ? Math.min(visibleRows, rowData?.length)
        : visibleRows;
    
        // Calculate the computed height based on actual visible rows
        const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
        
        // If container style exists and visible rows is greater than 0, compare heights
        if (visibleRows > 0) {
        // Parse the container style height (removing 'px' if present)
        const containerHeight = parseInt(containerStyle?.height);

        // If row data is empty, we return the existing container height
        if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
        }
        
        // If container height is greater than computed height, use container height
        if (containerHeight > computedHeight) {
            return containerStyle;
        }
        } else if (containerStyle) {
        return containerStyle;
        }
        
        // Default to computed height based on visible rows
        return {
        width: '100%',
        height: `${computedHeight}px`
        };
    }, [containerStyle, visibleRows, rowData?.length]);
    /* WA - Row Selector */

    return (
        <div className='col-lg d-flex align-items-center mb-2'> {/* WA - Row Selector */}
            <div style={{width: '100%'}}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Circuit Divisions - CircuitPointsGrid"}/>
                </div>
                <div style={effectiveContainerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        <AgGridReact 
                            columnDefs={columnDefs} 
                            defaultColDef={{
                                ...defaultColDef,
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                                resizable: true,
                                cellClass: 'text-center'
                            }}
                            onGridReady={params => setCircuitPointsGridRef(params.api)}
                            rowData={rowData} 
                            // containerStyle={containerStyle}
                            rowHeight={getNarrowRowHeight}
                            headerHeight={getNarrowHeaderHeight}
                        />
                    </div>
                </div>                
            </div> {/* WA - Row Selector */}
        </div>
    )
}

export default CircuitPointsGrid