import { useEffect, useMemo, useState} from 'react'
import { useFormContext } from "react-hook-form";
import { AgGridReact } from 'ag-grid-react';
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import DecimalPointRenderer from '../../../../modules/output-listing/renderers/DecimalPointRenderer';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../../modules/output-listing/OutputListingRowOptions';

const DEFAULT_COLUMN_DEF = [
    { 
        field: 'horse',
        flex: 1, 
        maxWidth: 250,
        cellClass: 'text-left'
    },
    { 
        field: 'number', 
        headerName: 'Entry',
        width: 100
    },
    {
        field: 'overFencePoints',
        headerName: 'O/F Sub',
        width: 100,
        cellRenderer: params => DecimalPointRenderer(params, 1, false)
    },
    {
        field: 'total',
        cellRenderer: params => DecimalPointRenderer(params, 1, false),
        flex: 1,
        maxWidth: 160
    }
]

const PointSummaryTab =() => {
    const methods = useFormContext();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), [])
    const [gridRef, setGridRef] = useState(null)
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [columnDefs, setColumnDefs] = useState(DEFAULT_COLUMN_DEF)

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const rowHeight = getNarrowRowHeight;
    const headerHeight = getNarrowHeaderHeight;
    const [rowData, setRowData] = useState([]);

    const effectiveContainerStyle = useMemo(() => {
        // Get actual number of rows to display (min of visibleRows and actual data length)
        const actualVisibleRows = rowData?.length > 0 
        ? Math.min(visibleRows, rowData?.length)
        : visibleRows;
    
        // Calculate the computed height based on actual visible rows
        const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
        
        // If container style exists and visible rows is greater than 0, compare heights
        if (visibleRows > 0) {
        // Parse the container style height (removing 'px' if present)
        const containerHeight = parseInt(containerStyle?.height);

        // If row data is empty, we return the existing container height
        if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
        }
        
        // If container height is greater than computed height, use container height
        if (containerHeight > computedHeight) {
            return containerStyle;
        }
        } else if (containerStyle) {
        return containerStyle;
        }
        
        // Default to computed height based on visible rows
        return {
        width: '100%',
        height: `${computedHeight}px`
        };
    }, [containerStyle, visibleRows, rowData?.length]);
    /* WA - Row Selector */
  
    useEffect(() => {
        const pointSummary = methods.getValues('pointSummary')
        const pointSummaryClasses = methods.getValues('pointSummaryClasses')
        if (gridRef){
            if (pointSummaryClasses && pointSummaryClasses.length > 0) {
                const newColumnDefs = [DEFAULT_COLUMN_DEF[0], DEFAULT_COLUMN_DEF[1]]
                for (const key of pointSummaryClasses) {
                    if (!isNaN(key)) {
                        newColumnDefs.push({
                            field: key,
                            headerName: key,
                            width: 100,
                            cellRenderer: params => DecimalPointRenderer(params, 1, false)
                        })
                    }
                }
                newColumnDefs.push(DEFAULT_COLUMN_DEF[2])

                for (const key of pointSummaryClasses) {
                    if (key.includes('-US')) {
                        newColumnDefs.push({
                            field: key,
                            headerName: key.split('-')[0],
                            width: 100,
                            cellRenderer: params => DecimalPointRenderer(params, 1, false)
                        })
                    }
                }

                newColumnDefs.push(DEFAULT_COLUMN_DEF[3])

                setColumnDefs(newColumnDefs)
            }
        
            if(pointSummary){
                setRowData(pointSummary ?? []) // WA - Row Selector
                gridRef.setRowData(pointSummary)
            }
        }
    }, [gridRef, methods.watch('pointSummary')])

    return (
        <div className='form-group'>
            <div className='row mb-2 pe-5'>
                <div className='col-lg d-flex align-items-center mb-2'> {/* WA - Row Selector */}
                    <div style={{width: '100%'}}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Divisions - PointSummary"}/>
                        </div>
                        <div style={effectiveContainerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact 
                                    rowData={rowData}
                                    columnDefs={columnDefs} 
                                    // containerStyle={containerStyle}
                                    defaultColDef={{ ...defaultColDef, sortable: true, cellClass: 'text-center' }}
                                    onGridReady={params => setGridRef(params.api)}
                                    rowHeight={getNarrowRowHeight}
                                    headerHeight={getNarrowHeaderHeight}
                                    visibleRows={visibleRows}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export { PointSummaryTab }