import { useIntl } from 'react-intl'
import { GeneralTab } from './Detail/GeneralTab'
import { EntriesTab } from './Detail/EntriesTab'
import { PrizesAndResultsTab } from './Detail/PrizesAndResultsTab'
import { JudgesAndScheduleTab } from './Detail/JudgesAndScheduleTab'
import { ClassRulesTab } from './Detail/ClassRuleTab'
import { HistoryTab } from '../../modules/components/HistoryTab'
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useEffect, useState, useCallback, useRef } from 'react'
import Select from "react-select";
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import axios from 'axios'
import { useAlert, useConfirm, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider'
import { NumericFormat } from 'react-number-format'
import { setNewShowId } from '../../redux/reducers/newShowIdReducer'
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import { PageTitle } from '../../../_metronic/layout/core'
import TeamsSetupDialog from './Detail/TeamsSetupModal'
import {v1 as uuid} from "uuid"; 
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import useAccessChecker from '../../modules/hooks/use-access-checker'
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import HelpLink from '../../modules/components/HelpLink'
import { setPreviousClassDetails } from '../../redux/reducers/previousClassDetailsReducer'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const { PUBLIC_URL } = process.env

export const requirementsHook = (
    key,
    dirtyFields,
    allValues,
  ) => {
    if(key == 'class_requirements') {
      let dirty_class_requirements = dirtyValues(dirtyFields, allValues)
      if (Object.keys(dirty_class_requirements).length > 0) {
        return allValues
      } else {
        return dirty_class_requirements
      }
    } else {
      return dirtyValues(dirtyFields, allValues)
    }
  }

export const dirtyValues = (
    dirtyFields,
    allValues
) => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // Here, we have an object.
    // return Object.fromEntries(
    //     Object.keys(dirtyFields).map((key) => [
    //     key,
    //     dirtyValues(dirtyFields[key], allValues[key])
    //     ])
    // );

    // Here, we have an object.
    return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
        key,
        requirementsHook(key,dirtyFields[key], allValues[key])
        ])
    );
}

const ClassDetail = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const class_id = useParams().class_id;
    const [current_class_id, setCurrentClassId] = useState(props.id || props.id == 0 ? props.id : class_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const [form_meta, setFormMeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("GENERAL");
    const alertDialog = useAlert();
    const confirmDialog = useConfirm();
    const { handleDetailPageCatch, hasAreaWritePermission } = useAccessChecker()
    const [setUpTeamButton, setSetUpTeamButton] = useState(false);
    const dispatch = useAppDispatch();
    const loadingOverlay = useLoadingOverlay();
    const [rowClicked, setRowClicked] = useState("")
    const [record_id, setRecordId] = useState("")
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [open_forms, setOpenForms] = useState([])
    const [prevData, setPrevData] = useState({});
    const [gridRowData, setGridRowData] = useState('')
    const [syncEvent, setSyncEvent] = useState(null);
    const [disableClassName, setDisableClassName] = useState(false);
    const [disableDivision, setDisableDivision] = useState(false);
    const [lock, setLock] = useState(false)
    const [showDetailPageModal, setShowDetailPageModal] = useState(false)
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const stopMultiUserUpdateForSelf = useRef(false)
    const formRef = useRef()
    let previousClassDetails = useAppSelector(state => state.previousClassDetails);

    useEffect(() => {
        if(current_class_id > 0){
            setLock(true)
        }
    }, [])
    
    const getDetailPageInfo = (id, area, component, prevData=null, data) => {
        setShowDetailPageModal(false)
        setRecordId(id)
        setSubArea(area)
        setChildComponent(component)
        if(area == 'Entries' && prevData){
            prevData = getTripForEntries(prevData)
        }
        setPrevData(prevData)
        sessionStorage.setItem("dialog_is_open_in_class", true)
        setRowClicked(rowClicked => !rowClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
		setOpenForms(forms_open)
        setGridRowData(data)
    };

    function getTripForEntries(prevData) {
        let row_data = prevData.updated_trip
        let classs = methods.getValues('classes')
        row_data.Class = {
            class_id: classs.class_id,
            name: classs.name,
            class_price: classs.class_price,
            division_id: classs.division_id,
            number: classs.number,
            required: classs.required,
            scheduled_date: classs.scheduled_date,
            sgl_id: classs.sgl_id,
            combined_class: classs.combined_class
        }
        return {"updated_trip": row_data}
    } 
    
    const [modalShow, setModalShow] = useState(false); // Teams Setup Modal

    const methods = useForm({
        defaultValues: {
            "classes": {
                        "sync_id": "",
                        "class_id": 0,
                        "name": "",
                        "description": "",
                        "class_price": 0,
                        "division_id": 0,
                        "show_id": 0,
                        "add_back_per_entry": 0,
                        "number": 0,
                        "required": 0,
                        "schedule_warmupminutes": 0,
                        "schedule_sequencetype": "",
                        "schedule_turntime_minutes": 0,
                        "schedule_orderofgo": "",
                        "schedule_tripsperentry": 0,
                        "schedule_rotateingroupingsof": 0,
                        "schedule_daynumber": 0,
                        "schedule_ringnumber": 0,
                        "schedule_starttime": "00:00:00",
                        "schedule_endtime": "00:00:00",
                        "class_type": "",
                        "lock_prize_money": 0,
                        "entry_count": 0,
                        "entry_list": "",
                        "usef_section_code": "",
                        "level": "",
                        "scheduled_date": "0000-00-00",
                        "addbackentries": 0,
                        "no_adds_days_before": 0,
                        "no_adds_time_of_day": "00:00:00",
                        "no_drops_days_before": 0,
                        "no_drops_time_of_day": "00:00:00",
                        "actual_start_time": "00:00:00",
                        "actual_end_time": "00:00:00",
                        "previous_class_in_group": 0,
                        "warmup_class": 0,
                        "estimated_start_time": "00:00:00",
                        "allatonce": 0,
                        "class_sequence": 0,
                        "ring_id": 0,
                        "max_size": 0,
                        "order_of_go_set": 0,
                        "order_set_date": "0000-00-00",
                        "order_set_time": "00:00:00",
                        "sponsor": "",
                        "verification_detail": "",
                        "creation_date": "0000-00-00",
                        "modified_date": "0000-00-00",
                        "creation_time": "00:00:00",
                        "modified_time": "00:00:00",
                        "creation_user": "",
                        "modified_user": "",
                        "no_results": 0,
                        "customer_id": 0,
                        "sgl_id": 0,
                        "results_verified": 0,
                        "unit": "",
                        "ec_section_code": "",
                        "ec_classtypeid": 0,
                        "ec_sanctionlevelid": 0,
                        "cancelled": 0,
                        "rider_required_org_membership": "",
                        "rider_membership_status": 0,
                        "pyramid_show_id": 0,
                        "pyramid_class_id": 0,
                        "results_updated_at": null,
                        "prize_template": "",
                        "jumper_table": "",
                        "hunter_type": "",
                        "web_adddrops_disabled": 0,
                        "classic_class": 0,
                        "class_uuid": "",
                        "teambasedon": "",
                        "dressage_test_id": 0,
                        "combined_class": 0,
                        "combined_class_id": 0,
                        "suppress_web_results": 0,
                        "fei_event_type_code": "",
                        "fei_competition_code": "",
                        "fei_event_code": "",
                        "fei_competition_rule": "",
                        "fei_competition_prize_money": 0,
                        "fei_prize_money_currency": "",
                        "fei_competition_round": "",
                        "ushja_qualifier": 0,
                        "ushja_qualifier_type": "",
                        "ushja_qualifier_classno": 0,
                        "ushja_section_code": "",
                        "addbackentries": 0,
                        "add_back_per_entry": 0,
            },
            "entryxclasses": [],
            "class_entries_drop": [],
            "class_prizes": [],
            "class_judges": [],
            "class_groups": [],
            "class_rules": [],
            "class_requirements": {},
            "divisionPrice": null,
            "checkprinted": 0,
            "prizeMoneyApplied": 0,
            "unAppliedPrizeMoney": 0,
            "show_tables": {},
            "show_add_backs": 0,
            "places_paid": 0,
            "added_team_trips": [],
            "deleted_team_trips": [],
            "fei_temp_log_msg": '',
            "updatedTripFaultTime": [],
            "updated_trips_heights": []
            },
            mode:'onBlur',
            reValidateMode: 'onBlur'
    })

    const { register, unregister, handleSubmit, watch, reset, setFocus, formState, getValues, setValue } = methods
    const { dirtyFields} = formState
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const show_financials_locked = watch('show_tables.islocked')

    useEffect(() => {
        // Cleanup on unmount
        return () => unregister(); // This will unregister all fields
    }, [unregister]);

    useEffect(() => {
        const classId = methods.getValues('classes.class_id') > 0 ? methods.getValues('classes.class_id'):  class_id

        setCurrentClassId(classId?classId:0) // Maintaining current class id using state
    }, [methods.watch('classes.class_id')]);

    useEffect(() => {
        let classId = class_id > 0 ? class_id : methods.getValues('classes.class_id')

        setCurrentClassId(classId?classId:0)
    }, [class_id]);

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    const onSubmit = async(form_data) => {

        loadingOverlay({ show: true })

        let submit_data = dirtyValues(dirtyFields, form_data)
        
        if(current_class_id == 0){ // For new class we populate class number from backend
            if(!submit_data?.classes){// if no field is change in class detail page classes object would not exist
                submit_data.classes = {} //Create new classes object
            }
            // Sending classes number explicitly to backend because we populate class number for new class from backend 
            // and if user does not change it. It will not be set dirty and won't be send to backend
            submit_data.classes.number = getValues('classes.number')

            const cachedClassInfo = [
                "name",
                "description",
                "sponsor",
                "ec_section_code",
                "usef_section_code",
                "class_type",
                "level",
                "unit",
                "class_price",
                "division_id",
                "required",
                "schedule_sequencetype"
            ];

            cachedClassInfo.forEach((field) => {
                submit_data.classes[field] = getValues(`classes.${field}`) || "";
            })
        }

        // Filter trips and send only if trip had some flags set.
        // DON'T maintain duplicate states if you want to changes trips in future. Update entryxclasses react-hook-form state variable and add you flags. DON'T update entire trip object on backend, only update required keys.
        const entryxclasses = methods.getValues("entryxclasses")
        const filteredEntryxclasses = []
        for (let exc of entryxclasses) {
            if (exc.isUpdatedByRoster || exc.isVerified || exc.isScratched || exc.isAdded) {
                filteredEntryxclasses.push(exc)
            }
        }
        submit_data.entryxclasses = filteredEntryxclasses

        const classRules = methods.getValues("class_rules").filter(cr => cr.isAdded || cr.isDeleted)
        submit_data.classRules = classRules

        return axios.post( process.env.REACT_APP_NEST_API_URL + '/classes/detail', {
            params: {
                class_id : current_class_id,
                customer_id: customer_id,
                show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID,
                class: submit_data,
                division_id: props.parent_id ? Number(props.parent_id) : (methods.getValues('classes.division_id') ?? 0)
            }
        }).then((response) => {
            loadingOverlay({show: false})
            
            if(response.data.success){
                if (response.data?.applyOrRollbackErrors?.length > 0) {
                    alertDialog({ message: response.data.applyOrRollbackErrors.join('<br/>') })
                }

                let useClassId = null
                if (methods.getValues('SubmitCallArea') === 'class-prize-money-action') {
                    const classId = response.data.saved_new_class.class_id
                    if (class_id == 0) { // Update URL for new class
                        window.history.replaceState({}, '', `${classId}`)
                        useClassId = classId
                    }
                }

                warningsOnSave(useClassId)
            }
            else{
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
        })
    }

    const [update_data, setUpdateData] = useState(0)
    // prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

    // Drops trip in Entries Tab & Verify Roster
    const dropTrip = async (trip, confirmDivisionDropChoice = "", classesNotDroppable=[], divisionClassesToScratch=[], divisionClassesToDrop=[], addResponse={}, invalidClasses=[]) => {
        let _errorMessage = ""

        // if (trip.gone_in || trip.trip_verified || trip.placing > 0) {
        //     _errorMessage = "Completed entry cannot be dropped."
        // } 
        // else
         if(trip.trip_verified){ //Checked for verified trips
            _errorMessage = (intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYVERIFIED"}))
        }
        // else if(trip.gone_in){ //check for gone trips
        //     _errorMessage = (intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYGONE"}))
        // }
        else if(trip.placing > 0){ //check for placed trips
            _errorMessage = (intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYPLACED"}))
        }
        else { 
            let userChoice = true
            if(trip.gone_in){ //check for gone trips
                userChoice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.ALLCLASSES.DROPTRIPS.ALREADYGONE"})})
            }
            if(userChoice){
            
            let classNumbersToAdd = ""
            let classNumbersToDrop = methods.getValues("classes.number").toString()
            const classData = {
                class_rules: methods.getValues("class_rules"),
                trips: methods.getValues("entryxclasses"),
                classes: methods.getValues("classes")
            }

            loadingOverlay({ show: true })
            const response = await axios.post(NEST_API_URL + "/class-add-drop/rapidAddDropClassByNumbers", {
                params: {
                    entry_number: trip.Entry.number,
                    rider_id: trip.rider_id,
                    rider_class_verified: false,
                    show_id: currentShowID,
                    customer_id,
                    classes_to_add: classNumbersToAdd, 
                    classes_to_drop: classNumbersToDrop,
                    confirm_division_drop_choice: confirmDivisionDropChoice, 
                    classes_not_droppable: classesNotDroppable, 
                    division_classes_to_scratch: divisionClassesToScratch, 
                    division_classes_to_drop: divisionClassesToDrop,
                    add_response: addResponse,
                    invalid_classes: invalidClasses,
                    update_to_db: false, // transactional mode
                    class_data: classData
                }
            })
            loadingOverlay({ show: false })

            if(response?.data?.success == false && response.data.error_message && response.data.error_message != ''){
                _errorMessage += response.data.error_message

            }  else if (response?.data?.status === "requiresDropConfirmation") {
                let userChoice = await confirmDialog({message: response.data.message, icon: "comment"})
                if(userChoice){
                    return await dropTrip(trip, 'Drop', response.data.classesNotDroppable, response.data.divisionClassesToScratch, response.data.divisionClassesToDrop, response.data.addResponse, response.data.invalidClasses)
                } else {
                    return { 
                        error_message: '',
                        dropped_trips: []
                    }
                }
            }  else if (response?.data?.status === "requiresScratchConfirmation") {
                return { 
                    error_message: 'The selected trip is part of a required by division class that has already started and cannot be dropped. You may scratch the trip.',
                    dropped_trips: []
                }

            }
            // Trip was not deleted
            else if (response.data.deleted_class_numbers.length === 0) {
                _errorMessage += (response.data.rule_failed_messages.join(" ")) // Append rules failed message
                _errorMessage += (response.data.fee_error.join(" ")) // Append fee error message

            }

            return {
                error_message: _errorMessage,
                dropped_trips: response?.data?.deleted_trip_ids??[]
            }
            }
        }
        
        return {
            error_message: _errorMessage,
            dropped_trips: []
        }
    }

    // Methods group starts here >>>
    // Add Entry to Class from [VerifyRoster] and [PrizesAndResultsTab]
    const addEntry = (setShowCombinedClassPopup, setActualClasses, setShowAddButton, setErrorMessage, currentEntry, selectedRider) => {
        const cl = methods.getValues("classes")
        if (cl.cancelled) {
            alertDialog({ message: "Entry cannot be added in Cancelled Class."})
            return
        }

        if (cl.combined_class_id > 0) {
            alertDialog({ message: "Entry cannot be added in Combined Class."})
            return
        }

        // handle class add for combined-to classes
        if (cl.combined_class) {
            if (methods.getValues("combined_classes").length > 0) { // Check if there are actual classes
                setShowCombinedClassPopup(true)
                setActualClasses({ [cl.number]: methods.getValues("combined_classes") })
            } else {
                alertDialog({ message: 'Combined class has no actual class.'})
                setErrorMessage('')
                setShowAddButton(false)
            }
        } else {
            combinedClassSelectHandler(0, setShowAddButton, setErrorMessage, currentEntry, selectedRider)
        }

    }

    const combinedClassSelectHandler = (actualClassNumber, setShowAddButton, setErrorMessage, currentEntry, selectedRider) => {
        loadingOverlay({show: true})

        let cl = methods.getValues("classes")

        let actualClass = null
        let actualClassId = 0
        let classNumber = cl.number

        if (actualClassNumber > 0) {
            actualClass = methods.getValues("combined_classes").find(c => c.number === actualClassNumber)
            classNumber = actualClass.number
            actualClassId = actualClass.class_id
        }

        // Transform list of class requirements to format that is stored in database
        const classRequirements = methods.getValues("class_requirements")
        const transformedClassRequirements = []
        for (let key in classRequirements) {
            if(classRequirements[key].value){
                switch(key){
                    case "Horse_Age":
                    case "Rider_Age":
                        let from = classRequirements[key].from? classRequirements[key].from:0;
                        let to = classRequirements[key].to? classRequirements[key].to:0;
                        classRequirements[key].value = from.toString().concat(":",to.toString())
                        
                        break;
                    case "Horse_Type":
                    case "Rider_Status":
                        classRequirements[key].value = classRequirements[key].value.toString()
                        break;
                }

                transformedClassRequirements.push({
                    value: classRequirements[key].value, type: key, error_warn_status: classRequirements[key].error_warn_status
                })
            }
        }
       
        const classData = {
            class_requirements_list: transformedClassRequirements,
            class_rules: methods.getValues("class_rules"),
            number: classNumber,
            trips: methods.getValues('entryxclasses'),
            classes: methods.getValues("classes")
        }

        axios.post(NEST_API_URL + "/class-add-drop/addEntryToClassViaRoster", {
            class_data: classData,
            customer_id,
            show_id: currentShowID,
            entry_id: currentEntry.entry_id,
            rider_id: selectedRider
        })
        .then(async response => {

        if(response?.data?.success){
            const { requirementsResponse, rulesResponse } = response.data

            if (requirementsResponse.cancel_classes_message.length > 0) {
                alertDialog({ message: requirementsResponse.cancel_classes_message.join('<br/>'), icon: 'stop' })
            }

            else if (requirementsResponse.warn_classes_message.length > 0) {
                alertDialog({ message: requirementsResponse.warn_classes_message.join('<br/>') })
            }

            if (rulesResponse) {
                if (!rulesResponse.class_rules_satisfied && rulesResponse.message.length > 0)  { // Check for rules failed
                    const choice = await confirmDialog({ 
                        message: "The following rules failed:" + "<br/>" + 
                        rulesResponse.message.join("<br/>") + "<br/>" +
                        "Add entry anyway?"
                    })
                    if (choice) {                        
                        addTrip(cl.class_id, { id: actualClassId, number: classNumber }, rulesResponse.entry_fees_list, setShowAddButton, setErrorMessage, currentEntry, selectedRider)
                    }
                } else {
                    addTrip(cl.class_id, { id: actualClassId, number: classNumber }, rulesResponse.entry_fees_list, setShowAddButton, setErrorMessage, currentEntry, selectedRider)
                }
            } else { // No rules response means requirement failed
                //entryNumberInputRef.current.value = ""
                setShowAddButton(false)
                setErrorMessage("")
            }
        }else{
            alertDialog({message: response.data.error, title: 'Error'})
        }
        })
        .finally(() => {
            loadingOverlay({show: false})
        });    }

    const addTrip = async (classId, actualClass, entryFeesList, setShowAddButton, setErrorMessage, currentEntry, selectedRider) => {
        const entryxclasses = methods.getValues('entryxclasses')

        const existingEntryFees = methods.getValues("entryFeesList")
        if (existingEntryFees) {
            methods.setValue("entryFeesList", [...existingEntryFees, ...entryFeesList])
        } else {
            methods.setValue("entryFeesList", entryFeesList, { shouldDirty: true})
        }

        const riderId = Number(selectedRider)
        // Determine what the latest end time is of the trips of this class. (start) 
        // Since this trip is being added after the fact we must mark it as gone with a gone time.  We'll do this by setting it to the end time fo the last entry that we know.
        entryxclasses.sort((a, b) => b.actual_go_time?.localeCompare(a.actual_go_time)) // sort the array by actual_go_time in desc order
        
        let lastTripTime = ""
        let lastTripGroupIndex = 0
        let lastGoneGroupId = 0
        let lastTripOrderOfGo = 0
        let lastTripActualOrder = 0
        if (entryxclasses.length > 0) { // Check if at least 1 trip exists.
            lastTripTime = entryxclasses[0].actual_go_time
            lastTripGroupIndex = entryxclasses[0].groupindex + 1
            lastGoneGroupId = entryxclasses[0].gone_in_group
            lastTripOrderOfGo = entryxclasses[0].order_of_go + 1
            lastTripActualOrder = entryxclasses[0].actual_order + 1
        }
        let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
            params: { customer_id: customer_id }
        })

        let date = response?.data?.date?response.data.date:''
        let time = response?.data?.time?response.data.time:''
        let date_time_stamp = date.replace(/-/g, '') + time.replace(/:/g, '')

        const trip = {
            // Set flag to create record at save
            isAdded: true,
            entryxclasses_id: uuid(),

            actual_go_time: lastTripTime,
            groupindex: lastTripGroupIndex,
            gone_in_group: -1,
            order_of_go: lastTripOrderOfGo,
            actual_order: lastTripActualOrder,
            trip_verified: 1,
            trip_verification_datetimestamp: date_time_stamp,
            gone_in: true,
            actual_class_id: actualClass.id,
            rider_id: riderId,
            rider_name: currentEntry.EntryRiders.find(er => er.rider_id === riderId).rider_name,
            class_id: classId,
            entry_id: currentEntry.entry_id, 
            number: Number(currentEntry.number),
            country_code: currentEntry.EntryRiders.find(er => er.rider_id === selectedRider)?.Rider?.country_code,
            
            Entry: {
                entry_id: currentEntry.entry_id,
                number: Number(currentEntry.number),
                trainer: currentEntry.trainer,
                owner: currentEntry.owner,
                horse: currentEntry.horse,
                status: currentEntry.status,
                PMR: currentEntry.PMR
            },

            EntryRider: {
                status: currentEntry.EntryRiders.find(er => er.rider_id === riderId).status
            }
        }

        if (actualClass.id > 0) {
            trip.ActualClass = { number: actualClass.number }
        }

        if (methods.getValues("show_tables.class_bandit_fee") > 0) {
            const choice = await confirmDialog({ message: "Charge the bandit fee to this entry?"})
            if (choice) {
                trip.chargeBanditFee = true
                trip.classBanditFee = methods.getValues("show_tables.class_bandit_fee")
            }
        }

        methods.setValue("entryxclasses", [...entryxclasses, trip])
        methods.setValue("individual_trips", [...methods.getValues('individual_trips'), trip])
        let entryCount = methods.getValues("classes.entry_count") ? methods.getValues("classes.entry_count") : 0
        methods.setValue("classes.entry_count", entryCount + 1,  { shouldDirty: true })
        methods.setValue("classes.results_verified", false, { shouldDirty: true })

        setShowAddButton(false)
        setErrorMessage("")
    }
    // Method group ends here <<<
    const [classStatus, setClassStatus] = useState('')
    useEffect(() => {
        let status = ""
        if(methods.getValues("classes.combined_class_id")>0){
            status = `Class is Combined into ${methods.getValues("combined_class_number")}`
        }
        else{
            if(activeTab == "GENERAL" && methods.getValues("classes.cancelled")){
                status = "Class has been cancelled"
            }
            else if(activeTab == "PRIZESANDRESULTS"){
                if(methods.getValues('prize_and_results')){
                    status = "Results cannot be modified. Checks have been printed and Prize Money applied for some prizes."
                }
                else if(methods.getValues('check_printed')){
                    status = `Results cannot be modified. Checks have already been printed for ${String(methods.getValues('checkprinted'))} prize(s).`
                }
                else if(methods.getValues('prize_money_applied')){
                    status = `Results cannot be modified. Prize Money has been applied for ${String(methods.getValues('prizeMoneyApplied'))} prize(s).`
                }
                else if(methods.getValues('unapplied_prize_money')){
                    status = `There are ${String(methods.getValues('unAppliedPrizeMoney'))} prize(s) with unapplied Prize Money.`
                }
            }
        }
        setClassStatus(status)
    }, [activeTab, methods.watch("classes.combined_class_id"), methods.watch('classes.cancelled'), methods.watch('prize_and_results'), methods.watch('check_printed'), methods.watch('prize_money_applied'), methods.watch('unapplied_prize_money'), methods.getValues('prizeMoneyApplied'), methods.getValues('unAppliedPrizeMoney')])

    const tabs = ["GENERAL", "ENTRIES", "PRIZESANDRESULTS", "JUDGESANDSCHEDULE", "CLASSRULES", "HISTORY"]
    const componentTags = [
        <GeneralTab form_meta={form_meta} callbackFromParent={getDetailPageInfo}/>, 
        <EntriesTab callbackFromParent={getDetailPageInfo} dropTrip={dropTrip} />, 
        <PrizesAndResultsTab 
            form_meta={form_meta} 
            id={props.id} 
            refresh_page={updateData} 
            dropTrip={dropTrip} 
            addEntry={addEntry} 
            combinedClassSelectHandler={combinedClassSelectHandler}
            classStatus={classStatus} 
            formRef={formRef}
            stopMultiUserUpdateForSelf={stopMultiUserUpdateForSelf}
        />, 
        <JudgesAndScheduleTab form_meta={form_meta} />, 
        <ClassRulesTab form_meta={form_meta}/>, 
        <HistoryTab area={"Classes"} rowData={methods.getValues('change_history_logs')}/>
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    // To open same detail page multiple times 
    useEffect(() => {
        if(sessionStorage.getItem("dialog_is_open_in_class") == "true"){
            setShowDetailPageModal(true)
            sessionStorage.removeItem("dialog_is_open_in_class")
            const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
            if(open_forms.includes(sub_area+"_"+record_id) == false){
                forms_open.push(sub_area+"_"+record_id);
                sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
            }
        }
    }, [rowClicked])

    const manageJudges = () => {
        let add_judge = []

        // if class_judges gets changed
        if(dirtyFields.class_judges_modifiable){
            let class_judges_modifiable = methods.getValues('class_judges_modifiable')

            // All the new class_judges will not have sgl_id so they will be added to add_judge array
            class_judges_modifiable.map(data =>{
                if(!data.sgl_id){
                    add_judge.push(data)
                }
            })
            methods.setValue('class_judges_added', add_judge, {shouldDirty: true})
        }
    }

    const warningsOnSave = async (useClassId = null) => {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        } 
        
        let divisionMessage = ""
        let oldDivisionId = getValues('oldDivisionId')
        let oldDivisionName = getValues('oldDivisionName')
        let newDivisionId = getValues('classes.division_id')
        let newDivisionName = getValues('newDivisionName')
        let newDivisionPrice = getValues('divisionPrice.price')
        let oldDivisionPrice = getValues('oldDivisionPrice')
        let isDivisionUpdated = dirtyValues(dirtyFields, methods.getValues())?.classes?.division_id

        if(!newDivisionName && newDivisionId > 0){
            let new_div = form_meta.division.find((ss) => ss.value == newDivisionId)
            newDivisionName = new_div?new_div.label:''
        }
        if(!oldDivisionName && oldDivisionId > 0){
            let old_div = form_meta.division.find((ss) => ss.value == oldDivisionId)
            oldDivisionName = old_div?old_div.label:''
        }

        if(newDivisionId != oldDivisionId && isDivisionUpdated){ //Class division changed
            if(newDivisionId == 0 && oldDivisionId != 0 && oldDivisionPrice > 0){ //if class is removed from division and division had price set
                divisionMessage = `Class is removed from ${oldDivisionName?oldDivisionName:''} division. Please verify the division price.`
            }
            else if(newDivisionId > 0 && (isNaN(oldDivisionId) || oldDivisionId == 0)){ //if class is added in division and division had price set and class has no division id
                divisionMessage = `Class is added in ${newDivisionName} division. Please verify the division price.`
            }
            else if(newDivisionId > 0 && oldDivisionId > 0 && (oldDivisionPrice > 0 || newDivisionPrice > 0)){ //if class is removed from division or added in division and any division had price set
                divisionMessage =  `Class division is changed from ${oldDivisionName?oldDivisionName:''} to ${newDivisionName?newDivisionName:''}. Please verify the divisions prices.`
            }

            // add safety check to display confirm dialog when message is present
            if(divisionMessage && divisionMessage !== ''){
                await alertDialog({title: 'warning', message: divisionMessage})  //End need to alert user
            }
        }

        if (methods.getValues('SubmitCallArea') === 'class-prize-money-action') {  // Don't close modal or redirect after save if called from specific areas
            getClassData(useClassId) 
        }
        // Use Case (Param Id changed): Next/Prev buttons
        else if(props?.id == undefined && Number(methods.getValues('classes.class_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
            if(row_selected && row_selected != "" && row_ids[row_selected]){
                setRowClicked(false)
                setLoading(true)
                history.push(`${PUBLIC_URL}/classes/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
            }
        }
        else if(props?.id == undefined && current_class_id > 0){
            history.push(`${PUBLIC_URL}/classes`, { allowNavigation: true })
        }
        else if(props?.id == undefined && current_class_id == 0){
            history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
        }
        else{
            if(props.updateCallbackFromParent){
                props.updateCallbackFromParent()
                props.closeModal()
            }
        }
        if( row_selected == row_ids?.length -1 && props?.id === undefined){
            if (next_button && last_button) {
                next_button.disabled = true;
                last_button.disabled = true;
                prev_button.disabled = false;
                first_button.disabled = false;
            }
          }
        if(row_selected == 0 && props?.id === undefined){
            if (prev_button && first_button) {
                prev_button.disabled = true;
                first_button.disabled = true;
                next_button.disabled = false;
                last_button.disabled = false;
            }
        }
    }

    const getClassData = (useClassId = null) => {
        // Show loading overlay
        loadingOverlay({show: true})
        
        let class_id = useClassId ? useClassId : current_class_id

        axios
        .all([
            axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/detail', {
            params: {
                class_id: class_id,
                customer_id: customer_id,
                show_id: currentShowID,
                previousClassDetails: class_id == 0 ? JSON.stringify(previousClassDetails) : {}
            },
            }),
            axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/classMetaData', {
            params: {
                
                class_id: useClassId ? useClassId : current_class_id,
                customer_id: customer_id,
                show_id : currentShowID,
            },
            }),
        ])
        .then(
            axios.spread(({data: classData},{data: metaData}) => {
                setFormMeta(metaData)
                //Check if record id is invalid then redirect to list page
                if((classData || classData === '') && isInvalidRecord(classData.classes, current_class_id, 'class_id')){
                    navigate('/classes')
                    loadingOverlay({show: false})
                }

                if(current_class_id > 0){
                    setDisableClassName(true)
                }
                
                if(current_class_id > 0 && currentShowID != classData.classes?.show_id){ //check if input show does not match current show in show Picker
                    dispatch(setNewShowId(classData.classes?.show_id)) //In this case update Show in ShowPicker
                }

                if(props?.id === undefined && Number(methods.getValues('classes.class_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                    reset(classData)
                }else{
                    reset(classData, { keepDirtyValues: true })
                }

                
                if(props?.parent_area == "Divisions" && (props.id || props.id == 0)){
                    if(props?.updatedData?.division_id > 0){ // When opened from division , set class division to that division (only when adding new class in existing division)
                        methods.setValue('classes.division_id',props?.updatedData?.division_id ,{shouldDirty:true})
                    }

                    setDisableDivision(true) // disable class division when opened from exisiting/new divison 
                }

                setValue('class_judges_modifiable',methods.getValues('class_judges'))
                setValue('class_groups_remove', [])
                setValue('class_entries_drop',[])
                setValue('oldDivisionId', classData.classes?.division_id)
                setValue('oldDivisionPrice', classData.divisionPrice?classData.divisionPrice.price:'') // if class does not have division price initialize it to empty
                setValue('prizeMoneyPref', classData.prizeMoneyPref)
                if(class_id == 0){
                    setValue('divisionPrice.price', previousClassDetails?.division_price)
                }

                if(classData.classes?.warmup_class || classData.classes?.classic_class){
                    setValue('open_card', 1 )
                }

                if(classData.classes?.classic_class){
                    setValue('open_card_class_type','classic_class')
                }
                else if(classData.classes?.warmup_class){
                    setValue('open_card_class_type','warmup_class')
                }
                
                if(getValues('classes.combined_class_id') > 0){
                    setValue('disabled', true)
                }

                if(getValues('checkprinted')> 0 && getValues('prizeMoneyApplied')>0){
                    setValue('disabled', true)
                    setValue('prize_and_results', true)
                }
                else if(getValues('checkprinted')>0){
                    setValue('disabled', true)
                    setValue('check_printed', true)
                }
                else if(getValues('prizeMoneyApplied')>0){
                    setValue('disabled', true)
                    setValue('prize_money_applied', true)
                }
                else if(getValues('unAppliedPrizeMoney')>0){
                    setValue('unapplied_prize_money', true)
                }
                
                let division_name = metaData.division.find((ss) => ss.value == classData.classes?.division_id)
                if(division_name){
                    setValue('oldDivisionName', division_name.label)
                }
                setSetUpTeamButton((methods.getValues('classes.jumper_table') == "Team Scoring" || methods.getValues('classes.jumper_table') == "FEI Nations Cup" || methods.getValues('classes.hunter_type') == "Team Scoring")) 
                if(props.id == undefined && (sessionStorage.getItem('forms_open') == undefined || current_class_id == class_id)){
                    sessionStorage.setItem('forms_open', JSON.stringify(["Classes_"+Number(current_class_id)]));
                }
               
                // Hide loading overlay
                loadingOverlay({show: false})
                setLoading(false)
                if(current_class_id == 0){
                    setLock(false)
                }

                // Enable Updates for Multi User after Reload Call (Add delay to avoid refresh call for self update)
                setTimeout(stopMultiUserUpdateForSelf.current, 5000);
            })
        )
        .catch(reason => {
            loadingOverlay({show: false})
            setLoading(false)
            handleDetailPageCatch(reason.response, loadingOverlay, props)
        })
    }

    useEffect(() => {
       getClassData(class_id)
    }, [class_id])

    useEffect(() => {
        getClassData()
    }, [update_data])

      useEffect(() => { // Update callback useEffect
        if(syncEvent){
            let parsedEvent = JSON.parse(syncEvent.data)
            let variableName = ''
            let updateFlag = false
            let updateId =  parsedEvent.additional_data.triggered_id
            
            switch(parsedEvent.type){
                case `Classes-${customer_id}-${currentShowID}-${current_class_id}`:
                    variableName = 'classes'
                    updateFlag = true
                    break;

                case `Entries-${customer_id}-${currentShowID}`:
                    variableName = 'entryxclasses'
                    let trips = methods.getValues('entryxclasses')
                    if(trips.find(trip => trip.Entry.entry_id == updateId)){
                        updateFlag = true
                    }
                    updateFlag = true
                    break;

            }

            if(updateFlag && stopMultiUserUpdateForSelf.current){
                axios.get( process.env.REACT_APP_NEST_API_URL + '/classes/detail', {
                    params: {
                        class_id: current_class_id,
                        customer_id: customer_id,
                        show_id: currentShowID,
                    }
                }).then((res) => {
                    if(parsedEvent.type.includes('Classes')){
                        reset(res.data, { keepDirtyValues: true })
                    }else{
                        methods.setValue(variableName, res.data[variableName])
                    }
                })
            }            
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
        return // SK - Disabling sync
        if(currentShowID && current_class_id && sse && current_class_id > 0){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `Classes-${customer_id}-${currentShowID}-${current_class_id}`, 
                `Entries-${customer_id}-${currentShowID}`,
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents) 
        }

        return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_class_id, sse]);

    useEffect(() => {
        // Update previousClassDetails when any of the watched fields change
        dispatch(setPreviousClassDetails({
            name: methods.watch("classes.name") || "",
            description: methods.watch("classes.description") || "",
            sponsor: methods.watch("classes.sponsor") != "" ? methods.watch("classes.sponsor") : previousClassDetails.sponsor, // Keep old value if empty
            ec_section_code: methods.watch("classes.ec_section_code") || "",
            usef_section_code: methods.watch("classes.usef_section_code") || "",
            class_type: methods.watch("classes.class_type") || "",
            level: methods.watch("classes.level") || "",
            unit: methods.watch("classes.unit") || "",
            class_price: methods.watch("classes.class_price") || "",
            required: methods.watch("classes.required") || "",
            division_id: methods.watch("classes.division_id") || "",
            division_price: methods.watch("divisionPrice.price") || "",
            schedule_sequencetype: methods.watch("classes.schedule_sequencetype") || ""
        }))
    }, [
        methods.watch("classes.name"),
        methods.watch("classes.description"),
        methods.watch("classes.sponsor"),
        methods.watch("classes.ec_section_code"),
        methods.watch("classes.usef_section_code"),
        methods.watch("classes.class_type"),
        methods.watch("classes.level"),
        methods.watch("classes.unit"),
        methods.watch("classes.class_price"),
        methods.watch("classes.required"),
        methods.watch("classes.division_id"),
        methods.watch("divisionPrice.price"),
        methods.watch("classes.schedule_sequencetype")
    ]);

    useAutoFocus('', loading , update_data , "classes" , current_class_id)

    return (
        <>
          {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('classes.name'), methods.getValues('classes.number')]}>{intl.formatMessage({ id: 'MENU.CLASSES' })}</PageTitle> : ""}
            <div id={"classes_detail_page"} className='modal-body py-3 px-4 class-input-form input-form'>
            <FormProvider {...methods}>
                <form id={"ClassDetail_"+current_class_id} noValidate className='form max-width' onSubmit={(e) => {e.preventDefault();handleSubmit(onSubmit)(manageJudges()) }} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } } ref={formRef}>
                <HelpLink tooltip="CLASS.DETAIL.LINK.HELP" classname="top" />
                <TeamsSetupDialog show={modalShow} onHide={() => setModalShow(false)} form_meta={form_meta} class_id={current_class_id}/>
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row"> {/* Column Group */}
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-80px pe-0' htmlFor='number' data-tooltip-id="CLASS.DETAIL.LABEL.CLASS">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.CLASS' })}</label>

                                        <div className='col-lg-1 px-0'>
                                            <Controller
                                                control={methods.control}
                                                name="classes.number"
                                                render={({ field: { onChange, name, value } }) => (
                                                    <NumericFormat
                                                    id='number'
                                                    name={name}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    value={Number(value)}
                                                    type='text'
                                                    disabled={lock}
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    onValueChange={(e)=>{
                                                        // methods.setValue('classes.number', e.value)
                                                        onChange(e.value)
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select(); // Highlight the value
                                                    }}
                                                />
                                                )}
                                            />
                                        </div>
                                        <div className='col-lg-1 px-0' disabled={true}>
                                            {!methods.getValues('disabled') &&
                                            // <i className="fas fa-solid fa-lock ms-2 py-3"
                                            // onClick={(e) => {
                                            //     document.getElementById('number').disabled = !e.target.classList.toggle('fa-lock-open')
                                            // }}></i>
                                            <i onClick={() => setLock(!lock)} className={"fas fa-solid ms-2 py-3" + (lock ? ' fa-lock' : ' fa-lock-open')}></i>
                                            }   
                                        </div>
                                        <label className='col-lg-1 text-end col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='name' data-tooltip-id="CLASS.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.NAME' })}</label>
                                        <div className='col'>
                                            <input
                                            {...register( 'classes.name', 
                                                {
                                                    required: "Class name is required."
                                                })
                                            }
                                                id='name'
                                                type='text'
                                                disabled={disableClassName}
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            />
                                            { methods?.formState?.errors?.classes?.name && <div><span className='error_message'>{methods.formState.errors.classes.name?.message}</span></div>}
                                        </div>
                                        <div className='col-lg-1 w-30px px-0'>
                                            <i className={`fas fa-solid fa-lock${!disableClassName ? '-open' : ''} ms-2 py-3`} onClick={(e) => setDisableClassName(!disableClassName)}></i>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-80px pe-0' htmlFor='show_id' data-tooltip-id="CLASS.DETAIL.LABEL.SHOW">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.SHOW' })}</label>

                                        <div className='col-lg-4 align-items-center ps-0'>
                                           {form_meta.show_list && (
                                                <Controller
                                                    name='classes.show_id'
                                                    render={({field: {onChange, value, name}}) => (
                                                    <Select
                                                        options={form_meta.show_list}
                                                        id='show_id'
                                                        isDisabled={true}
                                                        value={form_meta.show_list.find((ss) => ss.value == value)}
                                                        name={name}
                                                        isSearchable={true}
                                                        onChange={(show_list) => {
                                                        onChange(show_list.value)
                                                        }}
                                                        theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        })}
                                                        styles={reactSelectStyles}
                                                        placeholder='Select'
                                                    />
                                                    
                                                    )}
                                                />
                                            )}
                                        </div>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-80px pe-0'></label>

                                        <div className='col-lg-4 d-flex align-items-center ps-0'>
                                            <div className='col-lg-8 form-check-sm form-check-custom'>
                                                <input
                                                {...register( 'classes.ushja_qualifier', 
                                                    {
                                                    required: false
                                                    })
                                                }
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='ushja_qualifier'
                                                    onClick={() => {
                                                            setValue('classes.ushja_qualifier_type', '', {shouldDirty: true})
                                                            setValue('classes.ushja_qualifier_classno', 0, {shouldDirty: true})
                                                    }}
                                                />
                                                <label data-tooltip-id="CLASS.DETAIL.LABEL.USEASUSHJAQUALIFIERCLASS" className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='ushja_qualifier'> {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.USEASQUALIFIERCLASS' })}</label>
                                            </div>
                                            <div className='col'>
                                            {form_meta.USHJA_qualifier_types && (
                                                    <Controller
                                                    name='classes.ushja_qualifier_type'
                                                    render={({field: {onChange, value, name}}) => (
                                                        <Select
                                                        options={form_meta.USHJA_qualifier_types}
                                                        id='ushja_qualifier_type'
                                                        isDisabled={!watch("classes.ushja_qualifier")}
                                                        value={form_meta.USHJA_qualifier_types.find(
                                                            (ss) => ss.value === value
                                                        )}
                                                        name={name}
                                                        isSearchable={true}
                                                        onChange={(USHJA_qualifier_types) => {
                                                            onChange(USHJA_qualifier_types.value)
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                        })}
                                                        styles={reactSelectStyles}
                                                        placeholder='Select'
                                                        />
                                                    )}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <label data-tooltip-id="CLASS.DETAIL.LABEL.QUALIFIERCLASS" className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-125px pe-0' htmlFor='ushja_qualifier_classno'>{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.QUALIFIERCLASS' })}</label>

                                        <div className='col-lg-1 ps-0'>
                                            <Controller
                                                control={methods.control}
                                                name="classes.ushja_qualifier_classno"
                                                render={({ field: { onChange, name, value } }) => (
                                                    <NumericFormat
                                                    disabled={!watch("classes.ushja_qualifier")}
                                                    allowNegative={false}
                                                    name={name}
                                                    value={Number(value)}
                                                    decimalScale={0}
                                                    id='ushja_qualifier_classno'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    onValueChange={ushja_qualifier_classno => onChange(ushja_qualifier_classno.value)}
                                                />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-80px pe-0' htmlFor='division_id' data-tooltip-id="CLASS.DETAIL.LABEL.DIVISION">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.DIVISION' })}</label>

                                        <div className='col-lg-4 align-items-center ps-0'>
                                          {form_meta.division && (
                                                <Controller
                                                    name='classes.division_id'
                                                    render={({field: {onChange, value, name}}) => (
                                                    <Select
                                                        options={form_meta.division}
                                                        id='division_id'
                                                        value={value !== undefined ?form_meta.division.find((ss) => ss.value == value):0}
                                                        name={name}
                                                        isSearchable={true}
                                                        onChange={(division) => {
                                                            onChange(division.value)
                                                            setValue('newDivisionName', division.label, {shouldDirty : true})
                                                            setValue('divisionPrice.price', division.price)
                                                            if(division?.type){
                                                                if(division.type == "Hunter"){
                                                                    setValue('classes.class_type', "Hunters", { shouldDirty: true })
                                                                }
                                                                else if (division.type == "Jumper"){
                                                                    setValue('classes.class_type', "Jumpers", { shouldDirty: true })
                                                                    setValue('classes.schedule_sequencetype', "Over Fences", { shouldDirty: true })
                                                                }
                                                                else{
                                                                    setValue('classes.class_type', division.type, { shouldDirty: true })
                                                                }
                                                            }
                                                        }}
                                                        theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        })}
                                                        styles={reactSelectStyles}
                                                        placeholder='Select'
                                                        isDisabled={show_financials_locked || disableDivision}
                                                    />
                                                    )}
                                                />
                                            )}
                                        </div>

                                        <div className='col-lg-1 align-items-center ps-0 w-70px'>
                                            {/* {methods.watch('classes.division_id') != "" && methods.watch('classes.division_id') != undefined ? <i className="fa-sharp fa-solid fa-circle-info fa-xl me-2 py-3" style={{ color: '#578EBE' }} onClick ={() => getDetailPageInfo(methods.getValues('classes.division_id'),"Divisions","DivisionDetail")}></i> : null} */}
                                            {methods.watch('classes.division_id') !== "" && methods.watch('classes.division_id') !== undefined ? (
                                                <i 
                                                    className="fa-sharp fa-solid fa-circle-info fa-xl me-2 py-3" 
                                                    style={{ color: disableDivision ? 'gray' : '#578EBE', cursor: disableDivision ? 'not-allowed' : 'pointer' }} 
                                                    onClick={!disableDivision ? () => getDetailPageInfo(methods.getValues('classes.division_id'), "Divisions", "DivisionDetail") : undefined}
                                                ></i>
                                            ) : null}
                                            {  hasAreaWritePermission('divisions') &&
                                            <button disabled={disableDivision} type="button" className="btn btn-sm btn-secondary fw-bold px-2 py-2" onClick ={() => getDetailPageInfo(0,"Divisions","DivisionDetail")} data-tooltip-id="CLASS.DETAIL.LABEL.BUTTON.ADD">
                                                <i className="fas fa-plus fs-5 px-0"></i>
                                            </button>
                                            }
                                        </div>

                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-125px pe-0' htmlFor='sponsor' data-tooltip-id="CLASS.DETAIL.LABEL.SPONSOR">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.SPONSOR' })}</label>

                                        <div className='col d-flex ps-0'>
                                            <input
                                            {...register( 'classes.sponsor', 
                                                {
                                                required: false
                                                })
                                            }
                                                id='sponsor'
                                                type='text'
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-80px pe-0' htmlFor='class_type' data-tooltip-id="CLASS.DETAIL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.TYPE' })}</label>

                                        <div className='col-lg-4 align-items-center ps-0'>
                                            <div className='row'>
                                                <div className='col-lg-5'>
                                                    {form_meta.class_type && (
                                                        <Controller
                                                            name='classes.class_type'
                                                            render={({field: {onChange, value, name}}) => (
                                                            <Select
                                                                options={form_meta.class_type}
                                                                id='class_type'
                                                                // add substring filter search to set default dropdown value 
                                                                value={value !== undefined ? form_meta.class_type.filter(ct => value?.includes(ct.value)):""}
                                                                name={name}
                                                                isSearchable={true}
                                                                onChange={(class_type) => {
                                                                    onChange(class_type.value)
                                                                    if (class_type.value.includes("Jumper")){
                                                                        setValue('classes.schedule_sequencetype', "Over Fences", { shouldDirty: true })
                                                                    }                                                           
                                                                }}
                                                                theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                })}
                                                                styles={reactSelectStyles}
                                                                placeholder='Select'
                                                            />
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                                <div className='col-lg-7'>
                                                    {form_meta.classSchedule_sequenceType && (
                                                        <Controller
                                                            name='classes.schedule_sequencetype'
                                                            render={({field: {onChange, value, name}}) => (
                                                            <Select
                                                                options={form_meta.classSchedule_sequenceType}
                                                                id='schedule_sequencetype'
                                                                isDisabled = {methods.watch("classes.class_type")=="Dressage"?true:false}
                                                                value={form_meta.classSchedule_sequenceType.find(
                                                                (ss) => ss.value === value
                                                                )}
                                                                name={name}
                                                                isSearchable={true}
                                                                onChange={(classSchedule_sequenceType) => {
                                                                onChange(classSchedule_sequenceType.value)
                                                                }}
                                                                theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                })}
                                                                styles={reactSelectStyles}
                                                                placeholder='Select'
                                                            />
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <label className='col-lg-1 py-1 w-70px'></label>
                                        {methods.watch("classes.class_type")=="Dressage"?(<>
                                        </>):
                                        methods.watch("classes.class_type")=="Hunters"? (<>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-125px pe-0' htmlFor='hunter_type' data-tooltip-id="CLASS.DETAIL.LABEL.HUNTINGSCORING">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.HUNTERSCORING' })}</label>
                                        <div className='col-lg-2 ps-0'>
                                            {form_meta.hunter_type && (
                                                        <Controller
                                                        key={"hunter_type"}
                                                            name='classes.hunter_type'
                                                            render={({ field: { onChange, value, name } }) => (
                                                                <Select
                                                                    options={form_meta.hunter_type}
                                                                    id='hunter_type'
                                                                    value={form_meta.hunter_type.find((ss) => ss.value === value)}
                                                                    name={name}
                                                                    isSearchable={true}
                                                                    onChange={(hunter_type) => {
                                                                            onChange(hunter_type.value) 
                                                                            if(methods.getValues('classes.hunter_type') == "Team Scoring"){
                                                                                setSetUpTeamButton(methods.getValues('classes.hunter_type') == "Team Scoring")  
                                                                            }else{
                                                                                setSetUpTeamButton(false)  
                                                                            }
                                                                        } 
                                                                    }
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                    })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder='Select' 
                                                                    // className='col-4'
                                                                />
                                                            )} 
                                                        />
                                                    )} 
                                        </div>
                                        <div className='col-auto p-0'>
                                            {setUpTeamButton && current_class_id > 0?
                                                <button onClick={() => setModalShow(true)} type = "button" className="btn btn-sm btn-secondary fw-bold px-2 py-1 min-h-30px float-end text-uppercase w-100" data-tooltip-id="CLASS.DETAIL.BUTTON.SETUPTEAMS">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.BUTTON.SETUPTEAMS' })}</button>
                                            :""}  
                                        </div>                  
                                        </>):
                                        (<>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-125px pe-0' htmlFor='jumper_table' data-tooltip-id="CLASS.DETAIL.LABEL.JUMPERTABLE">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.LABEL.JUMPERTABLE' })}</label>
                                                <div className='col-lg-2 ps-0'>
                                                    {form_meta.jumper_table && (
                                                        <Controller
                                                            key={"jumper_table"}
                                                            name='classes.jumper_table'
                                                            render={({ field: { onChange, value, name } }) => (
                                                                <Select
                                                                    options={form_meta.jumper_table}
                                                                    id='jumper_table'
                                                                    value={form_meta.jumper_table.find((ss) => ss.value === value)}
                                                                    name={name}
                                                                    isSearchable={true}
                                                                    onChange={(jumper_table) => {
                                                                        onChange(jumper_table.value);
                                                                        if (methods.getValues('classes.jumper_table') === "Team Scoring" || methods.getValues('classes.jumper_table') === "FEI Nations Cup") {
                                                                            setSetUpTeamButton(true);
                                                                        } else {
                                                                            setSetUpTeamButton(false);
                                                                        }
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                    })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder='Select'
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                                <div className='col-auto ps-0'>
                                                    {setUpTeamButton && current_class_id > 0 && (
                                                        <button
                                                            onClick={() => setModalShow(true)}
                                                            type="button"
                                                            className="btn btn-sm btn-secondary fw-bold px-2 py-1 min-h-30px float-end text-uppercase w-100"
                                                            data-tooltip-id="CLASS.DETAIL.BUTTON.SETUPTEAMS"
                                                        >
                                                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.BUTTON.SETUPTEAMS' })}
                                                        </button>
                                                    )}
                                                </div>
                                                </>)
                                        }
                                        { classStatus != '' && (methods.watch("classes.combined_class_id") > 0 || methods.watch('classes.cancelled') > 0 ) && activeTab != 'PRIZESANDRESULTS' ?
                                            <label className='col col-form-label fw-bold py-1 fs-5 text-danger text-end'>
                                                {classStatus}
                                            </label>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div> {/* End Column Group */}

                            <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <li key={index} className='nav-item' onClick={() => { setActiveTab(tab); setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                    <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 2 ? ' min-w-200px text-center' : '') + (index == 3 ? ' min-w-225px text-center' : '') + (index == 4 ? ' min-w-175px text-center' : '') }
                                                        tabIndex="-1"
                                                        id={tab + "-tab"}
                                                        data-bs-toggle='tab'
                                                        href={"#" + tab + "-Class-" + current_class_id}
                                                        data-tooltip-id={`CLASS.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.' + tab })}
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className="tab-content mt-5 pb-2" id="myTabContent">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Class-" + current_class_id} role="tabpanel" >
                                                    <HelpLink tooltip={"CLASS.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                    {componentTags[index]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Card Body */}

                    <input type='hidden' {...register("oldDivisionId")} />
                    <input type='hidden' {...register("oldDivisionName")} />
                    <input type='hidden' {...register("newDivisionName")} />
                    <input type='hidden' {...register("oldDivisionPrice")} />

                    <InputFormFooter goBackPath={'/classes'} propId={props?.id}  data={'classes'}/>
                </form>
            </FormProvider>
            </div>
            {showDetailPageModal && <DetailPageModal prev_data={prevData} area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData?.Entry ? gridRowData?.Entry : gridRowData} setValueFromClass={setValue} parent_id={current_class_id}/>}
        </>
    );
}

export { ClassDetail }