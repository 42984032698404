import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext} from "react-hook-form";
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions';

const PeopleTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext();

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const columnDefs = [
        { field: 'lf_name',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.NAME' })},
        { field: 'city',flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.CITY' })},
        { field: 'state',flex: 1,  headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.STATE' }) },
        { field: 'owner', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.ENTRIES.OWNER' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
        { field: 'rider', headerName: intl.formatMessage({ id: 'FORM.INPUT.STABLES.TAB.PEOPLE.RIDER' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' } },
    ]

    const rowData = methods.getValues('people')

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group'>
                <div className="d-flex justify-content-end align-items-end"> {/* WA - Row Selector */}
                    <p className='me-6 py-0 my-0'>{rowData.length}</p>
                    <div>
                        <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Stables - People"}/>
                    </div>
                </div>
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.people_id, "People", "PeopleDetail", row_data)}} area ={"Stables - People"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic> {/* WA - Row Selector */}
                </div>
            </div>
        </>
    );
}

export { PeopleTab }