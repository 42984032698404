import { useEffect, useRef, useState } from "react"
import { JobCountByStatusByWidget } from "./QueuedJobs/JobCountByStatusWidget"
import axios from "axios"
import { useAppSelector } from "../../redux/hooks"
import { InProgressJobsWidget } from "./QueuedJobs/InProgressJobsWidget"
import { FailedJobsWidget } from "./QueuedJobs/FailedJobsWidget"
import { SlowestJobsWidget } from "./QueuedJobs/SlowestJobsWidget"
import { MostTimeConsumingJobsWidget } from "./QueuedJobs/MostTimeConsumingJobsWidget"
import { HighestThroughputJobsWidget } from "./QueuedJobs/HighestThroughputJobsWidget"

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

export const QueuedJobsTab = () => {
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const [isLoading, setIsLoading] = useState(true);
    const [queuedJobsData, setQueuedJobsData] = useState({
        jobCountByStatus: {
            "Failed": 0,
            "Started": 0,
            "Completed": 0,
            "Queued": 0,
            "Stalled": 0
        },
        inProgressQueuedJobs: [],
        failedQueuedJobs: [],
        slowestQueuedJobs: [],
        timeConsumingQueuedJobs: [],
        highestThroughputQueuedJobs: []
    })
    const [selectedDuration, setSelectedDuration] = useState({
        label: '24 hours', value: '1-days'
    })
    const [refreshTimeout, setRefreshTimeout] = useState(0)
    const timer = useRef(null);

    const getQueuedJobsStatus = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${NEST_API_URL}/utility/getQueuedJobsStats?customer_id=${customerId}&duration=${selectedDuration.value}`)  
            setQueuedJobsData(prevQueuedJobsData => {
                const newQueuedJobsData = structuredClone(prevQueuedJobsData)
                return { ...newQueuedJobsData, ...response.data }
            })
        } catch (reason) {
        } finally {
            setIsLoading(false)


            // // Clear existing timeout as we are going to reset
            // if (timer.current) {
            //     clearTimeout(timer.current)
            // }

            // timer.current = setTimeout(() => {
            //     setRefreshTimeout(Date.now())
            // }, 60*1000)
        }
    }

    useEffect(() => {
        if (customerId > 0) {
            getQueuedJobsStatus()
        }
    }, [customerId, selectedDuration, refreshTimeout])

    return (
        <div className="row m-5 mt-0 p-0 mb-0">
            <div className="mb-2">
                <div className="col">
                    <JobCountByStatusByWidget 
                        selectedDuration={selectedDuration}
                        setSelectedDuration={setSelectedDuration}
                        queuedJobsData={queuedJobsData}
                        getQueuedJobsStatus={getQueuedJobsStatus}    
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="mb-2">
                        <InProgressJobsWidget isLoading={isLoading} queuedJobsData={queuedJobsData} />
                    </div>
                    <div className="mb-2">
                        <HighestThroughputJobsWidget isLoading={isLoading} queuedJobsData={queuedJobsData} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-2">
                        <FailedJobsWidget isLoading={isLoading} queuedJobsData={queuedJobsData} />
                    </div>
                    <div className="mb-2">
                        <MostTimeConsumingJobsWidget isLoading={isLoading} queuedJobsData={queuedJobsData} />
                    </div>
                    <div className="mb-2">
                        <SlowestJobsWidget isLoading={isLoading} queuedJobsData={queuedJobsData} />
                    </div>
                </div>
            </div>
        </div>
    )
}