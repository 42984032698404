import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent'
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer'
import ButtonRenderer from '../../modules/output-listing/renderers/ButtonRenderer'
import {BooleanFilterOptions} from '../../modules/output-listing/filters/BooleanFilter'
import {useAppSelector} from '../../redux/hooks'

const BarnsList = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const customer_id = useAppSelector((state) => state.showCompany.company_id)

  const columnDefs = [
    {
      field: 'barn_id',
      headerName: intl.formatMessage({id: 'LIST.BARNS.BARNID'}),
      filter: 'agNumberColumnFilter',
      hide: true,
    },
    {
      field: 'name',
      flex: 1,
      headerName: intl.formatMessage({id: 'LIST.BARNS.NAME'}),
      filter: 'agTextColumnFilter',
    },
    {
      field: 'active',
      headerName: intl.formatMessage({id: 'LIST.BARNS.ACTIVE'}),
      filter: 'agNumberColumnFilter',
      filterParams: BooleanFilterOptions,
      cellRenderer: BooleanCheckmarkRenderer,
      cellStyle: {textAlign: 'center'},
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.BARNS'})}</PageTitle>
      <OutputListingComponent
        key={customer_id}
        area={'Barns'}
        columnDefs={columnDefs}
        apiEndPoint={'barns/list'}
        onRowDoubleClicked={(rowData) =>
          navigate(`/barns/detail/${rowData.barn_id}?customer_id=${customer_id}`)
        }
        onNewTabClick={(row_data) =>
          window.open(
            process.env.PUBLIC_URL +
              '/barns/detail/' +
              row_data.barn_id +
              `?customer_id=${customer_id}`,
            '_blank'
          )
        }
      />
    </>
  )
}

export {BarnsList}
