import React, { useEffect, useState } from 'react'
import { SaveModifiedColumnDefinitions, overwriteColumnDefinitions } from '../sgl-utils/agGridHelpers'
import { useAuth } from '../auth'
import { useAppSelector } from '../../redux/hooks'

const DEFAULT_OPTIONS = [10, 20, 30, 40, 50]
export const DEFAULT_VISIBLE_ROWS = 10

export const RowSelector = ({
  value,
  onChange,
  options = DEFAULT_OPTIONS,
  className = 'ag-grid-row-selector',
  area
}) => {
  const [selectedValue, setSelectedValue] = useState(value)
  const { currentUser } = useAuth()
  const customer_id = useAppSelector(state => state.showCompany.company_id)
  
  // Load saved preference on mount
  useEffect(() => {
    if (area) {
      const savedValue = localStorage.getItem(`${area}_visible_rows`)
      if (savedValue) {
        const parsedValue = parseInt(savedValue)
        setSelectedValue(parsedValue)
        onChange(parsedValue)
      }
    }
  }, [area])

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value)
    setSelectedValue(newValue)
    onChange(newValue)
    // Save to localStorage if we have an area
    if (area) {
      SaveModifiedColumnDefinitions(
        [],
        area,
        currentUser,
        customer_id,
        'settings updated',
        { visible_rows: newValue }
      )
      // localStorage.setItem(`${area} - Visible Rows`, newValue)
    }
  }

  return (
    <select value={selectedValue} onChange={handleChange} className={className}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option} rows
        </option>
      ))}
    </select>
  )
}
