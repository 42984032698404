import { useMemo,useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from "react-hook-form";
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { AgGridReact } from 'ag-grid-react';
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight, getRowData } from '../../../../modules/sgl-utils/agGridHelpers';
import { useAlert, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { NumericFormat } from 'react-number-format';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../../modules/output-listing/OutputListingRowOptions';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ChampionReserveTab = () => {
    const intl = useIntl();
    const methods = useFormContext();
    const entryNumberInputRef = useRef(null)
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const divisionId = methods.getValues('division.division_id')
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const [gridRef, setGridRef] = useState(false)
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const rowHeight = getNarrowRowHeight;
    const headerHeight = getNarrowHeaderHeight;
    const [rowData, setRowData] = useState([]);
    /* WA - Row Selector */

    const columnDefs = [
        { field: 'type', width: 150, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.TYPE' })},
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.ENTRY' }), },
        { field: 'Entry.horse',flex:2, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.HORSE' })  },
        { field: 'Entry.rider_list', flex: 4, headerName: intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.RIDERLIST' }) },
    ]

    /* WA - Row Selector */
    const effectiveContainerStyle = useMemo(() => {
        // Get actual number of rows to display (min of visibleRows and actual data length)
        const actualVisibleRows = rowData?.length > 0 
        ? Math.min(visibleRows, rowData?.length)
        : visibleRows;
    
        // Calculate the computed height based on actual visible rows
        const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
        
        // If container style exists and visible rows is greater than 0, compare heights
        if (visibleRows > 0) {
        // Parse the container style height (removing 'px' if present)
        const containerHeight = parseInt(containerStyle?.height);

        // If row data is empty, we return the existing container height
        if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
        }
        
        // If container height is greater than computed height, use container height
        if (containerHeight > computedHeight) {
            return containerStyle;
        }
        } else if (containerStyle) {
        return containerStyle;
        }
        
        // Default to computed height based on visible rows
        return {
        width: '100%',
        height: `${computedHeight}px`
        };
    }, [containerStyle, visibleRows, rowData?.length]);
    /* WA - Row Selector */

    useEffect(() => {
        const champRes = methods.getValues('champRes')
        if (gridRef && champRes) {
            setRowData(champRes.sort((a, b) =>  a.type.localeCompare(b.type)) ?? [])
            gridRef.setRowData(champRes.sort((a, b) =>  a.type.localeCompare(b.type)))
        }
    }, [gridRef, methods.watch('champRes')])

    const addChampOrReserve = async (type) => {
        try {
            loadingOverlay({ show: true, message: `Adding ${type}` })

            const existingChampRes = methods.getValues('champRes')
            const response = await axios.post(`${NEST_API_URL}/divisions/addChampOrReserve`, {
                customer_id: customerId,
                division_id: divisionId,
                type,
                entry_number: entryNumberInputRef.current?.value ?? 0,
                champ_res: existingChampRes
            })

            methods.setValue('champRes', response.data.champRes, { shouldDirty: true })
        } catch (reason) {
            alertDialog({ message: reason?.response?.data?.error ?? reason })
        } finally {
            loadingOverlay({ show: false, message: `Adding ${type}` })
        }
    }

    const deleteChampReserve = () => {
        const selectedRows = gridRef.getSelectedRows()
        if (selectedRows.length === 0) {
            alertDialog({ message: 'Please select champ/reserve to delete.'})
            return
        }

        const oldChampRes = getRowData(gridRef)
        const deletedChampRes = methods.getValues('deletedChampRes') ?? []
        const newChampRes = []
        for (const champRes of oldChampRes) {
            if (champRes.entry_id === selectedRows[0].entry_id) {
                if (champRes.sgl_id) { // it was a database record
                    deletedChampRes.push(champRes.sgl_id)
                }
            } else {
                newChampRes.push(champRes)
            }
        }

        methods.setValue('champRes', newChampRes, { shouldDirty: true })
        if (deletedChampRes.length > 0)
            methods.setValue('deletedChampRes', deletedChampRes, { shouldDirty: true }) // remove first selected row
    }

    return (
        <>
            <div className='form-group'>
                <div className='row mb-2'>
                    <div className='col-lg-11 mb-2'>
                        <div className='col-lg d-flex align-items-center mb-2'> {/* WA - Row Selector */}
                            <div style={{width: '100%'}}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Divisions - ChampionReserve"}/>
                                </div>
                                <div style={effectiveContainerStyle}>
                                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                        <AgGridReact 
                                            rowData={rowData}
                                            columnDefs={columnDefs} 
                                            // containerStyle={containerStyle}
                                            defaultColDef={defaultColDef}
                                            onGridReady={params => setGridRef(params.api)}
                                            rowHeight={getNarrowRowHeight}
                                            headerHeight={getNarrowHeaderHeight}
                                            rowSelection='single'
                                            visibleRows={visibleRows}
                                            />
                                    </div>
                                </div>
                            </div> {/* WA - Row Selector */}
                        </div>
                    </div>
                    <div className='col-lg-1 mb-2 d-flex justify-content-end'>
                        <button 
                            type='button' 
                            className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase" 
                            onClick={() => deleteChampReserve()}
                            data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.SECTION.CHAMPIONRESERVE.BUTTON.REMOVE"
                        >
                            {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.REMOVE' })}
                        </button>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='row mb-2'>
                        <label className='col-lg-1 col-form-label fs-5 py-1 w-100px' htmlFor='ADDENTRY' data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.SECTION.CHAMPIONRESERVE.LABEL.ADDENTRY">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.LABEL.ADDENTRY' })}</label>
                        <div className='col-lg-1 w-125px'>
                            {/* <input
                                ref={entryNumberInputRef}
                                id='ADDENTRY'
                                type='text'
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.LABEL.ADDENTRY' })}
                            /> */}
                            <NumericFormat
                                getInputRef={(instance) => {
                                    entryNumberInputRef.current = instance;
                                }}
                                id='ADDENTRY'
                                decimalScale={0}
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.LABEL.ADDENTRY' })}
                            />
                        </div>
                        <div className='col-auto'>
                            <button 
                                type='button' 
                                className="btn btn-sm btn-secondary fw-bold text-uppercase py-2" 
                                onClick={() => addChampOrReserve('Champion')}
                                disabled={methods.watch('division.division_id') == 0 || !methods.getValues('classesList') || methods.getValues('classesList').length == 0}
                                data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.SECTION.CHAMPIONRESERVE.BUTTON.ADDASCHAMPION"
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.ADDASCHAMPION' })}
                            </button>
                        </div>
                        <div className='col-lg-1 w-200px'>
                            <button 
                                type='button' 
                                className="btn btn-sm btn-secondary fw-bold text-uppercase py-2" 
                                onClick={() => addChampOrReserve('Res. Champion')}
                                disabled={methods.watch('division.division_id') == 0 || !methods.getValues('classesList') || methods.getValues('classesList').length == 0}
                                data-tooltip-id="DIVISION.DETAIL.TAB.GENERAL.SECTION.CHAMPIONRESERVE.BUTTON.ADDASRESCHAMPION"
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.GENERAL.TAB.CHAMPIONRESERVE.ADDASRESCHAMPION' })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { ChampionReserveTab }