import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import axios from 'axios';
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from "react-hook-form";
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { AddShowFee } from './AddShowFee';
import { useAppSelector } from '../../../redux/hooks';
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import CurrencyRenderer from '../../../modules/output-listing/renderers/CurrencyRenderer';
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions';

const FeesTab = (props) => {
  const intl = useIntl();
  const currency = intl.formatMessage({ id: 'CURRENCY' })
  const methods = useFormContext();
  const [gridRef, setGridRef] = useState(false)
  const [gridColumnApiRef, setGridColumnApiRef] = useState(false)
  const [fees_deleted, setFeesDeleted] = useState([])
  const [rowData, setRowData] = useState([])
  const alertDialog = useAlert()
  const { hasAreaDeletePermission, hasAreaWritePermission } = useAccessChecker()
  const [showAddShowFee, setShowAddShowFee] = useState(false)
  const [allMasterFeesNotAdded, setAllMasterFeesNotAdded] = useState([]);
  const [allActiveMasterFeesNotAdded, setAllActiveMasterFeesNotAdded] = useState([]);
  const [activeFeesCount, setActiveFeesCount] = useState(0);
  const customer_id = useAppSelector((state) => state.showCompany.company_id);
  const loadingOverlay = useLoadingOverlay()
  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
  /* WA - Row Selector */
  const show_financials_locked = methods.watch('show.islocked')

  const columnDefs = [
    { field: 'order', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.ORDER'}), cellStyle: { textAlign: 'center' }, sortable: false,minWidth: 90, maxWidth: 90,  },
    { field: 'description', flex: 3, headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.DESCRIPTION'}), sortable: false  },
    { field: 'category', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.CATEGORY'}), sortable: false  },
    { field: 'default_qty', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.DEFAULTQTY'}), cellStyle: { textAlign: 'center' }, sortable: false  },
    { field: 'web_default_qty', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.WEBDEFAULTQTY'}), cellStyle: { textAlign: 'center' }, sortable: false  },
    {field: 'unit_price', headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.UNITPRICE'}), cellStyle: { textAlign: 'center' }, sortable: false, /*cellRendererSelector: (params) => {return {component: CurrencyRenderer,params: {currency: currency}}} */}
  ]

  const AddFeeToShow = () => {
    setShowAddShowFee(true)

    // Separating active master fees
    const activeMasterFees = methods.getValues('master_fees').filter((fee) => fee.active === 1);
    setActiveFeesCount(activeMasterFees.length);
    setAllActiveMasterFeesNotAdded(activeMasterFees)
    setAllMasterFeesNotAdded(methods.getValues('master_fees'))
  }

  useEffect(() => {
    const fees = methods.getValues('show_fees');
    const default_fees = methods.getValues('default_fees');
  
    if (fees && fees.length > 0) {
      setRowData(fees)
    } else if(default_fees && default_fees.length > 0 && fees_deleted.length === 0) {
      // Modify the default fees to add 'order' field with a value of 0
      const modifiedDefaultFees = default_fees?.map(row => ({
        ...row,
        order: 0,
        web_default_qty: 0
      }))

      setRowData(modifiedDefaultFees); // Use modified default fees
    }
  }, [methods.watch('show_fees'), methods.getValues('default_fees')]);

  function deleteSelectedFees() {
    if (gridRef) {
      let selected_fee = gridRef.getSelectedRows();
      if (selected_fee.length > 0) {
        const { showfees_id, customer_id } = selected_fee[0];
        if(showfees_id !== undefined && customer_id !== undefined){
          axios
            .get(process.env.REACT_APP_NEST_API_URL + '/fees/validateShowFeesDeletion', {
              params: {
                showfees_id: showfees_id,
                customer_id: customer_id,
              },
            })
            .then((response) => {
              const { success, error_messages } = response.data;
              if (success) {
                // Add the selected row to fees_deleted
                setFeesDeleted([...fees_deleted, selected_fee[0]]);
                gridRef.applyTransaction({ remove: [selected_fee[0]] });
                setRowDataFromGrid()
              } else {
                // Validation errors occurred, display an alert
                alertDialog({message: error_messages.join("<br/>"), title: 'Error'})
              }
            })
        }else{
          gridRef.applyTransaction({ remove: [selected_fee[0]] });
          setRowDataFromGrid()
        }
      }
    }
  }

  const setRowDataFromGrid = () => {
    let newRowData = []
    gridRef.forEachNode(node => newRowData.push({...node.data, order: node.rowIndex +1}));
    setRowData(newRowData)
    methods.setValue('show_fees', newRowData , {shouldDirty: true})
  }

  useEffect(() => {
    if(fees_deleted.length > 0){
      methods.setValue('fees_deleted', fees_deleted, {shouldDirty:true})
    }
  }, [fees_deleted])

  const resetFeeOrder = (params) => {
    if (params?.target?.classList?.contains('ag-row')) {
      let totalRowCount = gridRef.getDisplayedRowCount()

      const newOrderedAllFees = []
      for (let i = 0; i < totalRowCount; i++) {
        let displayedRowData = gridRef.getDisplayedRowAtIndex(i).data
        displayedRowData.order = i + 1
        newOrderedAllFees.push(displayedRowData)
      }
  
      setRowData(newOrderedAllFees)
      methods.setValue('show_fees', newOrderedAllFees , {shouldDirty: true})
  
      // gridColumnApiRef.resetColumnState()
      gridRef.refreshCells({ force: true })
    }
  }

  const containerStyle = useMemo(() => ({ width: '100%', height: '270px' }), []);

  function makeDefaultFees(){
    // Show loading overlay
    loadingOverlay({ show: true });
    axios.post(process.env.REACT_APP_NEST_API_URL + '/shows/setCompanyDefaultFees', {
        params: {
          show_id: props.show_id,
          customer_id: customer_id,
          show_fees_list: methods.getValues('show_fees')
        }
      }).then((response) => {
          // Hide loading overlay
          loadingOverlay({ show: false });
      })
  }

  return (
    <>
    {showAddShowFee && <AddShowFee show={showAddShowFee} handleClose={() => setShowAddShowFee(false)} allMasterFeesNotAdded={allMasterFeesNotAdded} allActiveMasterFeesNotAdded={allActiveMasterFeesNotAdded} activeFeesCount={activeFeesCount} updateCallbackFromParent={props.updateCallbackFromParent} show_id={props.show_id}/>}
        <div className='row mb-2'>
            <div className='col-lg-11 d-flex align-items-center mb-2'>
                <div className='form-group mb-2 w-100'> {/* WA - Row Selector */}
                  <div className="d-flex justify-content-end align-items-center">
                    <div className='me-6'>{rowData !== undefined ? rowData.length : ""}</div>
                    <div>
                      <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Shows - Fess"}/>
                    </div>
                  </div>
                  <OutputListingGridStatic
                  onRowDoubleClicked={(row_data) => row_data?.showfees_id ? props.callbackFromParent(row_data.showfees_id, "Fees", "ShowFeeDetail", row_data) : ""}
                  area = {"Shows - Fess"} 
                  setGridRef={setGridRef}
                  setGridColumnApiRef={setGridColumnApiRef}
                  columnDefs={columnDefs} 
                  rowData={rowData} 
                  containerStyle={containerStyle}
                  rowDragEntireRow={true}
                  rowDragManaged={true}
                  suppressMoveWhenRowDragging={false}
                  animateRows={true}
                  onDragStopped={resetFeeOrder}
                  visibleRows={visibleRows}
                  ></OutputListingGridStatic>
                </div> {/* WA - Row Selector */}
            </div>

            <div className='col-lg-1'>
                { hasAreaWritePermission('fees') &&
                <button className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" type="button" onClick ={() => methods.getValues('show.masterfees_changeenabled') > 0 ? AddFeeToShow() : props.callbackFromParent(0,"Fees","ShowFeeDetail")} data-tooltip-id="SHOW.DETAIL.TAB.FEES.BUTTON.ADD">
                    <i className="fas fa-plus fs-5 px-1 py-3"></i>
                </button>
                }
                <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                { hasAreaDeletePermission('fees') &&
                <button className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' type='button' onClick={(e)=> { deleteSelectedFees();e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.TAB.FEES.BUTTON.REMOVE">
                    <i className='fas fa-minus fs-5 px-1 py-3'></i>
                </button>
                }
            </div>
        </div>
        <div className='row mb-2'>
          <div className='col-lg-7'>
            <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px me-3' type='button' onClick={makeDefaultFees} data-tooltip-id="SHOW.DETAIL.TAB.FEES.BUTTON.MAKEDEFAULT">
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.BUTTON.MAKEDEFAULT'})}
            </button>

            <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px' type='button' onClick={resetFeeOrder} data-tooltip-id="SHOW.DETAIL.TAB.FEES.BUTTON.RESETORDER">
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.BUTTON.RESETORDER'})}
            </button>
          </div>
          <div className='col-lg-4 align-items-center d-flex justify-content-end' >
            <p className='mx-0 my-0 fs-6' style={{textAlign:"right"}}>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.FEES.LABEL.DRAGANDDROP'})}</p>
          </div>
        </div>
    </>
    )
  }
  
export {FeesTab}