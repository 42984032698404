/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react'
import { KTSVG, useDebounce } from '../../../../_metronic/helpers'
import { useOutputContext, useOutputContextUpdater } from '../../output-listing/OutputListingContext'
import {SearchForm} from '../../../pages/shows/SearchForm'
import {SearchEntriesForm} from '../../../pages/entries/SearchForm'
import {useIntl} from 'react-intl'
import { SearchHorsesForm } from '../../../pages/horses/SearchForm'
import { SearchClassesForm } from '../../../pages/classes/SearchForm'
import { SearchDivisionsForm } from '../../../pages/divisions/SearchForm'
import { SearchPeopleForm } from '../../../pages/people/SearchPeopleForm'
import {SearchPaymentsForm} from '../../../pages/payments/SearchForm'
import { SiteMonitoringFilter } from '../../../pages/site-monitoring/Filters'
import { CloudApiLogsFilter } from '../../../pages/cloud-api-logs/Filters'
import { QueuedJobsFilter } from '../../../pages/queued-jobs/Filters'
import { ErrorsFilters } from '../../../pages/errors/Filters'
import { RingsDisplayFilter } from '../../../pages/rings/Filters'
import { PaymentBatchesSearchForm } from '../../../pages/payment-batches/SearchForm'
import { SearchOrganizationsForm } from '../../../pages/organizations/SearchForm'
import ClearSearchLink from './ClearSearchLink'

const ListSearchComponent = () => {
  const intl = useIntl()

  const [showSearchForm, setShowSearchForm] = useState<boolean>(false)

  //const {updateState} = useQueryRequest()
  const {outputSearchTerm, setOutputSearchTerm, outputGrid, setOutputAdvanceFilter, setOutputSearchType, outputSearchType, disableSearchMode, triggerSearch, setSearchTriggered, saveSearchState, getCurrentRecordSetIds, setIsListOpen, shouldFocusSearch, shouldHighlightSearch, searchInputRef} = useOutputContext();
  const outputContextUpdater = useOutputContextUpdater();
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(outputSearchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && outputSearchTerm !== undefined) {
        //updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

    // Bind Ctrl + F to open advance search form
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.code === 'KeyF'){  // Meta key is Command on MAC
                event.preventDefault()
                setShowSearchForm(true)
            }
        }
    
        // Attach the keydown event listener
        document.addEventListener('keydown', handleKeyDown);
    
        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    const onClearFilter = () => {};

    const applyAdvancedSearch = async (data: any) => {
      if(data.search == 'replace'){
        onClearFilter()
      }
      else if (data.search == 'add_to' || data.search == 'refine' ||  data.search == 'remove') {
        // Add previous record ids for add to, refine and remove filters
        const recordIds = await getCurrentRecordSetIds();
        data.previous_record_ids = recordIds;
      }

      setOutputAdvanceFilter(data)
      setShowSearchForm(false) //close/hide dialog
    }

  const renderSearchForm = () => {
    if(outputGrid.area == 'Shows' && showSearchForm) {
      return <SearchForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)}  />
    }
    else if(outputGrid.area == 'Entries' && showSearchForm){
      return <SearchEntriesForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)}/>
    }
    else if(outputGrid.area == 'Payments' && showSearchForm){
      return <SearchPaymentsForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)} />
    }
    else if(outputGrid.area == 'Horses' && showSearchForm){
      return <SearchHorsesForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)} />
    }
    else if(outputGrid.area == 'Classes' && showSearchForm){
      return <SearchClassesForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={ () => setShowSearchForm(false) } />
    }
    else if(outputGrid.area == 'Divisions' && showSearchForm){
      return <SearchDivisionsForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)} />
    }
    else if(outputGrid.area == 'People' && showSearchForm){
      return <SearchPeopleForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)} />
    }
    else if(outputGrid.area == 'PaymentBatches' && showSearchForm){
      return <PaymentBatchesSearchForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)} />
    }
    else if(outputGrid.area == 'Organizations' && showSearchForm){
      return <SearchOrganizationsForm show={showSearchForm} onSubmitForm={(searchFilters) => applyAdvancedSearch(searchFilters)} onClearFilter={onClearFilter} handleClose={() => setShowSearchForm(false)}  />
    }
  }

  const handleCtrlA = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Enable ctrl A in search
    if ((event.ctrlKey || event.metaKey) && (event.key === 'a' || event.key === 'A')) {
      const target = event.target as HTMLInputElement;
      event.preventDefault();
      target.select();
    }

    if (event.key === 'Tab') {
      const target = event.target as HTMLInputElement;
      triggerSearch(target.value);
      setSearchTriggered(true);
      event.preventDefault();
    }
  };


  useEffect(() => {
    if (['Add', 'Refine'].includes(outputSearchType) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [outputSearchType]);


  useEffect(() => {
    if (shouldFocusSearch.current && searchInputRef.current && outputSearchTerm) {
      searchInputRef.current.focus();
    }
  }, [shouldFocusSearch.current]);

  useEffect(() => {
    if (shouldHighlightSearch.current && searchInputRef.current && outputSearchTerm) {
      searchInputRef.current.focus();
      searchInputRef.current.select();
    }
  }, [shouldHighlightSearch.current]);

  return (
    <div className='card-container'>
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          id="simple-search-bar"
          ref={searchInputRef}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control ps-14 fw-bolder w-300px h-40px'
          placeholder={intl.formatMessage({id: 'TOOLBAR.LABEL.SEARCH'})}
          value={outputSearchTerm}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') {
              outputContextUpdater({action: 'saveRefinedSelection'}) 
              saveSearchState()
              outputContextUpdater({ action: 'clearFiltersAndRefresh' });
              setIsListOpen(true);
              setSearchTriggered(false);
            } else if (value.trim() !== '') {
              setOutputSearchTerm(value);
              setIsListOpen(true);
              setSearchTriggered(false);
            }
          }}
          onKeyDown={handleCtrlA}
        />

        {
          (outputGrid.area == 'SiteMonitoring') ? <SiteMonitoringFilter /> : ''
        }

        {
          (outputGrid.area == 'CloudApiLogs') ? <CloudApiLogsFilter /> : ''
        }

        {
          (outputGrid.area == 'QueuedJobs') ? <QueuedJobsFilter /> : ''
        }

        {
          (outputGrid.area == 'ErrorsListing') ? <ErrorsFilters /> : ''
        }

        {
          (outputGrid.area == 'Rings') ? <RingsDisplayFilter /> : ''
        }

        {
          (outputGrid.area == 'Barns') ? <RingsDisplayFilter /> : ''
        }
        
        {/* begin::Filter Button */}
        {(outputGrid.area !== 'Rings' && outputGrid.area !== 'ClassRules' && outputGrid.area !== 'Reports' && outputGrid.area !== 'Organization' && outputGrid.area !== 'Facilities' && outputGrid.area !== 'ShowSeries' && outputGrid.area !== 'Circuits' && outputGrid.area !== 'TaxRates'  && outputGrid.area !== 'Stables' && outputGrid.area !== 'MasterFees' && outputGrid.area !== 'Fees' &&  outputGrid.area !== 'CloudApiLogs' && outputGrid.area !== 'QueuedJobs' &&  outputGrid.area !== 'SiteMonitoring'  &&  outputGrid.area !== 'CronJobs'  && outputGrid.area !== 'ErrorsListing'  && outputGrid.area !== 'Barns')? 
        <a href="#" className="btn btn-secondary fw-bolder mx-2 px-4" 
          data-toggle='modal'
          data-bs-target='#kt_modal_search_form'
          onClick={() => setShowSearchForm(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
          {/*<KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />*/}
          {intl.formatMessage({id: 'TOOLBAR.LABEL.ADVANCEDSEARCH'})}
        </a> : ""
        }
        {/* end::Filter Button */}

      </div>
      {/* end::Search */}

      { renderSearchForm() }
    </div>

    {(outputGrid.area !== 'ErrorsListing' && outputGrid.area !== 'CloudApiLogs' && outputGrid.area !== 'QueuedJobs' && outputGrid.area !== 'SiteMonitoring' && outputGrid.area !== 'CronJobs') && (
      <div className='add-refine-wrapper d-flex justify-content-between'>
        <div className="add-refine d-flex">
          <label className="fw-bolder fs-6 me-4">Search Mode: </label>

          {/* Replace Checkbox */}
          <label className="mt-checkbox mt-checkbox-outline me-9 align-items-center d-flex w-50px">
            <input
              type="radio"
              checked={outputSearchType == 'Replace'}
              onClick={() => {
                setOutputSearchType('Replace')
              }}
            />&nbsp;<span className="fw-bolder fs-6 ms-1">Replace</span>
          </label>

          {/* Refine Checkbox */}
          <label className="mt-checkbox mt-checkbox-outline me-7 align-items-center d-flex w-50px">
            <input
              type="radio"
              disabled={disableSearchMode}
              checked={outputSearchType == 'Refine'}
              className='add-search-radio'
              onClick={() => {
                setOutputSearchTerm('')
                setOutputSearchType('Refine')
              }}
            />&nbsp;<span className="fw-bolder fs-6 ms-1 add-search-label">Refine</span>
          </label>

          {/* Add Checkbox */}
          <label className="mt-checkbox mt-checkbox-outline me-3 align-items-center d-flex w-50px">
            <input
              type="radio"
              checked={outputSearchType == 'Add'}
              disabled={disableSearchMode}
              className='add-search-radio'
              onClick={() => {
                setOutputSearchTerm('')
                setOutputSearchType('Add')
              }}
            />&nbsp;<span className="fw-bolder fs-6 ms-1 add-search-label">Add</span>
          </label>
          </div>
          <div className="d-flex">
            <ClearSearchLink />
          </div>
        </div>
    )}
    </div>
  )
}

export {ListSearchComponent}
