import { useMemo, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import axios from 'axios';
import { useAlert, useConfirm, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../../redux/hooks';

// Custom Components
import { PersonSection } from '../General/PersonSection';
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import BooleanCheckmarkRenderer from '../../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import NumberRenderer from '../../../../modules/output-listing/renderers/NumberRenderer';
import CurrencyRenderer from '../../../../modules/output-listing/renderers/CurrencyRenderer';
import TimeRenderer from '../../../../modules/output-listing/renderers/TimeRenderer';
import ClassAddDrop from './ClassAddDrop';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../../modules/output-listing/OutputListingRowOptions';

const RTOTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const show_financials_locked = methods.watch('show.islocked')
    const dropInputRef = useRef()

    const [rowData, setRowData] = useState([]);
    const [gridRef, setGridRef] = useState();
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const [riderTrips, setRiderTrips] = useState([]);
    const currency = intl.formatMessage({ id: 'CURRENCY'})
    const [classRuleFailed, setClassRuleFailed] = useState('');

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    // In order to gain access to the child component instance,
    // we need to assign it to a `ref`, so we call `useRef()` to get one
    const childRef = useRef(); 

    const columnDefs = [
        { field: 'placing', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.PLACING' }),  cellClass: 'text-end',   },
        { field: 'Class.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.CLASS' }),  cellClass: 'text-end',   },
        { field: 'Class.name', width: 400, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.NAME' }) },
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.TOTALPRIZEMONEY' }), cellClass: 'text-end',   
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) { //Renderer for footer row
                return {
                    component: CurrencyRenderer,
                    params: {
                    currency: currency,
                    },
                }
                } else { //Renderer for other rows
                return {
                    component: NumberRenderer,
                }
                }
            }
        },
        { field: 'points_earned', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.POINTSEARNED' }),cellClass: 'text-end',   },
        { field: 'check_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.CHECKPAID' }),   cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center',   },
        { field: 'prize_money_spent', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.PRIZEMONEYSPENT' }), cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center',   },
        { field: 'creation_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.CREATIONDATE' }), cellClass: 'text-end',  cellRenderer: DateRenderer,  },
        { field: 'creation_time', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.CREATIONTIME' }), cellClass: 'text-end',  cellRenderer: TimeRenderer,  },
        { field: 'gone_in', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.GONEIN' }),   cellRenderer: BooleanCheckmarkRenderer, cellClass: 'text-center',   },
        { field: 'ActualClass.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.ACTUALCLASS' }),   cellClass: 'text-center',  hide: true,  },
        {
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.DAY' }),
            field: 'day_number',
            cellClass: 'text-center',
            valueGetter: (params) => {
              const classGroupxclass = params?.data?.Class?.ClassGroupxclass; 
              if (classGroupxclass && classGroupxclass.length > 0) {
                const classGroup = classGroupxclass[0].ClassGroup; 
                return classGroup ? classGroup.day_number : null;
              }
              return null; 
            }
        },
    ]

    // valid input regex (can contain numbers, comma, hyphen, plus, space, period)
    const regex = /^[\d,-\s+\.]+$/

    useEffect(() => {
        let totalPrizeMoney = 0
        if(methods.getValues('trips')){
            let rider_trips = methods.getValues('trips').filter(trip => trip.rider_id == props.person_id)
            methods.getValues('trips').map(trip => {
                if(trip.rider_id == props.person_id){
                    totalPrizeMoney += trip.total_prize_money
                }
            })
            setRiderTrips(rider_trips)

            if(gridRef){
                gridRef.setRowData(rider_trips)
                setRowData(rider_trips) /* WA - Row Selector */
                if(methods.getValues('trips').filter(trip => trip?.Class?.combined_class)?.length > 0){
                    let colDefs = gridRef.getColumnDefs()
                    colDefs[10].hide = false
                    gridRef.setColumnDefs(colDefs)
                }
                gridRef.setPinnedBottomRowData([{
                    total_prize_money: totalPrizeMoney
                }])
            }
        }

    }, [gridRef, methods.watch('trips'), methods.watch('entry.EntryRiders'), props.person_id]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    const removeRider = async () => {
        let riderTrips = methods.getValues('trips').filter(trip => trip.rider_id == props.person_id)
        let entryRiders = methods.getValues('entry.EntryRiders')

        if(riderTrips.length > 0){
            alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.RTO.RIDER.TRIPSERROR"})})
        }
        else{
            let choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.RTO.REMOVERIDER"}), icon: "comment"})
            if(choice){
                let index = entryRiders.findIndex(entryRider => entryRider.rider_id == props.person_id)
                entryRiders[index].isDeleted = true

                // If deleted rideer is pmr then set the next rider as pmr else set owner as pmr
                if (methods.getValues('entry.prizemoneyrecipientsource') == '2'){
                    if(entryRiders[index].rider_id == methods.getValues('entry.prizemoneyrecipient_id') && entryRiders.length > index+1){
                        methods.setValue('entry.prizemoneyrecipient_id', entryRiders[index+1].rider_id, {shouldDirty: true})
                    }else{
                        methods.setValue('entry.prizemoneyrecipient_id', methods.getValues('entry.entryowner_id'), {shouldDirty: true})
                    }
                }

                // If deleted rider is rp then set the next rider as rp else set owner as rp
                if(methods.getValues('entry.responsiblepartysource') == '2'){
                    if(entryRiders[index].rider_id == methods.getValues('entry.responsibleparty_id') && entryRiders.length > index+1){
                        methods.setValue('entry.responsibleparty_id', entryRiders[index+1].rider_id, {shouldDirty: true})
                    }else{
                        methods.setValue('entry.responsibleparty_id', methods.getValues('entry.entryowner_id'), {shouldDirty: true})
                    }
                }

                methods.setValue('entry.EntryRiders', entryRiders, {shouldDirty: true})
                props.setTrigger(!props.trigger)
            }
        }
        props.entryCheckOnHold()
    }

    const markAsGone = () => {
        let selected = gridRef.getSelectedRows()
        let riderTrips = methods.getValues('trips')
        let index = riderTrips.findIndex(riderTrips => riderTrips.entryxclasses_id == selected[0].entryxclasses_id)

        if(selected.length > 0){
            if(selected[0].scratch_trip){
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.RTO.SCRATCHEDTRIP.MARKASGONE.ERROR"})})
            }
            else if(selected[0].gone_in){
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.RTO.ALREADYGONE"})})
            }
            else{
                selected[0].gone_in = 1
                selected[0]["gone_in_by_user"] = 1
                selected[0].actual_go_time = moment().format('HH:mm:ss')
                riderTrips[index] = selected[0]
                methods.setValue('trips', riderTrips, {shouldDirty: true})
            }
        }
    }

    const onAddClassBlur = (e) => {
       if (e.target.value !== "") { // Check for empty input
            let on_vet_hold = methods.getValues('horse.horse_data.on_vet_hold')
            if(on_vet_hold){
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.ADDCLASS.ONHOLDERROR"})})
            } else if (regex.test(e.target.value)) { // Check for valid input
                childRef.current.callAddClassesApi(e.target.value)
            } else {
                alertDialog({message: "Please provide valid input."})
            }
            e.target.value = ""
            methods.setValue('dropInputRef', true)
        } 
    }

    const onDropClassBlur = (e) => {
        if (e.target.value !== "") { // Check for empty input
            let on_vet_hold = methods.getValues('horse.horse_data.on_vet_hold')
            if(on_vet_hold){
                alertDialog({message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.ADDCLASS.ONHOLDERROR"})})
            } else if (regex.test(e.target.value)) { // Check for valid input
                childRef.current.callConfirmDivisionDrop(e.target.value)
            } else {
                alertDialog({message: "Please provide valid input."})
            }
            e.target.value = ""
        } 
        methods.setValue('dropInputRef', false)
    }

    return (
        <>     
            <ClassAddDrop 
                ref={childRef}
                person_id={props.person_id}
                setErrors = {setClassRuleFailed}
                calledFrom="Entries"
                dropInputRef={dropInputRef}
            />
            <div className='form-group mb-2'>
                <PersonSection type={"RIDER"} person_id={props.person_id} form_meta={props.form_meta} RTOSuspensionStatus={props.RTOSuspensionStatus} callbackFromParent={props.callbackFromParent} entry_id={props.entry_id} callFormSubmit={props.callFormSubmit} showTaxInformation={props.showTaxInformation} setShowTaxInformation={props.setShowTaxInformation} personIdForTaxStatusLookup={props.personIdForTaxStatusLookup} setPersonIdForTaxStatusLookup={props.setPersonIdForTaxStatusLookup} selectedTaxFormRequestType={props.selectedTaxFormRequestType} clearMembership={props.clearMembership} getRiderStatus={props.getRiderStatus}/>
                <hr className='my-2' />
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='ENTERADDS' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.LABEL.ENTERADDS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.LABEL.ENTERADDS' })}</label>
                    <div className='col-lg-2'>
                        <input
                            onKeyPress={(e) => { 
                                if (e.key === "Enter")  {
                                    e.target.blur() 
                                }
                            }}
                            onKeyDown={(e) => { 
                                if (e.key === "Enter")  {
                                    e.target.blur() 
                                }
                            }}
                            onBlur={onAddClassBlur} 
                            id='ENTERADDS'
                            name='ENTERADDS'
                            disabled={methods.watch("entry.scratched") || show_financials_locked}
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.LABEL.ENTERADDS' })}
                        />
                    </div>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px' htmlFor='ENTERDROPS' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.LABEL.ENTERDROPS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.LABEL.ENTERDROPS' })}</label>
                    <div className='col-lg-2'>
                        <input
                            onKeyPress={(e) => { 
                                if (e.key === "Enter")  {
                                    e.target.blur() 
                                }
                            }}
                            onKeyDown={(e) => { 
                                if (e.key === "Enter")  {
                                    e.target.blur() 
                                }
                            }}
                            onBlur={onDropClassBlur}
                            id='ENTERDROPS'
                            name='ENTERDROPS'
                            ref={dropInputRef}
                            type='text'
                            disabled={show_financials_locked}
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.LABEL.ENTERDROPS' })}
                        />
                    </div>
                    <label className='col col-form-label text-muted fs-6 py-1 w-lg-100px'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.ADDDROPINFO' })}</label>
                </div>
                <div className='form-group mb-2'> {/* WA - Row Selector */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <RowSelector
                            value={visibleRows}
                            onChange={setVisibleRows}
                            area={"Entries - RTO"}
                            />
                    </div>
                    <div className='row mb-2'>
                        <OutputListingGridStatic
                            area ={"Entries - RTO"}
                            setGridRef={setGridRef}
                            columnDefs={columnDefs}
                            rowData={rowData}
                            containerStyle={containerStyle}
                            visibleRows={visibleRows}
                        ></OutputListingGridStatic>
                    </div> {/* WA - Row Selector */}
                </div>
                <div className='row mb-2'>
                    <div className='col-lg-6 d-flex align-items-center'>
                        <label className='me-5' data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.LABEL.CLASSESCOUNT">Classes: {riderTrips.length}</label>
                        {/* To show class rule failed message */}
                        <label className='error_message'>{classRuleFailed}</label>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row justify-content-end'>
                            <div className='col-auto'>
                                <button type='button' onClick={markAsGone} className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.BUTTON.MARKASGONE">
                                    {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.BUTTON.MARKASGONE' })}
                                </button>
                            </div>
                            <div className='col-auto'>
                                <button type='button' onClick={removeRider} className="btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="ENTRIES.DETAIL.TAB.GENERAL.SECTION.RIDER.BUTTON.REMOVERIDER">
                                    {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.BUTTON.REMOVERIDER' })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { RTOTab }