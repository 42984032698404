import { useMemo, useState, useEffect, useRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl'
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import Select from 'react-select';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import './tab.css'
import { BooleanCheckMarkComponent } from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers'
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import {DecimalEditor} from '../../../modules/sgl-utils/decimalEditor.js'
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import SymbolRenderer from '../../../modules/output-listing/renderers/SymbolRenderer';
import { NumericFormat } from 'react-number-format';
import VerifyRoster from './PrizesAndResultsTab/VerifyRoster';
import { addFloatingNumbers, checkSocialOrFederalId, createArrayOfNumbersFromText } from '../../../modules/sgl-utils/SglFunctions';
import { CombinedClassPopup } from '../../entries/Detail/Classes/CombinedClassPopup';
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import DecimalPointRenderer from '../../../modules/output-listing/renderers/DecimalPointRenderer';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import { prizeMoneyOptions } from '../../../modules/sgl-utils/reactSelectOptions';
import SetFeiPrizeTemplate from './PrizesAndResultsTab/SetFeiPrizeTemplate';
import { ApplyOrRollbackModal } from './PrizesAndResultsTab/ApplyOrRollbackModal';
import {v1 as uuid} from "uuid"; 
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions';

export const requirementsHook = (
    key,
    dirtyFields,
    allValues,
  ) => {
    if(key == 'class_requirements') {
      let dirty_class_requirements = dirtyValues(dirtyFields, allValues)
      if (Object.keys(dirty_class_requirements).length > 0) {
        return allValues
      } else {
        return dirty_class_requirements
      }
    } else {
      return dirtyValues(dirtyFields, allValues)
    }
  }


export const dirtyValues = (
    dirtyFields,
    allValues
) => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // Here, we have an object.
    return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
        key,
        requirementsHook(key,dirtyFields[key], allValues[key])
        ])
    );
}

const PrizesAndResultsTab = (props) => {
		const intl = useIntl();
		const methods = useFormContext()
        const currencyFormatter = useCurrencyFormatter()
		const [open_edit_fault_time_form,setEditFaultTimeForm] = useState(false)
		const [current_tab, setCurrentTab] = useState('Times & Faults')
		const [editTimeFaultRowData, setEditTimeFaultRowData] =useState([])
		const [updatedTripFaultTime, setUpadtedTripFaultTime] =useState([])
		const [loading, setLoading] = useState(true)
		const {class_id} = useParams();
		let current_class_id = props.id || props.id == 0 ? props.id : class_id
		const customer_id = useAppSelector(state=> state.showCompany.company_id)
		const show_financials_locked = methods.watch('show_tables.islocked')
		const [gridRef, setGridRef] = useState();
		const [split_code, setSplitCode] = useState();
		const alertDialog = useAlert()
		const confirmDialog = useConfirm()
		const [isLocked, setIsLocked] = useState(true)
        const { hasSpecialPermissionToAccess } = useAccessChecker()
        const [showFeiPrizeTemplate, setShowFeiPrizeTemplate] = useState(false);
        const [showVerifyRoster, setShowVerifyRoster] = useState(false)
        const [showCombinedClassPopup, setShowCombinedClassPopup] = useState(false)
        const [showAddButton, setShowAddButton] = useState(false) // Show/Hide Add Entry to Class Button on this tab
        const [prizeErrorMessage, setPrizeErrorMessage] = useState('') // Error/Message when Adding Entry to Class
        const [selectedRider, setSelectedRider] = useState() // Rider Id of Selected Rider
        const [currentEntry, setCurrentEntry] = useState() // Entry Number to Add To Class
        const [actualClasses, setActualClasses] = useState({})
        const [splitCodesList, setSplitCodesList] = useState([])
        const [showApplyOrRollbackModal, setApplyOrRollbackModal] = useState(false)
        const prizeMoneyActionRef = useRef('')
		const [splitTotal, setSplitTotal] = useState({
			splitPrizeMoney: 0,
			splitAddBack: 0,
			splitWithAddBack: 0,
			totalPrizeMoney: 0,
			totalAddBack: 0,
			totalWithAddBack: 0
		});
		const currencyList = [
			{'label': 'USD', 'value': 'USD'},
			{'label': 'CAD', 'value': 'CAD'},
			{'label': 'EUR', 'value': 'EUR'},
			{'label': 'CHF', 'value': 'CHF'}
		]
        const [prizeMoneyAction, setPrizeMoneyAction] = useState('')

		/* WA - Row Selector */
		const [rowDataForAGGridWithoutFlickering, setRowDataForAGGridWithoutFlickering] = useState([]);
		const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
		/* WA - Row Selector */
		
        const handlePrizeMoneyAction = async (action, entryXClasses) => {

			let confirmMessage = ''
			// if prize money is applied for one trip only 
			if(action === 'Apply' && methods.getValues('prizeMoneyPref') && entryXClasses.length == 1){
				let trip = methods.getValues("entryxclasses").find((trip) => trip.entryxclasses_id == entryXClasses[0].entryxclasses_id)
				let pmr = trip?.Entry?.PMR
				let pmrHasSSNOrForeigner = checkSocialOrFederalId(pmr)

				if(!pmr){
					alertDialog({message: 'Prize Money cannot be applied because Prize Money Recipient not found.'})
					return
				}

				confirmMessage = `Are you sure you want to transfer prize money to ${pmr?.fl_name}?`

                if(!pmrHasSSNOrForeigner && methods.getValues('prizeMoneyPref')?.PMR_SSN_Required){
                    confirmMessage += ` The RTO does not have valid tax information on file.`;
                }

				confirmMessage += ' Class record will be saved if you continue.'
			}

			if(!confirmMessage){
				confirmMessage = intl.formatMessage({ id: `FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.ENTRY.${action.toUpperCase()}PRIZEMONEYMESSAGE` })
			}

            const choice = await confirmDialog({ message: confirmMessage })

            if (choice) {
                methods.setValue('applyOrRollbackPrizeMoney', {
                    action,
                    entryXClasses,
                }, { shouldDirty: true })

                methods.setValue('SubmitCallArea', 'class-prize-money-action')

                props.formRef.current.requestSubmit()
                props.stopMultiUserUpdateForSelf.current = false
            }
        }

		const columnDefs = [
            { 
                field: 'place', 
                editable: false, 
				sortable: false,
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PLACING' }),
                cellRenderer: params => <div className='d-flex justify-content-center align-items-center'>{ params.data.lockRow && <i className='fas fa-lock me-2'></i> }<span>{ params.data.place }</span></div> 
            },
            { 
                field: 'prize_money',  
				sortable: false,
                editable: params => !params.data.lockRow && methods.getValues('classes.combined_class_id') == 0,  
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PRIZE' }), 
                cellRenderer: params => currencyFormatter(params.value), 
                valueSetter: params => { params.data.prize_money = parseFloat(params.newValue) } 
            },
            { 
                field: 'addbackpercent',  
				sortable: false,
                editable: params => !params.data.lockRow && methods.getValues('classes.combined_class_id') == 0,
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.ADDBACK' }),
                cellRenderer: params => SymbolRenderer(params.value), 
                valueSetter: params => { params.data.addbackpercent = parseFloat(params.newValue)}
            },
            { 
                field: 'withaddBack',  
				sortable: false,
                width: 200, 
                editable: false, 
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.WITHADDBACK' }), 
                cellRenderer: params => currencyFormatter(params.value),
                valueSetter: params => { params.data.withaddBack = parseFloat(params.newValue)}
            },
            { 
                field: 'TieAmount', 
                width: 150,
                editable: false, 
				sortable: false, 
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.TIEAMOUNT' }), 
                cellRenderer: params => currencyFormatter(params.value)
            },
            { 
                field: 'points', 
				sortable: false, 
                editable: params => !params.data.lockRow && methods.getValues('classes.combined_class_id') == 0,  
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.POINTS' }), 
                cellRenderer: params => DecimalPointRenderer(params), 
                valueSetter: params => { params.data.points = parseFloat(params.newValue)} 
            },
            { 
                field: 'winningnums',
                flex:1, 
				sortable: false, 
                editable: params => !params.data.lockRow && methods.getValues('classes.combined_class_id') == 0, 
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.WINNERSNUMBER' }) 
            },
            {
                field: 'prizeMoneyApplied',
                width: 180, 
				sortable: false,
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PRIZEMONEYAPPLIED' }),
                cellRenderer: params => {
                    return params.data.prizeMoneyApplied.map(status => {
                        if (status === 'Applied') {
                            return BooleanCheckMarkComponent
                        } else if (status === 'Unapplied') {
                            return <span>-</span>
                        }
                    })
                },
                cellClass: 'text-center',
                headerName: intl.formatMessage({ id : 'FORM.INPUT.ENTRIES.LABEL.APPLIED' })
            },
            {
                field: 'prizeMoneyAction',
                width: 180, 
				sortable: false,
                headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PRIZEMONEYACTION' }),
                cellRenderer: params => {
                    if (params.data.prizeMoneyAction === 'No Action') {
                        return <></>
                    } else if (params.data.prizeMoneyAction === 'Both') {
                        return (
                            <div className='d-flex justify-content-center align-items-center'>
                                <div 
                                    className="link-color cursor-pointer me-1 fw-bold"
                                    onClick={() => handlePrizeMoneyAction('Apply', params.data.entryXClasses)}    
                                >
                                    { intl.formatMessage({ id: `FORM.INPUT.COMMON.BUTTON.APPLY` }) }
                                </div>
                                |
                                <div 
                                    className="ms-1 link-color cursor-pointer fw-bold"
                                    onClick={() => handlePrizeMoneyAction('Rollback', params.data.entryXClasses)}
                                >
                                    { intl.formatMessage({ id: `FORM.INPUT.COMMON.BUTTON.ROLLBACK` }) }
                                </div>
                            </div>
                        )
                    }

                    return(
                        <span className="link-color fw-bold cursor-pointer" onClick={() => handlePrizeMoneyAction(params.data.prizeMoneyAction, params.data.entryXClasses)}>
                            { params.data.prizeMoneyAction == 'Apply' ? 'Transfer' : params.data.prizeMoneyAction }
                        </span>
                    )
                },
                cellClass: 'text-center'
            },
		]
		const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
		const classGroupData = methods.getValues("class_groups")
		
		const onEditTimeFault = async (updatedTripFaultTime) => {  
				if(updatedTripFaultTime?.length > 0){
					methods.setValue('updatedTripFaultTime', updatedTripFaultTime, {shouldDirty: true})
				}
		}

		function onCellValueChanged(params){
			let found_trip = updatedTripFaultTime.some(el => el.e_number === params.node.data.e_number);
			if(found_trip){
					let objIndex = updatedTripFaultTime.findIndex((obj => obj.e_number == params.node.data.e_number));
					updatedTripFaultTime[objIndex].faults_one = params.node.data.faults_one
					updatedTripFaultTime[objIndex].time_fault_one = params.node.data.time_fault_one
					updatedTripFaultTime[objIndex].disqualify_status_one = params.node.data.disqualify_status_one
					updatedTripFaultTime[objIndex].time_one = params.node.data.time_one
					updatedTripFaultTime[objIndex].faults_two = params.node.data.faults_two
					updatedTripFaultTime[objIndex].time_fault_two = params.node.data.time_fault_two
					updatedTripFaultTime[objIndex].disqualify_status_two = params.node.data.disqualify_status_two
					updatedTripFaultTime[objIndex].time_two = params.node.data.time_two
					updatedTripFaultTime[objIndex].scoreone = params.node.data.scoreone
					updatedTripFaultTime[objIndex].scoretwo = params.node.data.scoretwo
					updatedTripFaultTime[objIndex].scorethree = params.node.data.scorethree
					updatedTripFaultTime[objIndex].scorefour= params.node.data.scorefour
					updatedTripFaultTime[objIndex].scorefive = params.node.data.scorefive
					updatedTripFaultTime[objIndex].scoresix = params.node.data.scoresix
					setUpadtedTripFaultTime([...updatedTripFaultTime])
			}
			else{
				setUpadtedTripFaultTime([...updatedTripFaultTime,params.node.data])
			}
		}
		var faultTimeColumnDefs = [
			{
				// PLACING
				field: 'placing',suppressMenu: true, flex:1 ,editable: false,resizable: true, sortable: true,headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.PLACING'}),cellStyle: {textAlign: 'right'},
			},
			{
				// ENTRY
				field: 'e_number',suppressMenu: true, flex:1,editable: false,resizable: true, sortable: true,headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.ENTRY'}),cellStyle: {textAlign: 'right',},
			},
			{
				// R1 FAULTS
				field: 'faults_one', valueFormatter: (params) => Math.round(Number(params.data.faults_one)).toFixed(2),suppressMenu: true, flex:1 ,editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true,cellEditor: DecimalEditor,headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.R1FAULTS'}),cellStyle: {textAlign: 'right',}, valueSetter: (params) => { params.data.faults_one = Number(params.newValue) },
			},
			{
				// R1 TIME FAULTS
				field: 'time_fault_one',
				suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.R1TIMEFAULTS'}), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.time_fault_one = Number(params.newValue) },
			},
			{
				// R1 TIME 1
				valueGetter: function getFieldData(params) { if (params.data.class_type === 'Jumpers') { if (params.data.disqualify_status_one === '') { if (params.data.time_one === 0) { if(params?.data?.cg_jumper_table?.includes('FEI')){ return Number(params.data.time_one).toFixed(2); } return Number(params.data.time_one).toFixed(3); } return Number(params.data.time_one).toFixed(3); } else { return params.data.disqualify_status_one; } } else { return Number(params.data.time_one).toFixed(3); } }, valueSetter: (params) => { if (isNaN(params.newValue)) { params.data.disqualify_status_one = String(params.newValue); } else { params.data.disqualify_status_one = ''; params.data.time_one = Number(params.newValue); } }, suppressMenu: true, width: 110, editable: true, cellClass: 'editable-cell', resizable: true, sortable: true, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.R1TIME'}), cellStyle: { textAlign: 'right' },
			},
			{
				// JO FAULTS 2
				field: 'faults_two', valueFormatter: (params) => Number(params.data.faults_two).toFixed(2), suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.JOFAULTS'}), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.faults_two = Number(params.newValue) },
			},
			{
				// JO TIME FAULTS 2
				field: 'time_fault_two', suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.JOTINEFAULTS'}), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.time_fault_two = Number(params.newValue) },
			},
			{
				// JO_Time TIME 2
				valueGetter: function getFieldData(params) { if (params.data.class_type === 'Jumpers') { if (params.data.disqualify_status_two === '') { if (params.data.time_two === 0) { if(params?.data?.cg_jumper_table?.includes('FEI')){ return Number(params.data.time_two).toFixed(2); } return Number(params.data.time_two).toFixed(3); } return Number(params.data.time_two).toFixed(3); } else { return params.data.disqualify_status_two; } } else { return Number(params.data.time_two).toFixed(3); } }, valueSetter: (params) => { if (isNaN(params.newValue)) { params.data.disqualify_status_two = String(params.newValue); } else { params.data.disqualify_status_two = ''; params.data.time_two = Number(params.newValue); } }, suppressMenu: true, flex: 1, editable: true, cellClass: 'editable-cell', resizable: true, sortable: true, headerName: intl.formatMessage({ id: 'LIST.EDITFAULTTIME.JOTIME' }), cellStyle: { textAlign: 'right' },
			}
			
		]
	
		// Score Tab List Columns
		var scoresColumnDefs = [
			{ field: 'placing', suppressMenu: true, flex:1, editable: false, resizable: true, sortable: true, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.PLACING'}), cellStyle: { textAlign: 'right' }, },
			{ field: 'e_number', suppressMenu: true, flex:1, editable: false, resizable: true, sortable: true, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.ENTRY'}), cellStyle: { textAlign: 'right' }, },
			{
				// SCORE 1
				valueGetter: function getFieldData(params) { if (params.data.class_type != 'Jumpers') { if (params.data.disqualify_status_one === '') { return Number(params.data.scoreone).toFixed(2) } else { return params.data.disqualify_status_one } } else { return Number(params.data.scoreone).toFixed(2) } }, valueSetter: (params) => { if (isNaN(params.newValue)) { params.data.disqualify_status_one = String(params.newValue) } else { params.data.scoreone = Number(params.newValue) } }, suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.SCORE1'}), cellStyle: { textAlign: 'right' },
			},
			{
				// SCORE 2
				field: 'scoretwo', valueFormatter: (params) => Number(params.data.scoretwo).toFixed(2), suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.SCORE2'}), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.scoretwo = Number(params.newValue) },
			},
	
				// SCORE 3
			{ valueGetter: function getFieldData(params) { if (classGroupData.length > 0) { if (classGroupData[0].hunter_type === 'Hunter Derby') { return params.data.scorethree !== 0 ? params.data.scorethree : 0; } } return params.data.scorethree; }, valueFormatter: function formatNumber(params) { return Number(params.data.scorethree).toFixed(2); }, suppressMenu: true, flex: 1, editable: true, cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({ id: 'LIST.EDITFAULTTIME.SCORE3' }), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.scorethree = Number(params.newValue); }, },
				
				// SCORE 4
			{ valueGetter: function getFieldData(params) { if (classGroupData.length > 0) { if (classGroupData[0].hunter_type === 'Hunter Derby') { return params.data.scorefour !== 0 ? params.data.scorefour : 0; } } return params.data.scorefour; }, valueFormatter: function formatNumber(params) { return Number(params.data.scorefour).toFixed(2); }, suppressMenu: true, flex: 1, editable: true, cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({ id: 'LIST.EDITFAULTTIME.SCORE4' }), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.scorefour = Number(params.newValue); }, },
			
			{
				// SCORE 5
				field: 'scorefive', valueFormatter: (params) => Number(params.data.scorefive).toFixed(2), suppressMenu: true, flex:1, editable: true, cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.SCORE5'}), cellStyle: { textAlign: 'right' }, valueSetter: (params) => { params.data.scorefive = Number(params.newValue) },
			},
			{
				// SCORE 6
				field: 'scoresix', valueFormatter: (params) => Number(params.data.scoresix).toFixed(2), suppressMenu: true, flex:1, editable: true,  cellClass: 'editable-cell', resizable: true, sortable: true, cellEditor: DecimalEditor, headerName: intl.formatMessage({id: 'LIST.EDITFAULTTIME.SCORE6'}), cellStyle: { textAlign: 'right', }, valueSetter: (params) => { params.data.scoresix = Number(params.newValue) }
			},
		]
			const tabContainerStyle = useMemo(() => ({width: '100%', height: '350px'}), [])
		
			function openTab(tabName) {
				setCurrentTab(tabName)
			}
		
			useEffect(() => {
				axios
					.all([
						current_class_id > 0 &&
						axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/editFaultTime', {
							params: {
								class_id: current_class_id,
								customer_id: customer_id,
								show_id: 0,
							},
						}),
					])
					.then(
						axios.spread(({data: editTimeFaultTabData}) => {
								setEditTimeFaultRowData(editTimeFaultTabData || [])
							setLoading(false)
						})
					)
			}, [current_class_id])

			useEffect(() => {
				if(open_edit_fault_time_form){
					
					const handleEscKey = (event) => {
						if (event.key === 'Enter') {
							let activeElement = document.activeElement
							let isButtonFocused = false
							let isNotAGGridEditableCell = (!event?.target?.className?.includes('ag-input-field-input') && !event?.target?.className?.includes('ag-text-field-input'))
						
							if (activeElement && activeElement.tagName === 'BUTTON') {
								isButtonFocused = true
							}

							if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused ) {
								if (isNotAGGridEditableCell) {
									let submitButton = document.getElementById('PrizeAndResultsTabOKButton')
                        			submitButton?.click()
									event.preventDefault();
								}
							}
						}
					};
					
					document.addEventListener('keydown', handleEscKey, true);
					
					return () => {
						document.removeEventListener('keydown', handleEscKey, true);
					};
				}
			}, [open_edit_fault_time_form]);

			// Function to change the grid data according to the selected split code
			const changePrize = (value) => {
                const classPrizesOfCurrentSplit = methods.getValues('class_prizes').filter(cp => cp.splitcode === value)
                const trips = methods.getValues('entryxclasses')

                for (const cp of classPrizesOfCurrentSplit) {
                    // Get All Winning Numbers of Class Prize in form of Array
                    const winningEntryNumbers = createArrayOfNumbersFromText(cp.winningnums)
                    const prizeMoneyAppliedStatus = []
                    const entryXClasses = []
                    for (const winningEntryNumber of winningEntryNumbers) {
                        const trip = trips.find(trip => trip.Entry && trip.Entry.number === Number(winningEntryNumber))
                        if (trip) { 
                            if (trip.total_prize_money > 0) {
                                if (trip.prize_money_spent) { // Prize money is applied.
                                    prizeMoneyAppliedStatus.push('Applied')
                                } else { // Prize Money is not applied
                                    prizeMoneyAppliedStatus.push('Unapplied')
                                }
                            }
                            entryXClasses.push({ entryxclasses_id: trip.entryxclasses_id, number: trip.Entry.number })
                        }
                    }

                    // If all entries have applied prize money then set the button to Rollback. Otherwise, even if one entry has unapplied prize money, set the button to Apply
                    const hasApplied = prizeMoneyAppliedStatus.includes('Applied')
                    const hasUnapplied = prizeMoneyAppliedStatus.includes('Unapplied')
                    if (hasApplied && hasUnapplied) {
                        cp.prizeMoneyAction = 'Both'
                    } else if (hasApplied) {
                        cp.prizeMoneyAction = 'Rollback'
                    } else if (hasUnapplied) {
                        cp.prizeMoneyAction = 'Apply'
                    } else {
                        cp.prizeMoneyAction = 'No Action'
                    }

                    cp.prizeMoneyApplied = prizeMoneyAppliedStatus
                    cp.entryXClasses = entryXClasses
                    cp.lockRow = prizeMoneyAppliedStatus.includes('Applied')

					//Set sgl_id for updated class prize template present in results tab of class (needed to add sgl_id after moving to updateAGGridWithoutFlickering)
					if(!cp?.uniqueId){
						
						cp.uniqueId = cp.sgl_id?cp.sgl_id:uuid()
					}
                }

				// Function to change the split and total label value
				calculateSplitTotal(value)
				methods.setValue('places_paid', classPrizesOfCurrentSplit.length)
				updateAGGridWithoutFlickering(({ current: { api : gridRef}}), classPrizesOfCurrentSplit, 'uniqueId') // update grid without rerendering 
				setRowDataForAGGridWithoutFlickering(classPrizesOfCurrentSplit) // WA - Row Selector
				// gridRef.setRowData(classPrizesOfCurrentSplit)
			}

			// function to be called on prize grid change
			const handleClassPrizesGridStopEditing = (params) => {
				if(params.newValue != params.oldValue && params.colDef.field != 'winningnums'){
					methods.setValue('classes.prize_template', 'Custom', { shouldDirty: true })
				}

				let updated_row = params.data
                updated_row.withaddBack = isNaN(updated_row.withaddBack) ? 0 : parseFloat(updated_row.withaddBack)
                updated_row.TieAmount = isNaN(updated_row.TieAmount) ? 0 : parseFloat(updated_row.TieAmount)
                updated_row.addbackpercent = isNaN(updated_row.addbackpercent) ? 0 : parseFloat(updated_row.addbackpercent)
                updated_row.points = isNaN(updated_row.points) ? 0 : parseFloat(updated_row.points)
                updated_row.prize_money = isNaN(updated_row.prize_money) ? 0 : parseFloat(updated_row.prize_money)

				let class_prizes = methods.getValues('class_prizes')

                // Find Updated Row Index
                let index = class_prizes.findIndex(cp => cp.splitcode == updated_row.splitcode && cp.place == updated_row.place)   

				class_prizes[index] = updated_row

				recalculatePrizeMoney(class_prizes)

				// Function to change the split and total label value
				calculateSplitTotal(split_code.value)
			}

			useEffect(() => {
				splitCodes()
				if(methods.getValues('class_prizes') && split_code && gridRef){
					changePrize(split_code.value)
				}
			}, [gridRef, methods.watch('class_prizes'), split_code]);

			// Function to change the split and total label value
			const calculateSplitTotal = (value) => {
				let class_prize = methods.getValues('class_prizes')
				let split_total = 0
				let split_addback = 0
				let split_withaddback = 0 
				let total = 0
				let total_addback = 0 
				let total_withaddback = 0
				// Calculate the split total label by checking every record
				class_prize.map((data) => {
					if(data.splitcode == value){
						split_total = split_total + (data.prize_money * 1)
						split_addback = split_addback + (data.addbackpercent * 1)
						split_withaddback = split_withaddback + (data.withaddBack * 1)
					}
					total = total + (data.prize_money * 1)
					total_addback = total_addback + (data.addbackpercent * 1)
					total_withaddback = total_withaddback + (data.withaddBack * 1)
				})

				setSplitTotal(prevState => ({
					...prevState,
					splitPrizeMoney: split_total.toFixed(2),
					splitAddBack: split_addback.toFixed(2),
					splitWithAddBack: split_withaddback.toFixed(2),
					totalPrizeMoney: total.toFixed(2),
					totalAddBack: total_addback.toFixed(2),
					totalWithAddBack: total_withaddback.toFixed(2)
				}))
			}

			const showFEIPrizeTemplate = () => {
				let class_prizes = methods.getValues('class_prizes')?methods.getValues('class_prizes'):0
				let FEIPlacingVisible = false
				let classResultPrize = 0
				if(class_prizes.length > 0){
					class_prizes.map(data => {
						if(data.winningnums > 0){
							classResultPrize++
						}
					})
				}

				if(classResultPrize == 0){
					FEIPlacingVisible = (methods.getValues('classes.fei_competition_code') && methods.getValues('classes.fei_event_code') && methods.getValues('prizeMoneyApplied') === 0)
				}
				return FEIPlacingVisible
			}

			// Function to remove split, not tested
			const removeSplit = async () => {
				let class_prizes = methods.getValues('class_prizes')
				let non_A_splitClassPrize = 0 
				let classResultPrize = 0
				if(class_prizes.length > 0){
					class_prizes.map(data => {
						if(data.splitcode != 'A'){
							non_A_splitClassPrize++
						}
					})
	
					if(non_A_splitClassPrize > 0){
						class_prizes.map(data => {
							if(data.winningnums > 0){
								classResultPrize++
							}
						})
						if(classResultPrize == 0){
							let choice = await confirmDialog({message: intl.formatMessage({id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.REMOVEDSPLITCONFIRMMESSAGE"})})
							if(choice){
								//Delete all records, will be recreated based on template through arrays
					  			//Deleting non-A split cause duplicate prize money template records
								let row = class_prizes.filter((data) =>{
									return data.splitcode == 'A'
									})
							setSplitCode({'label': 'A', 'value': 'A'})
							methods.setValue('class_prizes', row, { shouldDirty: true })
							//gridRef.setRowData(row)
							}
						}
						else{
							alertDialog({message: intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SPLITCANNOTREMOVED'})})
						}
					}
				}
			}
			// Function for prize template drop down value
			const prizeTemplate = (class_template) => {
				// if(methods.getValues('class_prizes')){
					let prizeRecordsResults = methods.getValues('class_prizes').filter(p => p.winningnums != "")
					let current_split_code = 'A'
					
					if(prizeRecordsResults.length > 0){
						alertDialog({message: intl.formatMessage({id:  "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.TEMPLATEAPPLIEDERROR"})})
					}
					else{
						
						axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/getSelectedPlacingTemplateData', {
							params: {
								class_template_id: class_template.value,
								customer_id: customer_id,
							},
						})
						.then(response => {
							if(response.data.classTemplate){
								let classTemplate = response.data.classTemplate
								methods.setValue("places_paid", classTemplate.placings)

								if (!methods.getValues('classes.results_verified')){  // Added if condition to do not update add back information for class with verified entries
									methods.setValue("classes.add_back_per_entry", classTemplate.addbackperentry, { shouldDirty: true })
									methods.setValue("classes.addbackentries", classTemplate.addbackentries, { shouldDirty: true })
								}
								if(splitCodesList.length > 0 && split_code){
									current_split_code = split_code.value
								}

								let ClassPrizesTemplates = []
								// creating class prize objects list from class prize templates
								for(let class_prize of classTemplate.ClassPrizesTemplates){
									let cp = {
                                        uniqueId: uuid(),
										place: class_prize.place,
										points: class_prize.points,
										prize_money: class_prize.prize_money,
										addbackpercent: class_prize.addbackpercent,
										totalprize: class_prize.totalprize,
										withaddBack: 0,
										TieAmount: 0,
										winningnums: '',
										checkprinted: 0,
										splitcode: current_split_code
									}
									ClassPrizesTemplates.push(cp)
								}

								let other_prizes = methods.getValues('class_prizes').filter(p => p.splitcode != current_split_code)
								const class_prizes = [...other_prizes, ...ClassPrizesTemplates];

								recalculatePrizeMoney(class_prizes)
							}
							
							if(class_template.value == ''){
								methods.setValue('classes.prize_template', '', {shouldDirty: true})
							}else{
								methods.setValue('classes.prize_template', class_template.label, {shouldDirty: true})
							}
							setSplitCode({'label': current_split_code, 'value': current_split_code})

						})
					}

					handleLock(false)
			}

			// !4D -> bf_RecalculatePrizeMoney
			async function recalculatePrizeMoney(ClassPrizes){
				let class_data = methods.getValues('classes')
				let trips = methods.getValues('entryxclasses')
				var prizeErrorMsg = ''
				var prizeErrorWarning = ''

				let addBackEntries = class_data.addbackentries ?? 0
				if (addBackEntries == 0){
					addBackEntries = trips.length // need to verify if it should be trips length or class prizes length
				}

				var totalAddBack = (class_data.add_back_per_entry ?? 0) * addBackEntries
				var class_prizes = []
				var placed_numbers = []

				// calculating withaddback prize value in separate loop because we need the next rows values for tie cases
				for(let class_prize of ClassPrizes){
					class_prize.withaddBack = addFloatingNumbers([class_prize.prize_money, ((totalAddBack * class_prize.addbackpercent)/100)], 2).toFixed(2)
					class_prize.TieAmount = 0
				}

				let split_code_options_list = splitCodes()
				let split_codes_list = split_code_options_list.map(sc => sc.value)
                
				// calculating for each split separately
				for(let s_code of split_codes_list) {
					let split_class_prize = ClassPrizes.filter(cp => cp.splitcode == s_code)
					let count = 0;
					let size = 0;

					for(let class_prize of split_class_prize){
						count ++;
						
						if(class_prize.winningnums && class_prize.winningnums != '' && prizeErrorMsg == ''){
							if(count <= size){
								prizeErrorMsg = "The entry at Placing "+count+" has already been used."
								setPrizeErrorMessage(prizeErrorMsg) // Display Error/Message 
								// class_prize.winningnums = ''

							} else {
								let winning_nums_list = createArrayOfNumbersFromText(class_prize.winningnums)
								for(let winning_num of winning_nums_list){
									// getting trips of winning num
									let winning_num_trips = trips.filter(trip => trip.Entry?.number == winning_num)
									if(winning_num_trips.length == 0){
										
										try {
											// calling API to get winning num entry
											let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/entries/getEntryFromNumber', {
												params: {
													show_id: class_data.show_id,
													customer_id: customer_id,
													entry_number: winning_num
												},
											})

											prizeErrorMsg = "The Winning Number "+winning_num+" ("+response.data.entry.horse+" - "+response.data.entry.owner+") is not an entry of this class."
											
											setSelectedRider(null)
											setCurrentEntry(response.data.entry)
											setShowAddButton(true) // Display Add Entry to Class Button

										} catch(reason) {
											// when entry doesnot exists in show or have no riders, we display error message
											let error = reason.response.data.error
											if (error) {
												if (error.includes('show')) {
													prizeErrorMsg = "The Winning Number "+winning_num+" is not an entry of this show."
												} else {
													prizeErrorMsg = error
												}
											}
										}
										class_prize.winningnums = ''

									} else {
										if(placed_numbers.includes(winning_num)){ // just display warning but allow adding a num twice
											prizeErrorWarning = "The Winning Number "+winning_num+" has been placed twice."

										} else {
											// do not allow adding result for scratched entry
											let scratch_trips = winning_num_trips.filter(exc => exc.scratch_trip)
											if(scratch_trips.length != 0){
												prizeErrorMsg = "The Winning Number "+winning_num+" cannot be added since it is already scratched."
												class_prize.winningnums = ''
											} else {
												// result is added for entry
												size++;
												placed_numbers.push(winning_num)
                                                
                                                // Update Prize Money in Trips
                                                for (const trip of winning_num_trips) {
                                                    if(class_prize['TieAmount'] > 0 && trip.total_prize_money != class_prize['TieAmount']){
                                                        trip.total_prize_money = class_prize['TieAmount']
                                                    } else if(trip.total_prize_money != class_prize['withaddBack']){
                                                        trip.total_prize_money = class_prize['withaddBack']
                                                    }
                                                }
											}
										}
									}
									setPrizeErrorMessage(prizeErrorMsg) // Display Error/Message 

								}
								if(winning_nums_list.length > 1){ // when there is any tie
									// For ties, we calculate sum of next number of tied prizes and then divide it on the tied entries
									// for example if 2 entries are tied at place 4, we calculate sum of 4 and 5 prizes and divide it equally for both entries 
									let total = 0  
									let row_count = count-1;
									for(let num of winning_nums_list){
										if(row_count >= split_class_prize.length){ // to prevent going beyond the available prize places.
											break;
										} else {
											total = total + split_class_prize[row_count].withaddBack * 1
										}
										row_count++
									}
									class_prize.TieAmount = parseFloat((total/winning_nums_list.length).toFixed(2))

								}

							}
						} else {
							// to update size for the rows where no result is added
							if(size<count) {
								size++
							}
						}

						class_prizes.push(class_prize)
					}
				}

				if(prizeErrorWarning != '' && prizeErrorMsg == ''){
					setPrizeErrorMessage(prizeErrorWarning) // Display Error/Message 

				}
				methods.setValue('class_prizes', class_prizes, { shouldDirty: true })

			}

			// Updates Ag-grid on blur of number of places
			const handlePlacesPaidBlur = () => {
				let class_prizes = methods.getValues('class_prizes')
				let current_split_code = split_code.value
				let current_split_prizes = class_prizes.filter(cp => cp.splitcode == current_split_code)
				const newPlacings = Number(methods.getValues('places_paid'))
				const oldPlacings = current_split_prizes.length
		
				if (newPlacings > oldPlacings) {
					for (let i = 0; i < newPlacings - oldPlacings; i++) {
						class_prizes.push({ 
                            uniqueId: uuid(),
							place: oldPlacings + i + 1, 
							points: 0, 
							prize_money: 0, 
							addbackpercent: 0, 
							totalprize: 0,
							withaddBack: 0,
							TieAmount: 0,
							winningnums: '',
							checkprinted: 0,
							splitcode: current_split_code
						})
					}    
				} else if (newPlacings < oldPlacings) {

					for (let i = newPlacings+1; i <= oldPlacings; i++) {

						let index = class_prizes.findIndex(cp => cp.splitcode == current_split_code && cp.place == i)
						class_prizes.splice(index, 1);
					}
				}
				
				methods.setValue('class_prizes', class_prizes, { shouldDirty: true })
			}

		const handleLock = (toggle = true) => {
			let colDefs = gridRef.getColumnDefs();
			let checkIsLocked = toggle ? isLocked : !isLocked
			// if (!methods.getValues('classes.lock_prize_money')) {
				// setting column editable only if lock prize money is false
				if (checkIsLocked && methods.getValues('classes.class_id')) {
					colDefs[1].editable = true;
					colDefs[2].editable = true;
					colDefs[5].editable = true;
				} else {
					colDefs[1].editable = false;
					colDefs[2].editable = false;
					colDefs[5].editable = false;
				}
				
				gridRef.setColumnDefs(colDefs);
				if(!methods.getValues('disabled') && toggle){
                    setIsLocked(!isLocked)
                }
			// }
		}

		// function to return list of split codes options for select
		function splitCodes() {

			let classPrizes = methods.getValues('class_prizes')
			let splitOptions = []

			for(let cp of classPrizes){
				if(!splitOptions.find(option => option.label == cp.splitcode)){
					splitOptions.push({'label': cp.splitcode, 'value': cp.splitcode})
				}
			}

			if(splitOptions.length == 0){
				splitOptions.push({'label': 'A', 'value': 'A'})
			}

			splitOptions.sort((a, b) => (a.value > b.value) ? 1 : -1)

			setSplitCodesList(splitOptions)
			if(!split_code || split_code == ''){
				let splitOption = splitOptions.length > 0? splitOptions[0]: ''
				setSplitCode(splitOption)
			}
			return splitOptions
		}

		// Function to add new split
		const addSplit = async () => {
			let split_codes = splitCodesList
			let classPrizes = methods.getValues('class_prizes')
			let current_split_code = split_code.value

			if(classPrizes.length > 0 && split_codes.length > 0){
				// generating new split code - next alphabet
				let max_split_code = split_codes[split_codes.length-1].value
				let next_split_code = max_split_code.substring(0, max_split_code.length - 1)
						  + String.fromCharCode(max_split_code.charCodeAt(max_split_code.length - 1) + 1)
	
				let current_split_prizes = classPrizes.filter(cp => cp.splitcode == current_split_code)

				for(let class_prize of current_split_prizes){
					let new_class_prize = { 
                        uniqueId: uuid(),
						place: class_prize.place,
						points: class_prize.points,
						prize_money: class_prize.prize_money,
						addbackpercent: 0,
						withaddBack: 0,
						TieAmount: 0,
						winningnums: '',
						checkprinted: 0,
						splitcode: next_split_code
					}
					// splitting tied places
					if(class_prize.winningnums && class_prize.winningnums != ''){
						let winning_nums_list = createArrayOfNumbersFromText(class_prize.winningnums)
						if(winning_nums_list.length > 1){
							let index = classPrizes.findIndex(cp => cp.splitcode == current_split_code && cp.place == class_prize.place)
							classPrizes[index]['winningnums'] = winning_nums_list[0]+''
							winning_nums_list.splice(0,1)
							new_class_prize.winningnums = winning_nums_list.join('.')
						}

					}

					classPrizes.push(new_class_prize)
				}
				classPrizes = splitAddBackPercent(classPrizes)
				methods.setValue('class_prizes', classPrizes)
				setSplitCode({'label': next_split_code, 'value': next_split_code})

				recalculatePrizeMoney(classPrizes)


			}
		}

		// function to split add back percent value equally in all splits
		function splitAddBackPercent(classPrizes){
			let placings = classPrizes.map(cp => cp.place)
			placings = [...new Set(placings)]; // to make the array unique
			for(let place of placings){
				let placing_prizes = classPrizes.filter(cp => cp.place == place)
				if(placing_prizes.length > 0){
					let total_percents = placing_prizes.reduce((a,v) =>  a = a + parseFloat(v.addbackpercent) , 0 )
					let percent = total_percents/placing_prizes.length
					for(let class_prize of classPrizes){
						if(class_prize.place == place){
							class_prize.addbackpercent = parseFloat(percent.toFixed(2))
						}
					}
				}
			}
			return classPrizes;
		}

    const handlePrizeMoneyChange = (e) => {
        prizeMoneyActionRef.current = e.value
        if (e.value === 'Apply Prize Money' || e.value === 'Rollback Prize Money') {
            setApplyOrRollbackModal(true)
        }
        setPrizeMoneyAction(e.value)
    }

	useEffect(() => {
        if (gridRef) {
			handleLock(false)
        }
    }, [gridRef])

	const onCellValueChangedHandler = (params) => {
		setShowAddButton(false);
		setPrizeErrorMessage('')
    };

	const rowClassRules = useMemo(() => {
		return {
            'bg-red-grid-row': function(params) {
				return (params.node.data?.class_type === 'Jumpers' && (params.node.data?.disqualify_status_one != '' || params.node.data?.disqualify_status_two != ''))
			}
        };
    }, []);

	const rowClassRulesHunters = useMemo(() => {
        return {
            'bg-red-grid-row': function(params) {
				return (params.node.data?.class_type != 'Jumpers' && params.node.data?.disqualify_status_one != '')
			}
        };
    }, []);

	return (
		<>
            { showApplyOrRollbackModal && 
                <ApplyOrRollbackModal
                    show={showApplyOrRollbackModal}
                    handleClose={() => { 
                        setApplyOrRollbackModal(false); 
                        prizeMoneyActionRef.current = ''; 
                        setPrizeMoneyAction('')
                    }}
                    prizeMoneyActionRef={prizeMoneyActionRef}
                    handlePrizeMoneyAction={handlePrizeMoneyAction}
                />
            }

            { showCombinedClassPopup && 
                <CombinedClassPopup 
                    show={showCombinedClassPopup}
                    handleClose={() => setShowCombinedClassPopup(false)}
                    isAddMode={true}
                    classList={actualClasses}
                    area="VerifyRoster"
                    addClickHandler={actualClassNumber => props.combinedClassSelectHandler(actualClassNumber, setShowAddButton, setPrizeErrorMessage, currentEntry, selectedRider)}
                />
            }
            {showVerifyRoster && <VerifyRoster 
                show={showVerifyRoster} 
                handleClose={() => setShowVerifyRoster(false)} 
                dropTrip={props.dropTrip} 
                addEntry={props.addEntry}  
                combinedClassSelectHandler={props.combinedClassSelectHandler} 
				disqualify_status_list={props.form_meta.disqualify_status_list}
            />}

            {showFeiPrizeTemplate && (
                <SetFeiPrizeTemplate
                    show={showFeiPrizeTemplate}
                    handleClose={() => setShowFeiPrizeTemplate(false)}
                    classId={current_class_id}
                    recalculatePrizeMoney={recalculatePrizeMoney}
                    setSplitCode={setSplitCode}	
                />
            )}
				{/* Edit Fault/Time Modal */}
					{open_edit_fault_time_form &&
						<Modal
								id='kt_modal_create_app'
								tabIndex={-1}
								backdrop='static'
								aria-hidden='true'
								dialogClassName='modal modal-dialog modal-dialog-centered mw-1000px search-form'
								show={open_edit_fault_time_form}
								onHide={()=>setEditFaultTimeForm(false)}
								onKeyPress={(event) => {
									// if (event.key == 'Enter')) {
									// 	onEditTimeFault(updatedTripFaultTime)
									// 	setEditFaultTimeForm(false)
									// 	setCurrentTab('Times & Faults')
									// }
								}}
						>
								{/* header */}
								<div className='modal-header py-0 px-4'>
								<h2 className='fs-4'>
										{intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.EDITFAULTTIME'})}
								</h2>
								<div
										className='btn btn-sm btn-icon btn-active-color-dark'
										data-bs-dismiss='modal'
										aria-label='Close'
										onClick={() => {
							 
										setEditFaultTimeForm(false)
										setCurrentTab('Times & Faults')
										}}
								>
										<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
								</div>
								</div>
								<div className='modal-body py-3 px-4'>
								{/* body */}
								<div className='card-body p-0'>
										{/* tabs body */}
										<div className='d-flex flex-column my-2 borderColor p-2 bg-white'>
										{/* tab-buttons */}
										<ul
												className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap'
												id='myTab'
												role='tablist'
												style={{
												overflowX: 'auto',
												overflowY: 'hidden',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												}}
										>
												<li className='nav-item'>
												<button
														type='button'
														style={{backgroundColor: '#E4E6EF'}}
														className={
														current_tab === 'Times & Faults'
																? ['nav-link text-active-dark px-10 py-1 text-gray-700', 'active'].join(' ')
																: 'nav-link text-active-dark px-10 py-1 text-gray-700'
														}
														id={'TIMES&FAULTS-tab'}
														data-bs-toggle='tab'
														onClick={() => openTab('Times & Faults')}
														data-tooltip-id="CLASS.DETAIL.MODAL.EDITFAULTTIME.BUTTON.TIMESANDFAULTS"
												>
														{intl.formatMessage({id: 'FORM.INPUT.PRIZESANDRESULTS.TAB.TIMES&FAULTS'})}
												</button>
												</li>
												<li className='nav-item'>
												<button
														type='button'
														style={{backgroundColor: '#E4E6EF'}}
														className={
														current_tab === 'Scores'
																? ['nav-link text-active-dark px-10 py-1 text-gray-700', 'active'].join(' ')
																: 'nav-link text-active-dark px-10 py-1 text-gray-700'
														}
														id={'SCORES-tab'}
														data-bs-toggle='tab'
														onClick={() => openTab('Scores')}
														data-tooltip-id="CLASS.DETAIL.MODAL.EDITFAULTTIME.BUTTON.SCORES"
												>
														{intl.formatMessage({id: 'FORM.INPUT.PRIZESANDRESULTS.TAB.SCORES'})}
												</button>
												</li>
										</ul>
										{/* end tab-buttons */}
										{/* tab-content */}
										<div className='tab-content mt-5 pb-2' id='myTabContent'>
												<div
												className={
														current_tab === 'Times & Faults'
														? ['tab-pane fade show ag-theme-alpine', ' active'].join(' ')
														: 'tab-pane fade show ag-theme-alpine'
												}
												id={'TIMES&FAULTS-tab'}
												role='tabpanel'
												style={{}}
												>
												<OutputListingGridStatic columnDefs={faultTimeColumnDefs} rowData={editTimeFaultRowData} containerStyle={tabContainerStyle} rowHeight={22} onCellValueChanged={onCellValueChanged} stopEditingWhenCellsLoseFocus={true} rowClassRules={rowClassRules} ></OutputListingGridStatic>
												</div>
												<div
												className={
														current_tab === 'Scores'
														? ['tab-pane fade show ag-theme-alpine', ' active'].join(' ')
														: 'tab-pane fade show ag-theme-alpine'
												}
												id={'SCORES-tab'}
												role='tabpanel'
												>
												<OutputListingGridStatic  columnDefs={scoresColumnDefs} rowData={editTimeFaultRowData} containerStyle={tabContainerStyle} rowHeight={22} onCellValueChanged={onCellValueChanged} rowClassRules={rowClassRulesHunters} stopEditingWhenCellsLoseFocus={true}>
												</OutputListingGridStatic>
												</div>
										</div>
										{/* end tab-content */}
										</div>
										{/* end tabs body */}
								</div>
								{/* end body */}
								{/* footer */}
								<div className='card-footer d-flex justify-content-end py-3 px-0'>
										<button
										type = 'button'
										className='btn btn-sm btn-secondary me-4 fw-bold'
										data-bs-dismiss='modal'
										onClick={() => {
												setEditFaultTimeForm(false)
												setCurrentTab('Times & Faults')
										}}
										>
										{intl.formatMessage({
												id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.EDITFAULTTIME.COMMON.BUTTON.CANCEL',
										})}
										</button>
										<button
										id='PrizeAndResultsTabOKButton'
										type='button'
										className='btn btn-sm btn-dark fw-bold'
										data-bs-dismiss='modal'
										autoFocus
										onClick={() => {
												onEditTimeFault(updatedTripFaultTime)
												setEditFaultTimeForm(false)
												setCurrentTab('Times & Faults')
										}}
										>
										{intl.formatMessage({
												id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.EDITFAULTTIME.COMMON.BUTTON.OK',
										})}
										</button>
								</div>
								{/* end footer */}
								</div>
						</Modal>
					}
				{/* End Modal */}
            <div id="class-detail-prizes-tab" className='form-group mb-2'>
                <div className='row justify-content-between'>
                    <div className='col-lg-9'>
                        <div className='row mb-2'>
                            <div className='col-lg-3'>
                                {
                                    props.form_meta.class_templates && 
                                    <Controller
                                        name="prize_template"
                                        render={({
                                                field: { onChange, value, name },
                                        }) => (
                                            <Select
                                                options = {props.form_meta.class_templates}
                                                menuPosition='fixed'
                                                isDisabled = {methods.getValues('classes.combined_class_id') > 0 || show_financials_locked}
                                                value = { methods.getValues('classes.prize_template') != 'Custom'?
                                                        props.form_meta.class_templates.filter(option => 
                                                                option.label === methods.watch('classes.prize_template')):
                                                                { value: 'Custom', label: 'Custom' }
                                                    }
                                                id = "prize_template"
                                                name={name}
                                                isSearchable={true}
                                                onChange={(class_templates) => {
                                                        onChange(class_templates.value);
                                                        prizeTemplate(class_templates)
                                                }}
                                                theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                })}
                                                styles={{...reactSelectStyles,  menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                            />    
                                            )}
                                        />
                                    }
                            </div>
														<label className='col-lg-1 col-form-label fs-5 py-1 w-90px pe-0' htmlFor='PLACESPAID' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.PLACESPAID">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.LABEL.PLACESPAID' })}</label>
														<div className='col-lg-1 p-0 w-50px'>
																<input
																	{...methods.register( 'places_paid', 
																		{
																			required: false
																		})
																	}
																		disabled ={methods.getValues('disabled')}
																		id='PLACESPAID'
																		onBlur={handlePlacesPaidBlur}
																		type='text'
																		className='form-control form-control-sm  fs-6 min-h-20px py-1 text-end'
																/>
														</div>
														<label className='col-lg-2 col-form-label fs-5 py-1 ps-0 text-end' style={{ width: '125px' }} htmlFor='add_back_per_entry' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.ADDBACKENTRY">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.LABEL.ADDBACKENTRY' })}</label>
														<div className='col-lg-1 p-0 w-60px'>
																<Controller
																		control={methods.control}
																		name="classes.add_back_per_entry"
																		render={({ field: { onChange, name, value } }) => (
																				<NumericFormat
                                                                                    id='add_back_per_entry'
																					prefix={'$'}
                                                                                    name={name}
                                                                                    value={(!value || isNaN(value)) ? parseFloat(0).toFixed(2) : value}
                                                                                    disabled ={methods.getValues('disabled')}
                                                                                    decimalScale={2}
                                                                                    allowNegative={false}
                                                                                    onValueChange={(e) => {
                                                                                        onChange(e.value)
                                                                                    }}
                                                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                                                    onBlur={() => recalculatePrizeMoney(methods.watch('class_prizes'))}
																					onFocus={(e) => {
																						e.target.select(); // Highlight the value
																					}}
																				/>
																		)}
																/>
														</div>
														<label className='col-lg-2 col-form-label fs-5 py-1 ps-0 text-end' style={{ width: '185px' }} htmlFor='addbackentries' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.ADDBACKENTRYOVERRIDE">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.LABEL.ENTRYOVERRIDE' })}</label>
														<div className='col-lg-1 p-0 w-50px'>
																<Controller
																		control={methods.control}
																		name="classes.addbackentries"
																		render={({ field: { onChange, name, value } }) => (
																				<NumericFormat
                                                                                    id='addbackentries'
                                                                                    name={name}
                                                                                    value={(!value || isNaN(value)) ? 0 : Number(value)}
                                                                                    disabled ={methods.getValues('disabled')}
                                                                                    decimalScale={0}
                                                                                    allowNegative={false}
                                                                                    onValueChange={(e) => {
                                                                                        onChange(e.value)
                                                                                    }}
                                                                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                                                    onBlur={() => recalculatePrizeMoney(methods.watch('class_prizes'))}
																				/>
																		)}
																/>
														</div>
                                                        { hasSpecialPermissionToAccess('cloud_classes:change-prize-money') && <>
														{isLocked? 
														<>
															<i onClick={() => handleLock()} className="col-lg-1 d-flex align-items-center fas fa-#E4E6EF fa-lock py-2 w-30px"></i>
															<label className='col-lg-1 col-form-label fs-5 py-1 px-0 w-100px' htmlFor='PRIZELOCKED' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.PRIZES$LOCKED">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PRIZELOCKED' })}</label></>
														:
														<>
															<i onClick={() => handleLock()} className="col-lg-1 d-flex align-items-center fas fa-#E4E6EF fa-lock-open py-2 w-30px"></i>
															<label className='col col-form-label fs-5 py-1 px-0 w-100px text-danger' htmlFor='PRIZELOCKED' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.PRIZES$UNLOCKED">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.PRIZEUNLOCKED' })}</label>
														</>
                                                        }
														</>
                                                        }
												</div>
										</div>
										<div id="split-column" className='col-lg-1 justify-content-end'>
												<div className='row mb-2 justify-content-end'>
														<label className='col-lg-2 col-form-label fs-5 py-1 text-lg-end w-90px' htmlFor='VIEWSPLIT' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.VIEWSPLIT">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.LABEL.VIEWSPLIT' })}</label>
														<div className='col-lg-2 w-90px'>
																{
																		props.form_meta.split_codes && 
																		<Controller
																				name="splitcodes"
																				render={({
																						field: { onChange, value, name },
																				}) => (
																				<Select
																						options={splitCodesList}
																						value = {split_code}
																						id = "splitcodes"
																						name={name}
																						isDisabled = {methods.getValues('classes.combined_class')>0}
																						isSearchable={true}
																						onChange={(split_codes) => {
																								changePrize(split_codes.value)
																								onChange(split_codes.value);
																								setSplitCode(split_codes)
																						}}
																						theme={(theme) => ({
																								...theme,
																								borderRadius: 0,
																						})}
																						styles={reactSelectStyles}
																						placeholder="Select"
																				/>    
																				)}
																		/>
																}
														</div>
														<div className='col-lg-1 ps-lg-0 w-75px'>
																<button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase"
																disabled ={methods.getValues('disabled')} onClick={() => addSplit()}
																data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.BUTTON.SPLIT"
																>
																		{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SPLIT' })}
																</button>
														</div>
														<div className='col-lg-1 ps-lg-0 w-lg-40px'>
																<button type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2"
																disabled ={methods.getValues('disabled')} onClick={() => removeSplit()}
																data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.BUTTON.REMOVE">
																		<i className="fas fa-light fa-xmark fs-5 px-1 py-2"></i>
																</button>
														</div>
												</div>
										</div>
								</div>
								<div className='row'>
									<div className='form-group mb-2'> {/* WA - Row Selector */}
										<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
											<RowSelector
											value={visibleRows}
											onChange={setVisibleRows}
											area={"Classes - PrizesAndResults"}
											/>
										</div>
										<div className='row mb-2'>
											<OutputListingGridStatic 
												stopEditing={handleClassPrizesGridStopEditing} 
												setGridRef={setGridRef}
												columnDefs={columnDefs} 
												rowDataForAGGridWithoutFlickering={rowDataForAGGridWithoutFlickering} 
												containerStyle={containerStyle} 
												stopEditingWhenCellsLoseFocus={true} 
												onCellValueChanged={onCellValueChangedHandler}
												visibleRows={visibleRows}
											/>
										</div>
									</div>
                                    <div className='row pe-0 mt-2'>
                                        <div className='col-lg-3'>
                                            <table className="table gy-0 mb-0">
                                                <thead>
                                                    <tr className="fw-bolder fs-6">
                                                        <th style={{ whiteSpace: 'nowrap' }}>{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SPLITTOTAL' })}</th>
														<th></th><th></th><th></th>
														<th className='text-end' id="splitPrizeMoney">{splitTotal.splitPrizeMoney}</th>
														<th></th><th></th><th></th>
                                                        <th className='text-end' id="splitAddback">{splitTotal.splitAddBack}</th>
														<th></th><th></th><th></th><th></th><th></th>
                                                        <th className='text-end' id="splitTotal">{splitTotal.splitWithAddBack}</th>
                                                    </tr>
                                                    <tr className="fw-bolder fs-6">
                                                        <th>{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.TOTAL' })}</th>
														<th></th><th></th><th></th>
                                                        <th className='text-end' id="totalPrizeMoney">{splitTotal.totalPrizeMoney}</th>
														<th></th><th></th><th></th>
                                                        <th className='text-end'id="totalAddback">{splitTotal.totalAddBack}</th>
														<th></th><th></th><th></th><th></th><th></th>
                                                        <th className='text-end'id="total">{splitTotal.totalWithAddBack}</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className='col-lg-9 pe-0 justify-content-end'>
                                            <div className='row d-flex justify-content-end'>
                                                { Boolean((methods.getValues('classes.class_id')) && methods.getValues('showClassRollbackSelect')) ?
                                                    <div className='col-lg-3'>
                                                        <Select
                                                            isDisabled = {methods.getValues('classes.combined_class_id') > 0 || show_financials_locked}
                                                            value={prizeMoneyOptions.find(opt => opt.value === prizeMoneyAction) ?? prizeMoneyOptions[0]}
                                                            onChange={handlePrizeMoneyChange}
                                                            options={prizeMoneyOptions}
                                                            defaultValue={prizeMoneyOptions[0]}
                                                            styles={reactSelectStyles}
                                                        />
                                                    </div>
                                                    : '' }
                                            </div>
                                            {showFEIPrizeTemplate() ?
                                            <div className='row justify-content-end'>
                                                <div className='col-lg-2 w-175px'>
                                                    <label  className='col-lg-2 col-form-label fs-5 py-1 ps-0 text-end w-125px' data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.FEICLASSPRIZE">
                                                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.FEICLASSPRIZE' })}
                                                    </label>
                                                </div>
                                                <div className='col-lg-1 w-150px'>
                                                    <Controller
                                                        name="classes.fei_prize_money_currency"
                                                        render={({
                                                                field: { onChange, value, name },
                                                            }) => {
                                                            const selectedOption = currencyList.filter(dt=> value?.toLowerCase()?.includes(dt.value.toLowerCase()))
                                                            return(
                                                                <Select
                                                                    // readOnly
                                                                    options = {currencyList}
                                                                    value={selectedOption || null}
                                                                    id = "fei_prize_money_currency"
                                                                    name={name}
                                                                    isSearchable={true}
                                                                    onChange={(curr) => {
                                                                        methods.setValue("classes.fei_prize_money_currency",curr?.value,{shouldDirty: true})

                                                                        // onChange(type.value);
                                                                    }}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                    })}
                                                                    styles={reactSelectStyles}
                                                                    placeholder="Select"
                                                                    menuPosition='fixed' 
                                                                />    
                                                            )}
                                                        }
                                                    />
                                                </div>
                                                <div className='col-lg-1'>
                                                    <Controller
                                                        control={methods.control}
                                                        name="classes.fei_competition_prize_money"
                                                        render={({ field: { onChange, name, value } }) => (
                                                            <NumericFormat
                                                                id='fei_competition_prize_money'
                                                                name={name}
                                                                value={Number(value)}
                                                                allowNegative={false}
                                                                onValueChange={(e) => {
                                                                    onChange(e.value)
                                                                }}
                                                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className='col-lg-2'>        
                                                    <button disabled={show_financials_locked} onClick={() => setShowFeiPrizeTemplate(true)} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.LABEL.SETFEIPRIZETEMPLATE">
                                                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.SETFEIPRIZETEMPLATE' })}
                                                    </button>
                                                </div>
                                            </div>:""}
											<div className='row d-flex justify-content-end'>
												<label className='col col-form-label fw-bold py-1 fs-5 text-danger text-end'>
														{ props.classStatus }
												</label>
											</div>
                                        </div>
                                    </div>
								</div>
                                <div className='d-flex mb-1 justify-content-between'>
                                    {methods.getValues('classes.verification_detail') ?
                                        <label className='col-form-label fs-6 text-muted'>
                                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.ENTRIESLASTVERIFIED' }) + methods.getValues('classes.verification_detail')}
                                        </label> : <label></label>
                                    }
                                    <label className='col-form-label fs-5 text-danger'>
                                        { prizeErrorMessage }
                                    </label>
                                </div>
								<div className="row mb-2">
                                    <div className='w-lg-150px'>
										{ methods.getValues('classes.class_id') ? 
                                        <button 
                                            disabled={methods.getValues("classes.combined_class_id") > 0 ? true : false}
                                            type='button' 
                                            className="btn btn-sm btn-secondary fw-bold text-uppercase"
                                            onClick={() => setShowVerifyRoster(true)}
											data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.BUTTON.VERIFYROSTER"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER' })}
                                        </button>
										: '' }
                                    </div>
                                    <div className='w-lg-200px px-0'>
                                        <button 
                                            type='button' 
                                            disabled={methods.getValues('classes.combined_class_id') > 0}  
                                            className="btn btn-sm btn-secondary fw-bold text-uppercase" 
                                            data-toggle="modal" 
                                            onClick={()=>{setEditFaultTimeForm(true)}}
											data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.BUTTON.EDITFAULTTIME"
                                        >
                                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.EDITFAULTTIME' })}
                                        </button>
                                    </div>
                                    
                                    <div className='col d-flex justify-content-end gap-2'>
                                        { showAddButton && [
                                            <div className='col-lg-3'>
                                                <Select
                                                    options={currentEntry?.EntryRiders?.map(er => ({
                                                        label: er.rider_name, value: er.rider_id
                                                    })) ?? []}
                                                    styles={reactSelectStyles}
                                                    theme={(theme) => ({ ...theme, borderRadius: 0})}
                                                    menuPlacement="top"
                                                    onChange={e => setSelectedRider(e.value)}
													isDisabled={show_financials_locked}
                                                />
                                            </div>,
                                            <button 
                                                type='button' 
                                                className="btn btn-sm btn-secondary fw-bold text-uppercase"
                                                onClick={() => props.addEntry(setShowCombinedClassPopup, setActualClasses, setShowAddButton, setPrizeErrorMessage, currentEntry, selectedRider)
                                                }
                                                disabled={!selectedRider}
												data-tooltip-id="CLASS.DETAIL.TAB.PRIZESANDRESULTS.BUTTON.ADDENTRYTOCLASS"
                                            >
                                                { intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.ADDENTRYTOCLASS' })}
                                            </button>]
                                        }
                                        {/* <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase">
                                                {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.REPRINTCHECK' })}
                                        </button> */}
                                    </div>
								</div>
						</div>
				</>
		);
}
export { PrizesAndResultsTab }