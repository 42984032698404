/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';
import moment from 'moment';
import ClassGroupsGrid from './AnnouncerSchedulerClassGroupsGrid';
import ClassGroupsScheduleGrid from './ClassGroupsScheduleGrid';
import ScheduleBreaksGrid from './ScheduleBreaksGrid';
import { MoveGroup } from './MoveGroup';
import { SignupGroup } from './SignupGroup';
import { RefreshSchedule, PendingSyncStats } from './ScheduleButtons';
import { SchedulerContextProvider } from './SchedulerContext';
import AddEditGroup from './AnnouncerSchedulerAddEditGroup';
import TotalEntries from './TotalEntries';
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { PageTitle } from '../../../../_metronic/layout/core';
import { SESSION_STORAGE_CURRENT_RING_ID } from '../../../modules/sgl-utils/sglConstants';
import MinutesPerTrip from './MinutesPerTrip';
import HelpLink from '../../../modules/components/HelpLink';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const AnnouncerScheduler = () => {
    const intl = useIntl();
    const [showMoveGroup, setShowMoveGroup] = useState(false)
    const [showSignupGroup, setShowSignupGroup] = useState(false)
    const [showDates, setShowDates] = useState([]);
    const [showRings, setShowRings] = useState([]);
    const [selectedDate, setSelectedDate] = useState(() => sessionStorage.getItem("selectedDate") || "");
    const [selectedRing, setSelectedRing] = useState(() => sessionStorage.getItem("selectedRing") || 0);
    const [scheduleStatus, setScheduleStatus] = useState();
    const [currentClassGroup, setCurrentClassGroup] = useState({});
    const classGroupsGridRef = useRef();
    const alertDialog = useAlert();
    
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state=> state.showCompany.company_id);

    useEffect(() => {
        setupScheduler();
        
    }, [currentShowID, customerID]);

    async function setupScheduler() {
        if(!currentShowID || !customerID){ clearSchedulerState(); return false; }

        axios.get(`${NEST_API_URL}/scheduler?show_id=${currentShowID}&customer_id=${customerID}`)
        .then(base_response => {
            if(base_response.data.Days == null || base_response.data.RingxShows == null){ clearSchedulerState(); return false; }
            
            setShowDates(base_response.data.Days);
            setShowRings(base_response.data.RingxShows);

            if (sessionStorage.getItem("selectedDate")) {
                setSelectedDate(sessionStorage.getItem("selectedDate"));
            }else{
                const currentDate = moment().format("YYYY-MM-DD")
                const isCurrentDatePresent = base_response.data.Days.find((day) => day == currentDate)
                setSelectedDate( isCurrentDatePresent ? currentDate : base_response.data.Days[0]);
            }

            // // If there's a ring ID in the session storage, and it matches a ring in the current show, select that ring. If no matching ring is found, select the first ring from the current show.
            // const currentRingId = sessionStorage.getItem(SESSION_STORAGE_CURRENT_RING_ID)
            // if(currentRingId) {
            //     if (base_response.data.RingxShows.find(rxs => rxs.ring_id == currentRingId)) {
            //         setSelectedRing(currentRingId)
            //     }
            // } 

            if(sessionStorage.getItem("selectedRing")) {
                setSelectedRing(sessionStorage.getItem("selectedRing"));
            } else { // Set First Ring as Selected
                setSelectedRing(base_response.data.RingxShows[0].ring_id);
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    const clearSchedulerState = () => {
        setShowDates([]);
        setShowRings([]);
        setSelectedDate('');
        setSelectedRing(0);
    }

    
    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
        sessionStorage.setItem("selectedDate", newDate);
    };

    const handleRingChange = (e) => {
        const newRing = e.target.value;
        setSelectedRing(newRing);
        // Set/Update Current Ring In Session Storage
        sessionStorage.setItem(SESSION_STORAGE_CURRENT_RING_ID, newRing)
        sessionStorage.setItem("selectedRing", newRing);
    };

  return (
    <>
    <SchedulerContextProvider>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SETPLANNEDTIME'})}</PageTitle>
      <div className='modal-body py-0 px-4 scheduler bg-white'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
        <HelpLink tooltip="SETPLANNEDTIME.DETAIL.LINK.HELP" classname="top" />
        <div className='card-body p-0'> {/* Card Body */}
          <div className="form-group row"> {/* Column Group */}
            <div className='col-lg-8'>{/* Column 1 */}
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-100px' htmlFor='SHOWDATE' data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.SHOWDATE">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.SHOWDATE'})}</span>
                    </label>

                    <div className='col-lg-4'>
                        <select
                            id="SHOWDATE"
                            className='form-select  form-select-sm fs-6 min-h-20px py-1'
                            onChange={(e) => handleDateChange(e)}
                            value={selectedDate}
                            >
                            {
                                showDates.map(date =>
                                <option key={date} value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>)
                            }
                        </select>
                    </div>

                    <label className='col-lg-2 col-form-label fs-5 py-1 w-50px' htmlFor='RING' data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.RING">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.RING'})}</span>
                    </label>

                    <div className='col-lg-4 '>
                        <select
                            id="RING"
                            className='form-select  form-select-sm fs-6 min-h-20px py-1'
                            onChange={(e) => { 
                                handleRingChange(e)
                            }}
                            value={selectedRing}
                            >
                            {
                                showRings.map(ring =>
                                <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                            }
                        </select>
                    </div>

                    <RefreshSchedule />
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-100px' htmlFor='NUMBERENTRIES' data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.NUMBEROFENTRIES">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.NUMBERENTRIES'})}</span>
                    </label>
                    <div className='w-lg-75px'>
                       <TotalEntries />
                    </div>
                    <MinutesPerTrip />

                    { (currentClassGroup.cancelled == true) &&
                    <label className='col-lg-5 col-form-label fs-5 py-1 justify-content-end align-self-end' style={{ color: "#e62721" }} data-tooltip-id="SETPLANNEDTIME.DETAIL.LABEL.CANCELSTATUS">
                       {intl.formatMessage({id: 'SCHEDULER.LABEL.CANCELSTATUS'})}
                    </label>
                    }
                </div>
            </div>

            <div className='col-lg-4'>{/* Column 2 */}
                <fieldset>
                    <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"red"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.RED'})}</span>
                            </span>
                        </label>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"green"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.GREEN'})}</span>
                            </span>
                        </label>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"yellow"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.YELLOW'})}</span>
                            </span>
                        </label>
                    </div>
                </fieldset>
            </div>
          </div> {/* End Column Group */}

          <div className="form-group row"> {/* Column Group */}
            <div className='col'>{/* Column 1 */}
                <div className='row mb-0'>
                    <div className='grid-classgroups'>
                        {<ClassGroupsGrid showDates={showDates} date={selectedDate} showRings={showRings} ring={selectedRing} setScheduleStatus={setScheduleStatus} classGroupsGridRef={classGroupsGridRef} autoHeight={false}></ClassGroupsGrid>}
                    </div>
                </div>
                <div className='row mb-2 mt-2'>
                    {<AddEditGroup setScheduleStatus={setScheduleStatus} setCurrentClassGroup={setCurrentClassGroup}/>}
                </div>
            </div>
            <div className='col-lg-4' style={{ display: 'none'}}>{/* Column 2 */}
                <fieldset>
                <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.SCHEDULEBREAKS'})} <b></b></legend>
                    <div className='grid-schedule-breaks'>
                        {<ScheduleBreaksGrid setScheduleStatus={setScheduleStatus}></ScheduleBreaksGrid>}
                    </div>
                </fieldset>

                <fieldset>
                <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.SCHEDULE'})} <b></b></legend>
                    <div className='grid-schedule'>
                        {<ClassGroupsScheduleGrid setScheduleStatus={setScheduleStatus}></ClassGroupsScheduleGrid>}
                    </div>
                </fieldset>
            </div>
          </div>
        </div>{/* End Card Body */}

        <div className='card-footer py-1 px-0 row' style={{ display: 'none' }}>   
            <PendingSyncStats></PendingSyncStats>

            <label className='col col-6 col-label fw-bold fs-5 py-1 d-flex justify-content-end align-self-end' style={{ color: "#e62721" }}>
            { scheduleStatus == 'Pending' &&
                intl.formatMessage({id: 'SCHEDULER.STATUS.PENDING'})
            }
            { scheduleStatus == 'Saved' &&
                intl.formatMessage({id: 'SCHEDULER.STATUS.SAVED'})
            }
            </label>         
        </div>

        </form>
      </div>
      { showMoveGroup && 
            <MoveGroup show={showMoveGroup} handleClose={() => setShowMoveGroup(false)}  setScheduleStatus={setScheduleStatus}/>
      }
      <SignupGroup show={showSignupGroup} handleClose={() => setShowSignupGroup(false)} />
      </SchedulerContextProvider>
    </>
  )
}

export {AnnouncerScheduler}
