import {useMemo, useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useFormContext} from 'react-hook-form'
import {useAlert, useLoadingOverlay} from '../../../modules/sgl-utils/DialogsProvider'
import {AgGridReact} from 'ag-grid-react'
import {useAppSelector} from '../../../redux/hooks'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import axios from 'axios'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../modules/sgl-utils/agGridHelpers'
import useAccessChecker from '../../../modules/hooks/use-access-checker'
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions'

const BarnsTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const showBarns = methods.getValues('associated_barns')
  const [rowData, setRowData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [addedBarns, setAddedBarns] = useState([])
  const [deletedBarns, setDeletedBarns] = useState([])
  const [barnxFacilityGridApi, setBarnxFacilityGridApi] = useState(null)
  const [barnxFacilityColumnApi, setBarnxFacilityColumnApi] = useState(null)
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const [showDialog, setShowDialog] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [availableToAddBarns, setAvailableToAddBarns] = useState([])
  const alertDialog = useAlert()
  const loadingOverlay = useLoadingOverlay()
  const { hasAreaWritePermission, hasAreaDeletePermission } = useAccessChecker()
  const propFacilityId = props?.showData?.show?.facility_id;

  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridClassName = "ag-theme-alpine";
  const rowHeight = getNarrowRowHeight;
  const headerHeight = getNarrowHeaderHeight;
  /* WA - Row Selector */

  const containerStyle = useMemo(() => ({width: '100%', height: '350px'}), [])

  //Hook to load show barns in list on load
  useEffect(() => {
    if(props?.showData?.show?.facility_id >= 0 && (methods.watch('show.facility_id') === propFacilityId)){
        // rowData will be initialized by database saved records
        setRowData(showBarns)
        //Get barns for show facility in order to check missing barns
        getShowFacilityBarns(methods.getValues('show.facility_id'))
        .then((facilityBarns) => {
          const barnsNotAddedInShow = facilityBarns.filter((barn) => {
            return !showBarns.some((showBarn) => showBarn?.barn_id === barn?.barn_id)
          })
          setAvailableToAddBarns(barnsNotAddedInShow)
      })
    }
  }, [showBarns, propFacilityId, methods.watch('show.facility_id')])

  // triggers when a new facility is selected 
  useEffect(() => {
    if(methods.getValues('facilityChangedFromRings') > 0 ){
        getShowFacilityBarns(methods.getValues('show.facility_id'))
          .then((facilityBarns) => {
            if(rowData){
              setDeletedBarns([...deletedBarns, ...rowData]);
            }
            setAvailableToAddBarns([])
            setRowData(facilityBarns)
            setAddedBarns(facilityBarns)
        })
    }
  }, [methods.watch('facilityChangedFromRings')])

  const facilityBarnsColumnDefs = [
    {
      field: 'barn_name',
      flex: 1,
      headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.BARNS.LABEL.AVAILABLEBARNS'}),
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
  ]

  const columnDefs = [
    {
      field: 'barn_number',
      headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.BARNS.LABEL.NUMBER'}),
      minWidth: 110,
      maxWidth: 110,
    },
    {
      field: 'barn_name',
      flex: 1,
      headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.BARNS.LABEL.BARNNAME'}),
      sortable: true,
      sortingOrder: ['asc', 'desc'] 
    },
  ]

  const handleButtonClick = () => {
    if(availableToAddBarns.length > 0){
      setShowDialog(true)
    }
  }

  const handleDialogClose = () => {
    setShowDialog(false)
  }

  const handleOnRowSelected = (event) => {
    if (event.node.selected) {
      setSelectedRowData(event.data);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 110,
      width: 110,
      resizable: true,
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellStyle: function (params) {
        if (typeof params.value === 'number') {
          return {textAlign: 'center'}
        } else {
          return {textAlign: 'left'}
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: 'ag-center-aligned-header',
    }
  }, [])

  /* WA - Row Selector */
  const effectiveContainerStyle = useMemo(() => {
    // Get actual number of rows to display (min of visibleRows and actual data length)
    const actualVisibleRows = rowData?.length > 0 
      ? Math.min(visibleRows, rowData?.length)
      : visibleRows;
  
    // Calculate the computed height based on actual visible rows
    const computedHeight = (actualVisibleRows * rowHeight) + headerHeight + 3; //3px is for vertical borders
    
    // If container style exists and visible rows is greater than 0, compare heights
    if (visibleRows > 0) {
      // Parse the container style height (removing 'px' if present)
      const containerHeight = parseInt(containerStyle?.height);

      // If row data is empty, we return the existing container height
      if(parseInt(rowData?.length) == 0 && containerHeight > 0 ){
        return containerStyle;
      }
      
      // If container height is greater than computed height, use container height
      if (containerHeight > computedHeight) {
        return containerStyle;
      }
    } else if (containerStyle) {
      return containerStyle;
    }
    
    // Default to computed height based on visible rows
    return {
      width: '100%',
      height: `${computedHeight}px`
    };
  }, [containerStyle, visibleRows, rowData?.length]);
  /* WA - Row Selector */

  useEffect(() => {
    // update added_barns array
    methods.setValue('barns', addedBarns, {shouldDirty: true})
  }, [addedBarns])

  const onGridReady = (params) => {
    setBarnxFacilityGridApi(params.api)
    setBarnxFacilityColumnApi(params.columnApi)
  }

  const handleOnRowDragEnd = () => {
    // Get the total number of rows in the grid
    let totalRowCount = barnxFacilityGridApi.getDisplayedRowCount()

    // Iterate through each row index and retrieve the row data object
    const newOrderedAllbarns = []
    for (let i = 0; i < totalRowCount; i++) {
      let displayedRowData = barnxFacilityGridApi.getDisplayedRowAtIndex(i).data
      displayedRowData.barn_number = i + 1
      newOrderedAllbarns.push(displayedRowData)
    }

    // Add the new row to the addedBarns array
    setAddedBarns(newOrderedAllbarns)

    setRowData(newOrderedAllbarns)

    // clear column state (sort order)
    barnxFacilityColumnApi.resetColumnState()
  }

  const handleOnMinusClick = () => {
    if (!selectedRowData) {
      // no row is selected, do nothing
      return
    }

    setAvailableToAddBarns([...availableToAddBarns, selectedRowData])
    setDeletedBarns([...deletedBarns, selectedRowData])
    const newRowData = rowData.filter((row) => row.barn_id !== selectedRowData.barn_id)

    // Update the 'number' property of the remaining rows
    for (let i = 0; i < newRowData.length; i++) {
      newRowData[i].barn_number = i + 1
    }

    // Clear the selected row
    setSelectedRowData(null)

    // Add the new row to the addedBarns array
    setAddedBarns(newRowData)
    // Save the updated row data to state
    setRowData(newRowData)
  }

  useEffect(() => {
    methods.setValue('deleted_barns', deletedBarns, {shouldDirty: true})
  }, [deletedBarns])

  async function getShowFacilityBarns(facility_id) {
    // Show loading overlay
    if(!props.hideLoadingOverlay){ loadingOverlay({show: true}) }
    let facilityBarns = await axios.get(process.env.REACT_APP_NEST_API_URL + '/facilities/facilityBarns', {
      params: {
        facility_id: facility_id,
        customer_id: customer_id,
      },
    });

    //hide loading overlay  
    loadingOverlay({show: false})
    return facilityBarns.data;
  }

  const addRow = () => {
    let newRowData = [...rowData]
    let barnNumber = newRowData.length + 1
    selectedRows.forEach((selectedRow) => {
      // Check if the selected row is already in the rowData array
      if (!newRowData.some((row) => row.barn_id === selectedRow.barn_id)) {
        let newbarn = {
          barn_id: selectedRow.barn_id,
          barn_number: barnNumber,
          barn_name: selectedRow.barn_name,
        }
        newRowData.push(newbarn)
        barnNumber++
      } else {
        // Show alert message if the selected row is already in the rowData array
        alertDialog({
          message: `The selected barn (${selectedRow.barn_name}) has already been added.`,
          title: 'Alert',
        })
      }
    })
    // Remove selected rows from facilityBarnsRowData
    let newDeleteArray = deletedBarns.filter((row) => {
      return !selectedRows.some((selectedRow) => selectedRow.barn_id === row.barn_id)
    })

    let newFacilityBarnsRowData = availableToAddBarns.filter((row) => {
      return !selectedRows.some((selectedRow) => selectedRow.barn_id === row.barn_id)
    })
    // Add the new row to the addedBarns array
    setAddedBarns(newRowData)
    setRowData(newRowData)
    setAvailableToAddBarns(newFacilityBarnsRowData)
    setDeletedBarns(newDeleteArray)
    setSelectedRows([])
  }

  // Enable Alpha sorting 
  const onSortChanged = useCallback((gridParams) => {
    const sortModel =  gridParams.columnApi.getColumnState().find(s => s.sort != null)
    if (sortModel && Object.keys(sortModel)?.length) {
      const sortedColumn = sortModel?.colId
      if (sortedColumn === 'barn_name') {
        // Get the total number of rows in the grid
        let totalRowCount = gridParams.api.getDisplayedRowCount()

        // Iterate through each row index and retrieve the row data object
        const sortedNodes = []
        for (let i = 0; i < totalRowCount; i++) {
          let displayedRowData = gridParams?.api?.getDisplayedRowAtIndex(i)?.data
          displayedRowData.barn_number = i + 1
          sortedNodes.push(displayedRowData)
        }

        setAddedBarns(sortedNodes)
        setRowData(sortedNodes)
      }
    }
  }, [])

  // Removes sorting to enable drag/drop
  const onRowDragEnter = useCallback((gridParams) => {
    const sortModel =  gridParams.columnApi.getColumnState().find(s => s.sort != null)
    if (sortModel && Object.keys(sortModel)?.length) {
      const sortedColumn = sortModel?.colId
      if (sortedColumn === 'barn_name') {
        gridParams.columnApi.resetColumnState()
      }
    }
  }, [])

  return (
    <>
      <div className='row mb-2'>
        <div className='col-lg d-flex align-items-center mb-2'>
          <div style={{width: '100%'}}> {/* WA - Row Selector */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Shows - Barns"}/>
            </div>
            <div style={effectiveContainerStyle}>
              <div style={gridStyle} className={gridClassName}>
                <AgGridReact
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  rowDragEntireRow={true}
                  rowDragManaged={true}
                  suppressMoveWhenRowDragging={false}
                  animateRows={true}
                  onDragStopped={handleOnRowDragEnd}
                  onRowSelected={handleOnRowSelected}
                  rowSelection={'single'}
                  headerHeight={getNarrowHeaderHeight}
                  rowHeight={getNarrowRowHeight}
                  suppressRowHoverHighlight={true}
                  onSortChanged={onSortChanged}
                  onRowDragEnter={onRowDragEnter}
                ></AgGridReact>
              </div>
            </div>
          </div> {/* WA - Row Selector */}
        </div>

        <div className='col-lg-1'>
          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleButtonClick}
            disabled={availableToAddBarns.length === 0}
            data-tooltip-id="SHOW.DETAIL.TAB.BARNS.BUTTON.ADD"
          >
            <i className='fas fa-plus fs-5 px-1 py-3'></i>
          </button>
          <div className='separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none'></div>

          <button
            className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2'
            type='button'
            onClick={handleOnMinusClick}
            data-tooltip-id="SHOW.DETAIL.TAB.BARNS.BUTTON.REMOVE"
          >
            <i className='fas fa-minus fs-5 px-1 py-3'></i>
          </button>
        </div>
      </div>
      <Modal
        id='kt_modal_create_app'
        show={showDialog}
        onHide={handleDialogClose}
        tabIndex={-1}
        backdrop='static'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
        onKeyDown={(event) => { //replaced onKeyPress with onKeyDown
          let isButtonFocused = false
          let activeElement = document.activeElement //gets the currently focussed element
          if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
              isButtonFocused = true;
          }
          if (event.key === "Enter" && !isButtonFocused) {
            addRow()
          }
        }}
      >
        <div className='modal-header py-0 px-4'>
          <h2 className='fs-4'>
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TITLE.FACILITYBARNS'})}
          </h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleDialogClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body py-3 px-4'>
          <div className='ag-theme-alpine' style={{height: '300px', width: '100%'}}>
            <AgGridReact
              headerHeight={getNarrowHeaderHeight}
              rowHeight={getNarrowRowHeight}
              rowData={availableToAddBarns}
              columnDefs={facilityBarnsColumnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              rowMultiSelectWithClick={true}
              onSelectionChanged={(event) => {
                setSelectedRows(event.api.getSelectedRows())
              }}
            />
          </div>
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button className='btn btn-sm btn-secondary me-4 fw-bold' type='button' onClick={handleDialogClose}>
              {intl.formatMessage({
                id: 'FORM.INPUT.SHOWS.FACILITYBARNS.BUTTON.CLOSE',
              })}
            </button>
            <button className='btn btn-sm btn-dark fw-bold' type='button' onClick={addRow} autoFocus data-tooltip-id="SHOW.DETAIL.MODAL.FACILITYBARNS.BUTTON.ADDTOSHOW">
              {intl.formatMessage({
                id: 'FORM.INPUT.SHOWS.FACILITYBARNS.BUTTON.ADDTOSHOW',
              })}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export {BarnsTab}
