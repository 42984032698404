import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getNarrowHeaderHeight, getNarrowRowHeight, defaultColDef } from "../../../modules/sgl-utils/agGridHelpers"
import { DateTimeRenderer } from '../../../modules/output-listing/renderers/DateTimeRenderer';
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../modules/output-listing/OutputListingRowOptions';

const AuditTrailTab = ({rowData}) => {
    const intl = useIntl()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []); 
    const currencyFormatter = useCurrencyFormatter()

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const columnDefs = [
        { 
            field: 'dateTime', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.AUDITTRAIL.DATETIME' }),
            cellRenderer: DateTimeRenderer,
            maxWidth: 250,
            resizable: true,
            sortable: false,
            cellClass: 'text-center'
        },
        { 
            field: 'user',
            width: 150, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.HISTORY.USER' }),
            resizable: true,
            sortable: false,
            cellClass: 'text-center'
        },
        { 
            field: 'description', 
            flex:1, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.AUDITTRAIL.DESCRIPTION' }),
            cellClass: 'text-left',
            resizable: true,
            sortable: false
        },
        { 
            field: 'amount', 
            width: 150, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.AUDITTRAIL.AMOUNT' }),
            valueGetter: (params) => {
                if(params.data?.user == 'Server Process' && params.data?.amount == 0){
                    return ''
                }
                else{
                    if(params.data.action == 'Debit'){
                        return currencyFormatter(Math.abs(params.data.amount))
                    }else{
                        return '('+ currencyFormatter(Math.abs(params.data.amount)) + ')'
                    }
                }
            },
            cellStyle:  params => params.data.action == 'Debit'? {color: 'black', textAlign: 'right'}: {color:'red', textAlign: 'right'},
            resizable: true,
            sortable: false,
            
        },
        { 
            field: 'balance', 
            width: 150, 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.AUDITTRAIL.BALANCE' }),
            valueGetter: (params) => {
                let taxSymbol = ''
                if(params.data.include_tax){
                    taxSymbol = '*'
                }
                if(params.data.balance >= 0){
                    return taxSymbol + currencyFormatter(params.data.balance)
                }else{
                    return taxSymbol + '('+ currencyFormatter(Math.abs(params.data.balance)) + ')'
                }
            },
            cellStyle: {textAlign: 'right'},
            resizable: true,
            sortable: false
        },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    return (
        <>
            <div className='form-group mb-2'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>  {/* WA - Row Selector */}
                    <RowSelector
                    value={visibleRows}
                    onChange={setVisibleRows}
                    area={'Entries - AuditTrail'}
                    />
                </div> {/* WA - Row Selector */}
                <div className='row mb-2'>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        <OutputListingGridStatic 
                            area={'Entries - AuditTrail'}
                            columnDefs={columnDefs} 
                            defaultColDef={{
                                ...defaultColDef,
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                            }}
                            rowData={rowData} 
                            containerStyle={containerStyle}
                            rowHeight={getNarrowRowHeight}
                            headerHeight={getNarrowHeaderHeight}
                            visibleRows={visibleRows} /* WA - Row Selector */
                        />
                    </div>
                </div>
                <div className='col text-end'><label className='fs-6' data-tooltip-id="ENTRIES.DETAIL.TAB.AUDITTRAIL.LABEL.INCLUDETAX"><em><strong> * {intl.formatMessage({id: "FORM.INPUT.COMMON.TAB.AUDITTRAIL.LEGEND"})}</strong></em></label></div>
            </div>
        </>
    );
}

export { AuditTrailTab }