import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOrderOfGoContext } from '../OrderOfGoContext';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';

const TrainerGrid = () => {
    const intl = useIntl();
    const { trainerRowData, setTrainerGridApi } = useOrderOfGoContext()
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const containerStyle = useMemo(() => ({ width: '100%', minHeight: '200px' }), []);

    const scrollIntervalRef = useRef(null);
    const isDraggingRef = useRef(false);

    const columnDefs = [
        {   
            field: 'trainer', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.TRAINER"}), 
            sortable: false, 
            suppressMenu: true, 
            suppressSizeToFit: true,
            flex: 1 
        },
        { 
            field: 'trips', 
            headerName: intl.formatMessage({id: "ORDEROFGO.GRID.LABEL.TRIPS"}), 
            sortable: false, 
            suppressMenu: true, 
            headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header',
            cellClass: 'text-center',
            width: 100
        },
    ]    

    const cleanupScrolling = () => {
        if (scrollIntervalRef.current) {
            window.clearInterval(scrollIntervalRef.current);
            scrollIntervalRef.current = null;
        }
        isDraggingRef.current = false;
    };

    useEffect(() => {
        return () => cleanupScrolling();
    }, []);

    const handleDragStart = () => {
        isDraggingRef.current = true;
        
        const handleScroll = (e) => {
            if (!isDraggingRef.current) return;

            const mouseY = e.clientY;
            const viewportHeight = window.innerHeight;
            const scrollThreshold = 100;
            const scrollStep = 5;

            // Clean up any existing interval
            if (scrollIntervalRef.current) {
                window.clearInterval(scrollIntervalRef.current);
                scrollIntervalRef.current = null;
            }

            if (mouseY < scrollThreshold) {
                // Scroll up
                scrollIntervalRef.current = window.setInterval(() => {
                    window.scrollBy({
                        top: -scrollStep,
                        behavior: 'auto'
                    });
                }, 16);
            } else if (mouseY > viewportHeight - scrollThreshold) {
                // Scroll down
                scrollIntervalRef.current = window.setInterval(() => {
                    window.scrollBy({
                        top: scrollStep,
                        behavior: 'auto'
                    });
                }, 16);
            }
        };

        // Add mousemove listener
        window.addEventListener('mousemove', handleScroll);
        
        // Return cleanup function
        return () => {
            window.removeEventListener('mousemove', handleScroll);
            cleanupScrolling();
        };
    };

    const handleDragEnd = () => {
        cleanupScrolling();
    };


    const onGridReady = (params) => {
        setTrainerGridApi(params.api)
    };

    return (
        <>
            <div className="col cursor-pointer">
                <fieldset>
                    <legend className='mt-2 fs-5 py-1 fw-bold d-flex justify-content-between align-items-center'>
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.TRAINERGRID.LABEL.STEP.TWO" }) }
                        </span>
                        <span>
                            { intl.formatMessage({ id: "ORDEROFGO.TRAINERGRID.LABEL.MULTIPLETRIPSTRAINER" }) }
                            {": "} 
                            { trainerRowData.length }
                        </span>
                    </legend>
                </fieldset>
            </div>
            <div className='col d-flex'>
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={trainerRowData}
                            headerHeight={getExtraNarrowHeaderHeight}
                            rowHeight={getNarrowRowHeight}
                            rowDragEntireRow={true}
                            rowDragManaged = {true}
                            onDragStarted={handleDragStart}
                            onDragStopped={handleDragEnd}
                            suppressMoveWhenRowDragging = {false}
                            animateRows = {true}      
                            rowSelection={'multiple'}
                            enableRangeSelection={false}
                            getRowStyle={params => ({ backgroundColor: params.data.rowColor })}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while trainers list is being loaded..</span>'}
                            domLayout='autoHeight'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TrainerGrid