import { useIntl } from 'react-intl'
import axios from 'axios';
import { useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import {FormProvider, Controller, useForm} from 'react-hook-form'
import Select from 'react-select'
import { useAlert, useConfirm } from '../../modules/sgl-utils/DialogsProvider';
import { useAppDispatch } from '../../redux/hooks';
import { setNewShowId } from '../../redux/reducers/newShowIdReducer';
import {
  reactSelectStyles,
} from '../../modules/sgl-utils/fieldControls'
// Custom Components
import { GeneralTab } from './Detail/GeneralTab'
import { HistoryTab } from '../../modules/components/HistoryTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { PageTitle } from '../../../_metronic/layout/core'
import {useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'
import { setCurrentListRecordIndex, setListIds } from '../../redux/reducers/currentDetailPageInfoReducer';

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const ShowFeeDetail = (props) => {
    const intl = useIntl();
    const [form_meta, setFormMeta] = useState({})
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    
    const navigate = useNavigate();
    const {showfees_id} = useParams();
    let current_showfees_id = props.id || props.id == 0 ? props.id : showfees_id
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const[loading,setLoading] = useState(true)
    const [masterFeeID, setMasterFeeID] = useState('')
    const [isMasterFeeEnable, setIsMasterFeeEnable] = useState(false)
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const dispatch = useAppDispatch()
    const [show_description, setShowDescription] = useState(null)
    const loadingOverlay = useLoadingOverlay()
    const { handleDetailPageCatch, hasAreaWritePermission } = useAccessChecker()
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const [syncEvent, setSyncEvent] = useState(null); 
    const methods = useForm({
        defaultValues: 
        {
            "showfees": {
                "sgl_id": 0,
                "showfees_id": 0,
                "description": "", 
                "abbreviation": "", 
                "tax_rate_id": 0,
                "show_tax_rate_id": 0, 
                "unit_price": 0,
                "default_qty": 0,
                "web_default_qty": 0,
                "inventory_cap": 0,
                "cost": 0.00,
                "stablefee": 0,
                "dont_delete_forscratchentry": 0,
                "feed_bedding_fee": 0,
                "appearonstatement": 0,
                "pass_through": 0,
                "web_deposit_required": 0,
                "card_fee": 0,
                "directuserordering":0
              },
        }
      });

    const { handleSubmit, reset, formState, setFocus } = methods
    const { dirtyFields } = formState; 
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const tabs = ["GENERAL", "HISTORY"]
    const componentTags = [
        <GeneralTab form_meta={form_meta} showfees_id={current_showfees_id}/>, 
        <HistoryTab area={"Fees"} rowData={methods.getValues('change_history_logs')}
    />]
    const [selectedTab, setSelectedTab] = useState(() => {
      const hash = window.location.hash.substring(1);
      return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
    });

    // Whenever a value from select fee drop changes, The form data will be updated from master fee table
    // But only for new record inside showfee, and feilds that were disbaled should be disabled for new record too
    // when showfee.showfeeID = 0 then on masterfee ID change we will call an api that will be getting data from master fee Record
    const updateFromMasterFee = (masterFeeID) => {
      if(current_showfees_id == 0 || isMasterFeeEnable){
        // call api for masterFeeID and get data from masterfee table for new record only. 
        axios.all(
          [
            axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/masterdetail', {
              params: {
                customer_id: customer_id,
                master_fee_id: masterFeeID? masterFeeID: 0,
                show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID 
              }
            }),
            axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/MetaData', {
              params: {
                  show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID ,
                  customer_id: customer_id,
                  showfees_id: current_showfees_id,
              }
            }),
          ]  
          ).then (
            axios.spread( ({data : showFeeData},{data : masterFeeMetaData}) => {
              if(showFeeData.master_fee!=null){
                setMasterFeeID(showFeeData.master_fee.master_fee_id)
              }
              const { description, unit_price, inventory_cap, ...otherFields } = showFeeData.showfees;
              methods.setValue('showfees', { ...otherFields, description: show_description, unit_price : showFeeData.master_fee.default_price, inventory_cap : showFeeData.master_fee.default_show_inventory_cap}, { shouldDirty: true });
              methods.setValue('master_fee', showFeeData.master_fee, {shouldDirty:true})
              methods.setValue('show', showFeeData.show, {shouldDirty:true})
           
              setFormMeta(masterFeeMetaData)
            })
          )
      }
    }

    useEffect(()=>{
      if(show_description !== null){
        if(current_showfees_id === 0 || current_showfees_id === '0' || isMasterFeeEnable){
          if(masterFeeID === ''){
            setShowDescription('')
          }
          updateFromMasterFee(masterFeeID)
        }
      }
    },[show_description])

    useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/detail', {
            params: {
              showfees_id: current_showfees_id,
              show_id:props?.parent_area === "Shows" ? props.parent_id : currentShowID,
              customer_id: customer_id,
            }
          }),
          axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/MetaData', {
            params: {
                show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID ,
                customer_id: customer_id,
                showfees_id: current_showfees_id,
            }
          }),
        ]  
        ).then (
          axios.spread( ({data : showFeeData},{data : masterFeeMetaData}) => {
            //Check if record id is invalid then redirect to list page
            if ((showFeeData || showFeeData === '') && isInvalidRecord(showFeeData.showfees, current_showfees_id, 'showfees_id')){
                navigate('/fees')
                loadingOverlay({show: false})
            }
            if(current_showfees_id > 0 && showFeeData.showfees.show_id != currentShowID){ //check if input show does not match current show in show Picker
              dispatch(setNewShowId(showFeeData.showfees.show_id)) //In this case update Show in ShowPicker
            }
            setFormMeta(masterFeeMetaData)
            if(showFeeData.showfees!=null){
              setMasterFeeID(showFeeData.showfees.master_fee_id)
              setIsMasterFeeEnable(showFeeData.showfees.master_fee_id == 0 && showFeeData.show.masterfees_changeenabled)
            }
            if(props?.id === undefined && Number(methods.getValues('showfees.showfees_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
              reset(showFeeData)
            }else{
                reset(showFeeData, { keepDirtyValues: true })
            }
            // Hide loading overlay
            loadingOverlay({show: false})
            setLoading(false)
          })
        )
        .catch(reason => {
            setLoading(false)
            handleDetailPageCatch(reason.response, loadingOverlay, props)
        })
    },[/*currentShowID,*/ showfees_id])

    useEffect(() => {
      const next_button = document?.getElementById('next-btn');
      const last_button = document?.getElementById('last-btn');
      const prev_button = document?.getElementById('prev-btn');
      const first_button = document?.getElementById('first-btn');
      if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
        next_button.disabled = true;
        last_button.disabled = true;
      }
      if (row_selected == 0 && prev_button && first_button) {
        prev_button.disabled = true;
        first_button.disabled = true;
      }
    }, [loading])
  
    useEffect(() => {
      if(syncEvent){
        let parsedEvent = JSON.parse(syncEvent.data)
        let variableName = ''
        let updateFlag = false
        
        switch(parsedEvent.type){              
          case `Fees-${customer_id}-${currentShowID}-${current_showfees_id}`:
            variableName = 'fees'
            updateFlag = true
            break;
        }

        if(updateFlag){                
          axios.get( process.env.REACT_APP_NEST_API_URL + '/fees/detail', {
            params: {
              showfees_id: current_showfees_id,
              customer_id: customer_id,
              show_id: currentShowID
            }
          }).then((res) => {
            if(parsedEvent.type.includes('Fees')){
              // Keep Dirty Values can't handle arrays, so need to manually handle arrays
              reset(res.data, { keepDirtyValues: true })
            }
          })
        }            
      }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
      removeAllEventListeners()
      return // SK - Disabling sync
      if(currentShowID && current_showfees_id && sse && current_showfees_id > 0){
        let syncEvents = []
        let eventIds = []
        // For Areas who contains show_id should add show id in its event id
        eventIds = [
          `Fees-${customer_id}-${currentShowID}-${current_showfees_id}`,
        ]

        for(let eventId of eventIds){
          // Sync Event will hold an array of eventId and callback
          syncEvents.push({
            eventId, 
            callback: (event) => setSyncEvent(event)
          })
        }
        addSubscribedEvents(syncEvents)   
      }
      return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_showfees_id, sse]);

    const onSubmit = async (form_data) => { 
      const next_button = document.getElementById('next-btn');
      const last_button = document.getElementById('last-btn');
      const prev_button = document.getElementById('prev-btn');
      const first_button = document.getElementById('first-btn');

      // means detail page is not open in modal
      if(props?.id === undefined){
        next_button.disabled = false;
        prev_button.disabled = false;
        last_button.disabled = false;
        first_button.disabled = false;
      }
 
        let submit_data = dirtyValues(dirtyFields, form_data)
        if(submit_data.showfees){
          submit_data.showfees.description = methods.getValues('showfees.description')
        }
        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/fees/detail', {
          params: {
            master_fee_id: masterFeeID,
            show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID ,
            showfees_id: current_showfees_id,
            customer_id: customer_id,
            data: submit_data,
            submit_call_from_shows_area: props?.parent_area === "Shows" ? 1 : 0
          }
        }).then(async (response) => {
          loadingOverlay({show: false})
          if(response.data.success) {
              if(response.data.alert_message && response.data.alert_message != ''){
                await confirmDialog({message: response.data.alert_message, title: 'Warning', hide: true})
              }
              // Use Case (Param Id changed): Next/Prev buttons
              if(props?.id == undefined && Number(methods.getValues('showfees.showfees_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                if(row_selected && row_selected != "" && row_ids[row_selected]){
                  setLoading(true)
                  history.push(`${PUBLIC_URL}/fees/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                }
              }
							else if(props?.id == undefined && current_showfees_id > 0){
                history.push(`${PUBLIC_URL}/fees`, { allowNavigation: true })
              }
              else if(props?.id == undefined && current_showfees_id == 0){
                history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
              }
              else{
                if(props.updateCallbackFromParent){
                  props.updateCallbackFromParent()
                  props.closeModal()
                }
              }

              if( row_selected == row_ids?.length -1 && props?.id === undefined){
                if (next_button && last_button) {
                    next_button.disabled = true;
                    last_button.disabled = true;
                    prev_button.disabled = false;
                    first_button.disabled = false;
                }
              }
              if(row_selected == 0 && props?.id === undefined){
                if (prev_button && first_button) {
                    prev_button.disabled = true;
                    first_button.disabled = true;
                    next_button.disabled = false;
                    last_button.disabled = false;
                }
              }
        } else {
            alertDialog({message: response.data.error_message, title: 'Error'})
        }
        })
    }

    useAutoFocus('SHOWDESCRIPTION', loading, 0, 'showfees', current_showfees_id)

    // navigate to Master Fee detail when clicked on 'Edit Master Fee'
    const navigateToMasterFee = () => {
      if(masterFeeID > 0){
        // Reset redux stored changes for the next/prev buttons to their initial state when the area is changed or closed.
        dispatch(setCurrentListRecordIndex(-1))
        dispatch(setListIds([]))

        navigate('/masterfees/detail/'+masterFeeID)
      }
    }

    return (
        <>
        {!loading && (
			  <>
        {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('showfees.description'), methods.getValues('showfees.showfees_id')]}>{intl.formatMessage({ id: 'MENU.FEES' })}</PageTitle> : "" }
        <FormProvider {...methods}>
            <div id={"fees_detail_page"} className='modal-body py-3 px-4 showfee-input-form input-form'>
                <form id={"ShowFeeDetail_"+current_showfees_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                <HelpLink tooltip="FEES.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row"> {/* Column Group */}
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-90px' htmlFor='SELECTFEE' data-tooltip-id="FEES.DETAIL.LABEL.SELECT.FEE">{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.LABEL.SELECTFEE' })}</label>
                                        <div className='col-lg-4'>
                                            {form_meta.fees_name && (
                                                // <Controller
                                                //     name='showfees.master_fee_id'
                                                //     render={({field: {onChange, value, name}}) => (
                                                //     <Select
                                                //         isDisabled = {(current_showfees_id == 0 || isMasterFeeEnable === true) ? false : true}
                                                //         options={form_meta.fees_name}
                                                //         id='SELECTFEE'
                                                //         value={value !== undefined ? form_meta.fees_name.find((ss) => ss.value === value) : "" }
                                                //         name={name}
                                                //         isSearchable={true}
                                                //         onChange={(fees_name) => {
                                                     
                                                //             onChange(fees_name.value)
                                                //             setMasterFeeID(fees_name.value)
                                                //             setShowDescription(fees_name.label)
                                                //         }}
                                                        
                                                //         theme={(theme) => ({
                                                //         ...theme,
                                                //         borderRadius: 0,
                                                //         })}
                                                //         styles={reactSelectStyles}
                                                //         placeholder='Select'
                                                //     />
                                                //     )}
                                                // />
                                                <Controller
                                                name='showfees.master_fee_id'
                                                render={({ field: { onChange, value, name } }) => {
                                                  const selectedOption = form_meta.fees_name.find((option) => option.value === value);
                                                    return (
                                                        <Select
                                                            isDisabled={(current_showfees_id == 0 || isMasterFeeEnable === true) ? false : true}
                                                            options={form_meta.fees_name}
                                                            id='SELECTFEE'
                                                            value={selectedOption || null}
                                                            name={name}
                                                            isSearchable={true}
                                                            onChange={(fees_name) => {
                                                                onChange(fees_name.value)
                                                                setMasterFeeID(fees_name.value)
                                                                setShowDescription(fees_name.label)
                                                            }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                            })}
                                                            styles={reactSelectStyles}
                                                            placeholder='Select'
                                                        />
                                                    );
                                                }}
                                            />
                                          )}
                                        </div>
                                        { hasAreaWritePermission('masterfees') &&
                                        <div className='col-lg-2 w-lg-150px'>
                                            <button type='button' disabled={props?.id !== undefined} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase" onClick={navigateToMasterFee} data-tooltip-id="FEES.DETAIL.BUTTON.EDITMASTERFEE">
                                                {intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.EDITMASTERFEE' })}
                                            </button>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div> {/* End Column Group */}

                            <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                    <a className={"nav-link text-active-dark py-1 px-10 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                        tabIndex="-1"
                                                        id={tab + "-tab"}
                                                        data-bs-toggle='tab'
                                                        href={"#" + tab +"-ShowFee-"+current_showfees_id}
                                                        data-tooltip-id={`FEES.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.SHOWFEES.TAB.' + tab })}
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className="tab-content mt-5 pb-2" id="myTabContent">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab +"-ShowFee-"+current_showfees_id} role="tabpanel" >
                                                    <HelpLink tooltip={"FEES.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                    {componentTags[index]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/fees'} propId={props?.id}  data={'showfees'}/>
                </form>
            </div>
        </FormProvider>
        </>
    		)}
        </>
    );
}

export { ShowFeeDetail }