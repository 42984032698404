import { useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { getNarrowHeaderHeight, getNarrowRowHeight, getRowData, renderCheckBox, updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import { useFormContext } from 'react-hook-form'
import { AgGridReact } from 'ag-grid-react'
import { addFloatingNumbers, getArrayFromObject, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions'
import { useAlert, useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../../redux/hooks'
import axios from 'axios'
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer'


const modalsRoot = document.getElementById('root-modals') || document.body

const RollForwardForm = ({ show, handleClose, rollableEntries, columnsToDisplay }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const methods = useFormContext() 
    const gridRef = useRef();
    const [pastBalances, setPastBalances] = useState({no: 0, total: 0})
    const [pastCredits, setPastCredits] = useState({no: 0, total: 0})
    const [selectAll, setSelectAll] = useState(false)
    const alertDialog = useAlert()
    const flashAlert = useFlashAlert()
    const customer_id = useAppSelector(state=> state.showCompany.company_id)

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 90,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'include',
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, () => {
                selectRollableEntries(params.node.data) 
            }), // disable checkbox if RTO can't be paid off
            minWidth: 55,
            width: 55,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    selectRollableEntries(null, checked)
                    setSelectAll(checked) 
                },
                checked: selectAll
            },
            cellStyle: ({textAlign: 'center'})
        },
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.ENTRYNUMBER' }), width: 90 },
        { field: 'horse', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.HORSE' }), hide: !columnsToDisplay.includes('Horse') },
        { field: 'responsibleparty', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.RP' }),  hide: !columnsToDisplay.includes('RP')},
        { field: 'trainer', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.TRAINER' }), hide: !columnsToDisplay.includes('Trainer')  },
        { field: 'Show.show_name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.SHOWNAME' }),   },
        { field: 'Show.end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.SHOWENDDATE' }), cellClass: 'text-center', width: 150, cellRenderer: DateRenderer },
        { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.BALANCE' }),cellRenderer: params => currencyFormatter(params.value, true), cellClass: 'text-end',  },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    let infoLabel = ''
    if(columnsToDisplay.includes('RP')){
        infoLabel = `${methods.getValues('entry.responsibleparty')} (RP${columnsToDisplay.includes('Trainer') && methods.getValues('entry.responsibleparty_id') == methods.getValues('entry.trainer_id') ? '/ Trainer)': ')'}`
    }
    
    if(columnsToDisplay.includes('Trainer') && (!columnsToDisplay.includes('RP') || methods.getValues('entry.responsibleparty_id') != methods.getValues('entry.trainer_id'))){
        infoLabel = `${!infoLabel ? methods.getValues('entry.trainer') : infoLabel + ', ' + methods.getValues('entry.trainer')} (Trainer)`
    }
    
    if(columnsToDisplay.includes('Horse')){
        infoLabel = `${!infoLabel ? methods.getValues('entry.horse')+'/'+methods.getValues('entry.responsibleparty') : infoLabel + ' and ' + methods.getValues('entry.horse')+'/'+methods.getValues('entry.responsibleparty')} (Horse/RP) combination`
    }

    function CheckboxHeader({ headerName, onChange, checked }) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className='w-lg-20px form-check-sm form-check-custom py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="checkAll" 
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)} 
                    />
                </div>
                { headerName.toUpperCase() }
            </div>
        );
    }

    const selectRollableEntries = (data, checked=false) => {
        const rowData = getRowData(gridRef.current.api)

        if(!data){
            for(let row of rowData){
                row['include'] = checked
            }
        }else if(!data.include){
            setSelectAll(false)
        }

        let _pastBalances = rowData.filter((data) => data.include && data.balance > 0)
        let _pastCredits = rowData.filter((data) => data.include && data.balance < 0)
        let _pastBalancesTotal = addFloatingNumbers(getArrayFromObject('balance', _pastBalances), 2)
        let _pastCreditsTotal = addFloatingNumbers(getArrayFromObject('balance', _pastCredits), 2)

        setPastBalances({no: _pastBalances.length, total: _pastBalancesTotal})
        setPastCredits({no: _pastCredits.length, total: _pastCreditsTotal})
        updateAGGridWithoutFlickering(({ current: { api : gridRef.current.api}}), rowData)
    }

    const rollForwardBalances = async(event) => {
        let selectedEntries = getRowData(gridRef.current.api)?.filter((row) => row['include'])
        selectedEntries = selectedEntries.map((entry) => {return {entry_id: entry.entry_id, amount: entry.balance} })
            
        if(selectedEntries.length <= 0){
            alertDialog({message: 'Please select some entries.'})
        }else{
            if(event){
                loadingSpinnerBtnWait(event)
            }

            axios.post(`${process.env.REACT_APP_NEST_API_URL}/accounting/transferBalancesToEntry`, {
                customer_id: customer_id,
                entry_id: methods.getValues('entry.entry_id'),
                selected_entries: selectedEntries,
                called_for: 'Roll Forward Balances'
            }).then((response) => {
                if(response.data.success){
                    methods.setValue("paymentxEntries", response.data.paymentxEntries)
                    methods.reset(response.data.entryData)
                    // display flash alert
                    flashAlert({ type: 'success', message: `Balance roll forward successful.`});
                }else{
                    alertDialog({message: response.data.error})
                }
            }).finally(() => {
                if(event){
                    loadingSpinnerBtnRelease(event)
                }
                handleClose()
            })
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered rollforward-balance search-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.TITLE.ROLLFORWARD' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form ' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col col-form-label fs-6 py-1'>
                                {`${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.INFOSTART' })} ${infoLabel} ${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.INFOEND' })}`}
                            </label>
                        </div>
                        <div className='row mb-2'>
                            
                            <div style={containerStyle}>
                                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                    <AgGridReact
                                        ref={gridRef}
                                        defaultColDef={defaultColDef}
                                        rowData={rollableEntries}
                                        columnDefs={columnDefs} 
                                        rowStyle={{ fontSize: '12px' }}
                                        headerHeight={getNarrowHeaderHeight}
                                        rowHeight={getNarrowRowHeight}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className='row mb-2'>
                            <label className='col-lg-12 col-form-label fs-6 py-0'>{pastCredits.no} {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.CREDITBALANCESTOTALING' })} {currencyFormatter(pastCredits.total)}</label>
                            <label className='col-lg-12 col-form-label fs-6 py-0'>{pastBalances.no} {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.PASTBALANCESTOTALING' })} {currencyFormatter(pastBalances.total)}</label>
                            <label className='col-lg-12 col-form-label fs-6 py-1'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ROLLFORWARD.WARNING' })}</label>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} autoFocus>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.CANCEL' })}
                        </button>
                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={(e) => rollForwardBalances(e)}>
                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { RollForwardForm }