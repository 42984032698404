import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import { useOutputContext } from '../../output-listing/OutputListingContext'
import {useIntl} from 'react-intl'
import QuickSearch from './QuickSearch'
import { SaveModifiedColumnDefinitions } from '../../sgl-utils/agGridHelpers'
import { useAppSelector } from '../../../redux/hooks'
import { useAuth } from '../../auth'

const ListFilter = () => {
  const intl = useIntl()

  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const {outputIncludeAllShows, setOutputIncludeAllShows, outputGrid, outputShowInactive, setOutputShowInactive} = useOutputContext();
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const { currentUser } = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
   // updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    // updateState({
    //   filter: {role, last_login: lastLogin},
    //   ...initialQueryState,
    // })
  }

  return (
    <>
      {
        (outputGrid.area !== 'Rings' && outputGrid.area !== 'ClassRules' && outputGrid.area !== 'Shows' && outputGrid.area !== 'Horses' && outputGrid.area !== 'People' && outputGrid.area !== 'MasterFees' && outputGrid.area !== 'Stables' && outputGrid.area !== 'Circuits' && outputGrid.area !== 'ShowSeries' && outputGrid.area !== 'Facilities' && outputGrid.area !== 'Organization' && outputGrid.area !== 'Reports')  &&  outputGrid.area !== 'CloudApiLogs' &&  outputGrid.area !== 'QueuedJobs' &&  outputGrid.area !== 'SiteMonitoring' &&  outputGrid.area !== 'CronJobs' && outputGrid.area !== 'ErrorsListing' && outputGrid.area !== 'Organizations' && outputGrid.area !== 'TaxRates' && outputGrid.area !== 'Barns' && outputGrid.area !== 'PaymentBatches' ? (
          <label className={`toggle-switch ${outputIncludeAllShows ? 'checked' : ''}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={outputIncludeAllShows} onChange={() => setOutputIncludeAllShows(!outputIncludeAllShows)} /> &nbsp; <div className="slider"></div> <span className="fw-bolder fs-5 label">{intl.formatMessage({ id: 'TOOLBAR.LABEL.INCLUDEALLSHOWS' })}</span>
          </label>
        ) : ""
      }

      {
        (outputGrid.area === 'MasterFees') ? (
          <label className={`toggle-switch ${outputShowInactive ? 'checked' : ''}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input 
              type="checkbox" 
              checked={['true', '1', true, 1].includes(outputShowInactive)} 
              onChange={() => {
                SaveModifiedColumnDefinitions(localStorage.getItem(outputGrid.area), outputGrid.area, currentUser, customerID, 'settings updated', {show_inactive_masterfees: !outputShowInactive}) //upadting settings in db
                setOutputShowInactive(!outputShowInactive)
              } }
            /> &nbsp; 
            <div className="slider"></div> 
            <span className="fw-bolder fs-5 label">{intl.formatMessage({ id: 'TOOLBAR.LABEL.SHOWINACTIVE' })}</span>
          </label>
        ) : ""
      }


      {
        (outputGrid.area !== 'QueuedJobs' && outputGrid.area !== 'CloudApiLogs' && outputGrid.area !== 'SiteMonitoring' && outputGrid.area !== 'CronJobs' && outputGrid.area !== 'ErrorsListing') ? <QuickSearch /> : ''
      }

      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Role:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value=''></option>
              <option value='Administrator'>Administrator</option>
              <option value='Analyst'>Analyst</option>
              <option value='Developer'>Developer</option>
              <option value='Support'>Support</option>
              <option value='Trial'>Trial</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              //disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              //disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ListFilter}
