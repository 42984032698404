import { Modal } from "react-bootstrap"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl"
import { KTSVG } from "../../../../../_metronic/helpers"
import VerifiedEntriesGrid from "./VerifiedEntriesGrid"
import AllEntriesGrid from "./AllEntriesGrid"
import DidNotCompleteGrid from "./DidNotCompleteGrid"
import DidNotCompeteGrid from "./DidNotCompeteGrid"
import { useFormContext } from "react-hook-form"
import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../../../../redux/hooks"
import { useAuth } from '../../../../modules/auth';
import axios from "axios"
import moment from 'moment';
import { useAlert, useConfirm } from "../../../../modules/sgl-utils/DialogsProvider"
import { CombinedClassPopup } from "../../../entries/Detail/Classes/CombinedClassPopup"
import useAccessChecker from "../../../../modules/hooks/use-access-checker"
import { reactSelectStyles } from "../../../../modules/sgl-utils/fieldControls"
import EliminationReason from "./elimination_reason";

import Select from "react-select"

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const VerifyRoster = ({ show, handleClose, dropTrip, addEntry, combinedClassSelectHandler, disqualify_status_list }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const methods = useFormContext()
    const { currentUser } = useAuth();

    const entryxclasses = methods.watch("entryxclasses")
    const individual_trips = methods.watch("individual_trips")
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const showId = useAppSelector(state => state.currentShow.show_id)
    const show_financials_locked = methods.watch('show_tables.islocked')
    const { hasSpecialPermissionToAccess } = useAccessChecker()

    const [verifiedEntriesGridRef, setVerifiedEntriesGridRef] = useState()
    const [didNotCompeteGridRef, setDidNotCompeteGridRef] = useState()
    const [didNotCompleteGridRef, setDidNotCompleteGridRef] = useState()
    const [allEntriesGridRef, setAllEntriesGridRef] = useState() // Remaining Entries
    const [errorMessage, setErrorMessage] = useState("")
    const entryNumberInputRef = useRef()
    const [showAddButton, setShowAddButton] = useState(false)
    const [reFillEntries, setReFillEntries] = useState(false)
    const [isEliminateBtnDisabled, setIsEliminateBtnDisabled] = useState(true)
    const [isScratchBtnDisabled, setIsScratchBtnDisabled] = useState(true)
    const [isDropBtnDisabled, setIsDropBtnDisabled] = useState(true)
    const [riderOptions, setRiderOptions] = useState([])
    const [showEliminationReasonForm, setShowEliminationReasonForm] = useState(false)
    const [eliminateRosterTrip, setEliminateRosterTrip] = useState([])

    
    
    // Entry & Rider to be used when adding a new trip via Verify Button
    const [currentEntry, setCurrentEntry] = useState()
    const [selectedRider, setSelectedRider] = useState()

    // COMBINED CLASS POPUP STATES
    const [showCombinedClassPopup, setShowCombinedClassPopup] = useState(false)
    const [actualClasses, setActualClasses] = useState({})

    // Get Ag-grid data
    const getRowData = (gridRef) => {
        const rowData = []
        gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    useEffect(() => {
        if (entryNumberInputRef.current) {
            entryNumberInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (verifiedEntriesGridRef) {
            verifiedEntriesGridRef.setRowData(
                entryxclasses
                .filter(exc => exc.trip_verified)
                .sort((a, b) => {
                    // First, sort by trip_verification_datetimestamp
                    if (a.trip_verification_datetimestamp !== b.trip_verification_datetimestamp) {
                        return a.trip_verification_datetimestamp - b.trip_verification_datetimestamp;
                    }
                    // If trip_verification_datetimestamp is the same, sort by Entry.number
                    return a.Entry.number - b.Entry.number;
                })
                // entryxclasses.filter(exc => exc.trip_verified).sort((a, b) => a.Entry.number - b.Entry.number )
            )
        }
    }, [verifiedEntriesGridRef, reFillEntries, entryxclasses])

    useEffect(() => {
        if (didNotCompeteGridRef) {
            didNotCompeteGridRef.setRowData(
                entryxclasses.filter(exc => !exc.trip_verified && exc.scratch_trip)
            )
        }
    }, [didNotCompeteGridRef, reFillEntries])

    useEffect(() => {
        if (didNotCompleteGridRef) {
            didNotCompleteGridRef.setRowData(
                entryxclasses.filter(exc => !exc.trip_verified && (exc.disqualify_status_one || exc.disqualify_status_two)  && !exc.scratch_trip)
            )
        }
    }, [didNotCompleteGridRef, reFillEntries])

    useEffect(() => {
        if (allEntriesGridRef) {
            allEntriesGridRef.setRowData(
                entryxclasses.filter(exc => {
                    return (
                        !exc.trip_verified &&
                        !((exc.disqualify_status_one || exc.disqualify_status_two) && !exc.scratch_trip) &&
                        !exc.scratch_trip
                    )
                })
                .sort((a, b) => a.Entry.number - b.Entry.number) // Sorting by Entry.number
            )
        }
    }, [allEntriesGridRef, reFillEntries])

    const verifyEntry = async () => {

        // validate input
        const entryNumber = Number(entryNumberInputRef.current.value)
        if (isNaN(entryNumber) || entryNumber <= 0) { 
            entryNumberInputRef.current.value = "" 
            entryNumberInputRef.current.focus()
            return
        }
        setErrorMessage('')
        setShowAddButton(false)
       
        const allEntries = getRowData(allEntriesGridRef)
        const didNotCompeteEntries = getRowData(didNotCompeteGridRef)
        const didNotCompleteEntries = getRowData(didNotCompleteGridRef)
        const verifiedEntries = getRowData(verifiedEntriesGridRef)
    
        const allEntriesIndex = allEntries.findIndex(exc => exc.Entry.number === entryNumber)
        const didNotCompeteIndex = didNotCompeteEntries.findIndex(exc => exc.Entry.number === entryNumber)
        const didNotCompleteEntriesIndex = didNotCompleteEntries.findIndex(exc => exc.Entry.number === entryNumber)
        const verifiedEntriesIndex = verifiedEntries.findIndex(exc => exc.Entry.number === entryNumber)

        let scratchTrip = false 
        let _errorMessage = ""
        
        if (allEntriesIndex >= 0 || didNotCompeteIndex >= 0 || didNotCompleteEntriesIndex >= 0) {
            let verifiedEntryxclassId

            if (allEntriesIndex >= 0) {
                verifiedEntryxclassId = allEntries[allEntriesIndex].entryxclasses_id
                scratchTrip = allEntries[allEntriesIndex].scratch_trip
            } else if (didNotCompeteIndex >= 0) {
                scratchTrip = didNotCompeteEntries[didNotCompeteIndex].scratch_trip
                verifiedEntryxclassId = didNotCompeteEntries[didNotCompeteIndex].entryxclasses_id
            } else if (didNotCompleteEntriesIndex >= 0) {
                scratchTrip = didNotCompleteEntries[didNotCompleteEntriesIndex].scratch_trip
                verifiedEntryxclassId = didNotCompleteEntries[didNotCompleteEntriesIndex].entryxclasses_id
            }

            if (scratchTrip) {
                _errorMessage = "The roster entry " + entryNumber + " is Scratched."
            } else {

                // Get last completed trip data
                entryxclasses.sort((a, b) => b.actual_go_time?.localeCompare(a.actual_go_time)) // sort the array by actual_go_time in desc order
                const lastTripTime = entryxclasses[0].actual_go_time
                const lastTripGroupIndex = entryxclasses[0].groupindex
                const lastGoneGroupId = entryxclasses[0].gone_in_group
          
                entryxclasses.sort((a, b) => b.order_of_go - a.order_of_go)
                const lastTripOrderOfGo = entryxclasses[0].order_of_go

                let foundTrip = entryxclasses.find(exc => exc.entryxclasses_id === verifiedEntryxclassId)
                
                foundTrip.trip_verified = 1
                if(!foundTrip.gone_in) {
                    foundTrip.gone_in = true
                    foundTrip.actual_go_time = lastTripTime
                    foundTrip.groupindex = lastTripGroupIndex
                    foundTrip.order_of_go = lastTripOrderOfGo + 1
                    foundTrip.actual_order = lastTripOrderOfGo + 1
                    foundTrip.gone_in_group = -1 // lastGoneGroupId - setting it to -1 to indicate this trip is verified from roster verification tool
                }
                let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
                    params: { customer_id: customerId }
                })

                let date = response?.data?.date?response.data.date:''
                let time = response?.data?.time?response.data.time:''
                let date_time_stamp = date.replace(/-/g, '') + time.replace(/:/g, '')
                foundTrip.trip_verification_datetimestamp = date_time_stamp

                    
                // Set verified flag of trip
                foundTrip.isVerified = true
                if(foundTrip.isUnverified){ // Remove unverified flag if it exist
                    delete foundTrip['isUnverified']
                }

                setReFillEntries(prevReFillEntries => !prevReFillEntries)
                entryNumberInputRef.current.value = ""
            }
        } else if (verifiedEntriesIndex >= 0) {
            verifiedEntriesGridRef.getRowNode(verifiedEntriesIndex).setSelected(true, true)
            setShowAddButton(false)
            entryNumberInputRef.current.value = ""
        } else { // entry not present in the class
            const response = await axios.post( NEST_API_URL + "/classes/verifyRoster", {
                customer_id: customerId,
                show_id: showId,
                entry_number: entryNumber
            }) 

            if (!response.data.success) {
                _errorMessage = response.data.error

                if (response.data.showAddButton) {
                    setShowAddButton(true)

                    let _riderOptions = []
                    for (let entryRider of response.data.entry.EntryRiders) {
                        _riderOptions.push({
                            label: entryRider?.Rider?.rider_status ? entryRider.rider_name + " (" + entryRider.Rider.rider_status +")" : entryRider.rider_name,
                            value: entryRider.rider_id
                        })
                    }
                    
                    setRiderOptions(_riderOptions)
                    setSelectedRider(_riderOptions[0].value)
                    setCurrentEntry(response.data.entry)
                } else {
                    // Disable Rider Select & Add Entry to Class Button
                    setShowAddButton(false)
                }
                entryNumberInputRef.current.value = ""
            }
        }

        setErrorMessage(_errorMessage)
    }

    const verifyAll = async () => {
        const allEntries = getRowData(allEntriesGridRef)
        if (allEntries.length === 0) { return }

        // Get last completed trip data
        entryxclasses.sort((a, b) => b.actual_go_time.localeCompare(a.actual_go_time)) // sort the array by actual_go_time in desc order
        const lastTripTime = entryxclasses[0].actual_go_time
        const lastTripGroupIndex = entryxclasses[0].groupindex
        const lastGoneGroupId = entryxclasses[0].gone_in_group
  
        entryxclasses.sort((a, b) => b.order_of_go - a.order_of_go)
        const lastTripOrderOfGo = entryxclasses[0].order_of_go

        for (let trip of allEntries) {
            let foundTrip = entryxclasses.find(exc => exc.entryxclasses_id === trip.entryxclasses_id)
            
            foundTrip.trip_verified = 1
            if(!foundTrip.gone_in) {
                foundTrip.gone_in = true
                foundTrip.actual_go_time = lastTripTime
                foundTrip.groupindex = lastTripGroupIndex
                foundTrip.order_of_go = lastTripOrderOfGo + 1
                foundTrip.actual_order = lastTripOrderOfGo + 1
                foundTrip.gone_in_group = -1 //lastGoneGroupId
            }
            
            // Set verified flag of trip
            foundTrip.isVerified = true
            if(foundTrip.isUnverified){ // Remove Unverified flag if exists
                delete foundTrip['isUnverified']
            }
        }

        methods.setValue("classes.results_verified", true, { shouldDirty: true })

        setReFillEntries(prevReFillEntries => !prevReFillEntries)
    }

    const unverifyAll = async () => {
        const allEntries = getRowData(verifiedEntriesGridRef)
        // didNotCompleteGridRef, didNotCompeteGridRef
        if (allEntries.length === 0) { return }

        const choice = await confirmDialog({ message: "Are you sure you wish to clear verification for all trips? This can not be undone." })
        if (choice) {
            for (let trip of allEntries) {
                trip.trip_verified = 0
                // Mark row as updated
                trip.isUpdatedByRoster = true
                if(trip.isVerified){
                    delete trip["isVerified"]
                }
                // Add isUnverified flag to log history changes
                trip.isUnverified = true
                trip.trip_verified = 0
                trip.trip_verification_datetimestamp = ""
                if(trip.gone_in_group == -1){
                    trip.gone_in = false
                    trip.gone_in_group = 0
                }
            }

            methods.setValue("classes.results_verified", false, { shouldDirty: true })
            methods.setValue("classes.verification_detail", '', { shouldDirty: true })
            

            setReFillEntries(prevReFillEntries => !prevReFillEntries)
        }

    }

    const eliminateRoster = (tripRow = null) => {
        let selectedRows = []


        if (!tripRow) {
            selectedRows = allEntriesGridRef.getSelectedRows() // Get Selected Rows
        } else { // Single Row was Dragged & Dropped 
            selectedRows = [tripRow]
        }
        setEliminateRosterTrip(selectedRows)
        // ask user for elimination reason
        setShowEliminationReasonForm(true)
    }

    function setDisqualifyStatus(disqualify_status){
        let selectedRows = eliminateRosterTrip??[]

        // Loop through selection
        for (let exc of selectedRows) {
            if (!exc.scratch_trip) {
                exc.disqualify_status_one = disqualify_status?disqualify_status:'DNS'

                // Mark row as updated
                exc.isUpdatedByRoster = true
            }
        }

        setIsEliminateBtnDisabled(true)
        setReFillEntries(prevReFillEntries => !prevReFillEntries)
        setEliminateRosterTrip([])

    }

    const scratchRoster = (tripRow = null) => {
        let selectedRows = []
        if (!tripRow) {
            selectedRows = allEntriesGridRef.getSelectedRows() // Get Selected Rows
        } else { // Single Row was Dragged & Dropped 
            selectedRows = [tripRow]
        }
        
        // Loop through selection
        for (let exc of selectedRows) {
            if (!exc.scratch_trip) {
                exc.scratch_trip = true
                if (exc.disqualify_status_one === "") { //disqualification status is not already set
                    exc.disqualify_status_one = "DNS"
                }

                // Mark row as updated
                exc.isUpdatedByRoster = true
            }
        }

        setIsScratchBtnDisabled(true)
        setReFillEntries(prevReFillEntries => !prevReFillEntries)
    }

    const dropRoster = async () => {
       
        let _errorMessage = ""
        const selectedRows = allEntriesGridRef.getSelectedRows() // Get Selected Rows
        // Loop through selection
        for (let exc of selectedRows) {
            let drop_res =  await dropTrip(exc)
            if (drop_res?.error_message) {
                _errorMessage += drop_res?.error_message
            } else if (drop_res?.dropped_trips?.length > 0) { // Trip can be dropped
                let deletedTrips = methods.getValues("deletedTrips")
                if (deletedTrips) {
                    deletedTrips = deletedTrips.concat(drop_res.dropped_trips)
                    methods.setValue("deletedTrips", deletedTrips, { shouldDirty: true })
                } else {
                    methods.setValue("deletedTrips", drop_res.dropped_trips, { shouldDirty: true })
                }
                
                exc.isDropped = true // Mark trip as deleted
            }
        }

        // Remove from all trips list
        methods.setValue("entryxclasses", entryxclasses.filter(exc => !exc.isDropped))
        methods.setValue("individual_trips", individual_trips.filter(exc => !exc.isDropped))
        setReFillEntries(prevReFillEntries => !prevReFillEntries)
        setErrorMessage(_errorMessage)
        setIsDropBtnDisabled(allEntriesGridRef.getSelectedRows().length === 0) // Disable drop button when nothing selected
    }

    const handleDone = async () => {
        const allEntries = getRowData(allEntriesGridRef)
        let verified = false
        if (allEntries.length > 0) {
            const choice = await confirmDialog({ message: "Do you want to mark the remaining un-scratched entries as Did Not Compete?" })
            if (choice) {
                verified = true

                for (let exc of allEntries) {
                    exc.scratch_trip = true
                    if (exc.disqualify_status_one === "") { //disqualification status is not already set
                        exc.disqualify_status_one = "DNS"
                    }

                    // Mark row as updated
                    exc.isUpdatedByRoster = true
                }
            } else {
                methods.setValue("classes.results_verified", false, { shouldDirty: true }) // Set class results flag false as results are not verified
            }
        } else {
            verified = true
        }

        if (allEntries.length === 0 || verified) {
            methods.setValue("classes.results_verified", true, { shouldDirty: true })

            let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
                params: { customer_id: customerId }
            })
            let date = response?.data?.date?response.data.date:''
            let time = response?.data?.time?response.data.time:''
            // let date_time_stamp = date.replace(/-/g, '') + time.replace(/:/g, '')

            let verification_detail =  moment(date).format("MM-DD-YY") + " - " + time + " - " + currentUser.first_name + ' ' + currentUser.last_name
            let results_updated_at = moment(date).format("MM-DD-YY") + " " + moment(time, "HH:mm:ss").format("h:mm A")
               
            methods.setValue("classes.verification_detail", verification_detail, { shouldDirty: true })
            methods.setValue("classes.results_updated_at", results_updated_at, { shouldDirty: true })
            
            // // Flag to update verification_detail at backend (Not done at Frontend as it requires smc data, time & logged in user's name)
            // methods.setValue("classes.updateVerificationDetail", true, { shouldDirty: true })

            const count = entryxclasses.filter(exc => !exc.scratch_trip).length
            const addBackEntries = Number(methods.getValues("classes.addbackentries"))
            if (addBackEntries === 0) {
                methods.setValue("classes.addbackentries", count, { shouldDirty: true })
            } else if (addBackEntries !== count) {
                const choice = await confirmDialog({ message: "The verified entry count is different from the recorded entry count.  Update now?"})
                if (choice) {
                    methods.setValue("classes.addbackentries", count, { shouldDirty: true })
                }
            }
        }

        onClose()
    }

    const undoVerified = (tripRow = null) => {
        if (!tripRow) { // Get selected row if called without data
            const selectedRows = verifiedEntriesGridRef.getSelectedRows()
            if (selectedRows.length > 0) {
                tripRow = selectedRows[0]
            } else { // Check for no row selected
                return 
            }
        }
        
        // Mark row as updated
        tripRow.isUpdatedByRoster = true

        delete tripRow["isVerified"]
        // Add isUnverified flag to log history change
        tripRow.isUnverified = true
        tripRow.trip_verified = 0
        tripRow.trip_verification_datetimestamp = ""
        if(tripRow.gone_in_group == -1){
            tripRow.gone_in = false
            tripRow.gone_in_group = 0
        }

        setReFillEntries(prevReFillEntries => !prevReFillEntries)
    }

    const undoDidNotComplete = (tripRow = null) => {
        if (!tripRow) { // Get selected row if called without data
            const selectedRows = didNotCompleteGridRef.getSelectedRows()
            if (selectedRows.length > 0) {
                tripRow = selectedRows[0]
            } else { // Check for no row selected
                return 
            }
        }

        // Mark row as updated
        tripRow.isUpdatedByRoster = true

        tripRow.eliminated_trip = false
        tripRow.disqualify_status_one = ""
        tripRow.disqualify_status_two = ""

        setReFillEntries(prevReFillEntries => !prevReFillEntries)
    }   

    const undoDidNotCompete = (tripRow = null) => {
        if (!tripRow) { // Get selected row if called without data
            const selectedRows = didNotCompeteGridRef.getSelectedRows()
            if (selectedRows.length > 0) {
                tripRow = selectedRows[0]
            } else { // Check for no row selected
                return 
            }
        }
           
        tripRow.isUpdatedByRoster = true
        tripRow.scratch_trip = false
        if (tripRow.disqualify_status_one === "DNS") {
            tripRow.disqualify_status_one = ""  //Clear Disqualify status on un scratch trip if its DNS
        }
        
        setReFillEntries(prevReFillEntries => !prevReFillEntries)
    }

    const onAllEntriesSelectionChanged = () => {
        const noRowSelected =  allEntriesGridRef.getSelectedRows().length === 0

        setIsEliminateBtnDisabled(noRowSelected)
        setIsScratchBtnDisabled(noRowSelected)
        // Disable drop button if not a new class record
        setIsDropBtnDisabled(noRowSelected && Number(methods.getValues("classes.class_id")) === 0)
    }

    const onClose = () => {
        // Clear reference of ag-grids on close
        setVerifiedEntriesGridRef(null)
        setAllEntriesGridRef(null)
        setDidNotCompeteGridRef(null)
        setDidNotCompleteGridRef(null)

        // Reset states to default
        setShowAddButton(false)
        setErrorMessage("")
        setIsEliminateBtnDisabled(true)
        setIsScratchBtnDisabled(true)
        setIsDropBtnDisabled(true)

        methods.setValue('entryxclasses', methods.getValues('entryxclasses'))

        handleClose()
    }

    // ALL ENTRIES GRID - ON DROP HANDLING >>> 
    useEffect(() => {
        if (verifiedEntriesGridRef && allEntriesGridRef) {
            verifiedEntriesGridRef.addRowDropZone(
                allEntriesGridRef.getRowDropZoneParams({
                    onDragStop: params => undoVerified(params.node.data)
                }) 
            )
        }
    }, [verifiedEntriesGridRef, allEntriesGridRef])

    useEffect(() => {
        if (didNotCompeteGridRef && allEntriesGridRef) {
            didNotCompeteGridRef.addRowDropZone(
                allEntriesGridRef.getRowDropZoneParams({
                    onDragStop: params => undoDidNotCompete(params.node.data)
                }) 
            )
        }
    }, [didNotCompeteGridRef, allEntriesGridRef])

    useEffect(() => {
        if (didNotCompleteGridRef && allEntriesGridRef) {
            didNotCompleteGridRef.addRowDropZone(
                allEntriesGridRef.getRowDropZoneParams({
                    onDragStop: params => undoDidNotComplete(params.node.data)
                }) 
            )
        }
    }, [didNotCompleteGridRef, allEntriesGridRef])
    // ALL ENTRIES GRID - ON DROP HANDLING <<<

    // DID NOT COMPLETE GRID - ON DROP HANDLING >>>
    useEffect(() => {
        if (allEntriesGridRef && didNotCompleteGridRef) {
            allEntriesGridRef.addRowDropZone(
                didNotCompleteGridRef.getRowDropZoneParams({
                   onDragStop: params => eliminateRoster(params.node.data)
                }) 
            )
        }
    }, [allEntriesGridRef, didNotCompleteGridRef])
    // DID NOT COMPLETE GRID - ON DROP HANDLING <<< 

    // DID NOT COMPETE GRID - ON DROP HANDLING >>>
    useEffect(() => {
        if (allEntriesGridRef && didNotCompeteGridRef) {
            allEntriesGridRef.addRowDropZone(
                didNotCompeteGridRef.getRowDropZoneParams({
                   onDragStop: params => scratchRoster(params.node.data)
                }) 
            )
        }
    }, [allEntriesGridRef, didNotCompeteGridRef])
    // DID NOT COMPETE GRID - ON DROP HANDLING <<< 

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            backdrop="static"
            dialogClassName='modal-dialog modal-dialog-centered verify-roster-modal'
            show={show}
            onHide={handleDone}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === 'Enter' && !isButtonFocused){
                    event.preventDefault();
                    if (activeElement?.id == "selectShow") {
                        verifyEntry()
                    } else {
                        handleDone()
                    }
                }
                if (event.key === 'Escape') {
                    handleDone()
                }
            }}
        >
            <CombinedClassPopup 
                show={showCombinedClassPopup}
                handleClose={() => setShowCombinedClassPopup(false)}
                isAddMode={true}
                classList={actualClasses}
                area="VerifyRoster"
                addClickHandler={actualClassNumber => combinedClassSelectHandler(actualClassNumber, setShowAddButton, setErrorMessage, currentEntry, selectedRider)}
            />
            { showEliminationReasonForm && 
                <EliminationReason
                    disqualify_status_list={disqualify_status_list}
                    show={showEliminationReasonForm}
                    handleClose={() => setShowEliminationReasonForm(false)}
                    setDisqualifyStatus={setDisqualifyStatus}
                />
            }
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">Verify Roster</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleDone}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <div className='card-body p-0'>
                    <div className='row mb-2'>
                        <label className='col-lg-2 col-form-label fs-5 py-1' htmlFor="selectShow" data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.LABEL.ENTRYNUMBER">
                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.INPUT.LABEL.ENTRYNUMBER" })}
                        </label>
                        <div className='col-lg-3'>
                            <input 
                                id="selectShow"
                                type="number"
                                ref={entryNumberInputRef}
                                className='form-control fs-6 min-h-20px py-1'
                            />
                        </div>
                        <div className="col-lg-2">
                            <button 
                                type="button" 
                                className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                onClick={verifyEntry}
                                data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.VERIFY">
                                { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.VERIFY" })}
                            </button>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="error_message">{ errorMessage }</label>
                    </div>

                    <div className={`row mb-4 d-flex align-items-end ${!showAddButton && "d-none"}`}>
                        <label className="col-form-label fs-5 py-1 w-lg-85px" data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.LABEL.RIDER">Rider</label>
                        <div className='col-lg-3'>
                            {/* <select
                                className="form-select form-select-sm fs-6 min-h-20px py-1"
                                onChange={e => setSelectedRider(e.target.value)}
                                selected={selectedRider}
                            >
                                { riderOptions.map(r => <option value={r.value}>{ r.label }</option>) }
                            </select> */}
                            <Select
                                onChange={e => setSelectedRider(e.value)}
                                value={riderOptions?.find((option) => option.value == selectedRider)}
                                styles={reactSelectStyles}
                                options={riderOptions}
                                isDisabled={show_financials_locked}
                            />
                        </div>
                        <div className="col-lg-3">
                            <button 
                                disabled={show_financials_locked}
                                type="button" 
                                className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                onClick={() => {addEntry(setShowCombinedClassPopup, setActualClasses, setShowAddButton, setErrorMessage, currentEntry, selectedRider);  if (entryNumberInputRef.current) { entryNumberInputRef.current.focus(); }}}
                                data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.ADDENTRYTOCLASS">
                                { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.ADDENTRYTOCLASS" })}
                            </button>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="w-lg-275px">
                            <VerifiedEntriesGrid 
                                setGridRef={setVerifiedEntriesGridRef}
                            />
                        </div>
                        <div className='w-lg-75px d-flex flex-column justify-content-center'>
                            <button   
                                onClick={() => undoVerified()}  
                                className='btn btn-secondary py-1 w-lg-50px fw-bold'
                                type="button"
                                data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.MOVEFROMVERIFIEDENTRIES"> 
                                &gt;
                            </button>
                        </div>
                        <div className="w-lg-275px">
                            <div className="row mb-2">
                                <DidNotCompleteGrid 
                                    setGridRef={setDidNotCompleteGridRef}
                                />
                            </div>
                           
                            <DidNotCompeteGrid 
                                setGridRef={setDidNotCompeteGridRef}
                            />
                        </div>
                        <div className='w-lg-75px d-flex flex-column justify-content-around'>
                            <button     
                                onClick={() => undoDidNotComplete()}  
                                className='btn btn-secondary py-1 w-lg-50px fw-bold'
                                type="button"
                                data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.MOVEFROMDIDNOTCOMPLETE"> 
                                &gt;
                            </button>

                            <button 
                                onClick={() => undoDidNotCompete()}    
                                className='btn btn-secondary py-1 w-lg-50px fw-bold'
                                type="button"
                                data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.MOVEFROMDIDNOTCOMPETE"> 
                                &gt;
                            </button>
                        </div>
                        <div className="w-lg-275px">
                            <div className="row mb-4">
                                <AllEntriesGrid 
                                    setGridRef={setAllEntriesGridRef}
                                    onSelectionChanged={onAllEntriesSelectionChanged}
                                />
                            </div>
                            {
                                hasSpecialPermissionToAccess('cloud_classes:auto-verify-results') &&
                                <div className="mb-2 mx-2">
                                    <button 
                                        type="button" 
                                        className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                        onClick={verifyAll}
                                        data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.VERIFYALL">
                                        { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.VERIFYALL" })}
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="w-lg-150px">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div>
                                    <div className="row mb-2 mx-2">
                                        <button 
                                            disabled={isDropBtnDisabled || show_financials_locked}
                                            type="button" 
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                            onClick={dropRoster}
                                            data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.DROP">
                                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.DROP" })}
                                        </button>
                                    </div>
                                    <div className="row mb-2 mx-2">
                                        <button 
                                            disabled={isEliminateBtnDisabled}
                                            type="button" 
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                            onClick={() => eliminateRoster()}
                                            data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.ELIMINATE">
                                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.ELIMINATE" })}
                                        </button>
                                    </div>
                                    <div className="row mb-2 mx-2">
                                        <button 
                                            disabled={isScratchBtnDisabled || show_financials_locked}
                                            type="button" 
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase py-2' 
                                            onClick={() => scratchRoster()}
                                            data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.SCRATCH">
                                            { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.SCRATCH" })}
                                        </button>
                                    </div>
                                    <div className="row mb-2 mx-2">
                                        <button 
                                            type="button" 
                                            className='btn btn-sm btn-secondary fw-bold text-uppercase px-0' 
                                            onClick={() => unverifyAll()}
                                            data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.CLEARVERIFICATION">
                                                { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.CLEARVERIFICATION" })}
                                        </button>
                                    </div>
                                    
                                </div>
                                <div className="row mb-2 mx-2">
                                    <button 
                                        type="button" 
                                        className='btn btn-sm btn-dark fw-bold text-uppercase py-2'
                                        onClick={handleDone}
                                        autoFocus
                                        data-tooltip-id="CLASS.DETAIL.MODAL.VERIFYROSTER.BUTTON.DONE"
                                        >
                                        { intl.formatMessage({ id: "FORM.INPUT.CLASSES.TAB.PRIZESANDRESULTS.VERIFYROSTER.BUTTON.DONE" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}


export default VerifyRoster