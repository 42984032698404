import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl'
import { HistoryTab } from '../../../modules/components/HistoryTab';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import TimeRenderer from '../../../modules/output-listing/renderers/TimeRenderer';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../modules/output-listing/OutputListingRowOptions';

const EntriesHistoryTab = ({ rowData }) => {
    const intl = useIntl();
    const methods = useFormContext()

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const columnDefs = [
        { field: 'creation_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.DATE' }), cellRenderer: DateRenderer },
        { field: 'creation_time', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.TIME' }), cellRenderer: TimeRenderer  },
        { field: 'People.fl_name', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.RTO' }) },
        { field: 'phone_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.PHONENO' }) },
        { field: 'status', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.STATUS' }) },
        { field: 'authorization_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.SIGNEDDATE' }), cellRenderer: DateRenderer },
        { field: 'authorization_time', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.SIGNEDTIME' }), cellRenderer: TimeRenderer },
        { field: 'paper_entry', headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.PAPERENTRY' }), cellRenderer: BooleanCheckmarkRenderer },
        { 
            field: 'sms_sent', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.SMSSENT' }), 
            suppressMenu: true, 
            cellClass: 'text-center', 
            cellRenderer: BooleanCheckmarkRenderer,
            valueGetter: (params) => params.data.sms_text !== "" && params.data.twilio_sid !==  "" && params.data.sms?.sent_date !== "0000-00-00"
        },
        { field: 'displayRole', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.ROLE' })},
    ]

    const entryAuthRequestsRowData = methods.getValues("entryAuthorizationRequests")

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), [])

    return (
        <>
            <div className='form-group mb-2'>
                <div className='row mb-2'>
                    <HistoryTab area={"Entries"} rowData={rowData} />
                </div>
                <fieldset className='mb-2'>
                    <legend className="fs-5 fw-bold col">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.HISTORY.GROUPLABEL.DIGITALSIGNATURES' })} <b></b></legend>
                    <div className='form-group mb-2'> {/* WA - Row Selector */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <RowSelector
                                value={visibleRows}
                                onChange={setVisibleRows}
                                area={"Entries - EntryAuthRequests"}
                                />
                            </div>
                        <div className='row mb-2'>
                            <OutputListingGridStatic 
                                columnDefs={columnDefs} 
                                rowData={entryAuthRequestsRowData}
                                containerStyle={containerStyle}
                                visibleRows={visibleRows}
                            />
                        </div> {/* WA - Row Selector */}
                    </div>
                </fieldset>
            </div>
        </>
    );
}

export { EntriesHistoryTab }