import { createContext, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import socketIOClient from "socket.io-client";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth";
import { customSessionExpired } from "../sgl-utils/customSessionExpiredDialog";

// create context
const syncUpdateContext = createContext()

const SyncUpdateContextProvider = ({ children }) => {
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const currentShowId = useAppSelector(state => state.currentShow.show_id);
    const [sse, setSse] = useState(null);
    const [subscribedEvents, setSubscribedEvents] = useState([]);
    const { pathname } = useLocation()
    const user = useAuth();
    const [currentUsers, setCurrentUsers] = useState([]);
    const [pendingUpdates, setPendingUpdates] = useState([]);
    const [socket, setSocket] = useState(null);
    const [sseReady, setSseReady] = useState(false);
    

    useEffect(() => { // Use Effect to open socket connection once for every user
        const uniqueToken = Math.random().toString(36).substring(7); 
        let socketConnection = socketIOClient.connect(process.env.REACT_APP_NEST_API_URL, { 
            query: {
                customer_id: customerId, // Used to create rooms for broadcasting
                unique_token: uniqueToken, // to create a new connection for each tab
                user_id: user.currentUser.id
            },
            transports: ['websocket']
        })

        setSocket(socketConnection)

        return () => {
            if(socketConnection){ // Clean Up function to close socket
                socketConnection.disconnect()
                socketConnection.removeAllListeners(); // Ensure all listeners are removed
            }
        }
    }, [customerId]);

    useEffect(() => {
        if(socket){
            socket.emit('joinRoom', customerId)
            let data = '';
            // Design this object to avoid back end query
            let userData = {
                sgl_id: user?.currentUser?.id, 
                first: user?.currentUser?.first_name,
                last_name: user?.currentUser?.last_name,
                profile_photo_thumbnail: user?.currentUser?.profile_photo_thumbnail,
                api_token: user?.currentUser?.api_token
            }

            if (pathname) {
                const path = pathname.split('/');
                if (pathname.includes('detail') && path[3] != 0) {
                  data = {connectionString: `${path[1].toUpperCase()}-${customerId}-${path[3]}`, user: userData, customerId};
                  socket.on(`${path[1].toUpperCase()}-${customerId}-${path[3]}`, (data) => { // Listen on this particular channel for current user update
                    setCurrentUsers(data?.activeUsers)
                    setPendingUpdates(data?.pendingUpdates)
                 });
                } else {
                  setPendingUpdates(0)
                  setCurrentUsers([])
                }
            }

           // Function to emit data to the server
           const emitData = () => {
               socket.emit('clientData', data);
            };
           
           // Initial Emit 
           emitData()
           

           return () => {
            // clearInterval(intervalId); // Clear the interval when the component is unmounted
            if(userData){
                socket.emit('clientData', {...data, disconnect: true, user: userData})
            }
            socket.off(data?.connectionString)
          }
        }

    }, [currentShowId, customerId, pathname, socket]);

    useEffect(() => {
        if(customerId > 0){
            removeAllEventListeners()
            closeSseInstance()
            const newSse = new EventSource(`${process.env.REACT_APP_NEST_API_URL}/utility/getSyncUpdate?customer_id=${customerId}&d=${Date.now()}&token=${localStorage.getItem('browser_token')}`);
            setSse(newSse)
            setSseReady(true);

            // Clean up when SSE changes or unmounts
            return () => {
              newSse.close();
              setSse(null);
              closeSseInstance();
            }
        }
    }, [customerId]);

    useEffect(() => {
        const uniqueToken = Math.random().toString(36).substring(7); // This uniue token will prevent displaying updates on the opened tab which trigger the event listener
        sessionStorage.setItem('user-tab-token', uniqueToken) // Storing it in sessionStorage to send it on logout

        let event = { // This event will display sessionExpired dialog in all tabs of a browser when user logout from one tab
            eventId: localStorage.getItem('browser_token'),
            callBack: (event) => {if(JSON.parse(event.data).additional_data.unique_token !== uniqueToken ) {customSessionExpired()}}
        }
        if(sse){
            sse.addEventListener(event.eventId, event.callBack)
        }

        return () => {
            if(sse){
                sse.removeEventListener(event.eventId, event.callBack)
            }
        }
    }, [sse]);

    // Close the existing SSE instance
    const closeSseInstance = () => {
        if (sse) {
            sse.close()
            setSse(null)
        };
    }

    // Add Event listeners for the dispatch event ids
    const addSubscribedEvents = (events) => {
      if(sse && events.length > 0){
        for(let event of events){
          sse.addEventListener(event.eventId, event.callback)
        }
        setSubscribedEvents(prevData => prevData.concat(events))
      }
    }

    // Remove all subscribed Event Listeners
    const removeAllEventListeners = () => {
      if(sse){
        if(subscribedEvents.length > 0){
          for(let event of subscribedEvents){
            sse.removeEventListener(event.eventId, event.callback)
          }
        }
        setSubscribedEvents([])
      }
    }

    // Remove Specific Event Listeners on the basis of prefix
    const removeEventListenersByPrefix = (prefix) => {
      // Remove those event listener whose prefix matches the subscribed events list
      if(subscribedEvents.length > 0){
        for(let event of subscribedEvents){
          if(event.eventId.includes(prefix)){
            sse.removeEventListener(event.eventId, event.callback)
          }
        }
      }
      let updatedSubscribeEvents = []
      if(subscribedEvents.length > 0){
        // updatedSubscribe Events will hold the filter out subscribed events on the basis of send prefix
        updatedSubscribeEvents = subscribedEvents?.filter(se => !se.eventId.includes(prefix))
      }
      setSubscribedEvents(updatedSubscribeEvents)
    }

    return (
        <syncUpdateContext.Provider value={{ addSubscribedEvents, removeAllEventListeners, removeEventListenersByPrefix, currentUsers, sse, pendingUpdates, sseReady}}>
            {children}
        </syncUpdateContext.Provider>
    );
}

// context consumer hook
const useSyncUpdateContext = () => {
    // get the context
    const context = useContext(syncUpdateContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useSyncUpdateContext was used outside of its Provider");
    }
  
    return context;
};

export { SyncUpdateContextProvider, useSyncUpdateContext }