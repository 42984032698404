import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'
import { useFormContext } from 'react-hook-form'

type Props = {
    show: boolean
    handleClose: () => void
    entryFeeGridRef: any
    rowData: any[],
    setEntryFeeGridRowData: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteFeeForm = ({ show, handleClose, entryFeeGridRef, rowData, setEntryFeeGridRowData }: Props) => {
    const intl = useIntl()
    const [reason, setReason] = useState<string>("")
    const [error, setError] = useState<string>("")
    const methods = useFormContext()
    const reasonRef = useRef<any>(null) //ref used for input field
    
    useEffect(() => { //set focus on input field
        if(show){
            reasonRef?.current?.focus()
        }
    }, [show])

    const validateReason = () => {
        if (reason === "") {
            setError("Please enter reason")
        } else {
            // const selectedEntryFee = methods.getValues(`entryFeesData.entryFees[${rowData.rowIndex}]`)
            // // Append Deleted Entry Fee to deletedEntryFees array only if the record was from database 
            // if (selectedEntryFee.entryfees_id) {
            //     // Set delete reason
            //     selectedEntryFee["reason"] = "Entry fee was deleted - " + selectedEntryFee.description + " (Reason: " + reason + ")" + " for "+ selectedEntryFee.totalprice
            //     // Append deleted row in deletedEntryFees Array
            //     const deletedEntryFees = methods.getValues("deletedEntryFees") ? [...methods.getValues("deletedEntryFees"), selectedEntryFee] : [selectedEntryFee]
            //     methods.setValue("deletedEntryFees", deletedEntryFees, { shouldDirty: true })
            // }

            // // Remove deleted row from Ag-grid
            // const filteredEntryFees = methods.getValues("entryFeesData.entryFees").filter((_: any, index : any) => index != rowData.rowIndex)
            // methods.setValue("entryFeesData.entryFees", filteredEntryFees)

            // // Reload Entry Fee Ag-grid
            // entryFeeGridRef.setRowData(methods.getValues("entryFeesData.entryFees"))
            // // Clear Errors and Reason
            // setReason("")
            // setError("")
            // // Close Reason Dialog
            // handleClose()

            const deletedEntryFees = methods.getValues("deletedEntryFees") || [];

            rowData.forEach((selectedEntryFee) => {
                // Append Deleted Entry Fee to deletedEntryFees array only if the record was from the database 
                if (selectedEntryFee?.entryfees_id) {
                    // Set delete reason
                    selectedEntryFee["reason"] = "Entry fee was deleted - " + selectedEntryFee.description + " (Reason: " + reason + ")" + " for " + selectedEntryFee.totalprice;
                    // Push deleted row to deletedEntryFees array
                    deletedEntryFees.push(selectedEntryFee);
                }
            });

            methods.setValue("deletedEntryFees", deletedEntryFees, { shouldDirty: true })

            // Remove deleted rows from Ag-grid
            const currentEntryFees = methods.getValues("entryFeesData.entryFees");
            const filteredEntryFees = currentEntryFees.filter((entryFee: any) =>
                rowData.every((selectedEntryFee) => selectedEntryFee.entryfees_id !== entryFee.entryfees_id)
            );
            methods.setValue("entryFeesData.entryFees", filteredEntryFees, { shouldDirty: true });

            setEntryFeeGridRowData(filteredEntryFees) // WA - Row Selector
            // Reload Entry Fee Ag-grid
            entryFeeGridRef.setRowData(filteredEntryFees);

            // Clear Errors and Reason
            setReason("");
            setError("");
            // Close Reason Dialog
            handleClose();
        }
    }


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event: any) => { //by using onKeyDown instead of onKeyPress, we ensure function is called even if a field is in focus
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) { //call function only when no button is in focus
                    validateReason()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.DELETEFEE"})}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                            <div className='col-lg-1 d-flex align-self-center'>
                                <i className="fa-sharp fa-solid fa-comment-dots fa-2xl"></i>
                            </div>
                            <div className='col ms-lg-2'>
                                <div className='row mb-2'>
                                    <label className='col-lg-12 col-form-label fs-5 py-1' htmlFor={'REASON'} data-tooltip-id="ENTRIES.DETAIL.MODAL.DELETEFEEFORM.LABEL.REASON">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.DELETEFEE.LABEL.REASON' })}</label>
                                    <div className='col-lg-12'>
                                        <input
                                            id={'REASON'}
                                            ref={reasonRef}
                                            type='text'
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            value={reason}
                                            onChange={e => setReason(e.target.value)}
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                                <label className='error_message'>{ error }</label>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} type="button" tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button 
                            className='btn btn-sm btn-dark fw-bold' 
                            type="button"
                            onClick={() => validateReason() }
                            autoFocus
                            tabIndex={3}
                        >
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { DeleteFeeForm }