import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation()
  const [isBarnDesignerPage, setIsBarnDesignerPage] = useState(false)
  const [isOrderOfGoPage, setIsOrderOfGoPage] = useState(false)
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  useEffect(() => {
    setIsBarnDesignerPage(location.pathname.includes('/barn-designer'))
    setIsOrderOfGoPage(location.pathname.includes('/classes/order-of-go'))
  }, [location.pathname])

  return (
    <div id='kt_content_container' className={clsx(
      classes.contentContainer,
      {
        'assign-full-height': isBarnDesignerPage,
        'no-user-select': isOrderOfGoPage
      }
      )} style={{maxWidth: '100%'}}>
      {children}
    </div>
  )
}

export {Content}
