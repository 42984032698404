import { useIntl } from 'react-intl'
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import {useEffect, useState} from 'react'
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios'
import { useFormContext } from 'react-hook-form';
import StandingsGrid from './StandingsTab/StandingsGrid';
import CircuitPointsGrid from './StandingsTab/CircuitPointsGrid';
import useReportPrint from '../../../modules/hooks/use-report-print';
import moment from 'moment';
import { loadingSpinnerBtnWait, loadingSpinnerBtnRelease } from '../../../modules/sgl-utils/SglFunctions';
import useAccessChecker from '../../../modules/hooks/use-access-checker';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const StandingsTab = ({ calculateStandings }) => {
    const intl = useIntl();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const loadingOverlay = useLoadingOverlay()
    const methods = useFormContext()
    const { hasAreaWritePermission } = useAccessChecker()
    const [standingsCount, setStandingsCount] = useState(0)
    const [showHiddenStandings, setShowHiddenStandings] = useState(null)
    const [standingsGridRef, setStandingsGridRef] = useState(null)

    const [circuitPointsCount, setCircuitPointsCount] = useState(0)
    const [showExcludedPoints, setShowExcludedPoints] = useState(null)
    const [circuitPointsGridRef, setCircuitPointsGridRef] = useState(null)
    const { printReport } = useReportPrint()

    /* WA - Row Selector */
    const [rowDataOne, setRowDataOne] = useState([])
    const [rowDataTwo, setRowDataTwo] = useState([])
    /* WA - Row Selector */

    // Set circuit standings in state, update count & call get standings api for first standing (by default)
    useEffect(() => {
        if (standingsGridRef) {
            const standings = methods.getValues('standings')
            setRowDataOne(standings) // WA - Row Selector
            standingsGridRef.setRowData(standings)
            setStandingsCount(standings.length)

            if(standings.length > 0){ // Select first standing and load its circuit point
                standingsGridRef.getRowNode(0).setSelected(true, true)
                findCircuitPointsForStanding(standings[0])
            } else {
                methods.setValue('standingsCircuitPoints', [])
            }
        }
    }, [standingsGridRef, methods.watch('standings')])

    const getStandings = async (_showHiddenStandings = null) => {
        try {
            if (!_showHiddenStandings) _showHiddenStandings = showHiddenStandings

            loadingOverlay({ show: true, message: 'Updating Standings..' })

            const response = await axios.get(`${NEST_API_URL}/circuit-divisions/getStandings?customer_id=${customer_id}&circuit_division_id=${methods.getValues('circuit_division.circuit_division_id')}&show_hidden_standings=${_showHiddenStandings}`)

            methods.setValue('standings', response.data.standings)
            methods.setValue('totalHiddenStandings', response.data.totalHiddenStandings)
            methods.setValue('totalUnhiddenStandings', response.data.totalUnhiddenStandings)
        } catch (reason) {

        } finally{
            loadingOverlay({ show: false, message: 'Updating Standings..' })
        }
    }

    useEffect(() => {
        const standings = methods.getValues('standings')

        // Any Standing with 0 position found? we need to show computation required message
        if (standings && standings.find(st => st.position == 0) && !showHiddenStandings) {
            methods.setValue('circuit_division.status', 'Computation required', { shouldDirty: true })
        }

    }, [methods.watch('standings')])

    useEffect(() => {
        if (showHiddenStandings !== null) { // call get standings on check box change, not on load.
            getStandings(showHiddenStandings)
        }
    }, [showHiddenStandings])

    useEffect(() => {
        const circuitPoints = methods.getValues('standingsCircuitPoints')
        if (circuitPointsGridRef && circuitPoints) {
            setRowDataTwo(circuitPoints) // WA - Row Selector
            circuitPointsGridRef.setRowData(circuitPoints)
            setCircuitPointsCount(circuitPoints.length)
        }
    }, [circuitPointsGridRef, methods.watch('standingsCircuitPoints')])

    const loadCircuitPointsOfSelectedStandings = async () => {
        const selectedRows = standingsGridRef.getSelectedRows()
        if (selectedRows.length > 0) {
            await findCircuitPointsForStanding(selectedRows[0])
        }
    }

    useEffect(() => {
        if (showExcludedPoints !== null) { // call on check box change, not on load.
            loadCircuitPointsOfSelectedStandings()
        }
    }, [showExcludedPoints])
  
    const findCircuitPointsForStanding = async (standing) =>{
        try {
            loadingOverlay({ show: true, message: 'Loading Circuit Points..' })
            
            const response = await axios.get( process.env.REACT_APP_NEST_API_URL + '/circuit-divisions/circuitpoints', {
                params: {
                    standingsselectionmethod: methods.watch('circuit_division.standingsselectionmethod'),
                    circuit_division_id: methods.getValues('circuit_division.circuit_division_id'),
                    trainer_id: standing.trainer_id,
                    rider_id: standing.rider_id,
                    owner_id: standing.owner_id,
                    horse_id: standing.horse_id,
                    customer_id: customer_id,
                    show_excluded_points: showExcludedPoints ? showExcludedPoints : false
                }
            })
            
            methods.setValue('standingsCircuitPoints', response.data.standingsCircuitPoints)
            methods.setValue('totalExcludedPoints', response.data.totalExcludedPoints)
            methods.setValue('totalUnExcludedPoints', response.data.totalUnExcludedPoints)
        } catch (reason) {

        } finally {
            loadingOverlay({ show: false, message: 'Loading Circuit Points..' })
        }
    }

    const printCircuitDivisionReport = async (event, recordLimit = null) => {
        // Show loading
        loadingSpinnerBtnWait(event)

        // 157 -> Circuit Division Standings
        await printReport(157, [ methods.getValues('circuit_division.circuit_division_id') ], { recordLimit })

        // Hide loading
        loadingSpinnerBtnRelease(event)
    }

    const printCurrentStandingPointReport = async (event) => {
        let ids = standingsGridRef.getSelectedRows()[0].sgl_id

        // Show loading
        loadingSpinnerBtnWait(event)

        // 158 -> CIR DIV - Individual Standing
        await printReport(158, [ ids ])

        // Hide loading
        loadingSpinnerBtnRelease(event)
    }

    const [statusLabel, setStatusLabel] = useState('')
    useEffect(() => {
        computeStatus()
    }, [methods.watch('circuit_division.status')])

    const computeStatus = () => {
        const status = methods.getValues('circuit_division.status')
        let labelText = ''
        if (status) {
            if (status === 'Computation required') {
                labelText = intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.UPDATEMESSAGE' })
            } else if (status === 'Processing') {
                labelText = 'Circuit Points and Standings calculation are In-Progress.'
            } else if (status === 'Scheduled') {
                labelText = 'Circuit Points and Standings calculation are Scheduled for computation.'
            }
        }

        setStatusLabel(labelText)
    }

    return (
        <>
            <div id="circuit-divisions-standings-tab" className='form-group'>
                <div className='row mb-2 d-flex justify-content-between'>
                    <div className='d-flex align-items-end col-lg-8'>
                        <div className='w-200px me-3'>
                            { hasAreaWritePermission('circuits') && <button 
                                type='button' 
                                className='btn col-12 btn-sm btn-secondary fw-bold p-2 text-uppercase' onClick={calculateStandings}
                                disabled={methods.watch('circuit_division.circuit_division_id') > 0 ? false : true}
                                data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.BUTTON.CALCULATESTANDINGS"
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.CALCULATESTANDINGS' })}
                            </button> }  
                        </div>
                        <div>
                            <label className='col text-danger col-form-label fs-5 py-1'>{ statusLabel }</label>
                        </div>
                    </div>
                    {(methods?.getValues(`circuit_division.lastmodified_date_time`) && moment(methods?.getValues(`circuit_division.lastmodified_date_time`), "YYYY/MM/DD-HH:mm:ss", true).isValid())&&
                        <label 
                            className='col col-form-label fs-5 py-1 text-end'
                            data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.LABEL.STANDINGLASTCALCULATED"
                        >
                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.LASTCALCULATEDTIME' })} 
                            { moment(methods?.getValues(`circuit_division.lastmodified_date_time`), "YYYY/MM/DD-HH:mm:ss").format('M/D/YY hh:mm A') }
                        </label>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col form-check-sm form-check-custom justify-content-end'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='showHiddenStandings'
                            onChange={e => setShowHiddenStandings(e.target.checked)}
                            checked={showHiddenStandings}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='showHiddenStandings' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.LABEL.SHOWHIDDENSTANDINGS">
                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.LABEL.SHOWHIDDENSTANDINGS' })} { showHiddenStandings ? '' : `(${methods.getValues('totalHiddenStandings') ?? 0} Hidden Standing(s))` }
                        </label>
                    </div>   
                </div>
                <div className='row'>
                    <StandingsGrid 
                        findCircuitPointsForStanding={findCircuitPointsForStanding}
                        setStandingsGridRef={setStandingsGridRef}
                        getStandings={getStandings}
                        showHiddenStandings={showHiddenStandings}
                        rowData={rowDataOne}
                    />
                </div>
                <div className='row'>
                    <label className='text-end col-form-label fs-5 py-1'>Total: {standingsCount}</label>
                </div>
                <hr className='mt-2 mb-2' />
                <div className='row'>
                    <div className='col form-check-sm form-check-custom justify-content-end'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='showExcludedPoints'
                            onChange = {(e) => {
                                setShowExcludedPoints(e.target.checked)
                            }}
                            checked={showExcludedPoints}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='showExcludedPoints' data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.LABEL.SHOWEXCLUDEDPOINTS"> 
                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.LABEL.SHOWEXCULDEDPOINTS' })} { showExcludedPoints ? '' : `(${methods.getValues('totalExcludedPoints') ?? 0} Excluded Point(s))` }
                        </label>
                    </div>
                </div>
                <div className='row'>
                    <CircuitPointsGrid 
                        setCircuitPointsGridRef={setCircuitPointsGridRef}
                        loadCircuitPointsOfSelectedStandings={loadCircuitPointsOfSelectedStandings}
                        rowData={rowDataTwo}
                    />
                </div>
                <div className='row mb-2'>
                    <label className='text-end col-form-label fs-5 py-1'>Total: {circuitPointsCount}</label>
                </div>
                <div className='row mb-2'>
                    <div className='col'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-1 w-lg-800px'>
                                <div className='row'>
                                        <div className='col-lg-1 w-lg-200px'>
                                            <button disabled={methods.watch('circuit_division.circuit_division_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={(event) => printCircuitDivisionReport(event)} data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.BUTTON.PRINTALL">
                                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                                {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTALL' })}
                                            </button>
                                        </div>
                                        <div className='col-lg-1 w-lg-200px'>
                                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                            <button disabled={methods.watch('circuit_division.circuit_division_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"  onClick={(event) => printCircuitDivisionReport(event, 20)} data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.BUTTON.PRINTTOPTWENTY">
                                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                                {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTTOPTWENTY' })}
                                            </button>
                                        </div>
                                        <div className='col-lg-1 w-lg-200px'>
                                            <button disabled={methods.watch('circuit_division.circuit_division_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12"  onClick={(event) => printCircuitDivisionReport(event, 10)} data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.BUTTON.PRINTTOPTENFORALL">
                                                <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                                {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITDIVISIONS.PRINTTOPTENFORALL' })}
                                            </button>
                                        </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='row justify-content-end'>
                                    <div id="calculate-standing-btn" className='col-lg-1 w-lg-250px'>
                                        <button disabled={methods.watch('circuit_division.circuit_division_id') > 0 ? false : true} type='button' className="btn btn-sm btn-secondary fw-bold px-2 py-2 text-uppercase col-12" onClick={(event) => printCurrentStandingPointReport(event)} data-tooltip-id="CIRCUITDIVISIONS.DETAIL.TAB.STANDINGS.BUTTON.PRINTCALCULATESTANDINGS">
                                            <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                            {intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.STANDINGS.PRINTCURRENTSTANDINGS' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { StandingsTab }