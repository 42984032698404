import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters'
import moment from 'moment'
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer'
import BooleanCheckmarkRenderer from '../../../../modules/output-listing/renderers/BooleanCheckmarkRenderer'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers'
import { addFloatingNumbers } from '../../../../modules/sgl-utils/SglFunctions'


const modalsRoot = document.getElementById('root-modals') || document.body

const DistributionsDetailForm = ({ show, handleClose, selectedAccountRegister }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const [gridRef, setGridRef] = useState(null)

    const columnDefsForDistributions = [
        { field: 'relatedAR.Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.ENTRY' }), width: 120},
        { field: 'relatedAR.Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SHOW' }), width: 250,
        valueGetter: params => {
            if(params?.data?.relatedAR?.Show && !params?.node?.rowPinned){
                let date = new Date(params.data.relatedAR.Show.end_date)
                return params.data.relatedAR.Show.show_name.concat(' [',date.getFullYear(),']')
            }
        }
        },
        { field: 'relatedAR.arentry_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DATE' }),  cellStyle: {textAlign: 'center'} ,cellRenderer: DateRenderer },
        { field: 'relatedAR.type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.TYPE' }), width: 150},
        { field: 'relatedAR.description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DESCRIPTION' }), width: 400, flex: 1 },
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DISTRIBUTION.DETAIL.AMOUNT' }), valueFormatter: params => params?.node?.rowPinned ? params.data?.amount ? params.data?.amount : '' : params.data?.amount?.toFixed(2),  cellStyle: {textAlign: 'center'}  },
        { field: 'relatedAR.amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DISTRIBUTION.DETAIL.TOTAL' }), valueFormatter: params => params?.node?.rowPinned ? '' : params.data?.relatedAR?.amount?.toFixed(2),  cellStyle: {textAlign: 'center'}  },
        { field: 'reversed',  headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.REVERSED' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, }
    ]

    const columnDefsForPaymentDetail = [
        { field: 'relatedAR.Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.ENTRY' }), width: 120, cellStyle: {textAlign: 'center'} },
        { field: 'relatedAR.Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SHOW' }), width: 300,
        valueGetter: params => {
            if(params.data.relatedAR.Show){
                let date = new Date(params.data.relatedAR.Show.end_date)
                return params.data.relatedAR.Show.show_name.concat(' [',date.getFullYear(),']')
            }
        }
    },
        { field: 'relatedAR.type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.TYPE' }), width: 150},
        { field: 'relatedAR.description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DESCRIPTION' }), width: 250, flex:1 },
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DISTRIBUTION.DETAIL.AMOUNT' }), valueFormatter: params => params.data?.amount?.toFixed(2),  cellStyle: {textAlign: 'center'}, width: 120 },
        { field: 'reversed',  headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.REVERSED' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, width: 120 }
    ]

    const columnDefsForPayments = [
        { field: 'relatedAR.Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.ENTRY' }), width: 90},
        { field: 'relatedAR.Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SHOW' }), width: 250,
        valueGetter: params => {
            if(params.data.relatedAR.Show){
                let date = new Date(params.data.relatedAR.Show.end_date)
                return params.data.relatedAR.Show.show_name.concat(' [',date.getFullYear(),']')
            }
        }
    },
        { field: 'relatedAR.arentry_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DATE' }),  cellStyle: {textAlign: 'center'} ,cellRenderer: DateRenderer },
        { field: 'relatedAR.type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.TYPE' }), width: 10, cellRenderer: 'agGroupCellRenderer',},
        { field: 'relatedAR.description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DESCRIPTION' }), width: 320, flex: 1 },
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DISTRIBUTION.DETAIL.AMOUNT' }), valueFormatter: params => params.data?.amount?.toFixed(2),  cellStyle: {textAlign: 'center'} },
        { field: 'relatedAR.amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DISTRIBUTION.DETAIL.TOTAL' }), valueFormatter: params => params.data?.relatedAR?.amount?.toFixed(2),  cellStyle: {textAlign: 'center'} },
        { field: 'reversed',  headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.REVERSED' }), cellRenderer: BooleanCheckmarkRenderer, cellStyle: { textAlign: 'center' }, }
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '45vh' }), []);

    const rowClassRules = {
        'bg-expired': function(params) {
            return params.node.data?.relatedAR?.reversal_id === selectedAccountRegister?.accountingregister_id;
        }
    }

    useEffect(() => {
        // update pinned row data
        if(gridRef){
            let totalAmount = 0
            if(selectedAccountRegister?.distributions?.length > 0){
                for(let distribution of selectedAccountRegister?.distributions){
                    totalAmount = addFloatingNumbers([totalAmount, distribution.amount], 2)
                }
            }
    
            gridRef.setPinnedBottomRowData([{
                amount: parseFloat(totalAmount).toFixed(2)
            }])
        }
    }, [selectedAccountRegister && gridRef])

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={selectedAccountRegister.is_sgl_cloud ? 'modal-dialog modal-dialog-centered modal-1400px-max-width' : 'modal-dialog modal-dialog-centered modal-900px-max-width'}
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                if (event.key === "Enter") {
                    handleClose()
                }
            }}
        >
            <style>{"@media (min-width: 992px) { .add-fee-form .w-lg-125px fw-bold { width: 145px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose} >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.DESCRIPTION' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { 
                                    selectedAccountRegister?.description 
                                }
                            </label>
                        </div>
                        {
                            (selectedAccountRegister?.entry_id || selectedAccountRegister?.show_id) ?
                            <div className='row mb-2'>
                                {/* Entry Number */}
                                {
                                    selectedAccountRegister?.Entry?.number ?
                                    <div className='col-lg-6'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.ENTRY' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { `${selectedAccountRegister?.Entry?.number} - ${selectedAccountRegister?.Entry?.horse}` }
                                        </label>
                                    </div> : null
                                }

                                {/* Check Number */}
                                {
                                    (selectedAccountRegister?.check_number || selectedAccountRegister?.type?.includes('Check')) ?
                                    <div className='col-lg-6'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.CHECKNO' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            { selectedAccountRegister?.check_number }
                                        </label>
                                    </div> : null
                                }
                                
                                <div className='col-lg-6'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.SHOW' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { selectedAccountRegister?.Show?.show_name }
                                    </label>
                                </div>
                            </div> : null
                        }

                        {
                            selectedAccountRegister?.year && selectedAccountRegister?.type?.includes('Check') ?
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.CHECKNO' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { selectedAccountRegister?.check_number }
                                    </label>
                                </div>

                                <div className='col-lg-6'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.YEAR' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                    { selectedAccountRegister?.year }
                                    </label>
                                </div>
                            </div> : null
                        }
                        
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.LABEL.AMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.AMOUNT' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { currencyFormatter(selectedAccountRegister?.amount) }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.LABEL.TYPE">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.TYPE' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { selectedAccountRegister?.type }
                                </label>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold' data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.LABEL.DATE">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.DATE' })}</label>
                                <label className='col col-form-label fs-5 py-1'>
                                    { 
                                        moment(selectedAccountRegister?.arentry_date).isValid() ? moment(selectedAccountRegister?.arentry_date).format("MM/DD/YYYY") : "" }
                                </label>
                            </div>
                            <div className='col-lg-6'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-80px fw-bold' data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.LABEL.REVERSED">{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.REVERSED' })}</label>
                                <label className={selectedAccountRegister?.reversed ? 'col col-form-label fs-5 py-1 text-danger' : 'col col-form-label fs-5 py-1 text-success'}>
                                    { selectedAccountRegister?.reversed ? 'Yes' : 'No' }
                                </label>
                            </div>
                        </div>

                        {
                            selectedAccountRegister?.year && !selectedAccountRegister?.type?.includes('Check') ?
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.YEAR' })}</label>
                                    <label className='col col-form-label fs-5 py-1'>
                                        { selectedAccountRegister?.year }
                                    </label>
                                </div>
                            </div> : null
                        }

                        {
                            (selectedAccountRegister?.used || selectedAccountRegister?.available) ?
                            <div className='row mb-2'>
                                {/* Used */}
                                {
                                    <div className='col-lg-6'>
                                        <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px fw-bold'>{intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.AVAILABLE' })}</label>
                                        <label className='col col-form-label fs-5 py-1'>
                                            {currencyFormatter(selectedAccountRegister?.available)}
                                        </label>
                                    </div>
                                }
                            </div> : null
                        }

                        { selectedAccountRegister.is_sgl_cloud ?
                        <div className='row'>
                            <div className='col'>
                                {/* Account register distributions */}
                                <fieldset className='mb-2'>
                                    <legend className="fs-5 fw-bold col">{selectedAccountRegister?.distributions?.filter((dis) => dis.accountregister_from_id != selectedAccountRegister.accountingregister_id)?.length > 0 || selectedAccountRegister?.reversal_id ? intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.GROUPLABEL.SOURCE' }) : intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.PAYMENTDETAIL.GROUPLABEL.DESTINATION' })} <b></b></legend>
                                    <div className='row'>
                                    <div className='distribution-detail'>
                                        <OutputListingGridStatic
                                            area ={"RTO - Account Register Distribution Detail"}
                                            columnDefs={columnDefsForDistributions}
                                            rowData={selectedAccountRegister?.distributions ?? []}
                                            containerStyle={containerStyle}
                                            groupDefaultExpanded={true}
                                            masterDetail={true}
                                            detailRowAutoHeight={true}
                                            onCellClicked={params => {
                                                //Note: don't display the inner grid
                                                //params.node.setExpanded(!params?.node?.expanded);
                                            }}
                                            detailCellRendererParams={{
                                                // level 2 grid options
                                                detailGridOptions: {
                                                    rowHeight:getNarrowRowHeight,
                                                    headerHeight: getNarrowHeaderHeight,
                                                    columnDefs: columnDefsForPaymentDetail
                                                },
                                                getDetailRowData: function(params) {
                                                    params.successCallback(params.data.children);
                                                }
                                            }}
                                            setGridRef={setGridRef}
                                            getRowStyle={params => getRowStyle(params)}
                                        ></OutputListingGridStatic>
                                    </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div> : 
                        <label className='col col-form-label fs-5 py-1 fw-bold text-danger'>
                            {intl.formatMessage({ id: 'FORM.INPUT.RTO.TAB.ACCOUNT.MODAL.ACCOUNTREGISTERDETAIL.LABEL.LEGACY' })}
                        </label>
                         }
                    </div>

                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-dark fw-bold text-uppercase' onClick={handleClose} autoFocus data-tooltip-id="PEOPLE.DETAIL.MODAL.ACCOUNTREGISTERDETAIL.BUTTON.DONE">
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDFEE.BUTTON.DONE' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { DistributionsDetailForm }