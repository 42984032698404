import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form'
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAppSelector } from '../../../../redux/hooks';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../../modules/output-listing/OutputListingRowOptions';

const SummaryByShow = () => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const methods = useFormContext()
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const [gridRef, setGridRef] = useState();
    const [showsGridRef, setShowsGridRef] = useState();
    const [paidOutGridRef, setPaidOutGridRef] = useState();
    const show_financials_locked = useAppSelector(state => state.currentShow.islocked);

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const [visibleRowsTwo, setVisibleRowsTwo] = useState(DEFAULT_VISIBLE_ROWS);
    const [visibleRowsThree, setVisibleRowsThree] = useState(DEFAULT_VISIBLE_ROWS);
    const [rowDataOne, setRowDataOne] = useState(methods.getValues('prizeMoneyTrips') ?? [])
    const [rowDataTwo, setRowDataTwo] = useState(methods.getValues('prizeMoneyTrips') ?? [])
    const [rowDataThree, setRowDataThree] = useState(methods.getValues('prizeMoneyTrips') ?? [])
    /* WA - Row Selector */

    const rowClassRules = useMemo(() => {
        return {
            'bg-red-grid-row': 'data.prize_money_error'
        };
    }, []);
    
    useEffect(() => {
        if(gridRef){
            gridRef.setRowData([])
            setRowDataTwo([]) // WA - Row Selector
            paidOutGridRef?.setRowData([])
            setRowDataThree([]) // WA - Row Selector
            setRowDataOne(methods.getValues('prizeMoneyTrips')); // Reinitialize data // WA - Row Selector
        }
    }, [gridRef, methods.watch('prizeMoneyTrips')]);

    const columnDefs = [
        { field: 'show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.SHOW' }), flex:1,
            valueGetter: params => {
                if(params.data.show_name){
                    let date = new Date(params.data.end_date)
                    return params.data.show_name.concat(' [',date.getFullYear(),']')
                }
            }
        },
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.PRIZEMONEY' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'total_unapplied_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.UNAPPLIEDPRIZEMONEY' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'transferred_to_account', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.TRANSFERTOACCOUNT' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'paid_out', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.PAIDOUT' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'to_be_paid', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.TOBEPAID' }),  cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, flex: 1 },
    ]

    const prizeMoneyTripsColumnDefs = [
        { field: 'Entry.Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.SHOW' }), flex:3 },
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.ENTRY' }) },
        { field: 'Class.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.CLASS' })},
        { field: 'reason', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.REASON' }), flex: 2},
        { field: 'total_prize_money', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.PRIZEMONEY' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'withholding_tax', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.WITHHOLDTAX' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'} },
        { field: 'Entry.balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.BALANCE' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, flex: 1 },
    ]

    const payoutColumnDefs = [
        { field: 'arentry_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.DATE' }), cellClass: 'ag-center-aligned-cell', cellRenderer: DateRenderer  },
        { field: 'check_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.CHECK' }) },
        { field: 'type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.TYPE' }), flex: 2},
        { field: 'description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.PAYOUTDESCRIPTION' }),flex: 3},
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.AMOUNT' }), cellRenderer: params => currencyFormatter(params.value), cellStyle: {textAlign: 'center'}, flex: 1 },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);

    const onCellClicked = (params) => {
        gridRef.setRowData(params.data.prizeMoneyTrips)
        setRowDataTwo(params.data.prizeMoneyTrips) // WA - Row Selector
        paidOutGridRef.setRowData(params.data.paidOutDetails)
        setRowDataThree(params.data.paidOutDetails) // WA - Row Selector
    }

    const onRowSelection = (params) => {
        let selectedRows = params.api.getSelectedNodes()
        if(selectedRows && selectedRows?.length > 0){
            gridRef?.setRowData(selectedRows[0].data.prizeMoneyTrips)
            setRowDataTwo(selectedRows[0].data.prizeMoneyTrips) // WA - Row Selector
            paidOutGridRef?.setRowData(selectedRows[0].data.paidOutDetails)
            setRowDataThree(selectedRows[0].data.paidOutDetails) // WA - Row Selector
        }
    }

    return (
        <>
            <div className='form-group'>
                <div className='d-flex'>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.SUMMARYBYSHOW.LABEL.ENTRIESBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ENTRIESBALANCE' }) } : {currencyFormatter(methods.getValues('rtosTotalBalances.entriesBalance'))}</label>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.SUMMARYBYSHOW.LABEL.ACCOUNTREGISTERBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTERBALANCE' }) } : { currencyFormatter(methods.getValues('rtosTotalBalances.accountRegisterBalance'))}</label>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.SUMMARYBYSHOW.LABEL.ACCOUNTBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTBALANCE' }) } : {currencyFormatter(methods.getValues('rtosTotalBalances.accountBalance'))}</label>
                </div>
                {/* <p className='mb-1 text-end'>Total: {methods.getValues('entries') && methods.getValues('entries').length}</p> */}
                {/* WA - Row Selector */}
                <div className='form-group mb-2'> 
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <RowSelector
                        value={visibleRows}
                        onChange={setVisibleRows}
                        area={"People - Account SummaryByShow 1"}
                        />
                    </div>
                    <div className='row align-items-center mb-2'>
                        <OutputListingGridStatic area ={"People - Account SummaryByShow 1"} onRowSelected={onRowSelection} rowSelection={'single'} setGridRef={setShowsGridRef} columnDefs={columnDefs} rowData={rowDataOne} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic>
                    </div>
                </div>
                <div className='form-group mb-2'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <RowSelector
                        value={visibleRowsTwo}
                        onChange={setVisibleRowsTwo}
                        area={"People - Account SummaryByShow 2"}
                        />
                    </div>
                    <div className='row align-items-center mb-2'>
                        <OutputListingGridStatic area ={"People - Account SummaryByShow 2"} rowClassRules={rowClassRules} setGridRef={setGridRef} columnDefs={prizeMoneyTripsColumnDefs} rowData={rowDataTwo} containerStyle={containerStyle} visibleRows={visibleRowsTwo}></OutputListingGridStatic>
                    </div>
                </div>
                <div className='form-group mb-2'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <RowSelector
                        value={visibleRowsThree}
                        onChange={setVisibleRowsThree}
                        area={"People - Account SummaryByShow 3"}
                        />
                    </div>
                    <div className='row align-items-center mb-2'>
                        <OutputListingGridStatic area ={"People - Account SummaryByShow 3"} setGridRef={setPaidOutGridRef} columnDefs={payoutColumnDefs} rowData={rowDataThree} containerStyle={containerStyle} visibleRows={visibleRowsThree}></OutputListingGridStatic>
                    </div> 
                {/* WA - Row Selector */}
                </div>
                <div className='row mb-2 d-flex justify-content-between'>
                    <div className='col-6'>
                    </div>
                    <div className=' row col-6 d-flex justify-content-end w-lg-600px me-0'>
                        <label className='mt-3' style={{backgroundColor: 'red', width:'40px',height:'5px'}}></label>
                        <label className='col-10 fw-bold fs-5 pe-0 text-end' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.SUMMARYBYSHOW.LABEL.INDICATEENTRIESWITHUNAPPLIEDPRIZEMONEY">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.SUMMARYBYSHOW.LABEL.UNAPPLIEDPRIZEMONEY' }) }</label>  
                    </div>
                </div>
            </div>
        </>
    );
}

export { SummaryByShow }