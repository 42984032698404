import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/hooks';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import { UpdateFeiCredentialsDialog } from '../../Detail/UpdateFeiCredentialsDialog';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import useGenerateExport from '../../../../modules/hooks/use-generate-export';
import { downloadTextFile, getCurrentDateTimeFormatted } from '../../../../modules/sgl-utils/SglFunctions';
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';

const modalsRoot = document.getElementById('root-modals') || document.body

const ExportFEIResults = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getOutputSelectionAreaIDs } = useOutputContext()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [showModal, setshowModal] = useState(false)
    const [displayErrors, setDisplayErrors] = useState(false)
    const [selectedShowId, setSelectedShowId] = useState(0)
    const [errorData, setErrorData] = useState([]);
    const [errorHeading, setErrorHeading] = useState('');
    const [errorCount, setErrorCount] = useState({});
    const generateExport = useGenerateExport();
    const {progressBar} = useProgress()
    const { getEventID } = useOutputContext();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const eventsContainerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);
    const summaryContainerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const [gridRef, setGridRef] = useState();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [classesData, setClassesData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [showRecord, setShowRecord] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [resultUploadStatus, setResultUploadStatus] = useState('');
    const [resultUploadColor, setResultUploadColor] = useState('red');
    const [submitFeiResults, setSubmitFeiResults] = useState(true);
    const [showFEICredentialDialog, setFEICredentialDialog] = useState(false);

    const updateCredentials = (fei_username, fei_password) => {
 
    }
    useEffect(() => {
        if (show) { 
            getOutputSelectionAreaIDs()
            .then(res => { 
                if (res.length !==  1){
                    // error message if no show is selected
                    alertDialog({ message: intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.SELECTSHOWSMESSAGE"}) })
                    handleClose()
                }
                else if (res.length ===  1) {
                    loadingOverlay({show: true, message: 'Processing FEI Export...'})
                    let currentShowID = res;
                    setSelectedShowId(currentShowID)
                    axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/getFeiExportMetadata', {
                        params: {
                          show_id: currentShowID,
                          customer_id: customerId
                        }
                      })
                    .then((response) => {
                        // api will return success(T/F), error_message, flag for display alert and data to display in modal
                        if(!response.data.success){                            
                            alertDialog({ message: response.data.erorr_message })
                            loadingOverlay({ show: false, message: 'Processing FEI Export...' })
                            handleClose()
                        } else {
                            //add changes to check if we are using user credentials, if so, check if credentials are invalid, ask user to enter credentials.
                            loadingOverlay({show: true, message: 'Processing FEI Export...'})
                            let currentShowID = res;
                            setSelectedShowId(currentShowID)
                            axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/validateUserCredentialsForFeiResultsExport', {
                                params: {                          
                                customer_id: customerId
                                }
                            })
                            .then(async (fei_response) => {
                                if(fei_response.data.success){ // credentials are fine
                                //process with results export                        
                                } else {                            
                                    let error_id = 'FORM.INPUT.SHOWS.TAB.RESULTS.EXPORT.ERROR.FEI.INVALID.USER.CREDENTIALS'
                                    let userChoice = await confirmDialog({message: intl.formatMessage({id: error_id})})
                                    if(userChoice){
                                        setFEICredentialDialog(true)                                
                                    } else {
                                            handleClose() // close. do not proceed with results export
                                        }                            
                                }
                            })                                                        
                            let errors = response.data.error_list;  // for immidiate processing
                            setshowModal(true);
                            setErrorData(errors);
                            setClassesData(response.data.classes_list);
                            setShowRecord(response.data.show_record);
                            setErrorHeading(response.data.error_heading);
                            if (errors.length > 0) {
                                setDisplayErrors(true);
                            }

                            // Get error counts to display on the right side of the modal
                            // e.g. Rider Name Missing: 10 Error(s)
                            const errorCounts = {};

                            // Find error count for each error
                            errors.forEach(item => {
                                const errorType = item.error_message;
                                errorCounts[errorType] = (errorCounts[errorType] || 0) + 1;
                            });

                            setErrorCount(errorCounts);
                        }
                        loadingOverlay({ show: false })
                    })
                    .catch(() => loadingOverlay({ show: false }))
                } 
            })
            .catch(() => loadingOverlay({ show: false }))
        }
    }, [show])


    const onClose = () => {
        loadingOverlay({ show: false })
        handleClose();  
    }

    // Check box renderer for AG grid in events grid
    const renderCheckBox = (params) => {
        return (
            <input
                type="checkbox"
                onChange={
                    (e) => {
                        params.node.setDataValue(params.column.colId, e.target.checked);
                    }
                }
                defaultChecked={params.value}
            />
        )
    }

    // Error grid col defs
    const getErrorsColDefs = () => {
        return [
            { field: 'class_number', cellStyle: {textAlign: 'center'},  width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.CLASS" })},
            { field: 'entry_number', cellStyle: {textAlign: 'center'}, width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.ENTRY" })},
            { field: 'rider_name',  cellStyle: {textAlign: 'left'}, width: 150, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.RIDER" }) },
            { field: 'error_message', cellStyle: {textAlign: 'left'}, width: 180, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.ERROR" })},
        ]
    }

    // Events grid col defs
    const getEventsColDefs = () => {
        return [
            { 
                field: 'selected',
                headerName: "", 
                cellStyle: {textAlign: 'center'},
                suppressSizeToFit: true,
                maxWidth: 50,
                cellRenderer: renderCheckBox
            },
            { field: 'fei_competition_code', cellStyle: {textAlign: 'left'},  width:200, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.COMPETITIONCODE" })},
            { field: 'number', cellStyle: {textAlign: 'center'},  width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.NUMBER" })},
            { field: 'name', cellStyle: {textAlign: 'left'},  width:500, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.NAME" })},
        ]
    }

    // Summary grid column definitions
    const getSummaryColDefs = () => {
        return [
            { field: 'error_message', flex: 1, wrapText: true, autoHeight: true, cellStyle: {textAlign: 'left'}, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.GRID.MESSAGES" })},
        ]
    }

    // Export FEI Results
    const exportResults = async () => {
        const selectedRows = classesData.filter((row) => row.selected == true);
        let classIds = selectedRows.map(row => row.class_id);
        let eventIds = selectedRows.map(row => row.fei_event_code);

        if (selectedRows == 0) {
            alertDialog({ message: 'Please select events to export' })
        }

        if (classIds.length > 0 && eventIds.length > 0) {
            const eventID = getEventID("export-show-FEI-Results");
            handleProgress(selectedRows.length, true, eventID);
            axios.post( process.env.REACT_APP_NEST_API_URL + `/shows/exportFeiResults`, {
                customer_id: customerId,
                show_id: selectedShowId,
                class_ids: classIds,
                event_codes: eventIds,
                progress_event_id: eventID
            })
            .then((response) => {
                if (response.data.success) {
                    // Download the file
                    downloadTextFile(response.data.file_content, response.data.file_name);
                    setShowSuccessMessage(true);
                    setResultUploadStatus('Results Exported Successfully.')
                    setResultUploadColor('green')
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000)
                } else {
                    alertDialog({ message: response.data.error_message })
                }
                handleProgress(selectedRows.length, false, eventID);
            })
            .catch((error) => {
                console.log(error);
                handleProgress(selectedRows.length, false, eventID);              
            })
        }
    }

    // Export FEI Results
    const uploadResults = async () => {
        setSummaryData([])
        setResultUploadStatus('')
        setShowSuccessMessage(false);

        const selectedRows = classesData.filter((row) => row.selected == true);
        let classIds = selectedRows.map(row => row.class_id);
        let eventIds = selectedRows.map(row => row.fei_event_code);

        if (selectedRows == 0) {
            alertDialog({ message: 'Please select events to upload' })
        }

        if (classIds.length > 0 && eventIds.length > 0) {
            const eventID = getEventID("upload-show-FEI-Results");
            progressBar({ show: true, eventID, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })

            // handleProgress(selectedRows.length, true, eventID);
            var response = await axios.post( process.env.REACT_APP_NEST_API_URL + `/shows/uploadFeiResults`, {
                customer_id: customerId,
                show_id: selectedShowId,
                class_ids: classIds,
                event_codes: eventIds,
                progress_event_id: eventID,
                submit_results: submitFeiResults
            })
            progressBar({ show: false, eventID, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })

            // .then((response) => {
                console.log(response)
                if (response.data.success) {
                    if (response.data.xml_success) {

                    } else {
                        if(response.data.display_warnings){
                            let message = 'There are warnings during the results upload to FEI. Do you want to continue with results submission?'
                            const choice = await confirmDialog({message: message, title: 'stop', icon: 'stop', okButtonTitle: intl.formatMessage({id: 'FORM.INPUT.CLASSES.OPENCARD.COMMON.BUTTON.YES'}),  cancelButtonTitle: intl.formatMessage({id: 'BUTTON.CANCEL'}) })
                            if(choice){
                                submitResultsAfterConfirmation(response.data)
                            } else {
                                setSummaryData(response.data.error_messages)
                                if(response.data.upload_status != ''){
                                    setResultUploadStatus(response.data.upload_status)
                                    setShowSuccessMessage(true);
                                    setResultUploadColor(response.data.color)
                                }
                            }
                        } else {
                            setSummaryData(response.data.error_messages)
                            if(response.data.upload_status != ''){
                                setResultUploadStatus(response.data.upload_status)
                                setShowSuccessMessage(true);
                                setResultUploadColor(response.data.color)

                            }
                        }
                        
                    }
                    
                } else {
                    alertDialog({ message: response.data.error_message })
                }
                handleProgress(selectedRows.length, false, eventID);
            // })
            // .catch((error) => {
            //     console.log(error);
            //     handleProgress(selectedRows.length, false, eventID);              
            // })
        }
    }

    async function submitResultsAfterConfirmation(submit_response){
        if (submit_response.event_codes.length > 0) {
            const eventID = getEventID("submit-FEI-Results");
            progressBar({ show: true, eventID, showProgress: 'inline', title: "Submitting Results to FEI", timeElapsed: true })
            axios.post( process.env.REACT_APP_NEST_API_URL + `/shows/submitResultsAfterConfirmation`, {
                customer_id: customerId,
                show_id: selectedShowId,
                event_codes: submit_response.event_codes,
                progress_event_id: eventID
            })
            .then((response) => {
                if (response.data.success) {
                    setSummaryData(response.data.error_messages)
                    if(response.data.upload_status != ''){
                        setResultUploadStatus(response.data.upload_status)
                        setShowSuccessMessage(true);
                        setResultUploadColor(response.data.color)

                    }
                } else {
                    alertDialog({ message: response.data.error_message })
                }
                progressBar({ show: false, eventID, showProgress: 'inline', title: "Submitting Results to FEI", timeElapsed: true })
            })
            .catch((error) => {
                console.log(error);
                progressBar({ show: false, eventID, showProgress: 'inline', title: "Submitting Results to FEI", timeElapsed: true })
            })
        }
    }

    const handleProgress = async (selectedRowsLength, show, eventID) => {
        // When small dataset is selecteed show loading overlay otherwise show progress bar
        if (selectedRowsLength > 25) {
            progressBar({ show: show, eventID, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })
        } else {
            loadingOverlay({show: show, message: 'Exporting Data...'})
        }
    }

    function exportErrors(){
        // let separater = '\t'
        if(summaryData.length>0){
            // let headers ="No"+separater+"Class"+separater+"Entry"+separater+"Place"+separater+"Horse"+separater+"Rider"+separater+"Owner"+separater+"Type"+separater+"Errors\n"
            // const tabSeparatedData = errorsRowData.map(item => `${item.name}\t${item.age}\t${item.country}`).join('\n');
            console.log(summaryData)
            let errors = summaryData.map(s => s.error_message)
            var data = errors.join('\n');
            console.log(data)
       
            let date = Date()
            // $vt_DocName:="FEI Results Upload Messages "+Substring([Show]Show_Name;1;11)  //KU 04/27/2023 - Changed <>vt_CurrentShowName to [Show]Show_Name. User can select other show than current show for export

            let doc_name = 'FEI Results Upload Messages '+showRecord.show_name+getCurrentDateTimeFormatted()+'.txt'
            downloadTextFile(data, doc_name)
        }
    }

    return createPortal(
        
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={showModal}
            onHide={onClose}
        >
            {showFEICredentialDialog && <UpdateFeiCredentialsDialog show={showFEICredentialDialog} handleClose={() => setFEICredentialDialog(false)} updateCredentials= {updateCredentials} />}
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{displayErrors?intl.formatMessage({ id: 'SHOWS.QUICKACTION.POPUP.HEADING.FEIRESULTSVERIFICATION' }): intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.FEIRESULTSVERIFICATION' }) }</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            
                            <div className='col'>
                                {(displayErrors) ?
                                    <div className='row mb-2'>
                                        <label className='col-lg-12 col-form-label fs-5 py-1 fw-bold'>
                                            { intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.USEFRESULTSVERIFICATION"  }) }
                                        </label>
                                        <label className='col-lg-12 col-form-label fs-5 py-1'>
                                            {errorHeading}
                                        </label>
                                    </div>
                                :
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            <div className='d-flex mb-2'>
                                                <div className='col-2'>
                                                    <label className='col-lg-12 col-form-label fs-5 py-1 fw-bold' data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.LABEL.FEISHOWCODE">
                                                        { intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.FEISHOWCODE"  }) }
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className='col-lg-12 col-form-label fs-5 py-1'>
                                                        {showRecord.fei_show_code}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> 
                        </div>
                     
                    </div>
                    
                    {displayErrors
                    ?
                        // Display errors if there are errors
                        <div className='row'>
                            <div className='col-8'>
                                {/* FEI Error Grid */}
                                <div style={containerStyle}>
                                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                        <OutputListingGridStatic
                                            key='export-grid'
                                            columnDefs={getErrorsColDefs()}
                                            rowData={errorData}
                                            setGridRef={setGridRef}
                                            containerStyle={containerStyle}
                                        ></OutputListingGridStatic>
                                    </div>
                                </div>

                                {/* Action buttons */}
                                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                                    <button type="button" onClick={(e) => { onClose(); e.preventDefault() }} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.CLOSEFEIEXPORT' })}
                                    </button>
                                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={() => setDisplayErrors(false)} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.BUTTON.UPLOADFEIRESULTS">
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.UPLOADFEIRESULTS' })}
                                    </button>
                                </div>
                            </div>
                            <div className='col-4'>
                                <label className='col-lg-12 col-form-label fs-6 py-1'>
                                    {intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.TOTAL" })}
                                    {errorData?.length}
                                    {intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.ERRORS" })}
                                </label>

                                {/* Show errors */}
                                {Object.keys(errorCount).map((key, index) => {
                                    return (
                                        <label className='col-lg-12 col-form-label fs-6 py-1' key={index}>
                                            {key}: {errorCount[key]}  {intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.ERRORS" })}
                                        </label>
                                    );
                                })}
                            </div>
                        </div>
                    :
                        <>
                            {/* If no errors or user decides to export, then show this area */}
                            {/* FEI Error Grid */}
                            <div className='row'>
                                <div className='col-12'>
                                    <div style={eventsContainerStyle}>
                                        <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                            <OutputListingGridStatic
                                                key='export-grid'
                                                columnDefs={getEventsColDefs()}
                                                rowData={classesData}
                                                setGridRef={setGridRef}
                                                containerStyle={eventsContainerStyle}
                                            ></OutputListingGridStatic>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Action buttons */}
                            <div className='row align-items-center mt-2 mb-2'>
                                
                                {/* <div className='col-12'> */}
                                    <div className='col-6'>
                                    <div className='form-check-sm form-check-custom  me-2'>

                                        <input

                                            className='form-check-input'
                                            type='checkbox'
                                            id='submit_results'
                                            checked={submitFeiResults}
                                            onChange={ e => setSubmitFeiResults(e.target.checked)}

                                            // onClick={() => {
                                            //     setSubmitFeiResults('classes.ushja_qualifier_type', '', {shouldDirty: true})
                                            // }}
                                        />
                                        <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='submit_results' data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.LABEL.SUBMITRESULTSTOFEISERVER"> {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.LABEL.UPLOADFEIRESULTS' })}</label>
                                    </div>
                                    </div>
                                    <div className='col-6 card-footer d-flex justify-content-end pt-3 px-0'>
                                        <button type="button" onClick={(e) => exportResults()} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.BUTTON.EXPORTFEIRESULTS">
                                            {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.EXPORTFEIRESULTS' })}
                                        </button>
                                        <button type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase' onClick={() => uploadResults()} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.BUTTON.UPLOADEVENTRESULTS">
                                            {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.UPLOADEVENTRESULTS' })}
                                        </button>
                                    </div>
                                {/* </div> */}
                            </div>


                            {/* Upload results summary */}
                            <div className='row align-items-center mt-2 mb-2'>
                                <div className='col-6'>
                                    <label className='fs-6 fw-bold'>
                                        {intl.formatMessage({ id: "SHOWS.EXPORTFEIRESULTS.MODAL.UPLOADRESULTSSUMMARY" })}
                                    </label>
                                </div>
                                {showSuccessMessage && (
                                    <div className='col-6 d-flex justify-content-end'>
                                        <div className='fs-7 fw-bold' style={{ color: resultUploadColor === 'red' ? '#FF0000' : '#7ca138' }}>
                                            <label>{resultUploadStatus} </label>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Result Summary Grid */}
                            <div className='row mb-2'>
                                <div className='col-12'>
                                    <div style={summaryContainerStyle}>
                                        <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                            <OutputListingGridStatic
                                                key='export-grid'
                                                columnDefs={getSummaryColDefs()}
                                                rowData={summaryData}
                                                setGridRef={setGridRef}
                                                containerStyle={summaryContainerStyle}
                                            ></OutputListingGridStatic>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Action buttons */}
                            <div className='row'>
                            <div className='col-12'>
                                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                                 
                                    {/* <button type="button" onClick={() => setFEICredentialDialog(true)} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.UPDTAEFEICREDENTIALS' })}
                                    </button> */}

                                    <button type="button" onClick={(e) => { onClose(); e.preventDefault() }} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.CLOSEFEIEXPORT' })}
                                    </button>
                                    <button type="button" disabled={!(summaryData.length>0)} className='btn btn-sm btn-secondary fw-bold text-uppercase'  onClick={exportErrors} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTFEIRESULTS.BUTTON.EXPORTUPLOADERRORS">
                                        {intl.formatMessage({ id: 'SHOWS.EXPORTFEIRESULTS.MODAL.BUTTON.EXPORTUPLOADERRORS' })}
                                    </button>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportFEIResults;