import {FC, createContext, useContext, useState, useEffect} from 'react'
import {DefaultLayoutConfig, showPicker, pageInfo} from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes
} from './LayoutModels'
import {WithChildren} from '../../helpers'
import { matchPath } from 'react-router-dom'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
  setPageLayout: (location: any) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setPageLayout: (location: any) => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  
  const setPageLayout = (location: any) => {
    var toolbar = Object.assign({}, config.toolbar);
    var aside = Object.assign({}, config.aside);
    var header = Object.assign({}, config.header);

    //Set Defaults
    toolbar.layout = 'toolbar5';
    toolbar.display = true;
    aside.minimized = false;
    showPicker.disabled = false;
    pageInfo.type = '';
    
    switch(location.pathname){
      //Output Listings
      case '/shows':
      case '/entries':
      case '/horses':
      case '/people':
      case '/shows':
      case '/classes':
      case '/divisions':
      case '/fees':
      case '/masterfees':
      case '/payments':
      case '/stables':
      case '/classrules':
      case '/taxrates':
      case '/circuits':
      case '/showseries':
      case '/facilities':
      case '/organizations':
      case '/rings':
      case '/barns':
      case '/reports':
      case '/sitemonitoring':
      case '/apilogs':
      case '/cronjobs':
      case '/errors':
      case '/payment-batches':
        toolbar.layout = 'toolbar1';
        header.left = 'show-picker';
        pageInfo.type = 'list';
        break;

      case '/classes/schooling-rounds':
      case '/classes/set-planned-time':
        header.left = 'show-picker';
        toolbar.layout = 'toolbar2'
        aside.minimized = true;
        pageInfo.type = 'tool';
        break;
      //Inputs

      case '/classes/announcer-interface': 
      case '/classes/scheduler':
      case '/classes/order-of-go':
        aside.minimized = true;
        toolbar.display = false;
        pageInfo.type = 'tool';
        break;

      case matchPath("/payment-batches/detail/*", location.pathname)?.pathname:
        aside.minimized = true;
        pageInfo.type = 'detail';
        break;

      case matchPath("/barns/barn-designer/*", location.pathname)?.pathname:
        aside.minimized = true;
        toolbar.display = false;
        pageInfo.type = 'detail';
        break;
      
      case matchPath("/divisions/detail/*", location.pathname)?.pathname:
      case matchPath("/stables/detail/*", location.pathname)?.pathname:
      case matchPath("/circuits/detail/*", location.pathname)?.pathname:
      case matchPath("/masterfees/detail/*", location.pathname)?.pathname:
      case matchPath("/fees/detail/*", location.pathname)?.pathname:
      case '/taxrate-detail':
      case matchPath("/shows/detail/*", location.pathname)?.pathname:
      case matchPath("/horses/detail/*", location.pathname)?.pathname:
      case matchPath("/classrules/detail/*", location.pathname)?.pathname:
      // case '/circuit-detail':
      // case '/circuit-divisions-detail':
      case matchPath("/circuitdivisions/detail/*", location.pathname)?.pathname:
      case matchPath("/classes/detail/*", location.pathname)?.pathname:
      case matchPath("/showseries/detail/*", location.pathname)?.pathname:
      case matchPath("/facilities/detail/*", location.pathname)?.pathname:
      case matchPath("/rings/detail/*", location.pathname)?.pathname:
        case matchPath("/barns/detail/*", location.pathname)?.pathname:
      case matchPath("/people/detail/*", location.pathname)?.pathname:
      case matchPath("/entries/detail/*", location.pathname)?.pathname:
      case matchPath("/payments/detail/*", location.pathname)?.pathname:
      case matchPath("/reports/detail/*", location.pathname)?.pathname:
      case matchPath("/organizations/detail/*", location.pathname)?.pathname:
      case matchPath("/taxrates/detail/*", location.pathname)?.pathname:
      case matchPath("/payment-batches/detail/*", location.pathname)?.pathname:
        header.left = 'show-picker';
        toolbar.layout = 'toolbar2';
        aside.minimized = true;
        pageInfo.type = 'detail';
        break;
    }

    switch(location.pathname){
      //Pages list where showPicker is disabled
      case matchPath("/divisions/detail/*", location.pathname)?.pathname:
      case matchPath("/stables/detail/*", location.pathname)?.pathname:
      case matchPath("/masterfees/detail/*", location.pathname)?.pathname:
      case matchPath("/shows/detail/*", location.pathname)?.pathname:
      case matchPath("/horses/detail/*", location.pathname)?.pathname:
      case matchPath("/classrules/detail/*", location.pathname)?.pathname:
      case matchPath("/classes/detail/*", location.pathname)?.pathname:
      case matchPath("/showseries/detail/*", location.pathname)?.pathname:
      case matchPath("/facilities/detail/*", location.pathname)?.pathname:
      case matchPath("/rings/detail/*", location.pathname)?.pathname:
        case matchPath("/barns/detail/*", location.pathname)?.pathname:
      case matchPath("/people/detail/*", location.pathname)?.pathname:
      case matchPath("/entries/detail/*", location.pathname)?.pathname:
      case matchPath("/payments/detail/*", location.pathname)?.pathname:
      case matchPath("/organizations/detail/*", location.pathname)?.pathname:
          //Pages under Development
      case matchPath("/reports/detail/*", location.pathname)?.pathname:
      case matchPath("/circuitdivisions/detail/*", location.pathname)?.pathname:
      case matchPath("/payment-batches/detail/*", location.pathname)?.pathname:
      //Pages under Development
      case '/fee-detail':
      case '/taxrate-detail':
      case '/circuit-detail':
      // case '/circuit-divisions-detail':
      case '/entry-detail':
        header.left = 'show-picker';
        showPicker.disabled = true;
        pageInfo.type = 'detail';
        break;
      case '/my-preferences':
        toolbar.layout = 'no-toolbar';
        header.left = 'page-title';
    }

    let toolbarUpdated = config.toolbar.layout != toolbar.layout || config.toolbar.display != toolbar.display;
    let asideUpdated = config.aside.minimized != aside.minimized;
    let headerUpdated = config.header.left != header.left
    if( toolbarUpdated || asideUpdated || headerUpdated ){ //Need to update toolbar?
      config.toolbar = toolbar;
      config.aside = aside;
      config.header = header;
      
      LayoutSetup.updatePartialConfig(config)
      setAttributes(LayoutSetup.attributes)
    }

    //set pathname in pageinfo at end, do not move above
    pageInfo.pathname = location.pathname;
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    setPageLayout,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
