import { useIntl } from 'react-intl'
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useState, useEffect, useCallback} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, Controller } from "react-hook-form";
import Select from "react-select";
import {reactSelectStyles} from '../../modules/sgl-utils/fieldControls';
import { useAlert, useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider';
// Custom Components
import { GeneralTab } from './Detail/GeneralTab';
import { HistoryTab } from '../../modules/components/HistoryTab';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { ClassesTab } from './Detail/ClassesTab';
import { DivisionsTab } from './Detail/DivisionTab';
import { useAppDispatch } from '../../redux/hooks';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions';
import { setNewShowId } from '../../redux/reducers/newShowIdReducer';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import useAutoFocus from '../../modules/hooks/use-auto-focus';
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
	dirtyFields,
	allValues
  ) => {
	// NOTE: Recursive function.
  
	// If *any* item in an array was modified, the entire array must be submitted, because there's no
	// way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
	if (dirtyFields === true || Array.isArray(dirtyFields)) {
	  return allValues;
	}
  
	// Here, we have an object.
	return Object.fromEntries(
	  Object.keys(dirtyFields).map((key) => [
		key,
		dirtyValues(dirtyFields[key], allValues[key])
	  ])
	);
  };
const DivisionDetail = (props) => {
	const intl = useIntl();
    const[loading,setLoading] = useState(true)
    const [form_meta, setFormMeta] = useState({})
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const alertDialog = useAlert()
	const [rowClicked, setRowClicked] = useState("")
	const [record_id, setRecordId] = useState("")
	const [sub_area, setSubArea] = useState("")
	const [child_component, setChildComponent] = useState("")
	const [open_forms, setOpenForms] = useState([])
	const [gridRowData, setGridRowData] = useState('')
    const { handleDetailPageCatch } = useAccessChecker() 
	const [divisionData, setDivisionData] = useState({})

	const getDetailPageInfo = (id, area, component, data, divisionData = {}) => {
		setRecordId(id)
		setSubArea(area)
		setChildComponent(component)
		sessionStorage.setItem("dialog_is_open_in_division", true)
		setRowClicked(rowClicked => !rowClicked)
		const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
		setOpenForms(forms_open)
		setGridRowData(data)
		setDivisionData(divisionData)
	};
    const tabs = ["GENERAL", "CLASSES", "DIVISIONS", "HISTORY"]
    const tabs_with_combined_division = ["GENERAL", "CLASSES", "HISTORY"]
   
    const {division_id} = useParams();
	let current_division_id = props.id == "" ? 0 : props.id || props.id == 0 ? props.id : division_id
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
	const currentShowName = useAppSelector(state => state.currentShow.show_name);
	const loadingOverlay = useLoadingOverlay()
	const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
	const [syncEvent, setSyncEvent] = useState(null);
	const [update_data, setUpdateData] = useState(0)

    const methods = useForm({
        defaultValues: 
        {
            "division": {
                "name": "",
                "show_id": 0,
                "rating": "",
                "price": 0,
                "type": "",
                "usef_section": "",
                "code": "",
                "ec_section": "",
                "champion_from_prizemoney": 0,
                "no_champ_reserve_award": 0,
                "combined_division": 0,
                "combined_division_id": 0,
            },
            "classesList": [],
            "divisionsList": [],
            "pointSummary": [],
			"pointSummaryClasses": [],
            "childDivisionName": "",
            "champRes": [],
			"all_results_verified": true
        }
      }); 
    const { handleSubmit, reset, formState, setFocus } = methods
    const { dirtyFields } = formState;
    useNavigationPrompt(formState)
	const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const componentTags = [
        <GeneralTab form_meta={form_meta} setUpdateData={setUpdateData} update_data={update_data}/>, 
        <ClassesTab callbackFromParent={getDetailPageInfo} divisionID={current_division_id}/>, 
        <DivisionsTab />, 
        <HistoryTab area={"Division"} rowData={methods.getValues('change_history_logs')}/>
    ]
    
    const componentTags_with_combined_division = [
        <GeneralTab form_meta={form_meta} id={props.id} setUpdateData={setUpdateData} update_data={update_data}/>, 
        <ClassesTab callbackFromParent={getDetailPageInfo} divisionID={current_division_id}/>, 
        <HistoryTab area={"Division"} rowData={methods.getValues('change_history_logs')}/>
    ]

	const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return 	methods.getValues('division.combined_division')
					? tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0
					: tabs_with_combined_division.indexOf(hash) >= 0 && props?.id == undefined ? tabs_with_combined_division.indexOf(hash) : 0
	});

	// To open same detail page multiple times 
	useEffect(() => {
	if(sessionStorage.getItem("dialog_is_open_in_division") == "true"){
		sessionStorage.removeItem("dialog_is_open_in_division")
		setRowClicked(true)
		const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
		if(open_forms.includes(sub_area+"_"+record_id) == false){
			forms_open.push(sub_area+"_"+record_id);
			sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
		}
	}
	}, [rowClicked])

	// prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

    useEffect(() => {
		// Show loading overlay
		loadingOverlay({show: true})
        axios.all(
        [
          axios.get( process.env.REACT_APP_NEST_API_URL + '/divisions/detail', {
            params: {
              division_id: current_division_id,
              customer_id: customer_id,
            }
          }),
          axios.get( process.env.REACT_APP_NEST_API_URL + '/divisions/metaData', {
            params: {
			  division_id: current_division_id,
              customer_id: customer_id,
            }
          }),
        ]  
        ).then (
          axios.spread( ({data : divisionData}, {data : divisionMetaData} ) => {
			//Check if record id is invalid then redirect to list page
			if((divisionData || divisionData === '') && isInvalidRecord(divisionData.division, current_division_id, 'division_id')){
				navigate('/divisions')
				loadingOverlay({show: false})
			}
			if(current_division_id > 0 && divisionData.division.show_id != currentShowID){ //check if input show does not match current show in show Picker
				dispatch(setNewShowId(divisionData.division.show_id )) //In this case update Show in ShowPicker 
			}
			if(props?.id === undefined && Number(methods.getValues('division.division_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
				reset(divisionData)
			}else{
				reset(divisionData, { keepDirtyValues: true })
			}
            setFormMeta(divisionMetaData)
			if(sessionStorage.getItem('forms_open') == undefined || current_division_id == division_id){
				sessionStorage.setItem('forms_open', JSON.stringify(["Divisions_"+Number(current_division_id)]));
			}
			// Hide loading overlay
			loadingOverlay({show: false})
			setLoading(false)
          })
		)
        .catch(reason => {
			setLoading(false)
            handleDetailPageCatch(reason.response, loadingOverlay, props)
        })
    },[division_id, update_data])

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

	useEffect(() => { // Update Callback useEffect
        if(syncEvent){
            let parsedEvent = JSON.parse(syncEvent.data)
            let variableName = ''
            let updateFlag = false
            
            switch(parsedEvent.type){
                                
                case `Divisions-${customer_id}-${currentShowID}-${current_division_id}`:
                    variableName = 'divisions'
                    updateFlag = true
                    break;
            }

            if(updateFlag){                

                axios.get( process.env.REACT_APP_NEST_API_URL + '/divisions/detail', {
					params: {
					  division_id: current_division_id,
					  customer_id: customer_id,
					}
				}).then((res) => {
                    if(parsedEvent.type.includes('Division')){
                        // Keep Dirty Values can't handle arrays, so need to manually handle arrays
                        reset(res.data, { keepDirtyValues: true })
                    }else{
                        methods.setValue(variableName, res.data[variableName])
                    }
                })
            }            
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
		return // SK - Disabling sync
        if(currentShowID && current_division_id && sse && current_division_id > 0){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
            	`Divisions-${customer_id}-${currentShowID}-${ current_division_id}`,
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
					eventId, 
					callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents)
        }

        return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_division_id, sse]);

    const onSubmit = async (form_data) => {  
		const next_button = document.getElementById('next-btn');
		const last_button = document.getElementById('last-btn');
		const prev_button = document.getElementById('prev-btn');
		const first_button = document.getElementById('first-btn');
  
		// means detail page is not open in modal
		if(props?.id === undefined){
			next_button.disabled = false;
			prev_button.disabled = false;
			last_button.disabled = false;
			first_button.disabled = false;
		}

        let submit_data = dirtyValues(dirtyFields, form_data)
		loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/divisions/detail', {
          params: {
            division_id: current_division_id,
            customer_id: customer_id,
            show_id: props?.parent_area === "Shows" ? props.parent_id : currentShowID,
            data: submit_data
          }
        }).then((response) => {
			loadingOverlay({show: false})
			if(response.data.success) {
			// Use Case (Param Id changed): Next/Prev buttons
			if(props?.id == undefined && Number(methods.getValues('division.division_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
				if(row_selected && row_selected != "" && row_ids[row_selected]){
					setRowClicked(false)
					setLoading(true)
					history.push(`${PUBLIC_URL}/divisions/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
				}
			}
			else if(props?.id == undefined && current_division_id > 0){
				history.push(`${PUBLIC_URL}/divisions`, { allowNavigation: true })
			}
			else if(props?.id == undefined && current_division_id == 0){
				history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
			}
			 else{
				if(props.updateCallbackFromParent){
					if(props?.setValueFromClass){ //if navigated from create class screen, set the selected division
						const division = {label: submit_data?.division?.name, price: submit_data?.division?.price, value: response?.data?.division_id, type: submit_data?.division?.type}
						props.setValueFromClass('classes.division_id', division?.value, { shouldDirty: true })
						props.setValueFromClass('newDivisionName', division?.label, { shouldDirty: true })
						props.setValueFromClass('divisionPrice.price', division?.price, { shouldDirty: true })
						if(division.type == "Hunter"){
							props.setValueFromClass('classes.class_type', "Hunters", { shouldDirty: true })
						}
						else if (division.type == "Jumper"){
							props.setValueFromClass('classes.class_type', "Jumpers", { shouldDirty: true })
							props.setValueFromClass('classes.schedule_sequencetype', "Over Fences", { shouldDirty: true })
						}
						else{
							props.setValueFromClass('classes.class_type', division.type, { shouldDirty: true })
						}
					}
					props.updateCallbackFromParent()
					props.closeModal()
				}
			 }
			 if( row_selected == row_ids?.length -1 && props?.id === undefined){
                if (next_button && last_button) {
                    next_button.disabled = true;
                    last_button.disabled = true;
                    prev_button.disabled = false;
                    first_button.disabled = false;
                }
              }
              if(row_selected == 0 && props?.id === undefined){
                if (prev_button && first_button) {
                    prev_button.disabled = true;
                    first_button.disabled = true;
                    next_button.disabled = false;
                    last_button.disabled = false;
                }
              }
		  } else {
			  alertDialog({message: response.data.error_message, title: 'Error'})
		  }
        })
    }

	useAutoFocus('CODE', loading, update_data)

	return (
		<>
	    {!loading && (
        <>
		{props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('division.name'), methods.getValues('division.division_id')]}>{intl.formatMessage({id: 'MENU.DIVISIONS'})}</PageTitle> : ""}
		 <FormProvider {...methods}>
			<div id={"divisions_detail_page"} className='modal-body py-3 px-4 horse-input-form input-form'>
				<form id={"DivisionDetail_"+current_division_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
					<HelpLink tooltip="DIVISION.DETAIL.LINK.HELP" classname="top" />
					<div className='card-body p-0'> {/* Card Body */}
						<div className="form-group row"> {/* Column Group */}
							<div className='row mb-2'>
								<div className='col-lg-12'>
									<div className='row mb-2'>
										<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='CODE' data-tooltip-id="DIVISION.DETAIL.LABEL.CODE">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.LABEL.CODE' })}</label>
										<div className='col-lg-1'>
											<input
												{...methods.register( 'division.code', 
													{
														required: false
													}
												  )
												}
												id='CODE'
												type='text'
												className='form-control form-control-sm fs-6 min-h-20px py-1'
												placeholder={intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.LABEL.CODE' })}
											/>
										</div>
										<label className='col-lg-1 text-end col-form-label fw-bold fs-5 py-1 me-xl-2 me-lg-2 me-xxl-3' htmlFor='NAME' data-tooltip-id="DIVISION.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.LABEL.NAME' })}</label>
										<div className='col'>
											<input
												{...methods.register( 'division.name', 
													{
														required: "Division name is required."
													}
												 )
												}
												id='NAME'
												type='text'
												className='form-control form-control-sm fs-6 min-h-20px py-1'
												// placeholder={intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.LABEL.NAME' })}
											/>
											 { methods.formState.errors?.division?.name && <div><span className='error_message'>{methods.formState.errors.division.name?.message}</span></div>}
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-5' >
											<div className='row'>
												<label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-100px' htmlFor='SHOW' data-tooltip-id="DIVISION.DETAIL.LABEL.SHOW">{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.LABEL.SHOW' })}</label>
												<div className='col align-items-center'>
													{
													form_meta.show && 
													<Controller
														name="division.show_id"
														id="show_id"
														render={({
															field: { onChange, value, name },
														}) => (
														<input
															disabled={true}
															type='text'
															value={currentShowName}
															className="form-control form-control-sm fs-6"
														/>
														)}
													/>
													}
												</div>
											  
											</div>
											
										</div>
										{ methods.getValues('division.combined_division') ? <label className='col-lg col-form-label  fw-bold fs-5 py-1 me-5' style ={{color:"red"}}> {intl.formatMessage({id: 'FORM.INPUT.DIVISIONS.LABEL.COMBINEDIVISIONSTATUS'}) }</label>: methods.getValues('division.combined_division_id') ? <label className='col-lg col-form-label  fw-bold fs-5 py-1 me-5' style ={{color:"red"}}>{intl.formatMessage({id: 'FORM.INPUT.DIVISIONS.LABEL.COMBINEDIVISIONID'}) + " " + methods.getValues("childDivisionName") + "."} </label> : ""}
									</div>
								</div>
							</div> {/* End Column Group */}
							<div className='d-flex flex-column border p-2 bg-white'>
								<ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
									{
										methods.getValues('division.combined_division') ? 
										tabs.map((tab, index) => 
											<li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
												<a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
													tabIndex="-1"
													id={tab + "-tab"}
													data-bs-toggle='tab'
													href={"#" + tab + "-Division-" + current_division_id}
													data-tooltip-id={`DIVISION.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.' + tab })}
												</a>
											</li>
										) :   
										tabs_with_combined_division.map((tabs, index) => 
											<li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tabs} }}>
												<a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
													id={tabs + "-tab"}
													data-bs-toggle='tab'
													href={"#" + tabs + "-Division-" + current_division_id}
													data-tooltip-id={`DIVISION.DETAIL.TAB.${tabs}`}>{intl.formatMessage({ id: 'FORM.INPUT.DIVISIONS.TAB.' + tabs })}
												</a>
											</li>
										)
									}
								</ul>
								<div className="tab-content mt-5 pb-2" id="myTabContent">
									{    
										methods.getValues('division.combined_division') ? 
										tabs.map((tab, index) => {
											return (
												<div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Division-" + current_division_id} role="tabpanel" >
													<HelpLink tooltip={"DIVISION.DETAIL.TAB."+tab+".LINK.HELP"}  />
													{componentTags[index]}
												</div>
											);
										}) :
										tabs_with_combined_division.map((tab, index) => {
											return (
												<div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Division-" + current_division_id} role="tabpanel" >
													<HelpLink tooltip={"DIVISION.DETAIL.TAB."+tab+".LINK.HELP"}  />
													{componentTags_with_combined_division[index]}
												</div>
											);
										}) 
									}
								</div>
							</div>
						</div> {/* End Card Body */}
					</div> {/* End Card Body */}
					<InputFormFooter goBackPath={'/divisions'} propId={props?.id} data={'division'}/> 
				</form>
			</div>
		</FormProvider>
		{rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} parent_id={current_division_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData} prev_data={divisionData} parent_area={"Divisions"}/>}
		</>
    	)}
		</>
	);
}
export { DivisionDetail }