import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';

// Custom Components
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import DecimalPointRenderer from '../../../modules/output-listing/renderers/DecimalPointRenderer';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../modules/output-listing/OutputListingRowOptions';

const ClassFeesTab = () => {
    const intl = useIntl();
    const methods = useFormContext()

    const columnDefs = [
        { field: 'description', flex: 4, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSFEES.DESCRIPTION' }) },
        { field: 'price', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSFEES.PRICE' }), cellClass: 'ag-right-aligned-cell', cellRenderer: (params) => DecimalPointRenderer(params, 2) },
    ]

    const rowData = methods.getValues("entryPrices")
    const recordCount = rowData.length

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), [])

    return (
        <>
            <div className='form-group mb-2'>
                <div className='form-group mb-2'> {/* WA - Row Selector */}
                    <div className="d-flex justify-content-end align-items-center">
                        <div className='me-6'>
                            <label className='col col-form-label fs-5 py-1 text-end' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSFEES.LABEL.TOTAL">
                                Total: { recordCount}
                            </label>
                        </div>
                        <div>
                            <RowSelector value={visibleRows} onChange={setVisibleRows} area={"Entries - ClassFees"}/>
                        </div>
                        </div>
                    <div className='row mb-2'>
                        <OutputListingGridStatic  area ={"Entries - ClassFees"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic>
                    </div> {/* WA - Row Selector */}
                </div>
            </div>
        </>
    );
}

export { ClassFeesTab }