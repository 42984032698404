import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { numberValueSetter, renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";

const EntriesGrid = ({ gridRef, setGridRef, tabIndex=0, entries, applyBalanceTransferAmount, amountToBeTransferred, selectedEntry }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const getEditableCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right"}
        }

        if(params.data.apply){ // payment column is editable
            return { textAlign: "right", border: '1px solid rgb(202, 202, 202)' }
        }
        
        // payment column is not editable
        return { textAlign: "right", backgroundColor: '#f4f8fb', border: '1px solid rgb(202, 202, 202)' }
    }

    const columnDefs = [
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.SHOW' }),
            flex: 1,
        },
        { 
            field: 'number', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.ENTRY' }), 
            cellStyle: { textAlign: 'center' },
            width: 100,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.HORSE' }),
            width: 250,
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.BALANCE" }),
            width: 120,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        },
        { 
            field: 'apply', 
            headerName: intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.APPLY" }),
            width: 55,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, () => {
                applyBalanceTransferAmount(amountToBeTransferred, true)
            }, selectedEntry?.entry_id == params.node.data.entry_id), 
        },
        { 
            field: 'amount_to_be_transferred', 
            headerName: intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.COL.PAYMENT" }),
            width: 120,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => currencyFormatter(params.value),
            editable: params => params.data.apply, 
            cellStyle: params => getEditableCellStyles(params),
            valueSetter: params => {
                params.allowNegative = true;
                return numberValueSetter(params)
            }
        },
        { 
            field: 'running_balance', 
            headerName: '',
            width: 120,
            cellStyle: { textAlign: 'right' },
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value)
        }
    ]

    return (
        <div>
            <div className="row mb-0 mt-2">
                <label className='col-lg-4 col-form-label fs-6 py-0 me-10'>
                    { intl.formatMessage({ id: "ENTRIES.QUICKACTION.POPUP.TRANSFER.BALANCE.GRID.MESSAGE" }) }
                </label>
            </div>
    
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={entries}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        onGridReady={params =>  { 
                            setGridRef(params.api)
                        }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        onCellEditingStopped={() => {
                            applyBalanceTransferAmount(amountToBeTransferred, false)
                        }}
                        getRowStyle={params => {
                            if (params.node.rowPinned) {
                                return { fontWeight: "bold" };
                            }
                        }}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
        </div>
       
    )
}


export default EntriesGrid