import {useState,useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import {reactSelectStyles, time_options_12hours,setTime} from '../../../modules/sgl-utils/fieldControls';
import { ImportShow } from './ImportShow';
import {PatternFormat, NumericFormat} from 'react-number-format';
import useAccessChecker from '../../../modules/hooks/use-access-checker';
import axios from 'axios';
import { useAppSelector } from '../../../redux/hooks';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { MembershipDetailForm } from './MembershipDetailForm';
import { useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider';
import ImportFEIResults from './ImportFEIResults';
import ImportFEIEntries from './ImportFEIEntries';
import AddNewOfficial from './ShowOfficials/AddNewOfficial';
import UpdateOfficialTypeModal from './ShowOfficials/UpdateOfficialTypeModal';
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter } from '../../../modules/sgl-utils/Formatters'
import { UpdateFeiCredentialsDialog } from './UpdateFeiCredentialsDialog';

const GeneralTab = (props) => {
  const intl = useIntl();
  const methods = useFormContext();
  const customer_id = useAppSelector((state) => state.showCompany.company_id);
  const [showImportModal, setShowImportModal] = useState(false);
  const [gridRef, setGridRef] = useState(false)
  const [show_judges_deleted, setShowJudgesDeleted] = useState([])
  const [rowData, setRowData] = useState([])
  const { hasSpecialPermissionToAccess } = useAccessChecker()
  const [showMembershipDetailForm, setShowMembershipDetailForm] = useState(false)
  const [lookupResponse, setLookupResponse] = useState([]);
  const alertDialog = useAlert()
  const confirmDialog = useConfirm();
  const loadingOverlay = useLoadingOverlay()
  const [showFeiResultsImport, setShowFeiResultsImport] = useState(false);
  const [showFeiEntriesImport, setShowFeiEntriesImport] = useState(false);
  const [feiEventsData, setFeiEventsData] = useState([]);
  const customerId = useAppSelector(state=> state.showCompany.company_id);
  const [importEntriesData, setImportEntriesData] = useState({riderMappings: [], horseMappings: [], entriesMappings: []});
  const [showAddOfficialModal, setShowAddOfficialModal] = useState(false)// show/hide the add official modal
  const [showUpdateOfficialTypeModal, setShowUpdateOfficialTypeModal] = useState(false)// show/hide the edit official type modal
  const [officialToBeUpdated, setOfficialToBeUpdated] = useState(null)// keep track of which official type needs to be updated
  const [ECMembershipLevelOptions, setECMembershipLevelOptions] = useState([])
  const ECMembershipLevel = [ 'None', 'Platinum', 'Gold', 'Silver', 'Bronze' ]
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [showFEICredentialDialog, setFEICredentialDialog] = useState(false);

  const columnDefs = [//Column defs for officials grid
    { field: 'fl_name', flex: 1, headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GRID.NAME'}), minWidth: 200, maxWidth: 200},
    { field: 'membership_number', flex: 1, headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GRID.USEF'})},//USEF membership number
    { field: 'official_type', flex: 1, headerName: intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GRID.TYPE'}), minWidth: 150, maxWidth: 150}
  ]

  const updateCredentials = (fei_username, fei_password) => {
 
    }
  useEffect(() => {
    const initialSponsor = props.form_meta.sponsors?.find(sponsor => sponsor.sponsor_id === methods.getValues('show.sponsor_id'));
    setSelectedSponsor(initialSponsor);
    }, [props.form_meta.sponsors, methods]); 

    useEffect(() => {
        let options = ECMembershipLevel.map((obj) =>{
            let value = (obj == 'None') ? '' : obj
            return {label: obj, value} 
        })
        setECMembershipLevelOptions(options)
    }, [])

    useEffect(() => {
        if(props.showData && props.showData.show){
            if(props.showData.show.phone && props.showData.show.phone.length < 10){
                methods.setError('show.phone',{
                    type: "custom",
                    message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                })
            }
            if(props.showData.show.fax && props.showData.show.fax.length < 10){
                methods.setError('show.fax',{
                    type: "custom",
                    message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
                })
            }
        }
    }, [props.showData]);

    useEffect(() => {     
        const officials = methods.getValues('officials')
        if(officials && officials.length > 0) {
            let rowData = officials.map((official) => {
                if(official.people_id){
                    //Set official type >>>
                    if(official?.isjudge){
                        official.official_type = 'Judge'
                    }else if(official?.iscoursedesigner){
                        official.official_type = 'Course Designer'
                    }else{
                        official.official_type = ''
                    }
                    //Set official type <<<
                    return official
                }
            })  
            setRowData(rowData)
        }
   },[methods.watch('officials')])
  
  const deleteSelectedShowJudge = async() => {
    let isJudgeUsed = false
    if ( gridRef ) {
      let selected_show_judges =  gridRef?.getSelectedRows()
      if ( selected_show_judges.length > 0 ) {
        let role = selected_show_judges[0]?.isjudge ? 'isjudge' : selected_show_judges[0]?.iscoursedesigner ? 'iscoursedesigner' : ''

        //Web request to check if official is used in any class >>>
        if(selected_show_judges[0].sgl_id && methods.getValues()?.show?.show_id){
            await axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/getClassJudgesRecordForOfficial?show_id=${methods.getValues().show.show_id}&customer_id=${customer_id}&people_id=${selected_show_judges[0].people_id}&role=${role}`)
            .then(response => {
               if(response.data.classesCount > 0){//If official is used in any class than display warning
                    alertDialog({message: intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.OFFICIAL.ASSIGN.TO.CLASS.ERROR' })})
                    isJudgeUsed = true 
               }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
        }
        //Web request to check if official is used in any class <<<

        //If official is used in any class than don't delete it
        if(!isJudgeUsed){
            setShowJudgesDeleted([...show_judges_deleted, selected_show_judges[0]])
            gridRef.applyTransaction({ remove: [selected_show_judges[0]] });
            setRowDataFromGrid()
        }
      }else{
        alertDialog({message: intl.formatMessage({ id: 'SHOWS.ADD.NEW.OFFICIALS.DIALOG.NO.OFFICIAL.SELECTED.DELETE.ERROR' })})
      }
    }
  }

  const setRowDataFromGrid = () => {
    let updatedShowOfficials = []
    gridRef.forEachNode(node => updatedShowOfficials.push({...node.data, order: node.rowIndex +1}));
    setRowData(updatedShowOfficials)
    methods.setValue('officials', updatedShowOfficials, {shouldDirty: true})
  }

  useEffect(() => {
    if(show_judges_deleted?.length > 0){
        methods.setValue('show_judges_deleted', show_judges_deleted, {shouldDirty:true})
    }
  }, [show_judges_deleted])

  const containerStyle = useMemo(() => ({ width: '100%', height: '150px' }), []);
  function importShow(){
    setShowImportModal(true);
  }

  function onLoadSetRange(){
    methods.setValue('show.starting_webentry_number',(Number(methods.watch('show.starting_entry_number')) + 10000) , {shouldDirty: true})
    methods.setValue('show.ending_web_entry_number',(Number(methods.watch('show.starting_webentry_number')) + 5000) , {shouldDirty: true})
    methods.setValue('show.starting_traineraccount_entryno',(Number(methods.watch('show.ending_web_entry_number')) + 1) , {shouldDirty: true})      
    methods.setValue('show.ending_traineraccount_entryno',(Number(methods.watch('show.starting_traineraccount_entryno')) + 5000) , {shouldDirty: true})          
  }

  const ec_event_lookup_data = () => {
    if(methods.getValues("show.ec_event_id") != ""){
        if(methods.getValues("show.ec_event_lookup_response") && methods.getValues("show.ec_event_lookup_response") != ""){
            let lookup = JSON.parse(methods.getValues("show.ec_event_lookup_response"))

            //Update the ec_rating with the Level obtained from lookup >>>
            let ec_rating = methods.getValues('show.ec_rating')
            if(ec_rating == "" && lookup.Level && lookup.Level != "") {
                if(ECMembershipLevel.includes(lookup.Level)){
                    ec_rating = lookup.Level
                }else{
                    ec_rating = ''
                }  
                methods.setValue("show.ec_rating", ec_rating, {shouldDirty: true})  
            }
            //Update the ec_rating with the Level obtained from lookup <<<

            parseLookupData(lookup)
            setShowMembershipDetailForm(true);
        } else {
            getECEventLookupData()
        }
    } else {
        alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.EC.INVALID' })})
    } 
  }

  function getECEventLookupData(){
        // Show loading overlay
        loadingOverlay({ show: true });

        let end_point = 'ecEventLookup'
        if(methods.getValues("show.ec_event_id") != ""){
            axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/' + end_point, {
                params: {
                    show: methods.getValues("show"),
                    customer_id: customer_id,
                }
            }).then((response) => {
                // Hide loading overlay
                loadingOverlay({ show: false });
                if(response.data.success) {
                    methods.setValue('show', response.data.show, {shouldDirty: true})
                    let lookup = response.data.show_lookup_data
                    parseLookupData(lookup)
                    setShowMembershipDetailForm(true);

                } else {
                    // Hide loading overlay
                    loadingOverlay({ show: false });
                    let error_id = 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.'+response.data.error_message_id
                    alertDialog({message: intl.formatMessage({ id: error_id })})
                }
            
            })
        } else {
            // Hide loading overlay
            loadingOverlay({ show: false });
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.EC.INVALID' })})
        } 
    
  }
  const parseLookupData = (lookup) => {
    if (lookup){
        var lookup_entries = Object.entries(lookup)
        let lookupData = []
        lookup_entries.map(([key, value]) => {
            // if(value != null && Array.isArray(value)){
            //     if(value.length > 0){
            //         value.map((data) => {
            //             let lookup = Object.entries(data)
            //             lookup.map(([key, value]) => {
            //                 lookupData.push({'key': key, "value": value})
            //             })
            //         })
            //     }
            // }
            // else{
                lookupData.push({'key':key, "value":value})
            // }
        })
        setLookupResponse(lookupData)
    }
    }

    // Update horse data after user consent
    const handleUpdateData = () => {
        getECEventLookupData()
    }

    const importFeiResultsData = async () => {
        const feiShowCode = methods.getValues('show.fei_show_code');
        if(hasSpecialPermissionToAccess('cloud_shows:access-fei-service')){
            // If invalid show code
            if (!feiShowCode || feiShowCode == '') {
                alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.FEI.INVALID' })})
            } else {
                const postBody = {
                    show: methods.getValues("show"),
                    customer_id: customer_id,
                }

                loadingOverlay({ show: true, message: "Show FEI Lookup" })

                // Send post request to get FEI results
                axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/feiEventLookup', { params: postBody })
                .then(async (response) => {
                    if (response?.data?.success) {
                        methods.setValue('show', response?.data?.show, {shouldDirty: true});
                        setFeiEventsData(response?.data?.events_list);
                        loadingOverlay({ show: false, message: "Show FEI Lookup" })
                        setShowFeiResultsImport(true);

                    } else {
                        loadingOverlay({ show: false, message: "Show FEI Lookup" })
                        let error_id = 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.'+response.data.error_message_id                        
                        if(response.data.error_message_id == "FEI.INVALID.USER.CREDENTIALS"){
                            let userChoice = await confirmDialog({message: intl.formatMessage({id: error_id})})
                            if(userChoice){
                                setFEICredentialDialog(true)
                            }
                        } else{
                            alertDialog({message: intl.formatMessage({ id: error_id })})
                        }
                    }
                })
                .catch(() => loadingOverlay({ show: false, message: "Show FEI Lookup" }))
            }
        }
        else {
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LOOKUP.ERROR.FEI.IMPORT.ACCESS.DENIED' })})
            }
    }

    // This will be called when "Import Entries" button is clicked in import events form
    const getImportMappings = async () => {
        loadingOverlay({ show: true, message: 'FEI Import Events'})
        await axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/getImportFeiEntriesData', {
            params: {
                show_id: methods.getValues('show.show_id'),
                customer_id: customerId,
                fei_show_code: methods.getValues('show.fei_show_code')
            }}).then((response) => {
                if(response.data.success) {
                    setImportEntriesData({
                        riderMappings: response.data.rider_mappings, 
                        horseMappings: response.data.horse_mappings, 
                        entriesMappings: response.data.entry_mappings
                    });
                    loadingOverlay({ show: false, message: 'FEI Import Events'});
                    setShowFeiEntriesImport(true);
                } else {
                    loadingOverlay({ show: false, message: "FEI Import Events" })
                    alertDialog({ message: response.data.error_message})
                }
        })
        .catch(() => loadingOverlay({ show: false, message: 'FEI Import Events'}))
    }
    
    const updateOfficialType = (officialData) => {
        setOfficialToBeUpdated(officialData)
        setShowUpdateOfficialTypeModal(true)
    }


    let validateShowLock = async () => {
        try {
            loadingOverlay({show: true})
            const response = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/shows/verifyShowLockConditions?show_id=${methods.getValues('show.show_id')}&customer_id=${customer_id}`);
            if (!response.data.success) {
                methods.setValue('show.islocked', false);
                alertDialog({ message: response.data.errorMessage });
            }
            else{
                methods.setValue('show.locked_detail', response.data.lockDetail, { shouldDirty: true })
            }
        } catch (error) {
            if (error.response) {
                alertDialog({ message: error.response.data.error });
            }
        } finally {
            loadingOverlay({show: false})
        }
    }
    
    useEffect(() => {
        let showId = methods.getValues('show.show_id');
        if (showId && methods.watch('show.islocked') && methods?.formState?.dirtyFields?.show?.hasOwnProperty('islocked')) {
            validateShowLock();
        }
    }, [methods.watch('show.islocked')])

  return (
    <>
    {showMembershipDetailForm && <MembershipDetailForm show={showMembershipDetailForm} handleClose={() => { setShowMembershipDetailForm(false)}} lookupResponse={lookupResponse} handleUpdateData={() => handleUpdateData()}/>}
    {showFeiResultsImport && <ImportFEIResults show={showFeiResultsImport} handleClose={() => setShowFeiResultsImport(false)} eventsData={feiEventsData} getImportMappings={getImportMappings} /> }    
    {showFeiEntriesImport && <ImportFEIEntries show={showFeiEntriesImport} handleClose={()=>setShowFeiEntriesImport(false)} data={importEntriesData} callbackFromParent={props.callbackFromParent}/> }
    {showFEICredentialDialog &&<UpdateFeiCredentialsDialog show={showFEICredentialDialog} handleClose={() => setFEICredentialDialog(false)} updateCredentials= {updateCredentials}/>}

    <div className="form-group row px-2"> {/* Column Group */}
            <div className='col-lg-7'>{/* Column 1 */}

            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='show_series_id' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SHOWSERIES">
                <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWSERIES'})}</span>
                </label>

                <div className='col-lg-9 '>
                {
                    props.form_meta.show_series && 
                    <Controller
                        name="show.show_series_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.show_series}
                            id='show_series_id'
                            value = {props.form_meta.show_series?.find((ss) => ss.value === value) ?? ""}
                            name={name}
                            isSearchable={true}
                            onChange={(show_series) => {
                                onChange(show_series.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                            isDisabled={props?.disableSeriesDropdpwn}
                        />    
                        )}
                    />
                }
                </div>
            </div>

                
            {/* <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='stabling_show_id' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.STABLINGSHOW">
                <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.STABLINGSHOW'})}</span>
                </label>

                <div className='col-lg-9 '>
                {
                    props.form_meta.stabling_show && 
                    <Controller
                        name="show.stabling_show_id"
                        id="stabling_show_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.stabling_show}
                            value = {props.form_meta.stabling_show?.find((ss) => ss.value === value) ?? ""}
                            name={name}
                            isSearchable={true}
                            onChange={(stabling_show) => {
                                onChange(stabling_show.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>
            </div> */}


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='facility_id' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.FACILITY">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FACILITY'})}</label>

                <div className='col-lg-6 '>
                {
                    props.form_meta.facilities && 
                    <Controller
                        name="show.facility_id"
                        id="facility_id"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            options = {props.form_meta.facilities}
                            value = {props.form_meta.facilities.find((ss) => ss.value === value) ?? ""}
                            name={name}
                            isSearchable={true}
                            isDisabled = {props?.showData?.show?.facility_id > 0}
                            onChange={(facilities) => {
                                onChange(facilities.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>

                <label className='col-lg-1 col-form-label  fs-5 py-1' htmlFor='zone' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.ZONE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.ZONE'})}</label>

                <div className='col-lg-2'>
                {
                    props.form_meta.zone && 
                    <Controller
                        name="show.zone"
                        id="zone"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                            
                        <Select
                            options = {props.form_meta.zone}
                            value = {props.form_meta.zone.find((z) => Number(z.value) === Number(value)) ?? ""}
                            name={name}
                            isSearchable={true}
                            onChange={(zone) => {
                                onChange(zone.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                }
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='show_number' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.USEFEVENTID">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.USEFEVENTID'})}</label>

                <div className='col-lg-4 '>
                <input
                    {...methods.register( 'show.show_number', 
                        {
                        required: false
                        })
                    }
                    id='show_number'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    type={'text'}
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.USEFEVENTID'})}
                />
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='usef_rating' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.USEFRATING">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.USEFRATING'})}</label>

                <div className='col-lg-3'>
                <input
                    {...methods.register( 'show.usef_rating', 
                        {
                        required: false
                        })
                    }
                    id='usef_rating'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    type={'text'}
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.USEFRATING'})}
                    />
                </div>
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor='ec_event_id' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.ECEVENTID">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.ECEVENTID'})}</label>

                    <div className='col-lg-3' >
                        <Controller
                            control={methods.control}
                            name="show.ec_event_id"
                            render={({ field: { onChange, name, value } }) => (
                            <NumericFormat
                                id="ec_event_id"
                                value={ value === 0 ? "" : Number(value)}
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                type="text"
                                name={name}
                                decimalScale={0}
                                valueIsNumericString={true} // Allow only numeric input
                                allowNegative={false} // Optionally, disallow negative values
                                    onValueChange={(e)=>{
                                        onChange(e.value)
                                }}
                            />
                            )}
                        />
                    </div>

                    { hasSpecialPermissionToAccess('cloud_sgadmin:has_access') &&
                        <div className='col-lg-1 d-flex align-items-center justify-content-start px-0' >
                            <i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: '#578EBE' }}  onClick ={(e) =>{e.preventDefault(); ec_event_lookup_data()}}></i>
                        </div>
                    }

                    <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='ec_rating' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.ECRATING">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.ECRATING'})}</label>

                    <div className='col-lg-3'>
                    <Controller
                        name="show.ec_rating"
                        id="ec_rating"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                            
                        <Select
                            options = {ECMembershipLevelOptions}
                            value = {ECMembershipLevelOptions.find((z) => z.value === value) ?? ""}
                            name={name}
                            isSearchable={true}
                            onChange={e => onChange(e.value)}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                            placeholder="Select"
                        />    
                        )}
                    />
                    </div>
                    
                    {/* <div className='col-auto'>
                    { hasSpecialPermissionToAccess('cloud_sgadmin:has_access') &&
                        <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 px-3' type='button' onClick={(e)=>{e.preventDefault(); ec_event_lookup_data()}}
                        >
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.BUTTON.GETSHOWINFO'})}
                        </button>
                    }
                    </div> */}
                
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor="fei_show_code" data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.FEISHOWCODE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FEISHOWCODE'})}</label>


                    <div className='col-lg-4 '>
                    <input
                        {...methods.register( 'show.fei_show_code', 
                            {
                            required: false
                            })
                        }
                        id='fei_show_code'
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1' 
                        type={'text'}
                        // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FEISHOWCODE'})}
                    />
                    </div>
                                    
                    <div className='col-auto'>
                        <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 px-4' type='button' onClick={() => importFeiResultsData()} data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.BUTTON.IMPORTSHOW">
                            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.BUTTON.IMPORTSHOW'})}
                        </button>
                    </div>
                    {/* <div className='col-auto'>
                        <button className='btn btn-sm btn-secondary fw-bold h-30px py-1 px-4' type='button' onClick={() => setFEICredentialDialog(true)}  data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.BUTTON.UPDATEFEICREDENTIALS">
                            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.BUTTON.UPDATEFEICREDENTIALS'})}
                        </button>
                    </div> */}
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor='fei_venue' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.FEISHOWVENUE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FEISHOWVENUE'})}</label>

                <div className='col-lg-4 '>
                <input
                    {...methods.register( 'show.fei_venue', 
                        {
                        required: false
                        })
                    }
                    id='fei_venue'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    type={'text'}
                    disabled={true}
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.FEISHOWVENUE'})}
                />
                </div>
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='starting_class_number' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.STARTINGCLASSNO">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.STARTINGCLASS'})}</label>

                <div className='col-lg-4 '>
                 <Controller
                    control={methods.control}
                    name="show.starting_class_number"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="starting_class_number"
                        value={Number(value)}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // Optionally, disallow negative values
                            onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                    />
                    )}
                 />
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='starting_entry_number' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.STARTINGENTRYNO">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.STARTINGENTRY'})}</label>

                <div className='col-lg-3'>
                 <Controller
                    control={methods.control}
                    name="show.starting_entry_number"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="starting_entry_number"
                        value={Number(value)}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                            onValueChange={(e)=>{
                                onChange(e.value)
                                onLoadSetRange()
                        }}
                    />
                    )}
                 />
                </div>
            </div>

            {/* Max rider limit and show tax id */}
            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label fs-5 py-1' htmlFor='max_rider_limit' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.MAXENTRYRIDERS">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.MAXENTRYRIDERS'})}</label>

                <div className='col-lg-4 '>

                 <Controller
                    control={methods.control}
                    name="show.max_rider_limit"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="max_rider_limit"
                        value={Number(value)}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                            onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                    />
                    )}
                 />
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='show_tax_id' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SHOWTAXID">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWTAXID'})}</label>
                <div className='col-lg-3'>
                    <Controller
                        control={methods.control}
                        name="show.show_tax_id"
                        render={({ field: { onChange, value } }) => (
                            <PatternFormat
                                id='show_tax_id'
                                format="##-#############"   // Adding format to the input
                                value={value == "" ? "" : Number(value)}
                                onValueChange={(e) => onChange(e.value)}
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            />
                        )}
                    />
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='start_time_default' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SHOWDEFAULTSTARTTIME">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.STARTTIME'})}</label>

                <div className='col-lg-4 '>
                    <div className="input-group date">
                    <Controller
                        name="show.start_time_default"
                        render={({
                            field: { onChange, value, name },
                        }) => (

                        <Flatpickr 
                            className="form-control form-control-sm  fs-6 min-h-20px py-1"
                            id = 'start_time_default'
                            name = {name}
                            autoComplete={"off"}
                            value={value && value !== "00:00:00" ? value : null}
                            placeholder="Select Time"
                            options={time_options_12hours}
                            onClose={(value) => setTime(value, onChange)}
                            onChange={(value) => setTime(value, onChange)}
                        />
                        )}
                    />

                        <div className="input-group-append">
                            <label htmlFor='start_time_default' className='date-label'
                                   onClick={(e) => {
                                    e.stopPropagation(); 
                                    document.getElementById('start_time_default')._flatpickr.open(); 
                                   }}
                            >
                                <span className="input-group-text">
                                    <i className="la la-clock-o"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='ring_count_default'>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.RINGS'})}</label>

                <div className='col-lg-3'>
                 <Controller
                    control={methods.control}
                    name="show.ring_count_default"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="ring_count_default"
                        value={Number(value)}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                        onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                    />
                    )}
                 />
                </div> */}
            </div>

            <div className='row mb-3'>
                <div className='col-lg-4 d-flex align-items-center'>

                <div className='form-check-sm form-check-custom  me-2'>
                    <input
                        {...methods.register( 'show.require_rp_signature', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='require_rp_signature'
                    />
                </div>
                <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='require_rp_signature' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.REQUIRERPSIGNATURE"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.REQUIRERPSIGNATURE'})}</label>
                </div>

                <div className='col-lg-4 d-flex align-items-center'>
                <div className='form-check-sm form-check-custom  me-2'>
                    <input
                        {...methods.register( 'show.require_web_entry_stall', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='require_web_entry_stall'
                    />
                </div>
                <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='require_web_entry_stall' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.REQUIREENTRYSTALL"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.REQUIREENTRYSTALL'})}</label>
                </div>
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='phone' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SHOWPHONE">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWPHONE'})}</label>

                <div className='col-lg-4 '>
                    <Controller
                        control={methods.control}
                        name="show.phone"
                        render={({ field: { onChange, value} }) => (
                            <IntlTelInput
                                id='phone'
                                inputClassName='form-control fs-6py-0'
                                containerClassName='intl-tel-input country-code-picker'
                                style={{ width: '100%', borderRadius: '0px' }}
                                preferredCountries={["us", "ca"]}
                                allowDropdown={true}
                                placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWPHONE'})}
                                value={PhoneNumberFormatter(value)}
                                defaultCountry={parsePhoneNumber(`+${methods.getValues('show.phone_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                    const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                    onChange(unformattedValue)
                                    if(_countryData.dialCode){
                                        methods.setValue('show.phone_country_code', _countryData.dialCode, { shouldDirty: true  })
                                    }
                                    if(unformattedValue.length < 10){
                                        methods.setError('show.phone',{
                                            type: "custom",
                                            message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                        })
                                    }
                                    else{
                                        methods.clearErrors('show.phone')
                                    }
                                }}
                                onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('show.phone_country_code', country.dialCode, { shouldDirty: true})}}}
                            />
                        )}
                    />
                    { methods.formState.errors?.show?.phone && <div><span className='error_message'>{methods.formState.errors.show.phone?.message}</span></div>}
                </div>

                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='fax' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SHOWFAX">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWFAX'})}</label>

                <div className='col-lg-3'>
                    <Controller
                        control={methods.control}
                        name="show.fax"
                        render={({ field: { onChange, value} }) => (
                            <IntlTelInput
                                id='fax'
                                inputClassName='form-control fs-6 py-0'
                                containerClassName='intl-tel-input country-code-picker'
                                style={{ width: '100%', borderRadius: '0px' }}
                                preferredCountries={["us", "ca"]}
                                defaultCountry={parsePhoneNumber(`+${methods.getValues('show.fax_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                                allowDropdown={true}
                                placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SHOWFAX'})}
                                value={PhoneNumberFormatter(value)}
                                onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                    const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                    onChange(unformattedValue)
                                    if(_countryData.dialCode){
                                        methods.setValue('show.fax_country_code', _countryData.dialCode, { shouldDirty: true  })
                                    }
                                    if(unformattedValue.length < 10){
                                        methods.setError('show.fax',{
                                            type: "custom",
                                            message: intl.formatMessage({id: 'FORM.INPUT.ALL.FAX.VALIDATION.MESSAGE'})
                                        })
                                    }
                                    else{
                                        methods.clearErrors('show.fax')
                                    }
                                }}
                                onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('show.fax_country_code', country.dialCode, { shouldDirty: true})}}}
                            />
                        )}
                    />
                    { methods.formState.errors?.show?.fax && <div><span className='error_message'>{methods.formState.errors.show.fax?.message}</span></div>}
                </div>
            </div>


            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor="email_address" data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.EMAILADDRESS">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.EMAILADDRESS'})}</label>

                <div className='col-lg-9 '>
                <input
                    {...methods.register( 'show.email_address', 
                        {
                        required: false
                        })
                    }
                    id="email_address"
                    type='text'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1 readonly'
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.EMAILADDRESS'})}
                />
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor="secretary_name" data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.SECRETARYNAME">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SECRETARYNAME'})}</label>

                <div className='col-lg-9 '>
                <input
                    {...methods.register( 'show.secretary_name', 
                        {
                        required: false
                        })
                    }
                    id="secretary_name"
                    type='text'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1 readonly'
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SECRETARYNAME'})}
                />
                </div>
            </div>

            <div className='row mb-2'>
                <label className='col-lg-3 col-form-label   fs-5 py-1' htmlFor="manager_name" data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.MANAGERNAME">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.MANAGERNAME'})}</label>

                <div className='col-lg-9 '>
                <input
                    {...methods.register( 'show.manager_name', 
                        {
                        required: false
                        })
                    }
                    id="manager_name"
                    type='text'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1 readonly'
                    // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.MANAGERNAME'})}
                />
                </div>
            </div>

            <div className='row mb-2'>
                {/* <div className='col-lg-6 d-flex align-items-center me-2'>
                    <div className='form-check-sm form-check-custom  me-2'>
                        <input
                            {...methods.register( 'show.no_ssn_for_pmr', 
                                {
                                required: false
                                })
                            }
                            className='form-check-input'
                            type='checkbox'
                            id='no_ssn_for_pmr'
                        />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='no_ssn_for_pmr' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.LABEL.DONTREQUIRESSNFORPRIZEMONEY"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.DONTREQUIRE'})}</label>
                </div> */}

                <div className='col-lg-5 d-flex align-items-center'>
                    <button className='btn btn-sm btn-secondary fw-bold h-30px py-1' type='button' onClick={(e)=> { importShow();e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.BUTTON.IMPORT"
                    >
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.BUTTON.IMPORT'})}
                    </button>
                </div>
            </div>
            
            </div>{/* End Column 1 */}


            <div className='col-lg-5 '>{/* Column 2 */}
            <fieldset className='mb-3'>
                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GROUPLABEL.OFFICIALS'})} <b></b></legend>
                {/* <div style={{display: "flex", justifyContent: 'right', paddingRight: '90px'}}>{rowData !== undefined ? rowData.length : ""}</div> */}
                <div className='row mb-2'>
                    <div className='col-lg-10 d-flex align-items-center mb-2'>
                            <OutputListingGridStatic area ={"Shows - Judges"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} onRowDoubleClicked={updateOfficialType}></OutputListingGridStatic>
                    </div>

                    <div className='col-lg-2'>
                        <button className="btn btn-sm btn-secondary me-2 fw-bold px-2 py-2" type='button' onClick={(e)=>setShowAddOfficialModal(true)} data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.SECTION.OFFICIALS.BUTTON.ADD">
                            <i className="fas fa-plus fs-5 px-1 py-3"></i>
                        </button>
                        <div className="separator my-1 border-transparent d-xl-block d-lg-block d-md-none d-sm-none d-none"></div>
                        <button className='btn btn-sm btn-secondary me-2 fw-bold px-2 py-2' type='button' onClick={(e)=> { deleteSelectedShowJudge();e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.SECTION.OFFICIALS.BUTTON.REMOVE">
                            <i className='fas fa-minus fs-5 px-1 py-3'></i>
                        </button>
                    </div>
                </div>
            </fieldset>


            <fieldset>
                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GROUPLABEL.REQUIREDMEMBERSHIPS'})} <b></b></legend>

                <div className='row mb-2'>
                <div className='col-lg-12 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                    <input
                        {...methods.register( 'show.require_usefmembership', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='require_usefmembership'
                    />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='require_usefmembership' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.SECTION.REQUIREDMEMBERSHIPS.LABEL.UNITEDSTATESEQUESTRIANMEMBERSHIPS"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.USEQUESTRIANFEDERATION'})}</label>
                </div>
                </div>

                <div className='row mb-2'>
                <div className='col-lg-12 d-flex align-items-center'>
                    <div className='form-check-sm form-check-custom  me-2'>
                    <input
                        {...methods.register( 'show.require_ecmembership', 
                            {
                            required: false
                            })
                        }
                        className='form-check-input'
                        type='checkbox'
                        id='require_ecmembership'
                    />
                    </div>
                    <label className='col-form-label  fs-5 py-1 ignore-max-width' htmlFor='require_ecmembership' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.SECTION.REQUIREDMEMBERSHIPS.LABEL.EQUINECANADA"> {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.EQUINECANADA'})}</label>
                </div>
                </div>

                <div className='row mb-2'>
                <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='require_membership_type' data-tooltip-id="SHOW.DETAIL.TAB.GENERAL.SECTION.REQUIREDMEMBERSHIPS.LABEL.REQUIREDTYPE">
                    <span className=''>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.REQUIREDTYPE'})}</span>
                </label>

                <div className='col-lg-6 '>
                {
                    props.form_meta.membership_type && 
                    <Controller
                        name="show.require_membership_type"
                        render={({
                            field: { onChange, value, name },
                        }) => (
                        <Select
                            placeholder="Select"
                            options = {props.form_meta.membership_type}
                            value = {props.form_meta.membership_type.find((ss) => ss.value === value) ?? ""}
                            name={name}
                            isDisabled = {!methods.watch('show.require_ecmembership')}
                            id="require_membership_type"
                            isSearchable={true}
                            onChange={(membership_type) => {
                                onChange(membership_type.value);
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                            })}
                            styles={reactSelectStyles}
                        />    
                        )}
                    />
                }
                </div>
                </div>

            </fieldset>

            <fieldset className='mt-3'>
                <legend className="fs-5 fw-bold d-flex mb-3">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.GROUPLABEL.SHOWSPONSOR'})} <b></b></legend>

                <div className='row mb-2 mt-2'>
                    <label className='col-lg-3 col-form-label  fs-5 py-1' htmlFor='facility_id'>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.SELECTSPONSOR'})}</label>

                    <div className='col-lg-6 '>
                        {props.form_meta.sponsors && 
                            <Controller
                                control={methods.control}
                                name="show.sponsor_id"
                                id="sponsor_id"
                                render={({ field: { onChange, value, name } }) => (
                                    <Select
                                        options={props.form_meta.sponsors.map(sponsor => ({
                                            value: sponsor.sponsor_id,
                                            label: sponsor.name
                                        }))}
                                        value={props.form_meta.sponsors.map(sponsor => ({
                                            value: sponsor.sponsor_id,
                                            label: sponsor.name
                                        })).find((sponsor) => sponsor.value === value)}
                                        name={name}
                                        isSearchable={true}
                                        onChange={(selectedOption) => {
                                            onChange(selectedOption.value);
                                            setSelectedSponsor(props.form_meta.sponsors.find(sponsor => sponsor.sponsor_id === selectedOption.value));
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        styles={reactSelectStyles}
                                        menuPlacement="auto"
                                    />
                                )}
                            />
                        }
                        {selectedSponsor && selectedSponsor.sponsor_logo && (
                            <div style={{ marginTop: '15px' }}>
                                <a href={selectedSponsor.sponsor_site_url} target="_blank" rel="noopener noreferrer">
                                    <img src={selectedSponsor.sponsor_logo} alt={selectedSponsor.name} style={{ maxWidth: '300px', maxHeight: '150px'}} />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </fieldset>

            </div> {/* END Column 2 */} 

        </div> {/* End Column Group */}
        {(showImportModal) &&
            <ImportShow show={showImportModal} handleClose={() => { setShowImportModal(false)}} showData={props.showData} updateImportCount={props.updateImportCount} facility_id={methods.getValues('show.facility_id')}/>
        }
        {(showAddOfficialModal) && // Display dialog to add new official
            <AddNewOfficial show={showAddOfficialModal} handleClose={() => {methods.setValue('rto_search_for_officials', '');setShowAddOfficialModal(false)}}/>
        }
        {(showUpdateOfficialTypeModal) && //Display dialog to update type for show officials
            <UpdateOfficialTypeModal show={showUpdateOfficialTypeModal} handleClose={() => {setOfficialToBeUpdated(null); setShowUpdateOfficialTypeModal(false)}} data={officialToBeUpdated}/>
        }
    </>
    )
  }
  
export {GeneralTab}