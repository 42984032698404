import { createBrowserHistory } from "history";
import { customConfirm } from "./customConfirmDialog";
const history = createBrowserHistory()

// Keep track of all active blocks
let activeBlocks = new Set();

export const handlePrompt = (promptMessage = 'You have unsaved changes. Are you sure you want to leave this page?') => {
    let allowNavigation = false;
    
    const funcRef = history.block(tx => {
        if (allowNavigation || (tx.location.state && tx.location.state.allowNavigation)) {
            if (!allowNavigation) {
                allowNavigation = true;
                history.replace(history.location.pathname, null);
            }
            // Clear all blocks when navigation is allowed
            activeBlocks.forEach(block => block());
            activeBlocks.clear();
            tx.retry();
        } else {
            sessionStorage.setItem("modal_is_open", JSON.stringify(true));
            Promise.resolve(customConfirm(promptMessage)).then((value) => {
                sessionStorage.removeItem("modal_is_open");
                if (value) {
                    // Clear all blocks when user confirms
                    activeBlocks.forEach(block => block());
                    activeBlocks.clear();
                    tx.retry();
                }
            });
        }
    });

    // Add this block to our set
    activeBlocks.add(funcRef);
    return funcRef;
};

export default history;