import { useMemo, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form'
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAlert, useConfirm, useFlashAlert, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import { useLoadReportAndPrint } from '../../../reports/ReportTypeModal';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import useFileDownloader from '../../../../modules/hooks/use-file-downloader';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { checkSocialOrFederalId, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions';
import TransferWithholdingTax from './TransferWithholdingTax';
import ReverseTaxPaymentToIRS from './ReverseTaxPaymentsToIRS';
import { DistributionsDetailForm } from './DistributionsDetailForm';
import SelectRenderer from './SelectRenderer';
import useAccessChecker from '../../../../modules/hooks/use-access-checker';
import { useSearchParams } from 'react-router-dom';
import {RowSelector, DEFAULT_VISIBLE_ROWS} from '../../../../modules/output-listing/OutputListingRowOptions';

const AccountRegister = (props) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const methods = useFormContext()
    const currency = intl.formatMessage({ id: 'CURRENCY' })
    const [gridRef, setGridRef] = useState();
    const [gridApi, setGridApi] = useState();
    const [totalPayable, setTotalPayable] = useState(0);
    const [accountRegisterBalance, setAccountRegisterBalance] = useState(0);
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const show_financials_locked = useAppSelector(state => state.currentShow.islocked);
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const {progressBar} = useProgress()
    const { getEventID } = useOutputContext();
    const loadingOverlay = useLoadingOverlay();
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    let flashAlert = useFlashAlert()
    const { loadReportsAndPrint } = useLoadReportAndPrint();
    const [displayTransferWithholdingTax, setDisplayTransferWithholdingTax] = useState(false)
    const [displayReverseTaxPaymentToIRS, setDisplayReverseTaxPaymentToIRS] = useState(false)
    const [displayDistributionDetailForm, setDisplayDistributionDetailForm] = useState(false)
    const [selectedAccountRegister, setSelectedAccountRegister] = useState()
    const [selectedPendingPayments, setSelectedPendingPayments] = useState(null)
    const { hasSpecialPermissionToAccess } = useAccessChecker();
    const [searchParams, setSearchParams] = useSearchParams();

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const [pendingPaymentsVisibleRows, setPendingPaymentsVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    const [rowDataOne, setRowDataOne] = useState([]);
    const [rowDataTwo, setRowDataTwo] = useState([]);
    /* WA - Row Selector */

    let debugModeOn = searchParams.get("debug")

    useEffect(() => {
       if(gridRef && methods.watch('accountRegisters')){
            calculateAccountRegisterBalance()
       }
    }, [gridRef, methods.watch('accountRegisters')]);

    useEffect(() => {
        if(gridApi && methods.watch('pendingBatchPayments')){
             calculatePendingBatchPaymentBalance()
        }
     }, [gridApi, methods.watch('pendingBatchPayments')]);

    let calculateAccountRegisterBalance = () => {
        let accountingRegisters = methods.getValues('accountRegisters')
        let balance = 0
        let accountRegisters = accountingRegisters.map((accountingRegister) =>{
            balance = balance + accountingRegister['amount']
            accountingRegister['balance'] = balance
            return accountingRegister
        })
        setAccountRegisterBalance(balance)
        gridRef.setRowData(accountRegisters)
        setRowDataOne(accountRegisters) // WA - Row Selector
    }

    let calculatePendingBatchPaymentBalance = () => {
        let pendingBatchPayments = methods.getValues('pendingBatchPayments')
        let balance = 0
        let BatchPayments = pendingBatchPayments.map((pendingBatchPayment) =>{
            balance = balance + pendingBatchPayment['amount']
            pendingBatchPayment['balance'] = balance
            return pendingBatchPayment
        })
        setTotalPayable(balance)
        gridApi.setRowData(BatchPayments)
        setRowDataTwo(BatchPayments) // WA - Row Selector
    }

    const columnDefs = [
        { field: 'Entry.number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.ENTRY' })},
        { field: 'Show.show_name', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SHOW' }), width: 400,
        valueGetter: params => {
            if(params.data.Show){
                let date = new Date(params.data.Show.end_date)
                return params.data.Show.show_name.concat(' [',date.getFullYear(),']')
            }
        }
    },
        { field: 'Entry.horse', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.HORSE' }), width: 200},
        { field: 'arentry_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DATE' }),  cellStyle: {textAlign: 'center'} ,cellRenderer: DateRenderer },
        { field: 'type', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.TYPE' }), width: 150},
        { field: 'check_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.REFERENCE' }) },
        { field: 'description', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DESCRIPTION' }), width: 200 },
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.AMOUNT' }), valueFormatter: params => params.data?.amount.toFixed(2),  cellStyle: {textAlign: 'right'},  cellRenderer: params => currencyFormatter(params.value)  },
        { field: 'balance', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BALANCE' }), valueFormatter: params => params.data?.balance.toFixed(2),  cellRenderer: params => currencyFormatter(params.value),  cellStyle: {textAlign: 'right'}  },
    ]

    // display used and available column for sgAdmin in debug mode only
    const isUserSgAdmin = hasSpecialPermissionToAccess('cloud_sgadmin:has_access');
    if(isUserSgAdmin && debugModeOn == '1'){
        columnDefs.push({ field: 'available', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.AVAILABLE' }), valueFormatter: params => !params.data?.used && !params.data?.available ? '' : `${currencyFormatter(params.data?.available)}`,  cellStyle: {textAlign: 'center'}, flex: 1  })
        columnDefs.push({ field: 'used', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.USED' }), valueFormatter: params => !params.data?.used && !params.data?.available ? '' : `${currencyFormatter(params.data?.used)}`,  cellStyle: {textAlign: 'center'}, flex: 1  },)
    }

    // Update payment_type, check number and description for the pending payments
    const updatePendingBatchItems = (payment_batchitems_id, value, type) => {
        let pendingBatchPayments = methods.getValues('pendingBatchPayments')
        for(let pendingBatchPayment of pendingBatchPayments){
            if(pendingBatchPayment.payment_batchitems_id === payment_batchitems_id){
                pendingBatchPayment[type] = value
            }
        }
        methods.setValue('pendingBatchPayments', pendingBatchPayments)
    }

    const onCellEditingStopped = (params) => {
        const {data, colDef, newValue, oldValue} = params
        // update batch item only if data is changed
        if(newValue !== oldValue){
            updatePendingBatchItems(data.payment_batchitems_id, colDef.field)
        }
    }  

    const pendingPaymentColumnDefs = [
        { field: 'batch_id', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BATCH' }), },
        { field: 'Show.show_name', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SHOW' }),},
        { field: 'check_number', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.CHECK' }), editable: true,},
        { field: 'payment_type', width: 150, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.TYPE' }), cellRenderer : params => SelectRenderer(params, updatePendingBatchItems, methods.getValues('pendingBatchPayments')) },
        { field: 'description', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.DESCRIPTION' }), editable: true,},
        { field: 'amount', headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.PAYABLE' }), cellRenderer: params => currencyFormatter(params.value), flex: 1},
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '250px' }), []);

    // Print invoice
    const printInvoice = async () => {
        // Get record ids for fetching data
        const peopleEntries = methods.getValues('peopleEntries');
        const peopleId = methods.getValues('people.people_id')
        let entryIds = peopleEntries.map(entry => entry.entry_id);
        // Get unique
        entryIds = [...new Set(entryIds)];

        // Do not print if there are no entries
        if (entryIds.length <= 0) {
            return;
        }

        loadingOverlay({ show:true, message: `Loading Report...`})

        // Post request to get report details
        await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
            pref_name: 'Default_Invoice',
            customer_id: customer_id,
            show_id: currentShowID, 
            params: {ids: entryIds, people_id: peopleId}
        })
        .then(async (response) => {
            if (response?.data?.success) {
                const report = response?.data?.report;
                report.report_definition = JSON.parse(report?.report_definition);

                // Load report to ARJS and then print
                await loadReportsAndPrint(report);
            }
            loadingOverlay({show: false})
        })
        .catch(() => {
            loadingOverlay({show: false})
        })

    }

    // Export entries (exports a csv file) 
    const handleExportEntries = async () => {
        // Get people id and its entries
        const peopleId = methods.getValues('people.people_id');
        const peopleName = methods.getValues('people.fl_name'); // Used for downloaded csv file name

        const peopleEntries = methods.getValues('peopleEntries');
        const entryIds = peopleEntries.map(entry => entry.entry_id);

        const settings = {
            url: 'people/exportEntries',
            progressBarTitle: 'Export Entries'
        }

        // Generate Event Id for progress bar and display progress bar and open event stream one
        const progressEventId = getEventID(settings.progressBarTitle) 
        progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: settings.progressBarTitle, timeElapsed: true })

        // Generate Event Id for file buffer response and open event stream two, to receive files from server
        const fileBufferEventId = getEventID(`${settings.progressBarTitle}-filebuffer`)
        startFileDownloader(fileBufferEventId)

        // Send post request to download csv file
        await axios.post(`${process.env.REACT_APP_NEST_API_URL}/${settings.url}`, {
            customer_id: customerId,
            current_show_id: currentShowId,
            people_id: peopleId,
            people_name: peopleName,
            record_ids: entryIds,
            progress_event_id: progressEventId,
            file_buffer_event_id: fileBufferEventId
        })
        .then(() => progressBar({ show: false, eventID: progressEventId }))
        .catch(() =>  {
            progressBar({ show: false, eventID: progressEventId })
            forceCloseFileDownloader()   
        })
    }

    const onCellDoubleClick = async (params) => {
        await axios.get( process.env.REACT_APP_NEST_API_URL + '/accounting/getAccountRegisterDetails', {
            params: {
                accountingregister_id: params?.data?.accountingregister_id,
                customer_id: customer_id
            }
        }).then((response) => {
            if(response?.data?.success){
                setSelectedAccountRegister({
                    ...params.data, 
                    distributions: response?.data?.distributions, 
                    payments: response?.data?.payments, 
                    reversals: response?.data?.reversals
                })
                setDisplayDistributionDetailForm(true)
            }
        })
    }

    const postPendingPaymentsToAccount = (event) => {
        let paymentBatchItemsToBePosted = gridApi?.getSelectedRows()
        if (paymentBatchItemsToBePosted?.length > 0) {
            let batchItems = [paymentBatchItemsToBePosted[0].payment_batchitems_id]
            confirmDialog({
                message: intl.formatMessage({ id: "FORM.INPUT.RTO.TAB.POST.PAYMENTS.CONFIRM.MESSAGE.POSTTOACCOUNT" })
            }).then((choice) => {
                if (choice) {
                    loadingSpinnerBtnWait(event)
                    axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/postPendingPayments', {
                        customer_id: customerId,
                        payment_batch_items_ids: batchItems,
                        show_id: paymentBatchItemsToBePosted[0].show_id,
                        batch_id: paymentBatchItemsToBePosted[0].batch_id,
                        update_batch_item_data: true,
                        check_number: paymentBatchItemsToBePosted[0].check_number,
                        description: paymentBatchItemsToBePosted[0].description,
                        payment_type: paymentBatchItemsToBePosted[0].payment_type
                    }).then(response => {
                        if(response.data.success){
                            // Display Flash Message
                            flashAlert({ type: 'success', message: ` ${batchItems.length} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.FLASHMESSAGE.POSTTOACCOUNT" })}` });
                            // refresh RTO data
                            props.updateCallbackFromParent()
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => {
                        loadingSpinnerBtnRelease(event)
                    })
                }
            });
        } else {
            alertDialog({
                message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR.POSTTOACCOUNT' })
            });
        }
    };

    const handleOnRowSelected = (event) => {
        let selectedRow = event?.api?.getSelectedRows()
        if (selectedRow?.length > 0) {
            setSelectedPendingPayments(selectedRow[0]);
        }else{
            setSelectedPendingPayments(null);
        }
    };

    const deletedSelectedPendingPayments = (event) => {
        let paymentBatchItemsToBeDeleted = gridApi?.getSelectedRows()
        let batchItems = paymentBatchItemsToBeDeleted.map((payment) => { return payment.payment_batchitems_id })
        if(paymentBatchItemsToBeDeleted?.length > 0){
            confirmDialog({ message: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.CONFIRM.MESSAGE.DELETE"}) +" "+ paymentBatchItemsToBeDeleted.length +" selected payment"+(paymentBatchItemsToBeDeleted?.length > 1 ? 's' : '')+` ? This action can't be undone.`})
            .then((choice) => {
                if(choice){
                    loadingSpinnerBtnWait(event)
                    axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/deletePendingPayments', {
                        customer_id: customerId,
                        payment_batch_items_ids: batchItems,
                        payment_batch_id: paymentBatchItemsToBeDeleted[0]?.batch_id
                    }).then(response => {
                        if(response.data.success){
                            // Display Flash Message
                            flashAlert({ type: 'success', message: ` ${batchItems.length} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.FLASHMESSAGE.DELETE" })}` });
                            // Refresh RTO
                            props.updateCallbackFromParent()
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => {
                        loadingSpinnerBtnRelease(event)
                    })
                }
            })

        }else{
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR.NO.PAYMENT.SELECTED.DELETE' })})
        }
    }

    return (
        <>
            { displayTransferWithholdingTax && <TransferWithholdingTax show={displayTransferWithholdingTax} handleClose={() => setDisplayTransferWithholdingTax(false)} updateCallbackFromParent={props.updateCallbackFromParent}/>}
            { displayReverseTaxPaymentToIRS && <ReverseTaxPaymentToIRS show={displayReverseTaxPaymentToIRS} handleClose={() => setDisplayReverseTaxPaymentToIRS(false)} updateCallbackFromParent={props.updateCallbackFromParent}/>}
            { displayDistributionDetailForm && <DistributionsDetailForm show={displayDistributionDetailForm} handleClose={() => setDisplayDistributionDetailForm(false)} selectedAccountRegister={selectedAccountRegister}/>}
            <div className='form-group'>
                <div className='d-flex'>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.LABEL.ENTRIESBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ENTRIESBALANCE' }) } : { currencyFormatter(methods.getValues('rtosTotalBalances.entriesBalance'))}</label>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.LABEL.ACCOUNTREGISTERBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTERBALANCE' }) } : { currencyFormatter(methods.getValues('rtosTotalBalances.accountRegisterBalance'))}</label>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.LABEL.ACCOUNTBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTBALANCE' }) } : { currencyFormatter(methods.getValues('rtosTotalBalances.accountBalance'))}</label>
                    <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.LABEL.WITHHOLDINGTAX">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.WITHHOLDINGTAX' }) } : { currencyFormatter(methods.getValues('rtosTotalBalances.payableWithholdingTax'))}</label>
                </div>
                {/* <p className='mb-1 text-end'>Total: {methods.getValues('entries') && methods.getValues('entries').length}</p> */}
                <div className='form-group mb-2'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}> {/* WA - Row Selector */}
                        <RowSelector
                        value={visibleRows}
                        onChange={setVisibleRows}
                        area ={"People - All Account Register"}
                        />
                    </div>
                    <div className='row align-items-center mb-2'>
                        <OutputListingGridStatic area ={"People - All Account Register"} columnDefs={columnDefs} setGridRef={setGridRef} rowData={rowDataOne} containerStyle={containerStyle} onCellDoubleClick={onCellDoubleClick} visibleRows={visibleRows}></OutputListingGridStatic>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col'>
                        
                        {/* <button type='button' className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.ADDPAYMENT' }) }
                        </button> */}
                        {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                        <button type='button' disabled={methods.getValues('people.people_id') <= 0} className="mx-0 my-2 btn btn-sm btn-secondary fw-bold text-uppercase" onClick={() => setDisplayTransferWithholdingTax(true)} data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.BUTTON.TRANSFER/PAYWHTAX">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.TRANSFERWHTAX' }) }
                        </button>
                        {/* TODO: Whoever implements this needs to add show_financials_locked in disabled condition because we cannot perform operation when show.islocked field is enabled */}
                        <button type='button' disabled={methods.getValues('people.people_id') <= 0} className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase" onClick={() => setDisplayReverseTaxPaymentToIRS(true)} data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.BUTTON.REVERSEIRSPAYMENTS">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.REVERSEIRSPAYMENTS' }) }
                        </button>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                        <label className='col fw-bold fs-5 pe-2 text-end' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.LABEL.ACCOUNTREGISTERBALANCE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.LABEL.ACCOUNTREGISTERBALANCE' }) } {currencyFormatter(accountRegisterBalance)}</label>  
                    </div>
                </div>
                {
                    (!checkSocialOrFederalId(methods.getValues("people"))) ?
                    <div className='row mb-2'>
                        <div className='col'></div>
                        <div className='col-3 d-flex align-items-center'>
                            <label className='col fw-bold fs-5 pe-2 text-end text-danger'>{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.SSN.ERROR' }) } </label>  
                        </div>
                    </div> : null
                }
                <fieldset>
                <legend className="fs-5 fw-bold d-flex">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.PENDINGPAYMENTS' })} <b></b></legend>
                <div className='form-group mb-2'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}> {/* WA - Row Selector */}
                        <RowSelector
                        value={pendingPaymentsVisibleRows}
                        onChange={setPendingPaymentsVisibleRows}
                        area ={"People - Account Register 2"}
                        />
                    </div>
                    <div className='row align-items-center mb-2'>
                        <OutputListingGridStatic area ={"People - Account Register 2"} setGridRef={setGridApi} columnDefs={pendingPaymentColumnDefs} rowData={rowDataTwo} containerStyle={containerStyle} stopEditingWhenCellsLoseFocus={true} stopEditing={onCellEditingStopped} onRowSelected={handleOnRowSelected} visibleRows={pendingPaymentsVisibleRows}></OutputListingGridStatic>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col'>
                        
                        <button type='button' className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.SECTION.PENDINGPAYMENTS.BUTTON.POSTTOACCOUNT" onClick={(e) => postPendingPaymentsToAccount(e)} disabled={selectedPendingPayments === null}>
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.POSTTOCHECK' }) }
                        </button>
                        
                        <button type='button' className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.SECTION.PENDINGPAYMENTS.BUTTON.DELETE" onClick={(e) => deletedSelectedPendingPayments(e)} disabled={selectedPendingPayments === null}>
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.DELETE' }) }
                        </button>
                        <button disabled={!methods.getValues('peopleEntries') || methods.getValues('peopleEntries').map(entry => entry.entry_id)?.length==0}  onClick={() => printInvoice()} type='button' className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.SECTION.PENDINGPAYMENTS.BUTTON.PRINTINVOICE">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.PRINTINVOICE' }) }
                        </button>
                        <button type='button' onClick={() => handleExportEntries()} className="m-2 btn btn-sm btn-secondary fw-bold text-uppercase" data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.SECTION.PENDINGPAYMENTS.BUTTON.EXPORTENTRIES">
                            { intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.BUTTON.EXPORTENTRIES' }) }
                        </button>
                    </div>
                    <div className='col-3 d-flex align-items-center'>
                        <label className='col fw-bold fs-5 pe-2 text-end' data-tooltip-id="PEOPLE.DETAIL.TAB.ACCOUNT.SECTION.ACCOUNTREGISTER.SECTION.PENDINGPAYMENTS.LABEL.TOTALPAYABLE">{ intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ACCOUNT.TAB.ACCOUNTREGISTER.LABEL.TOTALPAYABLE' }) } {currencyFormatter(totalPayable)}</label>  
                    </div>
                </div>
                </fieldset>
            </div>
        </>
    );
}

export { AccountRegister }