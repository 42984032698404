import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { Controller } from "react-hook-form";
import Select from "react-select";
import { reactSelectStyles } from '../../../modules/sgl-utils/fieldControls'
import { useEffect } from 'react';
import { useAlert, useConfirm } from '../../../modules/sgl-utils/DialogsProvider';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { RowSelector, DEFAULT_VISIBLE_ROWS } from '../../../modules/output-listing/OutputListingRowOptions';


const ClassRulesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const [gridRef, setGridRef] = useState();
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const [rowData, setRowData] = useState([]);
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);

    const columnDefs = [
        { field: 'rule', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.RULES' }), cellStyle: params => { if (params?.data?.class_rule_type == "Max Class Size") { return {color: 'var(--kt-primary)' }; } return null; }},
        { field: 'class_rule_type', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.TYPE' }), cellStyle: params => { if (params?.data?.class_rule_type == "Max Class Size") { return {color: 'var(--kt-primary)' }; } return null; }},
        { field: 'class_rule_attributes', flex: 1, headerName: intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.ATTRIBUTES' }), cellStyle: params => { if (params?.data?.class_rule_type == "Max Class Size") { return {color: 'var(--kt-primary)' }; } return null; }},
    ]

    useEffect(() => {
        if(gridRef){
            const filteredRules = methods.getValues('class_rules')?.filter(cr => !cr.isDeleted) || [];
            setRowData(filteredRules);
            gridRef.setRowData(filteredRules);
        }
    },[gridRef, methods.watch('class_rules')]);

    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);

    const deleteRule = async () => {
        // get selected row to delete
        var selectedRow = gridRef.getSelectedRows()
        if(selectedRow.length > 0){

            const choice = await confirmDialog({message: `Remove ${selectedRow[0].rule} rule? Fees added by the rule must be removed manually from Entries. This can not be undone.`})
            if(choice){
                let class_rules = methods.getValues('class_rules')

                // find the class rule in added_rules array
                let index = class_rules.findIndex(cr => cr.class_rule_id == selectedRow[0].class_rule_id)
                if(class_rules[index].isAdded){
                    // if the class rule is found in array remove it from the array
                    class_rules.splice(index,1)
                }
                else{
                    class_rules[index].isDeleted = true
                }

                methods.setValue("class_rules", class_rules)
            }
        }
    }

    const addRule = async (class_rule) => {
        
        let formattedClassRules = props.form_meta.formattedClassRules
        let rule_rec = formattedClassRules?.find(cr => cr.class_rule_id == class_rule.value)

        // Rule to add in added rules array
        let rule = {
            rule: class_rule.label, 
            class_rule_id: class_rule.value,
            class_rule_type_id: class_rule.class_rule_type_id, 
            effective_end_date: "0000-00-00",
            effective_start_date: "0000-00-00",
            effective_start_time: "00:00:00",
            effective_end_time: "00:00:00",
            isAdded: true,
            ClassRulexclasses: [{ class_rule_id: class_rule.value }],
            class_rule_type: rule_rec && rule_rec.class_rule_type?rule_rec.class_rule_type:'',
            class_rule_attributes: rule_rec && rule_rec.class_rule_attributes?rule_rec.class_rule_attributes:'',
        }

        let class_rules = methods.getValues('class_rules')
        // Find if the class_rule is in deleted_rules array
        let index = class_rules.findIndex(index => index.class_rule_id == rule.class_rule_id)
        console.log(index)

        if(index < 0 || class_rules[index].isDeleted){
            if(class_rules && class_rules.length > 0){
                let rule_type_ids = class_rules.filter(cr => !cr.isDeleted).map(r => r.class_rule_type_id)
                let existing_rule_type_ids = rule_type_ids.join(',')
                let class_data = methods.getValues('classes')

                // calling API to get winning num entry
                let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/classes/validateClassRuleToAdd', {
                    params: {
                        // class_id: class_data.class_id,
                        // show_id: class_data.show_id,
                        customer_id: class_data.customer_id,
                        class_rule_id: class_rule.value,
                        existing_rule_type_ids
                    },
                })
                if(response?.data?.success){
                    if(index >= 0){
                        class_rules[index].isDeleted = false
                    } else {
                        class_rules.push(rule)
                    }
                } else {
                    alertDialog({message: response?.data?.error})
                }
            } else {
                class_rules.push(rule)
            }
        }

        

        methods.setValue("class_rules", class_rules)
    }

    return (
        <>
            <div className='form-group mb-2'>
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 pe-0 w-80px' htmlFor='max_size' data-tooltip-id="CLASS.DETAIL.TAB.CLASSRULES.LABEL.MAXSIZE">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.MAXSIZE' })}</label>
                    <div className='col-lg-1 px-0 w-80px'>
                        <Controller
                            control={methods.control}
                            name="classes.max_size"
                            render={({ field: { onChange, name, value } }) => (
                                <NumericFormat
                                    id='max_size'
                                    name={name}
                                    value={Number(value)}
                                    decimalScale={0}
                                    allowNegative={false}
                                    disabled={methods.getValues('classes.combined_class')}
                                    onValueChange={(e) => {
                                    onChange(e.value)
                                    }}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                />
                            )}
                        />
                    </div>
                    <label className='col-lg-2 col-form-label fs-5 py-1 text-end w-175px align-self-center' htmlFor='CURRENTTRIPCOUNT' data-tooltip-id="CLASS.DETAIL.TAB.CLASSRULES.LABEL.CURRENTTRIPCOUNT">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.CURRENTTRIPCOUNT' })}</label>
                    <label className='col-lg-1 col-form-label align-self-center fs-5 py-1 w-75px'>{methods.getValues('entryxclasses')?methods.getValues('entryxclasses').length:0}</label>
                </div>
                <div className="clearfix mb-2">
                    <p className='d-inline-block mb-0 pt-4 fs-6'>
                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.RULESDESCRIPTION' })}
                    </p>
                    <button type="button" className="btn btn-sm btn-secondary fw-bold text-uppercase float-end" onClick={() => deleteRule()} data-tooltip-id="CLASS.DETAIL.TAB.CLASSRULES.BUTTON.DELETE">
                        {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.DELETE' })}
                    </button>
                </div>
                <div className='row mb-2'>
                    <div className='col-lg-12 align-items-center mb-2'>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}> {/* WA - Row Selector */}
                            <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Classes - ClassRule"} />
                        </div> {/* WA - Row Selector */}
                        <OutputListingGridStatic  area ={"Classes - ClassRule"} setGridRef ={setGridRef} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic>
                    </div>
                </div>
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 pe-0 w-80px' htmlFor='ADDRULE' data-tooltip-id="CLASS.DETAIL.TAB.CLASSRULES.LABEL.ADDRULE">{intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.CLASSRULES.ADDRULE' })}</label>
                    <div className='col-lg-3 px-0 w-300px'>
                         {
                            props.form_meta.class_rules && 
                            <Controller
                                name="ADDRULE"
                                render={({
                                    field: { onChange, value, name },
                                }) => (
                                <Select
                                    options = {props.form_meta.class_rules}
                                    value = {props.form_meta.class_rules.find((ss) => ss.value === value)}
                                    // set dropdown position to top - no space below
                                    menuPlacement="top"
                                    id = "ADDRULE"
                                    name={name}
                                    isSearchable={true}
                                    onChange={(class_rules) => {
                                        addRule(class_rules)
                                        onChange(class_rules.value);
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder="Select"
                                    isDisabled={methods.getValues('classes.combined_class')}
                                />    
                                )}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { ClassRulesTab }