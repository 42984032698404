import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic';
import { useFormContext } from 'react-hook-form'
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer';
import { DEFAULT_VISIBLE_ROWS, RowSelector } from '../../../modules/output-listing/OutputListingRowOptions';

const EntriesTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()

    const columnDefs = [
        { field: 'number', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.ENTRY' }) },
        { field: 'Show.show_name', width: 300, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.SHOWNAME' }),  },
        { field: 'Show.start_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.STARTDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
        { field: 'Show.end_date', headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.ENDDATE' }), cellClass: 'text-center', cellRenderer: DateRenderer },
        { field: 'trainer', width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.TRAINER' }),  },
        { field: 'owner',  width: 200, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.OWNER' }),  },
        { field: 'rider_list', width: 300,  headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ENTRIES.RIDERLIST' }),  },
    ]

    const rowData = methods.getValues('entries')
    const containerStyle = useMemo(() => ({ width: '100%', height: '60vh' }), []);

    /* WA - Row Selector */
    const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS);
    /* WA - Row Selector */
    
    return (
        <>
            <div className='form-group'>
                <div className="d-flex justify-content-end align-items-end"> {/* WA - Row Selector */}
                    <p className='me-6 py-0 my-0'>Total: {methods.getValues('entries') && methods.getValues('entries').length}</p>
                    <div>
                        <RowSelector value={visibleRows} onChange={setVisibleRows} area = {"Horses - Entries"}/>
                    </div>
                </div>                    
                <div className='row align-items-center mb-2'>
                    <OutputListingGridStatic onRowDoubleClicked={(row_data) => {props.callbackFromParent(row_data.entry_id, "Entries", "EntryDetail", row_data)}} area ={"Horses - Entries"} columnDefs={columnDefs} rowData={rowData} containerStyle={containerStyle} visibleRows={visibleRows}></OutputListingGridStatic> {/* WA - Row Selector */}
                </div>
            </div>
        </>
    );
}

export { EntriesTab }