import { customSessionExpired } from '../../sgl-utils/customSessionExpiredDialog';
import {AuthModel} from './_models'
import Cookies from 'js-cookie';
import axiosRetry from 'axios-retry';
import history from '../../sgl-utils/unstableHistory';

const AUTH_LOCAL_STORAGE_KEY = 'sgl-auth-react-cloud'
const AUTH_COOKIE_KEY = 'sglcloud'
const CURRENT_COMPANY_LOCAL_STORAGE_KEY = 'currentCompany'
export const SGL_CLOUD_REFERER = "sgl-cloud-referer"
const SGL_BROWSER_TOKEN = "browser_token"
const {PUBLIC_URL} = process.env

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return getCookieAuth()
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    return getCookieAuth()
  }
}

const getCookieAuth = () => {

  // do not use cookie auth
  let sglcloudcookie = ''//Cookies.get(AUTH_COOKIE_KEY)

  if ( sglcloudcookie ) {
    let auth = {
      api_token: sglcloudcookie
    }
    if (localStorage) {
      const lsValue = JSON.stringify(auth)
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    }
    return auth
  } else {
    return
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    if (!localStorage.getItem(SGL_BROWSER_TOKEN)) {
      localStorage.setItem(SGL_BROWSER_TOKEN,  `${Math.random().toString(36).substring(7)}-${JSON.parse(lsValue).id}`)
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  Cookies.remove(AUTH_COOKIE_KEY, { path: '/', domain: '.showgroundslive.com' })
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(SGL_BROWSER_TOKEN)
    
    // clear current selected company
    localStorage.removeItem(CURRENT_COMPANY_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// Function to generate or retrieve a unique tab identifier
function getTabIdentifier() {
  if (!sessionStorage.getItem('tabIdentifier')) {
    // Generate a unique tab ID on first access
    const tabId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
    sessionStorage.setItem('tabIdentifier', tabId);
  }
  return sessionStorage.getItem('tabIdentifier');
}

// Function to get browser memory usage data in MBs
function getPerformanceMetrics() {
  if (performance.memory) {
    const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = performance.memory;

    // Convert bytes to megabytes
    const toMB = (bytes: number) => (bytes / 1024 / 1024).toFixed(2); // Keep 2 decimal places

    return {
      usedJSHeapSize: toMB(usedJSHeapSize),
      totalJSHeapSize: toMB(totalJSHeapSize),
      jsHeapSizeLimit: toMB(jsHeapSizeLimit),
    };
  }

  return null; // Return null if `performance.memory` is not supported
}

export function setupAxios(axios: any) {
  // const customer_id = useAppSelector(state=> state.showCompany.company_id);

  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.common['SGL-REQUEST-ORIGIN'] = 'SGL-API';
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';
  axios.defaults.headers.common['Pragma'] = 'no-cache';
  axios.defaults.headers.common['Expires'] = '0';
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string , CustomReferer: string, XTabIdentifier:string|null, XMemoryUsage: string }, params: {customer_id: string}}) => {
      const auth = getAuth()
      const tabIdentifier = getTabIdentifier();
      const memoryData = getPerformanceMetrics();
  
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      config.headers.CustomReferer = window.location.href

      // Add unique tab ID
      config.headers.XTabIdentifier = tabIdentifier;

      // Add memory usage data (if available) to headers or request params
      if (memoryData) {
        config.headers.XMemoryUsage = JSON.stringify(memoryData); // Alternatively, append to `config.params`
      }

      config.params = config.params || {};
      // config.params['customer_id'] = customer_id.toString();

      return config
    },
    (err: any) => Promise.reject(err)
  )

  // Unhandled exception occurred
  axios.interceptors.response.use(
    (response: any) => {
      // Handling response when refresh token is expired. Show logged out dialog.
      if(response.data?.status == 401 && response.data?.message == "refresh_token_expired" ) {
        setSglCloudReferer()
        if(!response.config.url.includes('/auth/verify_token') && !response.config.url.includes('/auth/refreshmytokens')){
          customSessionExpired()
        }
      }
      return response
    },
    (error: any) => {
      if (error.response && error.response.status === 500) {
        // Render Error500 page if status is 500 - INTERNAL SERVER ERROR
        history.push(`${PUBLIC_URL}/error/500`, { allowNavigation: true }) 
        // window.location.href = PUBLIC_URL + '/error/500';
      }

      return Promise.reject(error);
    }
  );

  axiosRetry(axios, {
    retries: 1, // number of retries
    retryCondition: (error: any) => {
        if (error.response?.status === 401 && error.response?.data?.message == "token_expired") {
          return updateTokens(axios) // update tokens
        } else {
          return false
        }
    },
  });
}

const setSglCloudReferer = () => {
    if (!sessionStorage) return

    const pathname = window.location.pathname
    sessionStorage.setItem(SGL_CLOUD_REFERER, JSON.stringify({ pathname }))
} 

const updateTokens = (axios: any): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let authModel = getAuth()
    if (authModel?.refreshToken) {
      axios.post(process.env.REACT_APP_NEST_API_URL + '/auth/refreshmytokens', {
        refreshToken: authModel.refreshToken
      })
        .then((response: any) => {
          if (response.data.success) {
            // TOKEN REFRESH SUCCESSFUL
            setAuth(response.data.authInfo) // update auth modal containing updated tokens
          } else {
            // TOKEN REFRESH FAILED
            removeAuth()
          }
          resolve(true)

        }).catch((error: any) => {
          reject(error); // Error occurred during token refresh
        });
    } else {
      reject(new Error("No refresh token available"));
    }
  })
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
